import { IConfigsType } from '../../types'

const burgerworksInventory: IConfigsType = {
  burgerworks: {
    '/:brand/corporate_inventory': {
      variables: [
        'corporateGroup',
        {
          type: 'items',
          initialSelection: ['1', 'category-1'],
          categoriesOnly: true,
          root: 'EXPO_COGS_CATEGORY_ROOT',
        },
      ],
      groupFilter: {
        api: 'corporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
        startDate: '2024-12-26',
      },
      title: {
        type: 'title',
        title: 'COGS Tracker',
      },
      kpis: {
        type: 'list',
        api: 'burgerworksListItemMetricValues',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'actualCogs',
            },
            bottom: 'Actual $',
          },
          {
            content: {
              key: 'idealCogs',
            },
            bottom: 'Ideal $',
          },
          {
            content: {
              key: 'actualCogsPercent',
            },
            bottom: 'Actual %',
          },
          {
            content: {
              key: 'idealCogsPercent',
            },
            bottom: 'Ideal %',
          },
          {
            content: {
              key: 'cogsVariance',
              color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
            },
            bottom: 'Variance $',
          },
          {
            content: {
              key: 'cogsVariancePercent',
              color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
            },
            bottom: 'Variance %',
          },
        ],
      },
      categoryFilter: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Inventory',
          },
          columns: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Category',
          },
        },
      },
      breakdownByStore: {
        type: 'table-v2',
        api: 'burgerworksLocationGroupInventory',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        row: {
          pinning: {
            top: ['summary'],
          },
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: true,
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'actualCogs',
            title: 'Actual $',
            sortable: true,
            unit: 'dollar',
            width: '120px',
          },
          {
            key: 'idealCogs',
            title: 'Ideal $',
            sortable: true,
            unit: 'dollar',
            width: '120px',
          },
          {
            key: 'actualCogsPercent',
            title: 'Actual %',
            sortable: true,
            width: '120px',
          },
          {
            key: 'idealCogsPercent',
            title: 'Ideal %',
            sortable: true,
            width: '120px',
          },
          {
            key: 'cogsVariance',
            title: 'Variance $',
            sortable: true,
            unit: 'dollar',
            width: '120px',
            color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
          },
          {
            key: 'cogsVariancePercent',
            title: 'Variance %',
            width: '120px',
            sortable: true,
            color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
          },
        ],
      },
    },
    '/:brand/inventory': {
      variables: [
        {
          type: 'items',
          initialSelection: ['1', 'category-1'],
          categoriesOnly: true,
          root: 'EXPO_COGS_CATEGORY_ROOT',
        },
        { type: 'inputValue', fields: ['itemSearch'] },
      ],
      inventoryTitle: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      groupFilter: {
        api: 'locationFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
        startDate: '2024-12-26',
      },
      expoAi: {
        type: 'ai-summary-box',
        api: 'locationPurchaseAiSummary',
      },
      purchaseTitle: {
        type: 'title',
        title: 'Purchases',
      },
      categoryFilter: {
        type: 'grid',
        children: {
          categorySelect: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Category',
          },
          itemSearch: {
            type: 'input',
            value: 'inputValue.itemSearch.value',
            onChange: 'inputValue.itemSearch.onChange',
            label: 'Item',
            placeholder: 'Add ";" to search multiple items',
            width: '325px',
          },
        },
      },
      purchaseOppCostTable: {
        type: 'table-v2',
        api: 'locationPurchase',
        pagination: { pageSize: 20 },
        sorting: [
          {
            id: 'cogsVariance',
            desc: true,
          },
        ],
        row: {
          pinning: {
            bottom: ['summary'],
          },
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            width: '250px',
            sortable: true,
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
            width: '175px',
            sortable: true,
          },
          {
            key: 'actualCogs',
            title: 'Actual COGS',
            align: 'center',
            sortable: true,
            unit: 'dollar',
            decimal: 2,
          },
          {
            key: 'idealCogs',
            title: 'Ideal Cogs',
            align: 'center',
            sortable: true,
            unit: 'dollar',
            decimal: 2,
          },
          {
            key: 'cogsVariance',
            title: 'Opportunity Cost',
            align: 'center',
            sortable: true,
            unit: 'dollar',
            color: 'black',
            decimal: 1,
            background: {
              range: [0, 100],
              colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
              value: '<%- values?.cogsVarianceHeatMap %>',
            },
          },
        ],
      },
    },
  },
}

export default burgerworksInventory
