import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import useDateRange from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import { getDateRangeLabelV2 } from 'pared/utils/date'

import useLocationMetricTrendsQuery, {
  IGroupByType,
  IMetricType,
  format,
  useCalculateStartDate,
} from '../../../hooks/useLocationMetricTrendsQuery'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IListLocationGroupsDataType {
  listLocationGroups: {
    nodes: {
      locationId: number
      code: string
      name: string
      locationGroupId: number
      locationGroupName: string
      locationGroupType: string
    }[]
  }
}

const query = gql`
  query ListLocationGroups(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroups(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        code
        name
        locationGroupId
        locationGroupName
        locationGroupType
      }
    }
  }
`

const useRegionMetricTrendsData = (options: {
  metrics?: IMetricType[]
  metricGroups?: IMetricType[]
}) => {
  const { variables } = useVariables()
  const dateRange = (variables.lastTimeRange?.value[0][0] ||
    'last_14_days') as IGroupByType
  const { dateRangeGroupBy } = useDateRange(dateRange)
  const { startDate, endDate } = useCalculateStartDate(dateRange)
  const {
    data: locationMetricTrendsData,
    loading: isLocationMetricTrendsLoading,
  } = useLocationMetricTrendsQuery({
    ...options,
    groupFilterTypes: ['listLocationGroup'],
    dateRange: dateRange,
    fields: ['metricData'],
    handler: (data) => {
      return {
        variables: {
          ...data,
          iGroupBy: dateRangeGroupBy,
          iFilter: {
            ...data.iFilter,
            location_group_types: ['Region'],
          },
          isLocationGroup: true,
        },
        skip:
          !data.iStartDate ||
          !data.iEndDate ||
          ((data.iFilter.metrics || []).length === 0 &&
            (data.iFilter.metric_groups || []).length === 0),
      }
    },
  })
  const { data: locationGroupsData, loading: isLocationGroupsLoading } =
    useQuery<IListLocationGroupsDataType>(query, {
      variables: {
        iStartDate: startDate?.format('YYYY-MM-DD'),
        iEndDate: endDate?.format('YYYY-MM-DD'),
        iFilter: {
          location_group_types: ['Region'],
          bypass_row_level_security: true,
        },
      },
      skip: !startDate || !endDate,
    })

  return {
    data: useMemo((): IApiDataType => {
      const locationGroups = locationGroupsData?.listLocationGroups?.nodes
      if (!locationMetricTrendsData || !locationGroups || !dateRangeGroupBy)
        return null

      const locationGroupsMap = locationGroups.reduce((res, l) => {
        const key = l.locationGroupId
        return res[key] ? res : { ...res, [key]: l.locationGroupName }
      }, {} as { [locationGroupId: number]: string })

      return locationMetricTrendsData.map((d) => ({
        ...format(d.metricData),
        locationGroupId: d.locationGroupId,
        locationGroupType: 'Region',
        locationGroupName: locationGroupsMap[d.locationGroupId],
        date: getDateRangeLabelV2(dateRangeGroupBy, d),
      }))
    }, [dateRangeGroupBy, locationMetricTrendsData, locationGroupsData]),
    loading: isLocationMetricTrendsLoading || isLocationGroupsLoading,
  }
}

export default useRegionMetricTrendsData
