import Pagination from '@mui/material/Pagination'
import {
  RowPinningState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'

import { useVariables } from '../variables'
import Dnd, { IPropsType as IDndPropsType } from './Dnd'
import Row, { IPropsType as IRowPropsType } from './Row'
import useApi, { IApiKeyType, configs } from './hooks/useApi'
import useColumns, { Header, IColumnsOptionsType } from './hooks/useColumns'
import useData from './hooks/useData'
import useTableState, { ITableStateOptionType } from './hooks/useTableState'

export interface IPropsType<K extends IApiKeyType = IApiKeyType>
  extends ITableStateOptionType<K> {
  type: 'table-v2'
  api: K
  row?: Omit<IRowPropsType['row'], 'index'> & {
    pinning?: RowPinningState
  }
  columns: IColumnsOptionsType<K>
  pagination?: boolean | { pageSize: number }
  emptyText?: string
  margin?: string
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const StyledTable = styled.table<{ margin?: string }>`
  border-collapse: collapse;
  ${({ margin }) => (!margin ? '' : `margin: ${margin};`)}

  tbody {
    counter-reset: rowNumber;
  }

  tbody tr {
    counter-increment: rowNumber;
  }

  tbody tr td.rowIndex::before {
    content: counter(rowNumber);
  }
`

const StyledPagination = styled(Pagination)`
  button {
    font-family: Lexend-Regular;

    &.Mui-selected,
    &:hover {
      background: initial;
      border: 1px black solid;
    }
  }
`

const StyledText = styled.div`
  font-family: Lexend-Regular;
  font-weight: 400;
  margin-top: 5px;
`

const Table = ({
  api,
  row,
  columns: columnsSource,
  sorting,
  expanded,
  pagination,
  emptyText,
  margin,
}: IPropsType) => {
  const { data: dataSource, loading, loadMore } = useApi(api)
  const data = useData(dataSource)
  const { initialState, state, ...tableConfig } = useTableState(
    data,
    Boolean(!loading && dataSource),
    {
      sorting,
      expanded,
    },
  )
  const { tableRef, columns } = useColumns(
    configs[api],
    columnsSource,
    loadMore,
  )
  const rowPinning = useMemo(() => {
    const ids = dataSource?.map((d) => d.id)
    const rowPinningSource = row?.pinning || {
      bottom: ['summary'],
    }

    return {
      top: rowPinningSource.top?.filter((r) => ids?.includes(r)),
      bottom: rowPinningSource.bottom?.filter((r) => ids?.includes(r)),
    }
  }, [row, dataSource])
  const table = useReactTable({
    ...tableConfig,
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: !pagination ? undefined : getPaginationRowModel(),
    getSubRows: (row) => row.subRows,
    getRowId: (row) => row.id as string,
    initialState: {
      ...initialState,
      pagination: !pagination
        ? undefined
        : {
            pageIndex: 0,
            pageSize: pagination === true ? 20 : pagination.pageSize,
          },
    },
    state: {
      ...state,
      rowPinning,
    },
    autoResetPageIndex: false,
  })
  const { pageSize, pageIndex } = table.getState().pagination
  const paginationCount = Math.ceil(
    table.getFilteredRowModel().rows.length / pageSize,
  )
  const { variables } = useVariables()
  const onDrop = useMemo(
    () =>
      (row?.onDrop && _.get(variables, row.onDrop)) as IDndPropsType['onDrop'],
    [row, variables],
  )
  const dataIds = useMemo(
    () => table.getCenterRows().map((r) => r.id),
    [data, table, state],
  )

  if (dataSource && dataSource.length === 0)
    return <StyledText>{emptyText ? emptyText : 'No Data Found.'}</StyledText>

  return (
    <Dnd dataIds={dataIds} table={table} onDrop={onDrop}>
      <Spin spinning={loading}>
        <StyledTable ref={tableRef} margin={margin}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {(() => {
                  if (!onDrop) return null

                  if (headerGroup.headers[0]?.isPlaceholder) return <th />

                  return <Header />
                })()}

                {headerGroup.headers.map((header) => (
                  <React.Fragment key={header.id}>
                    {header.isPlaceholder ? (
                      <th colSpan={header.colSpan} />
                    ) : (
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )
                    )}
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {(['getTopRows', 'getCenterRows', 'getBottomRows'] as const).map(
              (key) => (
                <React.Fragment key={key}>
                  {table[key]().map((rowData, index) => (
                    <Row
                      key={rowData.id}
                      row={{
                        ...row,
                        index,
                      }}
                      table={table}
                      rowData={rowData}
                    />
                  ))}
                </React.Fragment>
              ),
            )}
          </tbody>
        </StyledTable>
      </Spin>

      {!pagination || paginationCount <= 1 ? null : (
        <StyledPagination
          count={paginationCount}
          page={pageIndex + 1}
          onChange={(_, page) => {
            table.setPageIndex(page - 1)
          }}
          variant="text"
          shape="rounded"
        />
      )}
    </Dnd>
  )
}

export default Table
