import { useDndContext } from '@dnd-kit/core'
import { PublicContextDescriptor } from '@dnd-kit/core/dist/store'
import { Row, Table } from '@tanstack/react-table'
import React, { useMemo } from 'react'

import { IDataType } from './hooks/useData'

export interface IPropsType {
  dataIds: string[]
  offsetLeft: number
  table: Table<IDataType>
  children: React.ReactNode
}

interface IDragMoveContextType {
  x: number
  isFirstItem: boolean
  depth: number
  data: Row<IDataType>
}

export const DragMoveContext = React.createContext<null | IDragMoveContextType>(
  null,
)
export const NullContext = React.createContext<null | IDragMoveContextType>(
  null,
)

const calcX = (table: IPropsType['table'], rem: number, depth: number) =>
  // same as hooks/useColumns.tsx paddingLeft
  58 + 10 + (table.getCanSomeRowsExpand() ? depth * 2 + 1.5 : 0) * rem

const getLastRow = (row: Row<IDataType>): Row<IDataType> =>
  row.getIsExpanded() && row.subRows.length !== 0
    ? getLastRow(row.subRows[row.subRows.length - 1])
    : row

const findNextRow = (
  table: Table<IDataType>,
  row: Row<IDataType>,
  id: string,
): Row<IDataType> => {
  const parentRow = row.getParentRow()
  const parentRows = parentRow?.subRows || table.getCenterRows()
  const nextRow = parentRows.find((p) => p.id === id)

  if (parentRow) return findNextRow(table, parentRow, id)

  if (!nextRow) throw new Error(`Couldn't find the row: ${id}`)

  return nextRow
}

export const calcDepth = ({
  active,
  over,
  offsetLeft,
  dataIds,
  table,
}: Omit<IPropsType, 'children'> &
  Pick<
    PublicContextDescriptor,
    'active' | 'over'
  >): null | IDragMoveContextType => {
  const activeData = active?.data.current as Row<IDataType> | null
  const overData = over?.data.current as Row<IDataType> | null

  if (!activeData || !overData) return null

  const activeIndex = dataIds.findIndex((d) => d === active?.id)
  const overIndex = dataIds.findIndex((d) => d === over?.id)
  const parentRows = overData.getParentRow()?.subRows || table.getCenterRows()

  const row = (() => {
    // move down
    if (activeIndex < overIndex) return overData

    // move up and not move to the first item
    if (overData.index !== 0) return getLastRow(parentRows[overData.index - 1])

    // move to the first item
    return overData.getParentRow() || table.getCenterRows()[0]
  })()
  const rowIndex = dataIds.findIndex((r) => r === row.id)
  const nextRowOffset = activeIndex === overIndex ? 2 : 1
  const nextRowId =
    rowIndex >= dataIds.length - nextRowOffset
      ? dataIds[dataIds.length - nextRowOffset]
      : dataIds[rowIndex + nextRowOffset]
  const nextRow = findNextRow(table, row, nextRowId)

  const rowDepth =
    row.original.isFrozen ||
    (!row.original.disableSubRows &&
      (!row.getIsExpanded() || row.subRows.length === 0))
      ? row.depth + 1
      : row.depth
  const nextRowDepth = nextRow.original.isFrozen
    ? nextRow.depth + 1
    : nextRow.depth
  const maxDepth = rowDepth > nextRowDepth ? rowDepth : nextRowDepth
  let minDepth = rowDepth < nextRowDepth ? rowDepth : nextRowDepth
  const isFirstItemInSameDepth =
    nextRow.index === 0 ||
    (activeIndex === overIndex &&
      overData.depth === nextRow.depth &&
      nextRow.index === 1)

  if (isFirstItemInSameDepth && nextRow.getParentRow()?.id === row.id)
    minDepth = maxDepth

  const rem = parseFloat(getComputedStyle(document.documentElement).fontSize)
  const currentLeft = calcX(table, rem, row.depth)
  const depth = ((diff) => {
    const depthDiff = Math.floor(diff / 2 / rem)

    if (minDepth + depthDiff > maxDepth) return maxDepth

    if (depthDiff < 0) return minDepth

    return minDepth + depthDiff
  })(offsetLeft - currentLeft)

  return {
    x: calcX(table, rem, depth),
    isFirstItem:
      isFirstItemInSameDepth ||
      (!row.original.disableSubRows &&
        !row.getIsExpanded() &&
        row.subRows.length === 0),
    depth,
    data:
      activeIndex === overIndex && activeData.depth === depth ? overData : row,
  }
}

const DragMoveContextProvider = ({ children, ...props }: IPropsType) => {
  const context = useDndContext()
  const data = useMemo(
    () =>
      calcDepth({
        ...props,
        ...context,
      }),
    [context, props],
  )

  return (
    <DragMoveContext.Provider value={data}>{children}</DragMoveContext.Provider>
  )
}

export default DragMoveContextProvider
