import { IConfigsType } from '../../types'

const bibibopRankingsConfig: IConfigsType = {
  bibibop: {
    '/:brand/rankings': {
      variables: [
        'corporateGroup',
        {
          type: 'rankings',
          defaultValue: ['financials', 'netSales'],
          options: [
            // financials
            { id: 'financials', parentId: 'root', displayName: 'Financials' },
            {
              id: 'netSales',
              parentId: 'financials',
              displayName: 'Net Sales',
              info: {
                type: 'price',
                fields: [
                  {
                    key: 'price0',
                    title: 'Net Sales',
                    metricCode: 'netSales',
                  },
                ],
              },
            },
            {
              id: 'daypart',
              parentId: 'financials',
              displayName: 'Daypart',
              info: {
                type: 'daypart',
                fields: [
                  {
                    key: 'price0',
                    title: 'Lunch',
                    metricCode: 'dayPartSalesLunch',
                  },
                  {
                    key: 'price1',
                    title: 'Dinner',
                    metricCode: 'dayPartSalesDinner',
                  },
                  {
                    key: 'price2',
                    title: 'After Dinner',
                    metricCode: 'dayPartSalesAfterDinner',
                  },
                  {
                    key: 'price3',
                    title: 'Non-Peak',
                    metricCode: 'dayPartSalesNonPeak',
                  },
                  {
                    key: 'price4',
                    title: 'Prep',
                    metricCode: 'dayPartSalesPrep',
                  },
                ],
              },
            },
            {
              id: 'sss',
              parentId: 'financials',
              displayName: 'SSS',
              info: {
                type: 'price + percent',
                fields: [
                  {
                    key: 'price0',
                    title: '$ SSS',
                    metricCode: 'netSalesSssVariance',
                  },
                  {
                    key: 'percent0',
                    title: 'SSS%',
                    metricCode: 'netSalesSssPercent',
                  },
                ],
              },
            },

            // loss prevention
            {
              id: 'lossPrevention',
              parentId: 'root',
              displayName: 'Loss Prevention',
            },
            {
              id: 'discounts',
              parentId: 'lossPrevention',
              displayName: 'Discounts',
              info: {
                type: 'price + percent',
                fields: [
                  {
                    key: 'price0',
                    title: '$',
                    metricCode: 'discount',
                  },
                  {
                    key: 'percent0',
                    title: '% of Sales',
                    metricCode: 'discountPercent',
                  },
                ],
              },
            },
            {
              id: 'cashOverOrShort',
              parentId: 'lossPrevention',
              displayName: 'Cash +/-',
              info: {
                type: 'price',
                fields: [
                  {
                    key: 'price0',
                    title: '$',
                    metricCode: 'cash_over_or_short',
                  },
                ],
              },
            },

            // guest
            { id: 'guest', parentId: 'root', displayName: 'Guest' },
            {
              id: 'yext',
              parentId: 'guest',
              displayName: 'Yext Rating',
              info: {
                type: 'count + number',
                fields: [
                  {
                    key: 'number0',
                    title: 'Rationg #',
                    metricCode: 'yextReviewCount',
                  },
                  {
                    key: 'number1',
                    title: 'Average Rating',
                    metricCode: 'yextReviewAverageStars',
                  },
                ],
              },
            },

            // delivery
            { id: 'delivery', parentId: 'root', displayName: 'Delivery' },
            {
              id: 'deliverySales',
              parentId: 'delivery',
              displayName: 'Delivery Sales',
              info: {
                type: 'price',
                fields: [
                  {
                    key: 'price0',
                    title: '$',
                    metricCode: 'delivery',
                  },
                ],
              },
            },
            {
              id: 'orderWithAnyIssues',
              parentId: 'delivery',
              displayName: '% Order w / Any Issues',
              info: {
                type: 'percent',
                fields: [
                  {
                    key: 'percent0',
                    title: '% Orders w/ Any Issues',
                    metricCode: 'deliveryOrderWithAnyIssuePercentDoordash',
                  },
                ],
              },
            },
            {
              id: 'combinedStarRating',
              parentId: 'delivery',
              displayName: 'Combined Star Rating',
              info: {
                type: 'number',
                fields: [
                  {
                    key: 'number0',
                    title: 'Combined Star Rating',
                    metricCode: 'deliveryAvgCustomerReviewScoreDoordash',
                  },
                ],
              },
            },
          ],
        },
      ],
      groupFilter: {
        api: 'bbbCorporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
        ],
      },
      title: {
        type: 'title',
        title: 'Rankings',
      },
      filterBy: {
        type: 'select',
        value: 'rankings.value',
        onChange: 'rankings.onChange',
        options: 'rankings.options',
        label: 'Rank By',
      },
      downloadRankings: {
        type: 'download-csv',
        api: 'bbbRankings',
        fileName: 'Ranking',
        message: 'Download Ranking as CSV',
        fields: [
          {
            key: 'rowIndex',
            title: 'Rank',
          },
          'groupInfo',

          // type = price
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "price" %>',
          },

          // type = percent
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "percent" %>',
          },

          // type = number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "number" %>',
          },

          // type = count
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "count" %>',
          },

          // type = count + number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "count + number" %>',
          },
          {
            key: 'number1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "count + number" %>',
          },

          // type = price + percent
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "price + percent" %>',
          },
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "price + percent" %>',
          },

          // type = daypart
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
          {
            key: 'price1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
          {
            key: 'price2',
            title: '<%- rankings.info?.fields[2].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
          {
            key: 'price3',
            title: '<%- rankings.info?.fields[3].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
          {
            key: 'price4',
            title: '<%- rankings.info?.fields[4].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
          },
        ],
      },
      rankings: {
        type: 'table-v2',
        api: 'bbbRankings',
        columns: [
          {
            key: 'rowIndex',
            title: 'Rank',
            align: 'center',
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
          },

          // type = price
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "price" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },

          // type = percent
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "percent" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },

          // type = number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "number" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },

          // type = count
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "count" %>',
            align: 'center',
            sortable: true,
          },

          // type = count + number
          {
            key: 'number0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "count + number" %>',
            align: 'center',
            sortable: true,
          },
          {
            key: 'number1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "count + number" %>',
            align: 'center',
            sortable: true,
            decimal: 1,
          },

          // type = price + percent
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "price + percent" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'percent0',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "price + percent" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },

          // type = daypart
          {
            key: 'price0',
            title: '<%- rankings.info?.fields[0].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'price1',
            title: '<%- rankings.info?.fields[1].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'price2',
            title: '<%- rankings.info?.fields[2].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'price3',
            title: '<%- rankings.info?.fields[3].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
          {
            key: 'price4',
            title: '<%- rankings.info?.fields[4].title %>',
            hide: '<%- rankings.info?.type !== "daypart" %>',
            decimal: 2,
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default bibibopRankingsConfig
