import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { getBrandId } from 'pared/utils/brand'

interface IGetNormalizationStatusNode {
  status: {
    latestNormalizedDate: string | null
    currentNormalizingDate: string | null
    isNormalizingNewerDate: boolean
  }
}

interface IGetNormalizationStatus {
  getNormalizationStatus: {
    nodes: IGetNormalizationStatusNode[]
  }
}

interface IGetNormalizationStatusVariables {
  iNormalizationTable: string
  iBrandId: number
}

const GET_NORMALIZATION_STATUS = gql`
  query GetNormalizationStatus($iNormalizationTable: String!, $iBrandId: Int!) {
    getNormalizationStatus(
      iNormalizationTable: $iNormalizationTable
      iBrandId: $iBrandId
    ) {
      nodes {
        status
      }
    }
  }
`

const useNormalizationStatus = () => {
  const brandId = getBrandId()
  const { data, loading } = useQuery<
    IGetNormalizationStatus,
    IGetNormalizationStatusVariables
  >(GET_NORMALIZATION_STATUS, {
    variables: {
      iNormalizationTable: 'location_normalized_data_daily',
      iBrandId: brandId,
    },
    skip: !brandId,
  })

  return {
    data: useMemo((): string[] | null => {
      const status = data?.getNormalizationStatus.nodes?.[0]?.status
      if (!status) return null

      const latestNormalizedDate = status.latestNormalizedDate
      return latestNormalizedDate
        ? [
            `Updated: ${moment
              .utc(latestNormalizedDate, 'YYYY-MM-DD', true)
              .add(1, 'day')
              .format('M/D')}`,
          ]
        : null
    }, [data]),
    loading,
  }
}

export default useNormalizationStatus
