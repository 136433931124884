import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

export interface IOptionType {
  manageType: 'editLocation'
  locationId: number
  locationName: string
  locationOpenedAt: string
  autoUpdateLocationGroupMapping: {
    locationGroupMappingId: number
    startDateOffset?: number
    endDateOffset?: number
  }[]
}

export interface IDataType {
  title: string
  value: moment.Moment | null
  onChange: (value: IDataType['value']) => void
  loading: boolean
  submit: () => Promise<void>
}

const mutate = gql`
  mutation EditLocation(
    $locationId: Int!
    $formValues: JSON!
    $locationGroupMappings: JSON!
  ) {
    editLocation(
      input: { iLocationId: $locationId, iFormValues: $formValues }
    ) {
      clientMutationId
    }

    updateLocationGroupMappingList(
      input: { iLocationGroupMappings: $locationGroupMappings }
    ) {
      clientMutationId
    }
  }
`

const useEditLocation = (option: IOptionType | null, close: () => void) => {
  const editLocationHook = useState<moment.Moment | null>(null)
  const prevManageType = usePrevious(option?.manageType)
  const [editLocation, { loading }] = useMutation(mutate, {
    refetchQueries: ['listLocationGroupMapping'],
    awaitRefetchQueries: true,
  })

  useEffect(() => {
    if (prevManageType !== option?.manageType) {
      if (option?.manageType === 'editLocation')
        editLocationHook[1](moment.utc(option.locationOpenedAt))
      else editLocationHook[1](null)
    }
  }, [option, prevManageType, editLocationHook])

  return useMemo((): IDataType | undefined => {
    if (option?.manageType !== 'editLocation') return

    const [value, onChange] = editLocationHook

    return {
      title: `Editing: ${option.locationName}`,
      value,
      onChange,
      loading,
      submit: async () => {
        if (!value || loading) return

        await editLocation({
          variables: {
            locationId: option.locationId,
            formValues: {
              openDate: value.format('YYYY-MM-DD'),
            },
            locationGroupMappings: option.autoUpdateLocationGroupMapping.map(
              (a) => ({
                locationGroupMappingId: a.locationGroupMappingId,
                startDate: !a.startDateOffset
                  ? value.format('YYYY-MM-DD')
                  : value
                      .clone()
                      .add(a.startDateOffset, 'days')
                      .format('YYYY-MM-DD'),
                endDate: !a.endDateOffset
                  ? null
                  : value
                      .clone()
                      .add(a.endDateOffset, 'days')
                      .format('YYYY-MM-DD'),
              }),
            ),
          },
        })
        close()
      },
    }
  }, [option, close, editLocationHook, loading, editLocation])
}

export default useEditLocation
