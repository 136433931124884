import { useMemo, useState } from 'react'

export interface IDataType {
  farwestTimeinterval?: {
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    value: string[][]
  }
}

const useFarwestTimeinterval = (): IDataType => {
  const intervalHook = useState([['business_week']])

  return {
    farwestTimeinterval: useMemo(() => {
      const options = [
        {
          id: 'business_week',
          parentId: 'root',
          displayName: 'Weekly',
        },
        {
          id: 'business_month',
          parentId: 'root',
          displayName: 'Monthly',
        },
        {
          id: 'business_quarter',
          parentId: 'root',
          displayName: 'Quarterly',
        },
      ]

      return {
        value: intervalHook[0],
        options: options,
        onChange: intervalHook[1],
      }
    }, [intervalHook]),
  }
}

export default useFarwestTimeinterval
