import { useQuery } from '@apollo/client'
import moment from 'moment'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrand } from 'pared/utils/brand'
import { getDateRangeLabel } from 'pared/utils/date'

import { TREND_LOCATION_GROUP_CUSTOMIZED_REPORT } from './gql'
import useConfig from './hooks/useConfig'

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350
const CHART_START_DATE = moment.utc('2023-01-01', 'YYYY-MM-DD', true)

interface IProps {
  locationGroupId: number
  endDate: string
}

interface IData {
  trendLocationGroupCustomizedReport: {
    nodes: {
      startDate: string
      endDate: string
      reportResult: {
        textData: {
          upsizePercent: string
        }
      }
    }[]
  }
}

export interface IOrganizedData {
  dateRanges: string[]
  inboundTotal: number[]
  inboundAnsweredPercent: number[]
  inboundMissedAndAbandonedPercent: number[]
  inboundMissedPercent: number[]
  inboundAbandonedPercent: number[]
}

const InboundCallsTrendChart = ({ locationGroupId, endDate }: IProps) => {
  const brand = getBrand()
  const config = useConfig(brand)

  const trendChartParams = {
    iReportName: 'LIST_LOCATION_GROUP_INBOUND_CALLS_TABLE',
    iLocationGroupId: locationGroupId,
    iEndDate: endDate,
    iGroupBy: 'business_month',
  }
  const { loading, data }: { loading: boolean; data: IData | undefined } =
    useQuery(TREND_LOCATION_GROUP_CUSTOMIZED_REPORT, {
      variables: trendChartParams,
      skip: !trendChartParams.iEndDate,
    })

  if (loading) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  if (!data) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  const {
    trendLocationGroupCustomizedReport: { nodes },
  } = data
  const organizedData = nodes.reduce(
    (acc, { startDate, reportResult }) => {
      if (CHART_START_DATE.isBefore(startDate)) {
        if (reportResult && Array.isArray(reportResult)) {
          const {
            inboundTotal,
            inboundAnsweredPercent,
            inboundMissedAndAbandonedPercent,
            inboundMissedPercent,
            inboundAbandonedPercent,
          } = reportResult[0] ?? {}

          acc.dateRanges.push(
            getDateRangeLabel(startDate, trendChartParams.iGroupBy),
          )
          acc.inboundTotal.push(inboundTotal || 0)
          acc.inboundAnsweredPercent.push(
            inboundAnsweredPercent?.toFixed(1) || 0,
          )
          acc.inboundMissedAndAbandonedPercent.push(
            inboundMissedAndAbandonedPercent?.toFixed(1) || 0,
          )
          acc.inboundMissedPercent.push(inboundMissedPercent?.toFixed(1) || 0)
          acc.inboundAbandonedPercent.push(
            inboundAbandonedPercent?.toFixed(1) || 0,
          )
        }
      }
      return acc
    },
    {
      dateRanges: [],
      inboundTotal: [],
      inboundAnsweredPercent: [],
      inboundMissedAndAbandonedPercent: [],
      inboundMissedPercent: [],
      inboundAbandonedPercent: [],
    } as IOrganizedData,
  )

  const yAxisDataArr = config.yAxisConfig.map((c) => ({
    type: c.type,
    yAxisId: c.yAxisId,
    data: organizedData[c.dataKey] as number[],
    tooltipLabel: c.tooltipLabel,
    borderColor: c.borderColor,
    backgroundColor: c.backgroundColor,
  }))

  const chartOptions = {
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '%',
    yRightShowGridLines: false,
  }

  return (
    <>
      <PageHeader>Trend Chart</PageHeader>
      <VerticalSpacer />
      <MobileContainer>
        <MixedChart
          xAxisData={organizedData.dateRanges}
          yAxisDataArr={yAxisDataArr}
          options={chartOptions}
        />
      </MobileContainer>
    </>
  )
}

export default InboundCallsTrendChart

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`
const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  color: ${COLORS.Chalkboard};
  @media ${MOBILE_WIDTH} {
    font-size: 18px;
  }
`

const VerticalSpacer = styled.div`
  height: 20px;
`
