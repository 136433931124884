import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import useLocationMetricValuesQuery from '../../hooks/useLocationMetricValuesQuery'
import calc from '../../utils/calc'
import { IApiDataType } from '../types'

interface IQueryData {
  glCodes: {
    nodes: {
      id: number
      code: string
      name: string
      parentGroupId: number
      displayOrder: number
    }[]
  }

  glGroups: {
    nodes: {
      id: number
      name: string
      parentGroupId: number
      exrayCategory: string | null
    }[]
  }

  locationMetricDefs: {
    nodes: {
      id: number
      code: string
      name: string
    }[]
  }
}

const query = gql`
  query ListGlCodesAndGroups {
    glCodes {
      nodes {
        id
        code
        name
        parentGroupId
        displayOrder
      }
    }

    glGroups {
      nodes {
        id
        name
        parentGroupId
        exrayCategory
      }
    }

    locationMetricDefs(condition: { metricGroup: "PnL" }) {
      nodes {
        id
        code
        name
      }
    }
  }
`

const findAllGlGroups = (
  glGroups: IQueryData['glGroups']['nodes'],
  parentGroups: IQueryData['glGroups']['nodes'],
): IQueryData['glGroups']['nodes'] => {
  if (parentGroups.length === 0) return []

  const ids = parentGroups.map((p) => p.id)
  const childGroups = glGroups.filter((g) => ids.includes(g.parentGroupId))

  return [
    ...parentGroups,
    ...findAllGlGroups(glGroups, childGroups).filter(
      (g) => !ids.includes(g.id),
    ),
  ]
}

const useGlCodes = (
  exrayCategory: 'Sales' | 'Expenses' | 'Food Cost' | 'Labor Cost',
) => {
  const { data, loading } = useQuery<IQueryData>(query)

  const glCodes = useMemo(() => {
    const glGroups = data?.glGroups.nodes || []
    const requiredGlGroups = findAllGlGroups(
      glGroups,
      glGroups.filter((n) => n.exrayCategory === exrayCategory),
    )
    const requiredGlGroupIds = requiredGlGroups.map((r) => r.id)
    const glCodesData = (data?.glCodes.nodes || []).filter((d) =>
      requiredGlGroupIds.includes(d.parentGroupId),
    )
    const glCodes = glCodesData.map((g) => g.name)
    const metricDefs = data?.locationMetricDefs.nodes.filter((n) =>
      glCodes.includes(n.name),
    )

    return glCodesData.map((g) => ({
      code: g.code,
      name: g.name,
      metricCode: metricDefs?.find((m) => m.name === g.name)?.code,
      displayOrder: g.displayOrder,
    }))
  }, [data])

  return {
    glCodes,
    loading,
  }
}

const buildPnl = (
  exrayCategory: 'Sales' | 'Expenses' | 'Food Cost' | 'Labor Cost',
) => {
  const usePnl = () => {
    const { groupFilter } = useGroupFilter()
    const { glCodes, loading: glCodesLoading } = useGlCodes(exrayCategory)
    const {
      data,
      yoyData,
      loading: metricValuesLoading,
    } = useLocationMetricValuesQuery({
      groupFilterTypes: ['listLocation', 'listLocationGroup'],
      metrics: [
        ...glCodes.map((g) => g.metricCode).filter(Boolean),
        ...glCodes
          .map((g) => g.metricCode && { key: g.metricCode, type: 'yoy' })
          .filter(Boolean),
      ] as string[],
      fields: ['metricSummaryData'],
      handler: (variables) => {
        const newVariables = {
          ...variables,
          iFilter: {
            ...variables.iFilter,
            location_ids: groupFilter?.ids,
          },
        }

        return {
          variables: newVariables,
          skip:
            !groupFilter ||
            !newVariables.iStartDate ||
            !newVariables.iEndDate ||
            (newVariables.iFilter.metrics || []).length === 0,
        }
      },
    })

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        return {
          source: glCodes
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((g) => {
              if (!g.metricCode) return { name: g.name }

              const d = data?.find((d) => d.metricSummaryData)
                ?.metricSummaryData[g.metricCode].value
              const yoyD = yoyData?.find((d) => d.metricSummaryData)
                ?.metricSummaryData[g.metricCode].value
              const variance = calc(d, '-', yoyD)

              return {
                name: g.name,
                variance,
                variancePercent: calc(
                  variance,
                  'percentageOf',
                  yoyD && Math.abs(yoyD),
                ),
              }
            })
            .filter((d) => d.variance)
            .sort((a, b) => {
              switch (exrayCategory) {
                case 'Sales':
                  return (
                    ((a.variance as number) || 0) -
                    ((b.variance as number) || 0)
                  )
                default:
                  return (
                    ((a.variancePercent as number) || 0) -
                    ((b.variancePercent as number) || 0)
                  )
              }
            }),
        }
      }, [data, yoyData, glCodes]),
      loading: glCodesLoading || metricValuesLoading,
    }
  }

  return usePnl
}

export const pnlConfigs = {
  name: 'string',
  variance: 'price',
  variancePercent: 'percent',
} as const

export const usePnlSales = buildPnl('Sales')
export const usePnlExpenses = buildPnl('Expenses')
export const usePnlLaborCost = buildPnl('Labor Cost')
export const usePnlFoodCost = buildPnl('Food Cost')
