import { gql, useQuery } from '@apollo/client'
import * as d3 from 'd3-scale'
import * as _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { getBrandLocationGroupId } from 'pared/utils/brand'
import { toUsdString, usdStringToNumber } from 'pared/utils/number'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

type IDataType = Record<
  | 'trendLocationGroupMetricValuesByDateRangeNumber'
  | 'trendLocationGroupYoyMetricValues',
  {
    nodes: {
      dateIndex: number
      metricData: Record<
        string,
        {
          name: string
          unit: 'CENT' | 'COUNT'
          value: number
        }
      >
    }[]
  }
>

interface IResultType {
  id: string
  parentId: string
  orderMode: string
  valueForDateRange1InPast: string | number | null
  valueForDateRange2InPast: string | number | null
  valueForDateRange3InPast: string | number | null
  valueForDateRange4InPast: string | number | null

  yoyForDateRange1InPast: number | null
  yoyForDateRange2InPast: number | null
  yoyForDateRange3InPast: number | null
  yoyForDateRange4InPast: number | null
}

const query = gql`
  query TrendLocationGroupMetricValuesByDateRange(
    $iEndDate: Date!
    $iGroupBy: String!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
    $iMetricGroups: [String!]!
  ) {
    trendLocationGroupMetricValuesByDateRangeNumber(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
        metric_groups: $iMetricGroups
      }
      iDateRangeNumber: 4
    ) {
      nodes {
        locationGroupId
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        dateIndex
        metricData
        metricSummaryData
      }
    }

    trendLocationGroupYoyMetricValues: trendLocationGroupMetricValuesByDateRangeNumber(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
        metric_groups: $iMetricGroups
        use_yoy: true
      }
      iDateRangeNumber: 4
    ) {
      nodes {
        locationGroupId
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        dateIndex
        metricData
        metricSummaryData
      }
    }
  }
`

export const fwWingstopSalesDigitalNondigitalConfigs = {
  orderMode: 'string',
  valueForDateRange1InPast: 'price',
  valueForDateRange2InPast: 'price',
  valueForDateRange3InPast: 'price',
  valueForDateRange4InPast: 'price',

  yoyForDateRange1InPast: 'percent',
  yoyForDateRange2InPast: 'percent',
  yoyForDateRange3InPast: 'percent',
  yoyForDateRange4InPast: 'percent',
} as const

export const fwWingstopChecksDigitalNondigitalConfigs = {
  orderMode: 'string',
  valueForDateRange1InPast: 'number',
  valueForDateRange2InPast: 'number',
  valueForDateRange3InPast: 'number',
  valueForDateRange4InPast: 'number',

  yoyForDateRange1InPast: 'percent',
  yoyForDateRange2InPast: 'percent',
  yoyForDateRange3InPast: 'percent',
  yoyForDateRange4InPast: 'percent',
} as const

export const fwWingstopCheckAvgDigitalNondigitalConfigs = {
  orderMode: 'string',
  valueForDateRange1InPast: 'string',
  valueForDateRange2InPast: 'string',
  valueForDateRange3InPast: 'string',
  valueForDateRange4InPast: 'string',

  yoyForDateRange1InPast: 'percent',
  yoyForDateRange2InPast: 'percent',
  yoyForDateRange3InPast: 'percent',
  yoyForDateRange4InPast: 'percent',
} as const

function heatmapMapping(
  value: number | string | null,
  min: number,
  max: number,
) {
  if (typeof value === 'number') {
    if (value >= 0) {
      const x = d3.scaleLinear([50, 100], [0, max])
      return x.invert(value)
    } else {
      const x = d3.scaleLinear([0, 50], [min, 0])
      return x.invert(value)
    }
  }
  return null
}

const KPI = (
  metricName: string,
): {
  id: string
  parentId: string
  orderMode: string
}[] => [
  {
    id: 'total',
    parentId: 'root',
    orderMode: 'Order Mode',
  },
  {
    id: `${metricName}_dine_in`,
    parentId: 'total',
    orderMode: 'Dine In',
  },
  {
    id: `${metricName}_walk_in_to_go`,
    parentId: 'total',
    orderMode: 'Walk in - To Go',
  },
  {
    id: `${metricName}_call_in_to_go_call_center`,
    parentId: 'total',
    orderMode: 'Call in - To Go / Call Center',
  },
  {
    id: `${metricName}_online`,
    parentId: 'total',
    orderMode: 'Online',
  },
  {
    id: `${metricName}_dispatch_delivery`,
    parentId: 'total',
    orderMode: 'Dispatch / Delivery',
  },
  {
    id: `${metricName}_marketplace`,
    parentId: 'total',
    orderMode: '3PD',
  },
  {
    id: `${metricName}_total_${metricName}`,
    parentId: 'root',
    orderMode: 'TOTAL',
  },
  {
    id: `${metricName}_nondigital`,
    parentId: `${metricName}_total_${metricName}`,
    orderMode: 'Non-Digital',
  },
  {
    id: `${metricName}_digital`,
    parentId: `${metricName}_total_${metricName}`,
    orderMode: 'Digital',
  },
]

const buildFwWingstopDigitalNondigitalHook = (metricType: string) => {
  const useHook = () => {
    const { endDate } = useDateFilter()
    const { groupFilter, hasGroupBy } = useGroupFilter()
    const { variables } = useVariables()
    const brandLocationGroupId = getBrandLocationGroupId()

    const yesterday = moment().subtract(1, 'day')
    const adjustedEndDate =
      endDate && moment(endDate, 'YYYY-MM-DD').isAfter(yesterday)
        ? yesterday.format('YYYY-MM-DD')
        : endDate

    const metricName = metricType.replace(/([A-Z])/g, '_$1').toLowerCase()
    const kpis = KPI(metricName)

    const dateType = variables.date?.value.type
    const iGroupBy = dateType === 'period' ? 'last_x_periods' : 'last_x_weeks'

    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iEndDate: adjustedEndDate,
        iGroupBy,
        iLocationGroupIds: !hasGroupBy
          ? [brandLocationGroupId]
          : groupFilter?.ids,
        iMetricCodes: [
          `${metricName}_online`,
          `${metricName}_marketplace`,
          `${metricName}_walk_in_to_go`,
          `${metricName}_total_${metricName}`,
        ],
        iMetricGroups: [`Expo Module - Digital and Nondigital ${metricType}`],
      },
      skip: !adjustedEndDate || !groupFilter,
    })

    return {
      data: useMemo((): IApiDataType => {
        const customizedData =
          data?.trendLocationGroupMetricValuesByDateRangeNumber?.nodes
        const yoyData = data?.trendLocationGroupYoyMetricValues?.nodes

        if (!customizedData || !yoyData) return null

        const yoyValues: { [key: string]: number[] } = {
          past1: [],
          past2: [],
          past3: [],
          past4: [],
        }

        const result = kpis.map((kpi) => {
          const values = [1, 2, 3, 4].reduce((acc, i) => {
            const act =
              customizedData.find((d) => d.dateIndex === i)?.metricData[kpi.id]
                ?.value || null
            const yoy = yoyData.find((d) => d.dateIndex === i)?.metricData[
              kpi.id
            ]?.value

            const yoyPercent = yoy ? (((act || 0) - yoy) / yoy) * 100 : null

            yoyValues[`past${i}`].push(yoyPercent || 0)

            acc[`yoyForDateRange${i}InPast`] = yoyPercent
            acc[`valueForDateRange${i}InPast`] =
              metricType === 'checkAvg'
                ? act
                  ? toUsdString(act / 100, 2)
                  : null
                : act
            return acc
          }, {} as Record<string, string | number | null>)

          return {
            ...kpi,
            ...values,
          } as IResultType
        })

        const resultWithHeatMap = result.map((r) => {
          const heatmap = [1, 2, 3, 4].reduce((acc, i) => {
            acc[`yoyForDateRange${i}InPastHeatMap`] = heatmapMapping(
              r[`yoyForDateRange${i}InPast` as keyof IResultType],
              Math.min(...yoyValues[`past${i}`]),
              Math.max(...yoyValues[`past${i}`]),
            )

            return acc
          }, {} as Record<string, number | null>)

          return {
            ...r,
            ...heatmap,
          }
        })

        if (metricType === 'checkAvg') {
          const digital = resultWithHeatMap.find(
            (r) => r.id === 'check_avg_digital',
          )
          const nondigital = resultWithHeatMap.find(
            (r) => r.id === 'check_avg_nondigital',
          )

          const summaryValue = [1, 2, 3, 4].reduce((acc, i) => {
            const currentDigital =
              digital?.[`valueForDateRange${i}InPast` as keyof typeof digital]
            const currentNonDigital =
              nondigital?.[
                `valueForDateRange${i}InPast` as keyof typeof nondigital
              ]
            const current =
              currentDigital && currentNonDigital
                ? `${(
                    (usdStringToNumber(currentDigital as string) /
                      usdStringToNumber(currentNonDigital as string) -
                      1) *
                    100
                  ).toFixed(2)} %`
                : '-'

            acc[`yoyForDateRange${i}InPast`] = null
            acc[`valueForDateRange${i}InPast`] = current
            return acc
          }, {} as Record<string, string | null>)

          return [
            ...resultWithHeatMap,
            {
              id: 'summary',
              parentId: 'root',
              orderMode: ' ',
              ...summaryValue,
            },
          ]
        }

        return resultWithHeatMap
      }, [groupFilter, data, variables]),
      loading,
    }
  }

  return useHook
}

export const useFwWingstopDigitalNondigitalSales =
  buildFwWingstopDigitalNondigitalHook('sales')
export const useFwWingstopDigitalNondigitalChecks =
  buildFwWingstopDigitalNondigitalHook('checks')
export const useFwWingstopDigitalNondigitalCheckAvg =
  buildFwWingstopDigitalNondigitalHook('checkAvg')
