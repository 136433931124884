import { IConfigsType } from '../../types'

const fwWingstopFlashDiscountsAndVoids: IConfigsType = {
  'fw_wingstop-customized-reports-7375b6fb': {
    '/:brand/discounts_and_voids': {
      variables: ['corporateGroup', 'date'],
      groupFilter: {
        api: 'fwWingstopCorporateFilter',
      },
      dateFilter: {
        types: ['week', 'this_week', 'period', 'quarter'],
        defaultType: 'this_week',
      },
      reportTitle: {
        type: 'title',
        title: 'Discounts and Voids',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'fwWingstopDiscountAndVoid',
        fileName: 'getexpo-far-west-discounts-and-voids',
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'discountPercentageDateRange4InPast',
            title:
              'Discount (<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
          },
          {
            key: 'discountPercentageDateRange3InPast',
            title:
              'Discount (<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
          },
          {
            key: 'discountPercentageDateRange2InPast',
            title:
              'Discount (<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
          },
          {
            key: 'discountPercentageDateRange1InPast',
            title:
              'Discount (<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
          },
          {
            key: 'voidPercentageDateRange4InPast',
            title:
              'Void (<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
          },
          {
            key: 'voidPercentageDateRange3InPast',
            title:
              'Void (<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
          },
          {
            key: 'voidPercentageDateRange2InPast',
            title:
              'Void (<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
          },
          {
            key: 'voidPercentageDateRange1InPast',
            title:
              'Void (<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
          },
          {
            key: 'refundPercentageDateRange4InPast',
            title:
              'Refund (<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
          },
          {
            key: 'refundPercentageDateRange3InPast',
            title:
              'Refund (<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
          },
          {
            key: 'refundPercentageDateRange2InPast',
            title:
              'Refund (<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
          },
          {
            key: 'refundPercentageDateRange1InPast',
            title:
              'Refund (<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>)',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
          },
        ],
      },
      flashTable: {
        type: 'table-v2',
        api: 'fwWingstopDiscountAndVoid',
        pagination: {
          pageSize: 30,
        },
        row: {
          pinning: {
            top: ['summary'],
          },
        },
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>',
            hide: '<%- _.isUndefined(corporateGroup?.tableColumns) %>',
            width: '200px',
            sortable: true,
            fixed: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(1, 2)) %>',
            hide: '<%- _.isUndefined(corporateGroup?.tableColumns) %>',
            width: '170px',
            sortable: true,
          },
          {
            key: 'market',
            title: 'Market',
            hide: '<%- _.isUndefined(corporateGroup?.locations) %>',
            sortable: true,
          },
          {
            key: 'discountPercentage',
            title: 'Discount',
            align: 'center',
            children: [
              {
                key: 'discountPercentageDateRange4InPast',
                title:
                  '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.discountPercentageDateRange4InPastHeatMap %>',
                },
              },
              {
                key: 'discountPercentageDateRange3InPast',
                title:
                  '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.discountPercentageDateRange3InPastHeatMap %>',
                },
              },
              {
                key: 'discountPercentageDateRange2InPast',
                title:
                  '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.discountPercentageDateRange2InPastHeatMap %>',
                },
              },
              {
                key: 'discountPercentageDateRange1InPast',
                title:
                  '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.discountPercentageDateRange1InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'voidPercentage',
            title: 'Void',
            align: 'center',
            children: [
              {
                key: 'voidPercentageDateRange4InPast',
                title:
                  '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.voidPercentageDateRange4InPastHeatMap %>',
                },
              },
              {
                key: 'voidPercentageDateRange3InPast',
                title:
                  '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.voidPercentageDateRange3InPastHeatMap %>',
                },
              },
              {
                key: 'voidPercentageDateRange2InPast',
                title:
                  '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.voidPercentageDateRange2InPastHeatMap %>',
                },
              },
              {
                key: 'voidPercentageDateRange1InPast',
                title:
                  '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.voidPercentageDateRange1InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'refundPercentage',
            title: 'Refund',
            align: 'center',
            children: [
              {
                key: 'refundPercentageDateRange4InPast',
                title:
                  '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.refundPercentageDateRange4InPastHeatMap %>',
                },
              },
              {
                key: 'refundPercentageDateRange3InPast',
                title:
                  '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.refundPercentageDateRange3InPastHeatMap %>',
                },
              },
              {
                key: 'refundPercentageDateRange2InPast',
                title:
                  '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.refundPercentageDateRange2InPastHeatMap %>',
                },
              },
              {
                key: 'refundPercentageDateRange1InPast',
                title:
                  '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
                hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
                align: 'center',
                sortable: true,
                background: {
                  range: [0, 100],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value:
                    '<%- values?.refundPercentageDateRange1InPastHeatMap %>',
                },
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashDiscountsAndVoids
