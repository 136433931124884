import { useCallback, useMemo, useState } from 'react'

import useChangeLocationGroup, {
  IDataType as IChangeLocationGroupDataType,
  IOptionType as IChangeLocationGroupOptionType,
} from './useChangeLocationGroup'
import useEditLocation, {
  IDataType as IEditLocationDataType,
  IOptionType as IEditLocationOptionType,
} from './useEditLocation'
import useEditLocationGroupHistory, {
  IDataType as IEditLocationGroupHistoryDataType,
  IOptionType as IEditLocationGroupHistoryOptionType,
} from './useEditLocationGroupHistory'
import useListLocationGroupMapping from './useListLocationGroupMapping'

type IStateType =
  | IEditLocationGroupHistoryOptionType
  | IChangeLocationGroupOptionType
  | IEditLocationOptionType

export interface IDataType {
  editLocationGroupHistory?: IEditLocationGroupHistoryDataType
  changeLocationGroup?: IChangeLocationGroupDataType
  editLocation?: IEditLocationDataType
  manageLocations?: {
    loading: boolean
    editLocationGroupHistoryOpen: boolean
    changeLocationGroupOpen: boolean
    editLocationOpen: boolean
    onOpen: (options: { values: IStateType }) => void
    onClose: () => void
  }
}

const useManageLocations = (): IDataType => {
  const manageHook = useState<IStateType | null>(null)
  const onClose = useCallback(() => manageHook[1](null), [manageHook])
  const { data, loading } = useListLocationGroupMapping()
  const editLocationGroupHistory = useEditLocationGroupHistory(
    data || null,
    manageHook[0] as IEditLocationGroupHistoryOptionType,
    onClose,
  )
  const changeLocationGroup = useChangeLocationGroup(
    data || null,
    manageHook[0] as IChangeLocationGroupOptionType,
    onClose,
  )
  const editLocation = useEditLocation(
    manageHook[0] as IEditLocationOptionType,
    onClose,
  )

  return {
    editLocationGroupHistory,
    changeLocationGroup,
    editLocation,
    manageLocations: useMemo((): IDataType['manageLocations'] => {
      const [manageState, setManageState] = manageHook

      return {
        loading,
        editLocationGroupHistoryOpen:
          manageState?.manageType === 'editLocationGroupHistory',
        changeLocationGroupOpen:
          manageState?.manageType === 'changeLocationGroup',
        editLocationOpen: manageState?.manageType === 'editLocation',
        onOpen: ({ values }) => setManageState(values),
        onClose,
      }
    }, [manageHook, loading, onClose]),
  }
}

export default useManageLocations
