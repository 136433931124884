import { IConfigsType } from '../../types'

const dhInventory: IConfigsType = {
  dh_wb: {
    '/:brand/corporate_inventory': {
      variables: [
        'corporateGroup',
        {
          type: 'items',
          initialSelection: ['28', 'category-28'],
          categoriesOnly: true,
          root: 'EXPO_COGS_CATEGORY_ROOT',
        },
      ],
      groupFilter: {
        api: 'corporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      title: {
        type: 'title',
        title: 'Inventory',
      },
      kpis: {
        type: 'list',
        api: 'dhWbListItemMetricValues',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'actualCogs',
            },
            bottom: 'Actual $',
          },
          {
            content: {
              key: 'theoreticalCogs',
            },
            bottom: 'Theoretical $',
          },
          {
            content: {
              key: 'actualCogsPercent',
            },
            bottom: 'Actual %',
          },
          {
            content: {
              key: 'theoreticalCogsPercent',
            },
            bottom: 'Theoretical %',
          },
          {
            content: {
              key: 'cogsVariance',
              color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
            },
            bottom: 'Variance $',
          },
          {
            content: {
              key: 'cogsVariancePercent',
              color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
            },
            bottom: 'Variance %',
          },
        ],
      },
      categoryFilter: {
        type: 'select',
        value: 'items.value',
        onChange: 'items.onChange',
        options: 'items.options',
        label: 'Category',
      },
      breakdownByStore: {
        type: 'table-v2',
        api: 'locationGroupInventory',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        row: {
          pinning: {
            top: ['summary'],
          },
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: true,
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'actualCogs',
            title: 'Actual $',
            sortable: true,
            unit: 'dollar',
          },
          {
            key: 'theoreticalCogs',
            title: 'Theoretical $',
            sortable: true,
            unit: 'dollar',
          },
          {
            key: 'actualCogsPercent',
            title: 'Actual %',
            decimal: 2,
            isDecimal: true,
            sortable: true,
          },
          {
            key: 'theoreticalCogsPercent',
            title: 'Theoretical %',
            decimal: 2,
            isDecimal: true,
            sortable: true,
          },
          {
            key: 'cogsVariance',
            title: 'Variance',
            unit: 'dollar',
            sortable: true,
            color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
          },
          {
            key: 'cogsVariancePercent',
            title: 'Variance %',
            decimal: 2,
            isDecimal: true,
            sortable: true,
            color: '<%- value > 0 ? colors.Pomodoro : "black" %>',
          },
        ],
      },
    },
  },
}

export default dhInventory
