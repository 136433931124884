import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'

export interface IDataType {
  salesByOrigin?: {
    options: {
      key: string
      label: string
    }[]
  }
}

interface IMetricDefsDataType {
  uListRequestedLocationMetrics: {
    nodes: { metricCode: string; metricName: string }[]
  }
}

const query = gql`
  query listMetricDefs($iFilter: JSON!) {
    uListRequestedLocationMetrics(iFilter: $iFilter) {
      nodes {
        metricCode
        metricName
      }
    }
  }
`

const useBurgerworksSalesByOrigin = (): IDataType => {
  const { data, loading } = useQuery<IMetricDefsDataType>(query, {
    variables: {
      iFilter: {
        metric_groups: ['Expo Module - Revenue Center Sales Sales By Origin'],
      },
    },
  })

  return {
    salesByOrigin: useMemo(() => {
      const nodes = data?.uListRequestedLocationMetrics?.nodes || []
      const newData = nodes
        ?.map((n) => ({
          key: _.camelCase(n.metricCode),
          label: n.metricName
            .replace(/^Revenue Center Sales - \s*/, '')
            .replace(/\s* Sales By Origin$/, ''),
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      return {
        options: [
          ...newData?.map((n, index) => ({
            borderColor: COLORS.STACKED_BAR_COLOR_HUE[index],
            backgroundColor: COLORS.STACKED_BAR_COLOR_HUE[index],
            ...n,
          })),
        ],
      }
    }, [data, loading]),
  }
}

export default useBurgerworksSalesByOrigin
