import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import getDirectors from 'pared/data/getDirectors'
import getLocations from 'pared/data/getLocations'

export interface ILocationFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

export interface IFilterInto {
  type: string
  displayName: string
  locationId: number | null
  locationGroupId: number | null
}

interface IFilterIntoMap {
  [filterDisplayStr: string]: IFilterInto
}

const FILTER_TYPE_LOCATION = 'location'
const FILTER_TYPE_LOCATION_GROUP = 'location_group'

const useLocationFilter = (brand: string) => {
  const [locationFilter, setLocationFilter] = useState<string[]>([])

  const filterInfoForAllStores = {
    type: FILTER_TYPE_LOCATION_GROUP,
    displayName: 'All Stores',
    locationId: null,
    locationGroupId: BRAND_LOCATION_GROUP_ID[brand],
  }

  const [allFilterInto, setAllFilterInto] = useState<IFilterInto[]>([
    filterInfoForAllStores,
  ])
  const [filterInfoMap, setFilterIntoMap] = useState<IFilterIntoMap>({})

  useEffect(() => {
    async function fetchData() {
      const [directors, locationData] = await Promise.all([
        getDirectors(),
        getLocations(),
      ])

      const allDirectors: IFilterInto[] = []
      _.forEach(directors, (director) => {
        if (director) {
          allDirectors.push({
            type: FILTER_TYPE_LOCATION_GROUP,
            displayName: `${director.firstName} ${director.lastName}`.trim(),
            locationId: null,
            locationGroupId: director.locationGroupId,
          })
        }
      })

      let allLocations: IFilterInto[] = []
      _.forEach(locationData?.locations, (location) => {
        if (location) {
          allLocations.push({
            type: FILTER_TYPE_LOCATION,
            displayName: `${location.code} ${location.name}`,
            locationId: location.id,
            locationGroupId: null,
          })
        }
      })

      setAllFilterInto([
        filterInfoForAllStores,
        ..._.orderBy(allDirectors, 'displayName'),
        ..._.orderBy(allLocations, 'displayName'),
      ])
    }

    fetchData()
  }, [brand])

  const filterData = useMemo(() => {
    const menuItemList: ILocationFilterType[] = [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
    ]

    const newFilterIntoMap: IFilterIntoMap = {}

    allFilterInto.forEach((info) => {
      menuItemList.push({
        id: info.displayName,
        parentId: 'root' as const,
        value: info.displayName,
        text: info.displayName,
      })
      newFilterIntoMap[info.displayName] = info
    })

    setFilterIntoMap(newFilterIntoMap)

    return menuItemList
  }, [brand, allFilterInto])

  useEffect(() => {
    setLocationFilter([filterData?.[1]?.value])
  }, [filterData])

  return {
    locationFilter,
    setLocationFilter,
    locationFilters: filterData,
    locationFilterInfoMap: filterInfoMap,
  }
}

export default useLocationFilter
