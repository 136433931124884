import { IConfigsType } from '../../types'

const fwWingstopPAndLConfig: IConfigsType = {
  'fw_wingstop-only-for-qa': {
    '/:brand/p-and-l-config': {
      variables: ['pnlConfig'],
      title: {
        type: 'title',
        title: 'Config',
      },
      status: {
        type: 'typography',
        apiOrContent: `Status: <%- pnlConfig.status %>`,
        variant: 'body1',
      },
      inputs: {
        type: 'grid',
        children: {
          company: {
            type: 'input',
            value: 'pnlConfig.company',
            onChange: 'pnlConfig.setCompany',
            label: 'Company',
          },
          username: {
            type: 'input',
            value: 'pnlConfig.username',
            onChange: 'pnlConfig.setUsername',
            label: 'Username',
          },
          password: {
            type: 'input',
            inputType: 'password',
            value: 'pnlConfig.password',
            onChange: 'pnlConfig.setPassword',
            label: 'Password',
          },
        },
        hide: '<%- !pnlConfig.needCredentials %>',
      },
      connect: {
        type: 'button',
        label: 'Connect',
        onClick: 'pnlConfig.fetchLegalEntities',
        loading: 'pnlConfig.isLoading',
        hide: '<%- !pnlConfig.needCredentials %>',
      },
      locations: {
        type: 'table-v2',
        api: 'r365Locations',
        columns: [
          {
            key: 'legalEntityName',
            title: 'Legal Entity',
            sortable: true,
          },
          {
            key: 'locationNumber',
            title: 'Location Number',
            sortable: true,
          },
          {
            key: 'locationName',
            title: 'Location Name',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default fwWingstopPAndLConfig
