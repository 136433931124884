import FormControl from '@material-ui/core/FormControl'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import useGroupSelector from 'pared/components/GroupSelector/hooks/useGroupSelector'
import ExpoSelect from 'pared/components/basicUi/select'
import Table from 'pared/components/basicUi/table'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import useDateRangeConfig from 'pared/components/nav/DateRangeSelector/hooks/useConfig'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_THIS_WEEK,
  TYPE_WEEK,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import useGetDirectors from 'pared/hooks/useGetDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'

import BottomTenTable from './BottomTenTable'
import ChickenCountTable from './ChickenCountTable'
import ChickenInventoryTable from './ChickenInventory'
import WingitnorthCustomFlash from './WingitnorthCustomFlash'
import useColumns from './hooks/useColumns'
import useDataSource from './hooks/useDataSource'
import useFlashConfig from './hooks/useFlashConfig'

interface IPropsType {
  navParams: INavParams
}

const Header = styled.h1`
  margin: 0px;
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};

  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 0px;
  }
`

const Container = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

export const SubHeader = styled.div`
  margin: 83px 0px 20px;
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  h3 {
    margin: 0px;
    display: inline-block;
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 25px;

    @media ${MOBILE_WIDTH} {
      margin: 30px 0px;
      display: block;
      gap: 15x;
    }
  }
`

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const rowStyle = `
  &:nth-child(even):not(:last-child) {
    background-color: #E5E5E5;
  }
`

const StyledFormControl = styled(FormControl)`
  background-color: white;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const Flash = ({ navParams }: IPropsType) => {
  const flashConfig = useFlashConfig()
  const { shouldUseGroupSelector, shouldHideBreakdown } = flashConfig
  const { dateRangeOptions } = useDateRangeConfig()
  if (!dateRangeOptions.includes(TYPE_CUSTOM)) {
    dateRangeOptions.push(TYPE_CUSTOM)
  }
  const { director, directors, directorChange, locationGroupIds } =
    useGetDirectors()
  const { groupBy, groupByType, setGroupBy, options, locationGroupId } =
    useGroupSelector({ initialGroupBy: 'store', shouldHideBreakdown })
  const { brand } = getBrandSettings()

  const isBreakdownByDirectors =
    director?.employeeId === -2 || groupByType !== 'store'
  const flashLocationGroupIds = useMemo(() => {
    if (shouldUseGroupSelector)
      return [parseInt(locationGroupId) || BRAND_LOCATION_GROUP_ID[brand]]

    return locationGroupIds
  }, [shouldUseGroupSelector, groupByType, locationGroupId, locationGroupIds])

  const { extendColumns, dataSource } = useDataSource(
    isBreakdownByDirectors,
    directors,
    flashLocationGroupIds,
    groupByType,
  )

  const columns = useColumns(isBreakdownByDirectors, groupByType)
    .filter(({ key }) => flashConfig.dailyFlash[key])
    .map((column) => ({
      ...column,
      ...flashConfig.dailyFlash[column.key],
    }))

  const isWeeklyFlash = flashConfig.customTables?.includes(
    'wingitnorthCustomFlash',
  )
  return (
    <>
      <Header>Systemwide Flash Report</Header>
      <Container>
        <SubHeader>
          <h1>{isWeeklyFlash ? 'WEEKLY' : 'DAILY'} FLASH</h1>
          <div>
            {!shouldUseGroupSelector ? (
              <DirectorFilterSelector
                selectedDirector={director}
                allDirectors={directors}
                onDirectorChange={directorChange}
              />
            ) : (
              <StyledFormControl margin="dense">
                <ExpoSelect
                  value={groupBy}
                  onChange={setGroupBy}
                  dataSource={options}
                  fullWidth
                  displayEmpty
                />
              </StyledFormControl>
            )}

            <h3>Filter by</h3>

            <DateRangeSelector
              navParams={navParams}
              dateRangeOptions={
                isWeeklyFlash
                  ? [TYPE_THIS_WEEK, TYPE_LAST_WEEK, TYPE_WEEK]
                  : dateRangeOptions
              }
              preSelectedDateRange={
                isWeeklyFlash ? TYPE_THIS_WEEK : TYPE_YESTERDAY
              }
            />
          </div>
        </SubHeader>

        {flashConfig.customTables?.includes('wingitnorthCustomFlash') ? (
          <WingitnorthCustomFlash locationGroupId={flashLocationGroupIds[0]} />
        ) : (
          <MobileCotainer>
            <Table
              dataSource={dataSource}
              columns={[...columns, ...extendColumns]}
              rowKey={(values) =>
                'storeId' in values ? values.storeId : values.directorId
              }
              rowStyle={rowStyle}
              pagination={{ pageSize: dataSource.length }}
              csvFileName="getexpo-flash"
            />
          </MobileCotainer>
        )}
        {flashConfig.customTables?.includes('chickenInventory') ? (
          <ChickenInventoryTable locationGroupId={flashLocationGroupIds[0]} />
        ) : null}
        {flashConfig.customTables?.includes('chickenCount') ? (
          <ChickenCountTable locationGroupId={flashLocationGroupIds[0]} />
        ) : null}
        {flashConfig.customTables?.includes('bottomTen') ? (
          <BottomTenTable locationGroupId={flashLocationGroupIds[0]} />
        ) : null}
      </Container>
    </>
  )
}

export default React.memo(Flash)
