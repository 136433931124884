import { IConfigsType } from '../../types'

const fwWingstopDigitalNondigital: IConfigsType = {
  'fw_wingstop-customized-reports-7375b6fb': {
    '/:brand/digital_nondigital': {
      variables: ['date'],
      groupFilter: {
        api: 'fwWingstopCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['week', 'period'],
        defaultType: 'week',
      },
      pageTitle: {
        type: 'title',
        title: 'Digital vs Non-Digital',
      },
      download: {
        type: 'download-csv',
        api: 'fwWingstopDigitalAndNondigital',
        fileName: 'getexpo-digital-and-non-digital',
        message: 'Download as CSV',
        fields: [
          {
            key: 'orderMode',
            title: 'Order Mode',
          },
          {
            key: 'valueForDateRange4InPast',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %> Actual',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
          },
          {
            key: 'yoyForDateRange4InPast',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %> YoY',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
          },
          {
            key: 'valueForDateRange3InPast',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %> Actual',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
          },
          {
            key: 'yoyForDateRange3InPast',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %> YoY',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
          },
          {
            key: 'valueForDateRange2InPast',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %> Actual',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
          },
          {
            key: 'yoyForDateRange2InPast',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %> YoY',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
          },
          {
            key: 'valueForDateRange1InPast',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %> Actual',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
          },
          {
            key: 'yoyForDateRange1InPast',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %> YoY',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
          },
        ],
      },
      salesTable: {
        type: 'table-v2',
        title: 'Sales $',
        api: 'fwWingstopDigitalNondigitalSales',
        expanded: ['total', 'sales_total_sales'],
        columns: [
          {
            key: 'orderMode',
            title: 'Mode',
            width: '300px',
          },
          {
            key: 'Past4',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange4InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange4InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange4InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'Past3',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange3InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange3InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange3InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'Past2',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange2InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange2InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange2InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'Past1',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange1InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange1InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange1InPastHeatMap %>',
                },
              },
            ],
          },
        ],
      },
      checksTable: {
        type: 'table-v2',
        title: 'Tickets #',
        api: 'fwWingstopDigitalNondigitalChecks',
        expanded: ['total', 'checks_total_checks'],
        columns: [
          {
            key: 'orderMode',
            title: 'Mode',
            width: '300px',
          },
          {
            key: 'Past4',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange4InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange4InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange4InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'Past3',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange3InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange3InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange3InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'Past2',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange2InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange2InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange2InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'Past1',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange1InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange1InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange1InPastHeatMap %>',
                },
              },
            ],
          },
        ],
      },
      checkAvgTable: {
        type: 'table-v2',
        title: 'Check Average',
        api: 'fwWingstopDigitalNondigitalCheckAvg',
        expanded: ['total', 'check_avg_total_check_avg'],
        columns: [
          {
            key: 'orderMode',
            title: 'Mode',
            width: '300px',
          },
          {
            key: 'Past4',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange4InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange4InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange4InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'Past3',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange3InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange3InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange3InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'Past2',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange2InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange2InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange2InPastHeatMap %>',
                },
              },
            ],
          },
          {
            key: 'Past1',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
            align: 'center',
            children: [
              {
                key: 'valueForDateRange1InPast',
                title: 'Actual',
              },
              {
                key: 'yoyForDateRange1InPast',
                title: 'YOY',
                background: {
                  range: [100, 0],
                  colors: ['hsl(120, 80%, 80%)', 'hsl(0, 80%, 80%)'],
                  value: '<%- values?.yoyForDateRange1InPastHeatMap %>',
                },
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopDigitalNondigital
