import { gql, useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'

import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../GroupFilterProvider'
import { IApiDataType } from '../types'

interface IQueryDataType {
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
        type: string
        start_ate: string
        end_date: string
      }[]
    }[]
  }
  listLocationGroups: {
    nodes: {
      locationGroupId: number
      locationGroupName: string
    }[]
  }
}

interface IQueryVariablesType {
  locationGroupTypes: [string]
  iStartDate?: string
  iEndDate?: string
}

const query = gql`
  query GetLegalEntity(
    $locationGroupTypes: [String!]!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationDetails: listLocationDetailsV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: { location_group_types: $locationGroupTypes }
    ) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }

    listLocationGroups(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: { location_group_types: $locationGroupTypes }
    ) {
      nodes {
        locationGroupId
        locationGroupName
      }
    }
  }
`

const useLegalEntityFilter = () => {
  const { brand } = useBrands()
  const { startDate, endDate } = useDateFilter()
  const location = useLocation()
  const { search } = location
  const history = useHistory()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const store = useMemo(
    () => (hasGroupBy ? null : groupFilter?.ids?.[0]),
    [groupFilter, hasGroupBy],
  )

  const group = useMemo(
    () =>
      hasGroupBy
        ? groupFilter?.id === 'group-all'
          ? 'all'
          : groupFilter?.ids[0]
        : null,
    [groupFilter, hasGroupBy],
  )

  const prevStore = usePrevious(store)
  const prevGroup = usePrevious(group)

  const {
    data: newData,
    loading,
    previousData,
  } = useQuery<IQueryDataType, IQueryVariablesType>(query, {
    variables: {
      locationGroupTypes: ['Legal Entity'],
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !startDate || !endDate,
  })

  const data = newData || previousData

  useEffect(() => {
    if (store && store !== prevStore) {
      const queryObject = new URLSearchParams(location.search)
      queryObject.delete('group')
      queryObject.set('store', store.toString())
      history.push(`${location.pathname}?${queryObject.toString()}`)
    }

    if (group && group != prevGroup) {
      const queryObject = new URLSearchParams(location.search)
      queryObject.delete('store')
      queryObject.set('group', group.toString())
      history.push(`${location.pathname}?${queryObject.toString()}`)
    }
  }, [location, history, store, prevStore, group, prevGroup])

  return {
    data: useMemo((): IApiDataType => {
      const queryObject = new URLSearchParams(location.search)
      const store = queryObject.get('store')
      const group = queryObject.get('group')
      if (!data) {
        return null
      }

      const legalEntities: { [locationGroupId: string]: string } = {}

      for (const location of data.listLocationGroups?.nodes ?? []) {
        legalEntities[location.locationGroupId] = location.locationGroupName
      }

      const hasOneLegalEntity = Object.keys(legalEntities).length === 1
      const legalEntityOptions = Object.keys(legalEntities)
        .map((locationGroupId) => ({
          id: `group-${locationGroupId}`,
          parentId: hasOneLegalEntity ? 'root' : 'legal-entity',
          ids: [parseInt(locationGroupId)],
          label: legalEntities[locationGroupId],
          list: data.listLocationDetails.nodes
            .filter((l) =>
              l.locationGroups.some(
                (lg) => lg.id === parseInt(locationGroupId),
              ),
            )
            .map((l) => {
              const queryObject = new URLSearchParams(search)

              queryObject.set('store', l.id.toString())

              const link = `/${brand}/p-and-l?${queryObject.toString()}`

              return {
                id: l.id,
                name: `${l.code} - ${l.name}`,
                header: 'Stores',
                link,
                groupBy: undefined,
              }
            }),
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      const values = [
        ...(hasOneLegalEntity
          ? []
          : [
              {
                id: `group-all`,
                parentId: 'root',
                ids: Object.keys(legalEntities).map((locationGroupId) =>
                  parseInt(locationGroupId),
                ),
                label: 'All Legal Entities',
                list: data.listLocationDetails.nodes
                  .filter((l) =>
                    l.locationGroups.some((lg) =>
                      Object.keys(legalEntities).includes(`${lg.id}`),
                    ),
                  )
                  .map((l) => {
                    const queryObject = new URLSearchParams(search)

                    queryObject.set('store', l.id.toString())

                    const link = `/${brand}/p-and-l?${queryObject.toString()}`

                    return {
                      id: l.id,
                      name: `${l.code} - ${l.name}`,
                      header: 'Stores',
                      link,
                      groupBy: undefined,
                    }
                  }),
              },
              {
                id: 'legal-entity',
                parentId: 'root',
                label: 'Legal Entity',
              },
            ]),
        ...legalEntityOptions,
        ...data.listLocationDetails.nodes.map((l) => {
          return {
            id: l.id.toString(),
            parentId: 'root',
            ids: [l.id],
            label: `${l.code} - ${l.name}`,
          }
        }),
      ]

      const defaultValue = values.find((v) =>
        store ? v.id === store : group ? v.id.split('-')[1] === group : true,
      )

      return {
        values,
        defaultValue: defaultValue?.id
          ? ['legal-entity', defaultValue?.id]
          : [values[0]?.id],
      }
    }, [data, brand, search]),
    loading,
  }
}

export default useLegalEntityFilter
