import useManageGls, {
  IDataType as IManageGlsDataType,
} from './administration/useManageGls'
import useManageLocationGroups, {
  IDataType as IManageLocationGroupsDataType,
} from './administration/useManageLocationGroups'
import useManageLocations, {
  IDataType as IManageLocationsDataType,
} from './administration/useManageLocations'
import usePnlConfig, {
  IDataType as IPnlConfigDataType,
} from './administration/usePnlConfig'
import useDate, {
  IDataType as IDateDataType,
  IOptionType as IDateOptionType,
} from './date'
import useAllStores, {
  IDataType as IAllStoresDataType,
  IOptionType as IAllStoresOptionType,
} from './useAllStores'
import useCorporateGroup, {
  IDataType as ICorporateGroupDataType,
} from './useCorporateGroup'
import useDeliveryDayParts, {
  IDataType as IDeliveryDayPartsType,
} from './useDeliveryDayParts'
import useDeliveryKpiType, {
  IDataType as IDeliveryKpiTypeDataType,
} from './useDeliveryKpiType'
import useDiscountType, {
  IDataType as IDiscountTypeDataType,
} from './useDiscountType'
import useInputValue, {
  IDataType as IInputValueDataType,
  IOptionType as IInputValueOptionType,
} from './useInputValue'
import useInterval, { IDataType as IIntervalDataType } from './useInterval'
import useItemByDaypartColummFilter, {
  IDataType as IItemByDaypartColumnFilterType,
} from './useItemByDaypartColumnFilter'
import useItemOrCategory, {
  IDataType as IItemOrCategoryDataType,
  IOptionType as IItemOrCategoryOptionType,
} from './useItemOrCategory'
import useLastTimeRange, {
  IDataType as ILastTimeRangeDataType,
  IOptionType as ILastTimeRangeOptionType,
} from './useLastTimeRange'
import usePnl, {
  IDataType as IPnlDataType,
  IOptionType as IPnlOptionType,
} from './usePnl'
import useRankings, {
  IDataType as IRankingsDataType,
  IOptionType as IRankingsOptionType,
} from './useRankings'
import useRevenueCenterSales, {
  IDataType as IRevenueCenterSalesType,
} from './useRevenueCenterSales'
import useSelectOptions, {
  IDataType as ISelectOptionsDataType,
  IOptionType as ISelectOptionsOptionType,
} from './useSelectOptions'
import useTimeBetween, {
  IDataType as ITimeBetweenDataType,
} from './useTimeBetween'
import useTrack, { IDataType as ITrackDataType } from './useTrack'

export type IVariablesType =
  | 'track'
  | 'date'
  | ({ type: 'date' } & IDateOptionType)
  | 'allStores'
  | ({ type: 'allStores' } & IAllStoresOptionType)
  | 'corporateGroup'
  | ({ type: 'items' } & IItemOrCategoryOptionType)
  | 'interval'
  | 'timeBetween'
  | 'itemByDaypartColumnFilter'
  | 'discountType'
  | 'lastTimeRange'
  | ({ type: 'lastTimeRange' } & ILastTimeRangeOptionType)
  | 'deliveryKpiType'
  | 'deliveryDayParts'
  | 'revenueCenterSales'
  | ({ type: 'rankings' } & IRankingsOptionType)
  | ({ type: 'inputValue' } & IInputValueOptionType)
  | 'manageLocations'
  | 'manageLocationGroups'
  | 'manageGls'
  | ({ type: 'pnl' } & IPnlOptionType)
  | ({ type: 'selectOptions' } & ISelectOptionsOptionType)
  | 'pnlConfig'

export type IDataType = IDateDataType &
  IAllStoresDataType &
  ITrackDataType &
  ICorporateGroupDataType &
  IItemOrCategoryDataType &
  IIntervalDataType &
  ITimeBetweenDataType &
  IItemByDaypartColumnFilterType &
  IDiscountTypeDataType &
  ILastTimeRangeDataType &
  IDeliveryKpiTypeDataType &
  IDeliveryDayPartsType &
  IRevenueCenterSalesType &
  IRankingsDataType &
  IInputValueDataType &
  IManageLocationsDataType &
  IManageLocationGroupsDataType &
  IManageGlsDataType &
  IPnlDataType &
  ISelectOptionsDataType &
  IPnlConfigDataType

export const hooks = [
  ['track', useTrack],
  ['date', useDate],
  ['allStores', useAllStores],
  ['corporateGroup', useCorporateGroup],
  ['items', useItemOrCategory],
  ['interval', useInterval],
  ['timeBetween', useTimeBetween],
  ['itemByDaypartColumnFilter', useItemByDaypartColummFilter],
  ['discountType', useDiscountType],
  ['lastTimeRange', useLastTimeRange],
  ['deliveryKpiType', useDeliveryKpiType],
  ['deliveryDayParts', useDeliveryDayParts],
  ['revenueCenterSales', useRevenueCenterSales],
  ['rankings', useRankings],
  ['inputValue', useInputValue],
  ['manageLocations', useManageLocations],
  ['manageLocationGroups', useManageLocationGroups],
  ['manageGls', useManageGls],
  ['pnl', usePnl],
  ['selectOptions', useSelectOptions],
  ['pnlConfig', usePnlConfig],
] as const
