import { IConfigsType } from '../../types'

const fwWingstopSalesAlertDashboard: IConfigsType = {
  'fw_wingstop-customized-reports-7375b6fb': {
    '/:brand/sales_alert_dashboard': {
      variables: [
        'allStores',
        {
          type: 'date',
          defaultType: 'yesterday',
          types: ['yesterday', 'custom_date'],
        },
        {
          type: 'selectOptions',
          options: {
            orderMode: {
              defaultId: [['dine_in']],
              options: [
                { id: 'dine_in', displayName: 'Dine In' },
                { id: 'walk_in_to_go', displayName: 'Walk in - To Go' },
                {
                  id: 'call_in_to_go_call_center',
                  displayName: 'Call in - To Go / Call Center',
                },
                { id: 'online', displayName: 'Online' },
                { id: 'dispatch_delivery', displayName: 'Dispatch / Delivery' },
                { id: 'marketplace', displayName: '3PD' },
              ],
            },
          },
        },
      ],
      title: {
        type: 'grid',
        layout: 'space-between',
        maxWidth: '1200px',
        minWidth: '800px',
        children: {
          title: {
            type: 'typography',
            apiOrContent: 'Sales Alert Dashboard',
          },
          selector: {
            type: 'grid',
            gap: '8px',
            children: {
              date: {
                type: 'date-filter',
              },
              orderMode: {
                type: 'select',
                value: 'selectOptions.orderMode.value',
                options: 'selectOptions.orderMode.options',
                onChange: 'selectOptions.orderMode.onChange',
              },
            },
          },
        },
      },
      download: {
        type: 'download-csv',
        api: 'fwWingstopSalesAlertDashboard',
        fileName: 'getexpo-sales-alert-dashboard',
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'market',
            title: 'Market',
          },
          {
            key: 'check',
            title: 'Trans #',
          },
          {
            key: 'sales',
            title: 'Sales $',
          },
          {
            key: 'sdlwSales',
            title: 'Sales SDLW $',
          },
          {
            key: 'wowSales',
            title: 'WOW Sales',
          },
          {
            key: 'marketWowSales',
            title: 'Market WOW Sales',
          },
          {
            key: 'varianceToMarket',
            title: 'Variance to Market',
          },
        ],
      },
      table: {
        type: 'table-v2',
        api: 'fwWingstopSalesAlertDashboard',
        sorting: [
          {
            id: 'sales',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(allStores?.tableColumns) %>',
            hide: '<%- _.isUndefined(allStores?.tableColumns) %>',
            width: '240px',
            sortable: true,
          },
          {
            key: 'market',
            title: 'Market',
            align: 'center',
            sortable: true,
          },
          {
            key: 'check',
            title: 'Trans #',
            align: 'center',
            sortable: true,
          },
          {
            key: 'sales',
            title: 'Sales $',
            align: 'center',
            sortable: true,
          },
          {
            key: 'sdlwSales',
            title: 'Sales SDLW $',
            align: 'center',
            sortable: true,
          },
          {
            key: 'wowSales',
            title: 'WOW Sales',
            align: 'center',
            sortable: true,
          },
          {
            key: 'marketWowSales',
            title: 'Market WOW Sales',
            align: 'center',
            sortable: true,
          },
          {
            key: 'varianceToMarket',
            title: 'Variance to Market',
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default fwWingstopSalesAlertDashboard
