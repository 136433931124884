import { gql, useMutation } from '@apollo/client'
import { arrayMove } from '@dnd-kit/sortable'
import * as d3 from 'd3-hierarchy'
import _ from 'lodash'
import moment from 'moment'
import { useCallback, useMemo } from 'react'

import { IQueryDataType } from './useListGlCodesAndGroups'

export type IDnDType =
  | {
      type: 'MOVE_TO_ANOTHER_GROUP'
      draggedId: string
      parentId: string
      moveUnder: string
    }
  | {
      type: 'MOVE_TO_GROUP_FIRST_ITEM'
      draggedId: string
      parentId: string
    }
  | {
      type: 'RE_ORDER'
      draggedId: string
      moveUnder: string
    }

export interface IDataType {
  onDrop: (data: IDnDType) => Promise<void>
  loading: boolean
}

interface ISortedDataType {
  id: string
  parentId: string
  code?: null | string
  displayOrder?: number
}

const updateGroupMutation = gql`
  mutation updateGroup(
    $id: BigInt!
    $parentGroupId: BigInt!
    $now: Datetime!
    $isGlCode: Boolean!
  ) {
    updateGlCode(
      input: {
        id: $id
        patch: { parentGroupId: $parentGroupId, updatedAt: $now }
      }
    ) @include(if: $isGlCode) {
      clientMutationId
    }

    updateGlGroup(
      input: {
        id: $id
        patch: { parentGroupId: $parentGroupId, updatedAt: $now }
      }
    ) @skip(if: $isGlCode) {
      clientMutationId
    }
  }
`

const updateOrderMutation = gql`
  mutation updateOrder($glGroupOrder: JSON!, $glCodeOrder: JSON!) {
    updateGlOrder(
      input: { glGroupOrder: $glGroupOrder, glCodeOrder: $glCodeOrder }
    ) {
      clientMutationId
    }
  }
`

const getData = ({
  data,
  children,
}: d3.HierarchyNode<ISortedDataType | { id: 'root' }>): (
  | ISortedDataType
  | { id: 'root' }
)[] => [data, ...(children || []).map(getData)].flat()

const hotfixWithoutDisplayOrder = (data: ISortedDataType[]) => {
  const dataTree = d3.stratify<ISortedDataType | { id: 'root' }>()(
    [
      { id: 'root' as const },
      data.sort((a, b) => {
        if (a.displayOrder && b.displayOrder) {
          return a.displayOrder - b.displayOrder
        }

        if (!a.displayOrder && !b.displayOrder && a.code && b.code) {
          return a.code.localeCompare(b.code)
        }

        if (!a.displayOrder) {
          return -1
        }

        if (!b.displayOrder) {
          return 1
        }

        return -1
      }),
    ].flat(),
  )

  return getData(dataTree)
    .slice(1)
    .map((n, index) => ({ ...n, displayOrder: index })) as ISortedDataType[]
}

const findGroupItems = (
  data: ISortedDataType[],
  id: string,
): ISortedDataType[] => {
  const items = data.filter((d) => d.parentId === id)
  const parentIds = items
    .filter((i) => /folder/.test(i.id))
    .map((i) => i.id)
    .reduce(
      (result, i) => (result.includes(i) ? result : [...result, i]),
      [] as string[],
    )

  return [...items, ...parentIds.map((p) => findGroupItems(data, p))].flat()
}

const getNewDisplayOrder = (
  data: ISortedDataType[],
  id: string,
  moveUnder: string,
  isAddToFirst: boolean,
) => {
  const oldIndex = data.findIndex((d) => d.id === id)
  const moveUnderId =
    !/folder/.test(moveUnder) || isAddToFirst
      ? moveUnder
      : (() => {
          const groupItems = findGroupItems(data, moveUnder)

          return groupItems[groupItems.length - 1]?.id ?? moveUnder
        })()
  const newIndex = data.findIndex((d) => d.id === moveUnderId)
  const groupItems = !/folder/.test(id) ? [] : findGroupItems(data, id)
  const groupItemIds = groupItems.map((g) => g.id)
  const newData = arrayMove(
    data,
    oldIndex,
    newIndex + (oldIndex > newIndex ? 1 : 0),
  ).filter((d) => !groupItemIds.includes(d.id))
  const changedDataIndex = newData.findIndex((d) => d.id === id)

  if (groupItems.length !== 0)
    newData.splice(changedDataIndex + 1, 0, ...groupItems)

  if (oldIndex === -1 || newIndex === -1 || newData.length !== data.length)
    throw new Error(
      `update gl order fail: ${oldIndex}, ${newIndex}, ${newData.length}`,
    )

  const newDisplayOrder = newData.map((n, index) => ({
    id: n.id,
    displayOrder: index,
  }))

  return {
    glGroupOrder: newDisplayOrder
      .filter((n) => /folder/.test(n.id))
      .map((n) => ({ id: n.id.split('-')[1], displayOrder: n.displayOrder })),
    glCodeOrder: newDisplayOrder
      .filter((n) => /item/.test(n.id))
      .map((n) => ({ id: n.id.split('-')[1], displayOrder: n.displayOrder })),
  }
}

const usePnlDrop = (data: IQueryDataType | null) => {
  const [updateGroup, { loading: updateGroupLoading }] = useMutation(
    updateGroupMutation,
    {
      refetchQueries: ['ListGlCodesAndGroups'],
      awaitRefetchQueries: true,
    },
  )
  const [updateOrder, { loading: updateOrderLoading }] = useMutation(
    updateOrderMutation,
    {
      refetchQueries: ['ListGlCodesAndGroups'],
      awaitRefetchQueries: true,
    },
  )
  const sortedData = useMemo(() => {
    const newData = [
      ...(data?.listGlGroups.nodes || []).map((n) => ({
        id: `folder-${n.id}`,
        parentId:
          n.parentGroupId === null ? 'root' : `folder-${n.parentGroupId}`,
        code: null,
        name: n.name,
        displayOrder: n.displayOrder,
      })),
      ...(data?.listGlCodes.nodes || []).map((n) => ({
        id: `item-${n.id}`,
        parentId: `folder-${n.parentGroupId}`,
        code: n.code,
        name: `${n.code} - ${n.name}`,
        displayOrder: n.displayOrder,
      })),
    ]

    if (newData.some((d) => _.isNil(d.displayOrder)))
      return hotfixWithoutDisplayOrder(newData)

    return newData.sort((a, b) => a.displayOrder - b.displayOrder)
  }, [data])

  const onDrop = useCallback(
    async (data: IDnDType) => {
      if (
        data.type === 'MOVE_TO_ANOTHER_GROUP' ||
        data.type === 'MOVE_TO_GROUP_FIRST_ITEM'
      )
        await updateGroup({
          variables: {
            id: data.draggedId.split('-')[1],
            parentGroupId: data.parentId.split('-')[1],
            now: moment.utc().format(),
            isGlCode: /item/.test(data.draggedId),
          },
        })

      const { glGroupOrder, glCodeOrder } = getNewDisplayOrder(
        sortedData,
        data.draggedId,
        data.type === 'MOVE_TO_GROUP_FIRST_ITEM'
          ? data.parentId
          : data.moveUnder,
        data.type === 'MOVE_TO_GROUP_FIRST_ITEM',
      )

      await updateOrder({
        variables: {
          glGroupOrder,
          glCodeOrder,
        },
      })
    },
    [updateGroup, updateOrder, sortedData],
  )

  return useMemo(
    (): IDataType => ({
      onDrop,
      loading: updateGroupLoading || updateOrderLoading,
    }),
    [onDrop, updateGroupLoading, updateOrderLoading],
  )
}

export default usePnlDrop
