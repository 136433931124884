import { useMemo, useState } from 'react'

import useDate, {
  IDataType as IDateDataType,
  IOptionType as IDateOptionType,
} from './date'

export interface IOptionType {
  dateOptions?: IDateOptionType
}

export interface IDataType extends IDateDataType {
  pnl?: {
    pnlColumns: {
      value: string[][]
      onChange: (value: string[][]) => void
      options: {
        id: string
        parentId: 'root'
        displayName: string
      }[]
    }
  }
}

const usePnl = ({ dateOptions = {} }: IOptionType) => {
  const { date } = useDate(dateOptions)
  const pnlColumnsHook = useState([
    ['pAndLSales'],
    ['pAndLYoySales'],
    ['pAndLYoyCompare'],
  ])

  return {
    date,
    pnl: useMemo((): IDataType['pnl'] => {
      const { displayType, toDateDisplayType } = date?.getInfo(0) ?? {}

      const period = displayType || 'Period'
      const ptd = toDateDisplayType || 'PTD'

      const options = [
        {
          id: 'pAndLSales',
          parentId: 'root' as const,
          displayName: `Current ${period}`,
        },
        {
          id: 'pAndLYoySales',
          parentId: 'root' as const,
          displayName: `Prior Year ${ptd}`,
        },
        {
          id: 'pAndLYoyCompare',
          parentId: 'root' as const,
          displayName: `Current ${period} vs PY ${ptd}`,
        },
        {
          id: 'pAndLYtdSales',
          parentId: 'root' as const,
          displayName: `Current YTD`,
        },
        {
          id: 'pAndLLYYtdSales',
          parentId: 'root' as const,
          displayName: 'Prior YTD',
        },
        {
          id: 'pAndLLYYtdCompare',
          parentId: 'root' as const,
          displayName: 'Current YTD vs Prior YTD',
        },
        {
          id: 'pAndLPriorPeriodSales',
          parentId: 'root' as const,
          displayName: `Prior ${period}`,
        },
        {
          id: 'pAndLPriorPeriodCompare',
          parentId: 'root' as const,
          displayName: `Current ${period} vs Prior ${period}`,
        },
      ]
      return {
        pnlColumns: {
          value: pnlColumnsHook[0],
          onChange: pnlColumnsHook[1],
          options,
        },
      }
    }, [date, pnlColumnsHook]),
  }
}

export default usePnl
