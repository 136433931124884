import TextField from '@mui/material/TextField'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import {
  CustomizedReportRender,
  useFetchCustomizedReportData,
} from 'pared/components/CustomizedReport'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import Select from 'pared/components/basicUi/select'
import Table from 'pared/components/basicUi/table'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import { TYPE_PERIOD, TYPE_QUARTER, TYPE_YEAR } from 'pared/data/getDateRanges'
import useDefaultLocation from 'pared/hooks/useDefaultLocation'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import PurchasesTable from 'pared/pages/Purchases/PurchasesTable'

import DailyInventoryAnalysis from './DailyInventoryAnalysis'
import {
  INVENTORY_CUSTOMIZED_REPORT_NAME_DAILY,
  INVENTORY_CUSTOMIZED_REPORT_NAME_PERIOD,
  INVENTORY_CUSTOMIZED_REPORT_NAME_WEEKLY,
} from './constants'
import getFilteredCustomReportDefinition from './getFilteredCustomReportDefinition'
import useCategoryFilter, {
  ICategoryFilterType,
} from './hooks/useCategoryFilter'
import useFetchSelectedInventoryReportData from './hooks/useFetchSelectedInventoryReportData'
import useGetTopRankedTableData from './hooks/useGetTopRankedTableData'
import useInventoryConfig from './hooks/useInventoryConfig'
import useItemFilter from './hooks/useItemFilter'
import useKpiFilter, { INVENTORY_KPI_TYPE } from './hooks/useKpiFilter'
import useReportFilter, { IReportFilterType } from './hooks/useReportFilter'
import useUnitFilter from './hooks/useUnitFilter'
import { IUnitFilterItemType } from './hooks/useUnitFiltersConfig'

interface IPropsType {
  navParams: INavParams
}

const Inventory = ({ navParams }: IPropsType) => {
  useDefaultLocation()
  const config = useInventoryConfig()

  const storeId = navParams.storeId || ''

  const locationInfo = useLocationInfo(parseInt(storeId || '0', 10))
  const locationId = locationInfo?.id
  const locationName = locationInfo?.name

  const dateRange = useGetDateRange()

  let customizedReportName: string = ''
  const queryVersionSuffix = config.queryVersion
    ? `_V${config.queryVersion}`
    : ''
  if (dateRange) {
    if (
      config.hasPeriodInventory &&
      (dateRange.type === TYPE_QUARTER || dateRange.type === TYPE_YEAR)
    ) {
      customizedReportName = `${INVENTORY_CUSTOMIZED_REPORT_NAME_PERIOD}${queryVersionSuffix}`
    } else if (dateRange.type === TYPE_PERIOD) {
      customizedReportName = `${INVENTORY_CUSTOMIZED_REPORT_NAME_WEEKLY}${queryVersionSuffix}`
    } else {
      customizedReportName = config.hasDailyInventory
        ? INVENTORY_CUSTOMIZED_REPORT_NAME_DAILY
        : INVENTORY_CUSTOMIZED_REPORT_NAME_WEEKLY
    }
  }

  const { reportDefinition, reportResult, isLoading } =
    useFetchCustomizedReportData(customizedReportName, {
      locationId,
      startDate: dateRange?.startDateStr,
      endDate: dateRange?.endDateStr,
    })

  const { reportFilter, setReportFilter, reportFilters, allWeeks } =
    useReportFilter(reportResult)

  const { kpiFilter, setKpiFilter, kpiFilters } = useKpiFilter()

  const reportFilterValue = _.get(reportFilter, '[0]')
  const selectedWeek = _.find(allWeeks, {
    key: reportFilterValue,
  })

  const selectedReportData = useFetchSelectedInventoryReportData(
    locationId,
    reportFilterValue,
    selectedWeek,
    dateRange,
  )

  let displayedReportName = customizedReportName
  let displayedReportDefinition = reportDefinition
  let displayedReportResult = reportResult
  let isLoadingDisplayed = isLoading
  if (selectedReportData) {
    displayedReportName = selectedReportData.customizedReportName
    displayedReportDefinition = selectedReportData.reportDefinition
    displayedReportResult = selectedReportData.reportResult
    isLoadingDisplayed = selectedReportData.isLoading
  }

  const {
    categoryFilter,
    setCategoryFilter,
    categoryFilters,
    categoryFilteredCustomizedReportResult,
  } = useCategoryFilter(displayedReportResult)

  const { itemFilter, setItemFilter, itemFilteredCustomizedReportResult } =
    useItemFilter(categoryFilteredCustomizedReportResult)

  const { unitFilter, setUnitFilter, unitFilters } = useUnitFilter('inventory')

  const selectedKpi = _.get(kpiFilter, '[0]')
  const selectedCategory = _.get(categoryFilter, '[0]')
  const selectedUnit = _.get(unitFilter, '[0]')

  const filteredCustomizedReportDefinition = getFilteredCustomReportDefinition(
    displayedReportDefinition,
    {
      kpi: selectedKpi,
      unit: selectedUnit,
    },
  )

  const topRankedTableData = useGetTopRankedTableData(
    itemFilteredCustomizedReportResult,
  )

  const PurchaseTable =
    locationId && locationName && dateRange ? (
      <PurchasesTableContainer>
        <PurchasesTable
          locationId={locationId}
          locationName={locationName}
          startDate={dateRange.startDateStr}
          endDate={dateRange.endDateStr}
        />
      </PurchasesTableContainer>
    ) : null

  const TopFiveTable =
    selectedKpi === INVENTORY_KPI_TYPE.VARIANCE ||
    selectedKpi === INVENTORY_KPI_TYPE.AVT ? (
      <RankedTableContainer>
        <TopFiveContainer>
          <h1>Top 5 Short</h1>
          <LeftRankedTableContainer>
            <Table
              dataSource={topRankedTableData.topFiveDollarShort}
              columns={topRankedTableData.topFiveDollarShortTableColumnTypes}
            />
          </LeftRankedTableContainer>
          <RightRankedTableContainer>
            <Table
              dataSource={topRankedTableData.topFivePercentShort}
              columns={topRankedTableData.topFivePercentShortTableColumnTypes}
            />
          </RightRankedTableContainer>
        </TopFiveContainer>

        <BottomFiveContainer>
          <h1>Top 5 Over</h1>
          <LeftRankedTableContainer>
            <Table
              dataSource={topRankedTableData.topFiveDollarOver}
              columns={topRankedTableData.topFiveDollarOverTableColumnTypes}
            />
          </LeftRankedTableContainer>
          <RightRankedTableContainer>
            <Table
              dataSource={topRankedTableData.topFivePercentOver}
              columns={topRankedTableData.topFivePercentOverTableColumnTypes}
            />
          </RightRankedTableContainer>
        </BottomFiveContainer>
      </RankedTableContainer>
    ) : null

  const InventoryTable = (
    <Container>
      <h1>{config.varianceTableTitle ?? 'Variance'}</h1>

      <FilterContainer>
        {!config.varianceFilter?.typeVisible ? null : (
          <>
            <span>Type</span>
            <MenuItemSelect<IReportFilterType>
              value={reportFilter}
              onChange={setReportFilter}
              dataSource={reportFilters}
            />
          </>
        )}

        {!config.varianceFilter?.kpiVisible ? null : (
          <>
            <span>KPI</span>
            <MenuItemSelect<ICategoryFilterType>
              value={kpiFilter}
              onChange={setKpiFilter}
              dataSource={kpiFilters}
            />
          </>
        )}

        <span>Category</span>
        <MenuItemSelect<ICategoryFilterType>
          value={categoryFilter}
          onChange={setCategoryFilter}
          dataSource={categoryFilters}
        />

        <span>Item</span>
        <StyledTextField
          value={itemFilter}
          placeholder="Start typing item name..."
          onChange={(e) => setItemFilter(e.target.value)}
        />

        {!config.varianceFilter?.unitVisible ? null : (
          <>
            <span>Unit</span>
            <MenuItemSelect<IUnitFilterItemType>
              value={unitFilter}
              onChange={setUnitFilter}
              dataSource={unitFilters}
            />
          </>
        )}
      </FilterContainer>

      {!dateRange ? <PageStatusDiv>Loading...</PageStatusDiv> : null}

      <CustomizedReportRender
        key={`${displayedReportName}-${dateRange?.key}-${selectedCategory}-${selectedUnit}`}
        reportDefinition={filteredCustomizedReportDefinition}
        reportResult={itemFilteredCustomizedReportResult}
        isLoading={isLoadingDisplayed}
        rowKey={(values) => values.item}
        csvFileName="getexpo-store-inventory"
      />
    </Container>
  )

  return (
    <>
      <Header>
        <div>
          <h1>Inventory</h1>
        </div>
        <Selectors>
          <LocationSelector navParams={navParams} />
          <DateRangeSelector
            navParams={navParams}
            preSelectedDateRange={TYPE_PERIOD}
          />
        </Selectors>
      </Header>

      {config.modules.map((module) => {
        switch (module) {
          case 'purchaseTable':
            return PurchaseTable
          case 'dailyInventoryAnalysis':
            return <DailyInventoryAnalysis locationId={locationId || 0} />
          case 'inventoryTable':
            return InventoryTable
          case 'topFiveTable':
            return TopFiveTable
          default:
            return null
        }
      })}
    </>
  )
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const Container = styled.div`
  padding: 20px 0 60px 0;
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 0px;
    }
  }
`

const MenuItemSelect = styled(Select)`
  width: 150px;
  background-color: white;
  margin: 0 30px 0 15px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 10px 0 20px 0;
  }
` as typeof Select

const StyledTextField = styled(TextField)`
  width: 250px;
  background-color: white;
  margin: 0 30px 0 15px;
  font-family: Lexend-Regular;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 10px 0 20px 0;
  }
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const FilterContainer = styled.div`
  width: 1300px;
  padding: 25px 0;

  span {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    text-align: left;
    width: 100%;
    padding: 5px 0 25px 0;

    span {
      display: block;
      padding: 0;
    }
  }
`

const RankedTableContainer = styled.div`
  width: 1300px;
  padding: 60px 0 0 0;
`

const TopFiveContainer = styled.div`
  padding: 0 0 50px 0;
  h1 {
    font-family: Lexend-SemiBold;
    font-size: 32px;
    font-weight: 600;
  }
`

const BottomFiveContainer = styled.div`
  h1 {
    font-family: Lexend-SemiBold;
    font-size: 32px;
    font-weight: 600;
  }
`

const LeftRankedTableContainer = styled.div`
  display: inline-block;
  width: 450px;
  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    padding: 0 0 30px 0;
  }
`

const RightRankedTableContainer = styled.div`
  display: inline-block;
  width: 600px;
  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    padding: 0;
  }
`

const PurchasesTableContainer = styled.div`
  padding: 0 0 60px 0;
  @media ${MOBILE_WIDTH} {
    padding: 0 25px;
  }
`

export default React.memo(Inventory)
