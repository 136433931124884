import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_DATABASE_FORMAT } from 'pared/constants'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { corporateGroupTableConfigs, useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

const query = gql`
  query ListFarwestDayTrendData(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "last_x_days"
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        startDate
        metricData
        metricSummaryData
      }
    }

    trendLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "last_x_days"
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        startDate
        metricData
        metricSummaryData
      }
    }

    listLocationMarketAndOpenDate(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        openedAt
        market
      }
    }
  }
`

export const fwWingstopLastTwentyEightDaysTrendDataConfigs = {
  '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>':
    corporateGroupTableConfigs['<%- JSON(corporateGroup?.tableColumns) %>'],
  '<%- JSON(corporateGroup?.tableColumns.slice(1, 2)) %>':
    corporateGroupTableConfigs['<%- JSON(corporateGroup?.tableColumns) %>'],
  market: 'string',

  // net_sales
  netSalesDay0InPast: 'price',
  netSalesDay1InPast: 'price',
  netSalesDay2InPast: 'price',
  netSalesDay3InPast: 'price',
  netSalesDay4InPast: 'price',
  netSalesDay5InPast: 'price',
  netSalesDay6InPast: 'price',
  netSalesDay7InPast: 'price',
  netSalesDay8InPast: 'price',
  netSalesDay9InPast: 'price',
  netSalesDay10InPast: 'price',
  netSalesDay11InPast: 'price',
  netSalesDay12InPast: 'price',
  netSalesDay13InPast: 'price',
  netSalesDay14InPast: 'price',
  netSalesDay15InPast: 'price',
  netSalesDay16InPast: 'price',
  netSalesDay17InPast: 'price',
  netSalesDay18InPast: 'price',
  netSalesDay19InPast: 'price',
  netSalesDay20InPast: 'price',
  netSalesDay21InPast: 'price',
  netSalesDay22InPast: 'price',
  netSalesDay23InPast: 'price',
  netSalesDay24InPast: 'price',
  netSalesDay25InPast: 'price',
  netSalesDay26InPast: 'price',
  netSalesDay27InPast: 'price',

  // net_sales_percentage_diff_to_last_year
  netSalesPercentageDiffToLastYearDay0InPast: 'percent',
  netSalesPercentageDiffToLastYearDay1InPast: 'percent',
  netSalesPercentageDiffToLastYearDay2InPast: 'percent',
  netSalesPercentageDiffToLastYearDay3InPast: 'percent',
  netSalesPercentageDiffToLastYearDay4InPast: 'percent',
  netSalesPercentageDiffToLastYearDay5InPast: 'percent',
  netSalesPercentageDiffToLastYearDay6InPast: 'percent',
  netSalesPercentageDiffToLastYearDay7InPast: 'percent',
  netSalesPercentageDiffToLastYearDay8InPast: 'percent',
  netSalesPercentageDiffToLastYearDay9InPast: 'percent',
  netSalesPercentageDiffToLastYearDay10InPast: 'percent',
  netSalesPercentageDiffToLastYearDay11InPast: 'percent',
  netSalesPercentageDiffToLastYearDay12InPast: 'percent',
  netSalesPercentageDiffToLastYearDay13InPast: 'percent',
  netSalesPercentageDiffToLastYearDay14InPast: 'percent',
  netSalesPercentageDiffToLastYearDay15InPast: 'percent',
  netSalesPercentageDiffToLastYearDay16InPast: 'percent',
  netSalesPercentageDiffToLastYearDay17InPast: 'percent',
  netSalesPercentageDiffToLastYearDay18InPast: 'percent',
  netSalesPercentageDiffToLastYearDay19InPast: 'percent',
  netSalesPercentageDiffToLastYearDay20InPast: 'percent',
  netSalesPercentageDiffToLastYearDay21InPast: 'percent',
  netSalesPercentageDiffToLastYearDay22InPast: 'percent',
  netSalesPercentageDiffToLastYearDay23InPast: 'percent',
  netSalesPercentageDiffToLastYearDay24InPast: 'percent',
  netSalesPercentageDiffToLastYearDay25InPast: 'percent',
  netSalesPercentageDiffToLastYearDay26InPast: 'percent',
  netSalesPercentageDiffToLastYearDay27InPast: 'percent',

  // check_average
  checkAverageDay0InPast: 'price',
  checkAverageDay1InPast: 'price',
  checkAverageDay2InPast: 'price',
  checkAverageDay3InPast: 'price',
  checkAverageDay4InPast: 'price',
  checkAverageDay5InPast: 'price',
  checkAverageDay6InPast: 'price',
  checkAverageDay7InPast: 'price',
  checkAverageDay8InPast: 'price',
  checkAverageDay9InPast: 'price',
  checkAverageDay10InPast: 'price',
  checkAverageDay11InPast: 'price',
  checkAverageDay12InPast: 'price',
  checkAverageDay13InPast: 'price',
  checkAverageDay14InPast: 'price',
  checkAverageDay15InPast: 'price',
  checkAverageDay16InPast: 'price',
  checkAverageDay17InPast: 'price',
  checkAverageDay18InPast: 'price',
  checkAverageDay19InPast: 'price',
  checkAverageDay20InPast: 'price',
  checkAverageDay21InPast: 'price',
  checkAverageDay22InPast: 'price',
  checkAverageDay23InPast: 'price',
  checkAverageDay24InPast: 'price',
  checkAverageDay25InPast: 'price',
  checkAverageDay26InPast: 'price',
  checkAverageDay27InPast: 'price',

  // check_count
  checkCountDay0InPast: 'number',
  checkCountDay1InPast: 'number',
  checkCountDay2InPast: 'number',
  checkCountDay3InPast: 'number',
  checkCountDay4InPast: 'number',
  checkCountDay5InPast: 'number',
  checkCountDay6InPast: 'number',
  checkCountDay7InPast: 'number',
  checkCountDay8InPast: 'number',
  checkCountDay9InPast: 'number',
  checkCountDay10InPast: 'number',
  checkCountDay11InPast: 'number',
  checkCountDay12InPast: 'number',
  checkCountDay13InPast: 'number',
  checkCountDay14InPast: 'number',
  checkCountDay15InPast: 'number',
  checkCountDay16InPast: 'number',
  checkCountDay17InPast: 'number',
  checkCountDay18InPast: 'number',
  checkCountDay19InPast: 'number',
  checkCountDay20InPast: 'number',
  checkCountDay21InPast: 'number',
  checkCountDay22InPast: 'number',
  checkCountDay23InPast: 'number',
  checkCountDay24InPast: 'number',
  checkCountDay25InPast: 'number',
  checkCountDay26InPast: 'number',
  checkCountDay27InPast: 'number',
} as const

const KPIS = [
  'net_sales',
  'net_sales_percentage_diff_to_last_year',
  'check_average',
  'check_count',
]

const useLastTwentyEightDaysTrendData = () => {
  const { endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { variables } = useVariables()
  const corporateDetails = useMemo(() => {
    if (!variables.corporateGroup) return

    if (
      'locations' in variables.corporateGroup &&
      variables.corporateGroup.locations
    )
      return variables.corporateGroup.locations

    if (
      'locationGroups' in variables.corporateGroup &&
      variables.corporateGroup.locationGroups
    )
      return variables.corporateGroup.locationGroups
  }, [variables])

  const momentEndDate = moment.utc(endDate, DATE_DATABASE_FORMAT, true)
  const startDate = momentEndDate
    .clone()
    .subtract(27, 'days')
    .format(DATE_DATABASE_FORMAT)

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: KPIS,
      },
      hasGroupBy,
    },
    skip: !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const dateToDayMap: { [dateStr: string]: string } = {}

      for (let di = 0; di < 28; di++) {
        const dateStr = momentEndDate
          .clone()
          .subtract(di, 'days')
          .format(DATE_DATABASE_FORMAT)
        dateToDayMap[dateStr] = `Day${di}InPast`
      }

      const customizedData: any =
        data?.[
          hasGroupBy
            ? 'trendLocationMetricValues'
            : 'trendLocationGroupMetricValues'
        ]?.nodes
      const locationDetails: any = data?.listLocationMarketAndOpenDate?.nodes

      if (!customizedData) return null

      const idToSourceDataMap: { [id: number]: any } = {}
      const summary: { [key: string]: number } = {}

      customizedData.forEach((locationData: any) => {
        const dayStr = dateToDayMap[locationData.startDate]

        const groupInfo = groupFilter?.list?.find(
          (l) =>
            l.id ===
            (hasGroupBy
              ? locationData.locationId
              : locationData.locationGroupId),
        )

        if (!groupInfo?.id || !corporateDetails) {
          return
        }

        let existingData: any = idToSourceDataMap[groupInfo.id]
        if (!existingData) {
          const marketDetail = locationDetails?.find(
            (l: any) => l.locationId === locationData.locationId,
          )

          existingData = {
            groupInfo,
            market: marketDetail?.market,
            ...Object.values(corporateDetails)?.find(
              (cd) => cd.id === groupInfo.id,
            )?.tableRow,
          }
        }

        for (const key in locationData.metricData) {
          if (locationData.metricData.hasOwnProperty(key)) {
            let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            newKey = `${newKey}${dayStr}`

            switch (locationData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                existingData[newKey] = locationData.metricData[key].value * 100
                break

              default:
                existingData[newKey] = locationData.metricData[key].value
                break
            }
          }
        }

        if (locationData.metricSummaryData) {
          const metricSummaryData = locationData.metricSummaryData

          for (const key in metricSummaryData) {
            if (metricSummaryData.hasOwnProperty(key)) {
              let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
              newKey = `${newKey}${dayStr}`

              switch (metricSummaryData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  summary[newKey] = metricSummaryData[key].value * 100
                  break

                default:
                  summary[newKey] = metricSummaryData[key].value
                  break
              }
            }
          }
        }

        idToSourceDataMap[groupInfo.id] = existingData
      })

      return [
        {
          parentId: 'root',
          id: 'summary',
          market: 'Total',
          locationGroup: {
            label: 'Total',
          },
          ...summary,
        },
        ...Object.values(idToSourceDataMap)?.map((d, index) => ({
          parentId: 'summary',
          id: index,
          ...d,
        })),
      ]
    }, [groupFilter, data]),
    loading,
  }
}

export default useLastTwentyEightDaysTrendData
