import _ from 'lodash'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'
import useRegionMetricTrendsData from './useRegionMetricTrendsData'

export const burgerworksTrendLocationSalesmanshipKpisConfigs = {
  date: 'string',
  checkAverage: 'price',
  systemwideCheckAverage: 'price',
  itemAttachmentRateDesserts: 'percent',
  itemAttachmentRateBeverages: 'percent',
  itemAttachmentRateBacon: 'percent',
  itemAttachmentRateCheese: 'percent',
  itemAttachmentRateJalapeno: 'percent',
  upsizedCountPercentage: 'percent',
  systemwideUpsizedCountPercentage: 'percent',
  coloradoRegionCheckAverage: 'price',
  coloradoRegionUpsizedCountPercentage: 'percent',
  texasRegionCheckAverage: 'price',
  texasRegionUpsizedCountPercentage: 'percent',
} as const

const useLocationMetricTrendsHook = buildMetricTrendsHook({
  groupFilterType: 'store',
  metrics: {
    codes: ['check_average', 'upsized_count_percentage'],
    groups: ['Expo Module - Item Attachment Rate'],
  },
})

const useSystemwideMetricTrendsHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['check_average', 'upsized_count_percentage'],
  },
})

const useBurgerworksTrendLocationSalesmanshipKpis = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''

  const { brand } = useBrands()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]

  const { data: locationData, loading: isLocationDataLoading } =
    useLocationMetricTrendsHook(dateRange)
  const { data: systemwideData, loading: isSystemwideDataLoading } =
    useSystemwideMetricTrendsHook(dateRange, {
      customLocationGroupIds: [brandLocationGroupId],
      shouldBypassRowLevelSecurity: true,
    })
  const { data: regionData, loading: isRegionDataLoading } =
    useRegionMetricTrendsData({
      metrics: ['check_average', 'upsized_count_percentage'],
    })

  return {
    data: useMemo((): IApiDataType => {
      if (!locationData || !systemwideData || !regionData) return null

      const timeRangeDataMap: { [timeRange: string]: Record<string, unknown> } =
        {}

      systemwideData.forEach((details: any, index: number) => {
        const timeRange: string = details.date
        if (timeRange) {
          const dataAlreadyInMap = timeRangeDataMap[timeRange] || {}
          timeRangeDataMap[timeRange] = {
            ...dataAlreadyInMap,
            date: details.date,
            timeRangeIndex: index,
            systemwideCheckAverage: details.checkAverage,
            systemwideUpsizedCountPercentage: details.upsizedCountPercentage,
          }
        }
      })

      regionData.forEach((details: any, index: number) => {
        const timeRange: string = details.date
        if (timeRange) {
          const dataAlreadyInMap = timeRangeDataMap[timeRange] || {}
          const regionName = _.camelCase(details.locationGroupName)
          timeRangeDataMap[timeRange] = {
            ...dataAlreadyInMap,
            date: details.date,
            timeRangeIndex: index,
            [`${regionName}RegionCheckAverage`]: details.checkAverage,
            [`${regionName}RegionUpsizedCountPercentage`]:
              details.upsizedCountPercentage,
          }
        }
      })

      locationData.forEach((details: any) => {
        const timeRange: string = details.date
        if (timeRange) {
          const dataAlreadyInMap = timeRangeDataMap[timeRange] || {}
          timeRangeDataMap[timeRange] = {
            ...dataAlreadyInMap,
            ...details,
          }
        }
      })

      return _.orderBy(Object.values(timeRangeDataMap), 'timeRangeIndex')
    }, [locationData, systemwideData, regionData]),
    loading:
      isLocationDataLoading || isSystemwideDataLoading || isRegionDataLoading,
  }
}

export default useBurgerworksTrendLocationSalesmanshipKpis
