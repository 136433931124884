import _ from 'lodash'
import { useMemo } from 'react'

import useDateRange from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import { getDateRangeLabelV2 } from 'pared/utils/date'

import useLocationMetricTrendsQuery, {
  IGroupByType,
  IGroupFilterDataType,
  IMetricType,
  format,
} from '../../../../hooks/useLocationMetricTrendsQuery'
import { IApiDataType } from '../../../types'

const buildMetricTrendsHook = ({
  handler = (data) => data,
  ...options
}: {
  groupFilterTypes: IGroupFilterDataType['type'][]
  dateRange: IGroupByType
  metrics?: IMetricType[]
  metricGroups?: IMetricType[]
  handler?: (data: Record<string, unknown>) => Record<string, unknown>
}) => {
  const useMetricTrends = () => {
    const { data, loading } = useLocationMetricTrendsQuery({
      ...options,
      fields: ['metricSummaryData'],
    })
    const { dateRangeGroupBy } = useDateRange(options.dateRange)

    return {
      data: useMemo((): IApiDataType => {
        if (!data || !dateRangeGroupBy) return null

        return data
          .filter((d) => d.metricSummaryData)
          .map((d) => ({
            ...format(d.metricSummaryData),
            date: getDateRangeLabelV2(dateRangeGroupBy, d),
          }))
      }, [data, dateRangeGroupBy]),
      loading,
    }
  }

  return useMetricTrends
}

export default buildMetricTrendsHook
