import { gql, useQuery } from '@apollo/client'
import { da } from 'date-fns/locale'
import moment from 'moment'
import { useMemo } from 'react'

import { getBrandLocationGroupId } from 'pared/utils/brand'

import { IApiDataType } from '../../types'

interface IDataType {
  trendLocationMetricValues: {
    nodes: {
      locationId: number
      startDate: string
      endDate: string
      metricData: Record<
        typeof METRIC_CODES[number],
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
  listLocationDetailsV2: {
    nodes: {
      id: number
      code: string
      name: string
    }[]
  }
}

const query = gql`
  query GetTrendLocationEodCompletedData(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    trendLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "last_x_days"
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        locationId
        startDate
        endDate
        metricData
      }
    }

    listLocationDetailsV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: { location_group_ids: $iLocationGroupIds }
    ) {
      nodes {
        id
        code
        name
      }
    }
  }
`

export const irmgBkTrendEodCompletedConfigs = {
  date: 'string',
  eodNotCompleted: 'string',
} as const

const METRIC_CODES = ['pos_eod_completed'] as const

const useIrmgBkTrendEodCompleted = () => {
  const brandLocationGroupId = getBrandLocationGroupId()
  // Trailing 7 Days
  const startDateStr = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const endDateStr = moment().subtract(1, 'days').format('YYYY-MM-DD')
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDateStr,
      iEndDate: endDateStr,
      iLocationGroupIds: [brandLocationGroupId],
      iMetricCodes: METRIC_CODES,
    },
    skip: !startDateStr || !endDateStr || !brandLocationGroupId,
  })

  return {
    data: useMemo((): IApiDataType => {
      const metricData = data?.trendLocationMetricValues.nodes
      const locationDetails = data?.listLocationDetailsV2.nodes

      if (!metricData || !locationDetails) return null

      const dates = [] as moment.Moment[]
      for (let i = 0; i < 7; i++) {
        dates.push(moment().subtract(1 + i, 'days'))
      }

      const allLocationCodes = locationDetails.map((l) => l.code)
      const locationIdCodeMap = locationDetails.reduce(
        (s, r) => ({
          ...s,
          [r.id]: r.code,
        }),
        {} as { [id: string]: string },
      )

      const rows = metricData.map((d) => ({
        locationCode: locationIdCodeMap[d.locationId],
        date: d.endDate,
        posEodCompleted: d.metricData['pos_eod_completed'].value,
      }))

      const result = dates.map((date) => {
        const dateData = rows.filter(
          (r) => r.date === date.format('YYYY-MM-DD'),
        )
        const unCompletedLocations =
          dateData.length > 0
            ? dateData
                ?.filter((r) => !r.posEodCompleted)
                ?.map((r) => r.locationCode)
            : allLocationCodes
        const dateStr = date.format('M/D/YYYY')

        return {
          parentId: 'root',
          id: dateStr,
          date: dateStr,
          eodNotCompleted:
            unCompletedLocations
              .sort((a, b) => parseInt(a) - parseInt(b))
              .join(', ') || 'All completed !',
        }
      })

      return result
    }, [data]),
    loading,
  }
}

export default useIrmgBkTrendEodCompleted
