import useBbbAllPeriodMetric, {
  bbbAllPeriodMetricConfigs,
} from './useAllPeriodMetric'
import useBbbFlash, { bbbFlashConfigs } from './useFlash'
import useBbbLocationList, { bbbLocationListConfigs } from './useLocationList'
import useBbbOpsDashboard, { bbbOpsDashboardConfigs } from './useOpsDashboard'
import useBbbPAndL, { bbbPAndLConfigs } from './usePAndL'
import useBbbPnlRankings, { bbbPnlRankingsConfigs } from './usePnLRankings'
import useBbbProfitAfterControllables, {
  bbbProfitAfterControllablesConfigs,
} from './useProfitAfterControllables'
import useBibibopRankings, { bibibopRankingsConfigs } from './useRankings'
import useBbbSystemwideOverview, {
  bbbSystemwideOverviewConfigs,
} from './useSystemwideOverview'
import useBbbTrendPeriodMetric, {
  bbbTrendPeriodMetricConfigs,
} from './useTrendPeriodMetric'

export const configs = {
  bbbSystemwideOverview: bbbSystemwideOverviewConfigs,
  bbbFlash: bbbFlashConfigs,
  bbbPAndL: bbbPAndLConfigs,
  bbbProfitAfterControllables: bbbProfitAfterControllablesConfigs,
  bbbTrendPeriodMetric: bbbTrendPeriodMetricConfigs,
  bbbAllPeriodMetric: bbbAllPeriodMetricConfigs,
  bbbPnlRankings: bbbPnlRankingsConfigs,
  bbbLocationList: bbbLocationListConfigs,
  bbbOpsDashboard: bbbOpsDashboardConfigs,
  bbbRankings: bibibopRankingsConfigs,
}

export const api = {
  bbbSystemwideOverview: useBbbSystemwideOverview,
  bbbFlash: useBbbFlash,
  bbbPAndL: useBbbPAndL,
  bbbProfitAfterControllables: useBbbProfitAfterControllables,
  bbbTrendPeriodMetric: useBbbTrendPeriodMetric,
  bbbAllPeriodMetric: useBbbAllPeriodMetric,
  bbbPnlRankings: useBbbPnlRankings,
  bbbLocationList: useBbbLocationList,
  bbbOpsDashboard: useBbbOpsDashboard,
  bbbRankings: useBibibopRankings,
}
