import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_FORMAT } from 'pared/data/getDateRanges'

import { useGroupFilter } from '../../groupFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'
import { useCorporateExpoAiLossPreventionLocationDetails } from './useCorporateExpoAiLossPreventionSummary'
import { IGroupInfoType } from './useGroupInfoColumns'

interface ILossPreventionOutliersType {
  listEmployeesLossPreventionOutliers: {
    nodes: {
      locationId: number
      employeeName: string
      outliersType: string
      outliersValue: number
      outliersTime: string
      checkNumber: string
    }[]
  }
}

interface ILossPreventionOutliersGroupType {
  locationId: number
  voidOutliers: number
  discountOutliers: number
}

const query = gql`
  query ListFarwestLossPreventionOutliers(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listEmployeesLossPreventionOutliers(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        employeeName
        outliersType
        outliersValue
        outliersTime
        checkNumber
      }
    }
  }
`

export const farwestCorporateExpoAiLossPreventionDetailsConfigs = {
  type: 'string',
  annualOpportunityCost: 'price',
  voidCount: 'number',
  voidCountPercent: 'percent',
  cashOverOrShort: 'price',
  voidOutliers: 'number',
  discountOutliers: 'number',
} as const

const useFarwestCorporateExpoAiLossPreventionDetails = () => {
  const { variables } = useVariables()
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()

  const { data: corperateDetailsData, loading: corperateDetailsLoading } =
    useCorporateExpoAiLossPreventionLocationDetails()
  const { data: outliersData, loading: outliersLoading } =
    useQuery<ILossPreventionOutliersType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_group_ids: groupFilter?.ids,
        },
      },
      skip: !startDate || !endDate || !groupFilter,
    })

  return {
    data: useMemo((): IApiDataType => {
      const outlier = outliersData?.listEmployeesLossPreventionOutliers?.nodes
      const dicountingType = variables?.aiSummary['uLossPrevention']?.clickedId

      if (!corperateDetailsData || !outlier || !dicountingType) return null
      if (
        !['discountOutliers', 'voidOutliers'].includes(dicountingType as string)
      )
        return corperateDetailsData

      const outlierMap = new Map<number, ILossPreventionOutliersGroupType>()
      const filterOutliers = outlier.filter((o) => {
        if (dicountingType === 'discountOutliers')
          return o.outliersType === 'discount'
        if (dicountingType === 'voidOutliers') return o.outliersType === 'void'
      })

      for (const o of filterOutliers) {
        if (!outlierMap.has(o.locationId)) {
          outlierMap.set(o.locationId, {
            locationId: o.locationId,
            voidOutliers: 0,
            discountOutliers: 0,
          })
        }

        if (o.outliersType === 'discount')
          outlierMap.get(o.locationId)!.discountOutliers++
        if (o.outliersType === 'void')
          outlierMap.get(o.locationId)!.voidOutliers++
      }

      return {
        source:
          Array.from(outlierMap.values())
            .map((o) => {
              const locationDetail = groupFilter?.list?.find(
                (g) => g.id == o.locationId,
              )

              const adjustLink = (() => {
                const originLink = locationDetail?.link?.replace(
                  'store_detail',
                  'loss_prevention',
                )

                if (!originLink) return originLink

                const [urlStr, queryString] = originLink.split(/\?/)
                const urlParams = new URLSearchParams(queryString || '')

                urlParams.delete('date_range')

                return `${urlStr}?${urlParams.toString()}`
              })()

              const groupInfo = {
                ...locationDetail,
                link: adjustLink,
              } as IGroupInfoType

              return {
                groupInfo,
                voidOutliers: o.voidOutliers,
                discountOutliers: o.discountOutliers,
              }
            })
            .sort((a, b) => {
              if (dicountingType === 'discountOutliers') {
                return b.discountOutliers - a.discountOutliers
              } else {
                return b.voidOutliers - a.voidOutliers
              }
            }) || [],
      }
    }, [corperateDetailsData, outliersData, groupFilter]),
    loading: corperateDetailsLoading || outliersLoading,
  }
}

export default useFarwestCorporateExpoAiLossPreventionDetails
