import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const dhDeliveryConfig: IConfigsType = {
  dh_wb: {
    '/:brand/corporate_delivery': {
      variables: ['corporateGroup', 'lastTimeRange'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
        ],
      },
      title: {
        type: 'title',
        title: 'Systemwide Delivery',
        subTitle: ['Updated Weekly'],
      },
      listLocationGroupDeliveryKpis: {
        type: 'list',
        api: 'dhWbListLocationGroupDeliveryKpis',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'deliveryTotalOrderCountDoordash',
            },
            bottom: '# of Delivery Orders	',
          },
          {
            content: {
              key: 'deliverySumSubtotalDoordash',
            },
            bottom: 'Delivery Sales',
          },
          {
            content: {
              key: 'deliverySumErrorChargesDoordash',
            },
            bottom: '$ of Adjustments',
          },
          {
            content: {
              key: 'deliveryAvgDasherWaitTimeDoordash',
              decimal: 1,
              format: '<%- valueStr + " min" %>',
            },
            bottom: 'Average Driver Wait Time',
          },
          {
            content: {
              key: 'deliveryOrderWithAnyIssuePercentDoordash',
              decimal: 1,
            },
            bottom: '% Orders w/ Any Issues',
          },
          {
            content: {
              key: 'deliveryAvgCustomerReviewScoreDoordash',
              decimal: 1,
            },
            bottom: 'Combined Star Rating',
          },
        ],
      },
      breakdownOfIssuesTitle: {
        type: 'title',
        title: 'Breakdown of Order Issues',
      },
      breakdownOfIssuesKpis: {
        type: 'list',
        api: 'dhWbListLocationGroupDeliveryKpis',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'deliveryInaccurateOrderPercentDoordash',
              decimal: 1,
            },
            bottom: 'Accuracy',
          },
          {
            content: {
              key: 'deliveryCancelledOrderPercentDoordash',
              decimal: 1,
            },
            bottom: 'Cancellations',
          },
          {
            content: {
              key: 'deliveryDelayedOrderPercentDoordash',
              decimal: 1,
            },
            bottom: 'Lateness',
          },
        ],
      },
      deliveryTrendFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Delivery Chart',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
          },
        },
      },
      deliveryTrendChart: {
        type: 'line-chart',
        api: 'dhWbTrendLocationGroupDelivery',
        width: 1100,
        height: 500,
        label: 'date',
        datasets: [
          {
            key: 'deliveryOrderWithAnyIssuePercentDoordash',
            label: 'Orders w/ Any Issues',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
            chartType: 'line',
            decimal: 1,
          },
          {
            key: 'deliveryInaccurateOrderPercentDoordash',
            label: 'Accuracy',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
            chartType: 'line',
            decimal: 1,
          },
          {
            key: 'deliveryCancelledOrderPercentDoordash',
            label: 'Cancellations',
            borderColor: COLORS.Expo,
            backgroundColor: COLORS.Expo,
            chartType: 'line',
            decimal: 1,
          },
          {
            key: 'deliveryDelayedOrderPercentDoordash',
            label: 'Lateness',
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
            chartType: 'line',
            decimal: 1,
          },
          {
            key: 'deliveryTotalOrderCountDoordash',
            label: '# of Delivery Orders',
            borderColor: COLORS.Smoke,
            backgroundColor: COLORS.Smoke,
            chartType: 'bar',
            yAxisID: 'y1',
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
            decimal: 0,
          },
          y1: {
            type: 'number',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {},
            y1: {
              title: {
                text: '# of Delivery Orders',
                display: true,
              },
              position: 'right',
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
      breakdownByStore: {
        type: 'table-v2',
        title: 'Breakdown By Store',
        api: 'dhWbListLocationGroupDeliveryData',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'deliveryTotalOrderCountDoordash',
            title: '# of Delivery Orders',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'deliverySumSubtotalDoordash',
            title: 'Delivery Sales $',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'deliverySumErrorChargesDoordash',
            title: '$ of Adjustments',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'deliveryAvgDasherWaitTimeDoordash',
            title: 'Average Driver Wait Time',
            width: '150px',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'deliveryOrderWithAnyIssuePercentDoordash',
            title: '% Orders w/ Any Issues',
            width: '150px',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'deliveryAvgCustomerReviewScoreDoordash',
            title: 'Combined Star Rating',
            width: '150px',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
        ],
      },
    },
    '/:brand/delivery': {
      variables: ['lastTimeRange', 'deliveryKpiType', 'deliveryDayParts'],
      groupFilter: {
        api: 'locationFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year'],
      },
      title: {
        type: 'title',
        title: 'Delivery',
        subTitle: ['Updated Weekly'],
      },
      expoAi: {
        type: 'ai-summary-box',
        api: 'locationDeliveryAiSummary',
      },
      listLocationDeliveryKpis: {
        type: 'list',
        api: 'dhWbListLocationDeliveryKpis',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'deliveryTotalOrderCountDoordash',
            },
            bottom: '# of Delivery Orders	',
          },
          {
            content: {
              key: 'deliverySumSubtotalDoordash',
            },
            bottom: 'Delivery Sales',
          },
          {
            content: {
              key: 'deliverySumErrorChargesDoordash',
            },
            bottom: '$ of Adjustments',
          },
          {
            content: {
              key: 'deliveryAvgDasherWaitTimeDoordash',
              decimal: 1,
              format: '<%- valueStr + " min" %>',
            },
            bottom: 'Average Driver Wait Time',
          },
          {
            content: {
              key: 'deliveryOrderWithAnyIssuePercentDoordash',
              decimal: 1,
            },
            bottom: '% Orders w/ Any Issues',
          },
          {
            content: {
              key: 'deliveryAvgCustomerReviewScoreDoordash',
              decimal: 1,
            },
            bottom: 'Combined Star Rating',
          },
        ],
      },
      breakdownOfIssuesTitle: {
        type: 'title',
        title: 'Breakdown of Order Issues',
      },
      breakdownOfIssuesKpis: {
        type: 'list',
        api: 'dhWbListLocationDeliveryKpis',
        fieldWidth: '160px',
        fields: [
          {
            content: {
              key: 'deliveryInaccurateOrderPercentDoordash',
              decimal: 1,
            },
            bottom: 'Accuracy',
          },
          {
            content: {
              key: 'deliveryCancelledOrderPercentDoordash',
              decimal: 1,
            },
            bottom: 'Cancellations',
          },
          {
            content: {
              key: 'deliveryDelayedOrderPercentDoordash',
              decimal: 1,
            },
            bottom: 'Lateness',
          },
        ],
      },
      correctOrderPercentTrendTimeRangeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Correct Order Percent Trend',
          },
          columns: {
            type: 'select',
            value: 'lastTimeRange.value',
            onChange: 'lastTimeRange.onChange',
            options: 'lastTimeRange.options',
          },
        },
      },
      correctOrderPercentTrendChart: {
        type: 'line-chart',
        api: 'dhWbTrendLocationDeliveryKpis',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'deliveryCorrectOrderPercentDoordash',
            label: 'Store Correct Order Percent',
            decimal: 2,
          },
          {
            key: 'systemwideDeliveryCorrectOrderPercentDoordash',
            label: 'Systemwide Correct Order Percent',
            decimal: 2,
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: 'Correct Order Percent',
              },
            },
          },
        },
      },
      deliveryKpiTypeFilters: {
        type: 'grid',
        children: {
          title: {
            type: 'title',
            title: 'Staff at a Glance',
          },
          columns: {
            type: 'select',
            value: 'deliveryKpiType.value',
            onChange: 'deliveryKpiType.onChange',
            options: 'deliveryKpiType.options',
          },
        },
      },
      staffAtAGlanceTable: {
        type: 'table-v2',
        api: 'dhWbListEmployeeDeliveryData',
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        sorting: [
          {
            id: 'deliveryOrderWithAnyIssuePercentDoordash',
            desc: true,
          },
        ],
        columns: [
          {
            key: 'employeeName',
            title: 'Employee Name',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'deliveryOrderWithAnyIssuePercentDoordash',
            title: '% Orders w/ Any Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithAnyIssuePercent")) %>',
            align: 'center',
            width: '225px',
            sortable: true,
          },
          {
            key: 'deliveryInaccurateOrderPercentDoordash',
            title: '% Orders w/ Accuracy Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithAccuracyIssuePercent")) %>',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'deliveryCancelledOrderPercentDoordash',
            title: '% Orders w/ Cancellations Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithCancellationsIssuePercent")) %>',
            align: 'center',
            width: '275px',
            sortable: true,
          },
          {
            key: 'deliveryDelayedOrderPercentDoordash',
            title: '% Orders w/ Lateness Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithLatenessIssuePercent")) %>',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'deliveryAvgCustomerReviewScoreDoordash',
            title: 'Combined Star Rating',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("combinedStarRating")) %>',
            align: 'center',
            width: '225px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'deliveryTotalOrderCountDoordash',
            title: '# of Orders',
            align: 'center',
            width: '150px',
            sortable: true,
          },
        ],
      },
      deliveryDayTrendsTitle: {
        type: 'title',
        title: 'Day Trends',
      },
      deliveryDayTrendsFilters: {
        type: 'grid',
        children: {
          deliveryKpiTypeSelector: {
            type: 'select',
            value: 'deliveryKpiType.value',
            onChange: 'deliveryKpiType.onChange',
            options: 'deliveryKpiType.options',
            label: 'Filter by',
          },
          deliveryDayPartsSelector: {
            type: 'select',
            value: 'deliveryDayParts.value',
            onChange: 'deliveryDayParts.onChange',
            options: 'deliveryDayParts.options',
          },
        },
      },
      dayTrendsTable: {
        type: 'table-v2',
        api: 'dhWbListLocationDeliveryDayTrendsData',
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        columns: [
          {
            key: 'dayOfWeekName',
            title: 'Day of Week',
            align: 'center',
            width: '150px',
          },
          {
            key: 'allOrderWithAnyIssuePercent',
            title: '% Orders w/ Any Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithAnyIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("all")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'lunchOrderWithAnyIssuePercent',
            title: '% Orders w/ Any Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithAnyIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("lunch")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'dinnerOrderWithAnyIssuePercent',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithAnyIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("dinner")) %>',
            title: '% Orders w/ Any Issues',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'allInaccurateOrderPercent',
            title: '% Orders w/ Accuracy Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithAccuracyIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("all")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'lunchInaccurateOrderPercent',
            title: '% Orders w/ Accuracy Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithAccuracyIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("lunch")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'dinnerInaccurateOrderPercent',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithAccuracyIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("dinner")) %>',
            title: '% Orders w/ Accuracy Issues',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'allCancelledOrderPercent',
            title: '% Orders w/ Cancellations Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithCancellationsIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("all")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'lunchCancelledOrderPercent',
            title: '% Orders w/ Cancellations Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithCancellationsIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("lunch")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'dinnerCancelledOrderPercent',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithCancellationsIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("dinner")) %>',
            title: '% Orders w/ Cancellations Issues',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'allDelayedOrderPercent',
            title: '% Orders w/ Lateness Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithLatenessIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("all")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'lunchDelayedOrderPercent',
            title: '% Orders w/ Lateness Issues',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithLatenessIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("lunch")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'dinnerDelayedOrderPercent',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("orderWithLatenessIssuePercent")) || !deliveryDayParts.value.some(v => v.includes("dinner")) %>',
            title: '% Orders w/ Lateness Issues',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'allAvgCustomerReviewScore',
            title: 'Combined Star Rating',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("combinedStarRating")) || !deliveryDayParts.value.some(v => v.includes("all")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'lunchAvgCustomerReviewScore',
            title: 'Combined Star Rating',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("combinedStarRating")) || !deliveryDayParts.value.some(v => v.includes("lunch")) %>',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'dinnerAvgCustomerReviewScore',
            hide: '<%- !deliveryKpiType.value.some(v => v.includes("combinedStarRating")) || !deliveryDayParts.value.some(v => v.includes("dinner")) %>',
            title: 'Combined Star Rating',
            align: 'center',
            width: '250px',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'allTotalOrderCount',
            title: '# of Orders',
            hide: '<%- !deliveryDayParts.value.some(v => v.includes("all")) %>',
            align: 'center',
            width: '150px',
            sortable: true,
          },
          {
            key: 'lunchTotalOrderCount',
            title: '# of Orders',
            hide: '<%- !deliveryDayParts.value.some(v => v.includes("lunch")) %>',
            align: 'center',
            width: '150px',
            sortable: true,
          },
          {
            key: 'dinnerTotalOrderCount',
            title: '# of Orders',
            hide: '<%- !deliveryDayParts.value.some(v => v.includes("dinner")) %>',
            align: 'center',
            width: '150px',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default dhDeliveryConfig
