import { Table, Row as TableRow } from '@tanstack/react-table'
import _ from 'lodash'
import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'

import { useVariables } from '../../variables'
import { IDataType } from './useData'

type IBaseRowStyleType = Partial<Record<typeof STYLE_KEYS[number], string>>
type ITdNthChildType = `${
  | ''
  | ', '
  | '&:hover '
  | ', &:hover '}td:nth-child(${number})`

export type IOtherStyleKeyType = `${ITdNthChildType}${ITdNthChildType}`

interface IRowConfigType
  extends Partial<Record<IOtherStyleKeyType, IBaseRowStyleType>>,
    IBaseRowStyleType {
  index: number
  onDrop?: string
}

export interface IRowType {
  row: IRowConfigType
  table: Table<IDataType>
  rowData: TableRow<IDataType> | null
}

export interface IRowStyleType
  extends Omit<IRowConfigType, 'index' | 'onDrop'> {}

const STYLE_KEYS = ['background', 'bold', 'fontSize', 'opacity'] as const

const getStyle = (
  row: IBaseRowStyleType & Pick<IRowConfigType, 'index'>,
  table: IRowType['table'],
  rowData: NonNullable<IRowType['rowData']>,
  template: ReturnType<typeof useVariables>['template'],
  hasDefault: boolean = true,
) => {
  const canSomeRowsExpand = table.getCanSomeRowsExpand()
  const rowStyle = !hasDefault
    ? row
    : {
        ...row,
        fontSize:
          row.fontSize ||
          (!canSomeRowsExpand
            ? undefined
            : '<%- depth >= 3 ? 14 : (18 - depth * 2) %>px'),
        bold:
          row.bold || (!canSomeRowsExpand ? undefined : '<%- depth === 0 %>'),
      }

  return (Object.keys(rowStyle) as (keyof typeof row)[]).reduce(
    (result, key) =>
      !rowStyle[key] || key === 'index'
        ? result
        : {
            ...result,
            [key]: template(rowStyle[key] as string, {
              external: {
                id: rowData.id,
                depth: rowData.depth,
                index: rowStyle.index,
                colors: COLORS,
              },
            }),
          },
    {} as IRowStyleType,
  )
}

const useRowStyle = (options: IRowType) => {
  const { template } = useVariables()

  return useMemo((): IRowStyleType => {
    const { row, table, rowData } = options

    if (!rowData) return {} as IRowStyleType

    return Object.keys(row).reduce(
      (result, key) =>
        [...STYLE_KEYS, 'index', 'onDrop'].includes(key)
          ? result
          : {
              ...result,
              [key]: getStyle(
                { ...row[key as IOtherStyleKeyType], index: row.index },
                table,
                rowData,
                template,
                false,
              ),
            },
      getStyle(_.pick(row, [...STYLE_KEYS, 'index']), table, rowData, template),
    )
  }, [options])
}

export default useRowStyle
