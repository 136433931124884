import React from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import useGetDirectors from 'pared/hooks/useGetDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'
import { getBrand } from 'pared/utils/brand'

import HistoricalPerformance from './HistoricalPerformance'
import PerformanceMartix from './PerformanceMartix'

interface IPropsType {
  navParams: INavParams
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 36px;
    font-weight: 700;
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;

  @media ${MOBILE_WIDTH} {
    position: initial;
    height: auto;
    padding: 0px 25px;
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const Container = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

const Product = ({ navParams }: IPropsType) => {
  const brand = getBrand()
  const dateRange = useGetDateRange()
  const { director, directors, directorChange, locationGroupIds } =
    useGetDirectors()

  const hideMainDining = ['mwb'].includes(brand)

  return (
    <>
      <Header>
        <div>
          <h1>Product</h1>

          {hideMainDining ? null : (
            <SubtitleContainer>Main Dining and Bar Only</SubtitleContainer>
          )}
          {brand === EDDIE_MERLOTS_BRAND_CODE ? (
            <SubtitleContainer>
              This page uses CrunchTime data, so some locations may not be
              included yet
            </SubtitleContainer>
          ) : null}
        </div>

        <Selectors>
          {brand === 'mwb' ? null : (
            <DirectorFilterSelector
              selectedDirector={director}
              allDirectors={directors}
              onDirectorChange={directorChange}
              disabledDirectors
            />
          )}

          <DateRangeSelector navParams={navParams} />
        </Selectors>
      </Header>

      <Container>
        <PerformanceMartix
          dateRange={dateRange}
          locationGroupIds={locationGroupIds}
        />

        <HistoricalPerformance
          dateRange={dateRange}
          locationGroupIds={locationGroupIds}
        />
      </Container>
    </>
  )
}

export default React.memo(Product) as unknown as typeof Product
