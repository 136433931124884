import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import {
  IGroupFilterType,
  buildGroupFilterConfigs,
} from '../utils/buildGroupFilter'

export interface IOptionType {
  locationGroupIds?: number[]
}

interface IQueryDataType {
  listDirectors: {
    nodes: {
      employeeId: number
      locationGroupId: number
      firstName: string
      lastName: string
    }[]
  }
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
      }[]
    }[]
  }
}

export interface IDataType {
  allStores?: IGroupFilterType<'listLocation'>
}

const query = gql`
  query ListLocationdetails($iLocationGroupIds: [Int!]!) {
    listDirectors(iFilter: { location_group_ids: $iLocationGroupIds }) {
      nodes {
        employeeId
        locationGroupId
        firstName
        lastName
      }
    }

    listLocationDetails(iFilter: { location_group_ids: $iLocationGroupIds }) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }
  }
`

const DISABLE_GROUP_BY_LINK = ['ghai_pop', 'bibibop', 'fw_wingstop']

export const tableConfigs = buildGroupFilterConfigs('allStores')

const useAllStores = ({
  locationGroupIds: optionLocationGroupIds,
}: IOptionType) => {
  const { brand } = useBrands()
  const locationGroupIds = optionLocationGroupIds || [
    BRAND_LOCATION_GROUP_ID[brand],
  ]
  const { data } = useQuery<IQueryDataType>(query, {
    variables: {
      iLocationGroupIds: locationGroupIds,
      iMetricCodes: ['total_food_and_paper', 'total_sales'],
    },
  })
  const groupByLabel = getBusinessLabel('director')
  const { search } = useLocation()

  return {
    allStores: useMemo((): IDataType['allStores'] => {
      const listLocationDetails = data?.listLocationDetails.nodes

      if (!listLocationDetails) return

      return {
        type: 'listLocation',
        tableColumns: [
          {
            key: 'location',
            title: 'Stores',
            onClick: 'track.locationClicked',
            width: '200px',
          },
          {
            key: 'groupBy',
            title: groupByLabel,
            onClick: 'track.groupByClicked',
            width: '200px',
          },
        ],
        locationGroupIds,
        locations: listLocationDetails.reduce(
          (result, { locationGroups, ...location }) => {
            const director = data.listDirectors.nodes.find((d) =>
              locationGroups.some((lg) => lg.id === d.locationGroupId),
            )
            const queryObject = new URLSearchParams(search)

            queryObject.set('store', location.id.toString())

            const link = `/${brand}/store_detail?${queryObject.toString()}`
            const displayName = `${location.code} - ${location.name}`
            const groupBy = director && {
              id: director.employeeId,
              displayName: `${director.firstName} ${director.lastName}`.trim(),
              link:
                !DISABLE_GROUP_BY_LINK.includes(brand) &&
                `/${brand}/employee_profile/${director.employeeId}`,
            }

            return {
              ...result,
              [location.id]: {
                ...location,
                displayName: `${location.code} - ${location.name}`,
                link,
                groupBy,
                tableRow: {
                  location: {
                    id: location.id,
                    link,
                    label: displayName,
                  },
                  groupBy: groupBy && {
                    id: groupBy.id,
                    link: groupBy.link,
                    label: groupBy.displayName,
                  },
                },
              },
            }
          },
          {},
        ),
      }
    }, [locationGroupIds, data, groupByLabel, search]),
  }
}

export default useAllStores
