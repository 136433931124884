import { useMemo } from 'react'

import useCorporateGroupFilter from '../../groupFilter/hooks/useCorporateFilter'
import useSalesAlertDashboard, {
  salesAlertDashboardConfig,
} from '../../tableV2/hooks/fwWingstop/useSalesAlertDashboard'

export const fwWingstopSalesAlertDashboardConfig = {
  ...salesAlertDashboardConfig,
} as const

const useFwWingstopSalesAlertDashboard = () => {
  const { data, loading } = useSalesAlertDashboard()
  const corporateGroupFilter = useCorporateGroupFilter()

  return {
    data: useMemo(() => {
      const groupFilter = corporateGroupFilter.data?.values.find(
        (v) => v.id === 'all-stores',
      )

      if (
        !data ||
        !groupFilter ||
        !('list' in groupFilter) ||
        !groupFilter.list
      )
        return null

      const dataWithIndex = data.map((d, index) => ({
        ...d,
        rowIndex: index + 1,
      }))

      return {
        source: groupFilter.list
          .map((l) => {
            const locationData = dataWithIndex.find(
              (d) => d.id === l.id.toString(),
            )

            return {
              ...locationData,
              groupInfo: l,
            }
          })
          .sort((a, b) => (a.rowIndex || 0) - (b.rowIndex || 0)),
        summary: null,
      }
    }, [data, corporateGroupFilter]),
    loading,
  }
}

export default useFwWingstopSalesAlertDashboard
