import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

const QUERY_NAME = 'listLocationGroupMetricValues'
const query = gql`
  query LocationGroupRevenueCenter(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    ${QUERY_NAME}(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        metricSummaryData
      }
    }
  }
`

export const locationGroupRevenueCenterConfigs = {
  name: 'string',
  sales: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  percentSales: 'percent',
} as const

const useLocationGroupRevenueCenter = (): IApiType => {
  const dateFilter = useDateFilter()
  const groupFilter = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()
  const { variables } = useVariables()

  const { startDate, endDate } = useMemo(() => {
    const dateFilterInfo = variables.date?.getInfo(0)

    return {
      startDate: dateFilterInfo?.dateRange.startDateStr || dateFilter.startDate,
      endDate: dateFilterInfo?.dateRange.endDateStr || dateFilter.endDate,
    }
  }, [variables, dateFilter])
  const locationGroupIds = useMemo(() => {
    const groupFilterInfo = variables.groupFilter?.getInfo([
      'listLocation',
      'listLocationGroup',
    ])

    return (
      groupFilterInfo?.locationGroupIds ||
      (groupFilter.hasGroupBy
        ? groupFilter.groupFilter?.ids
        : [brandLocationGroupId])
    )
  }, [variables, groupFilter, groupFilter])

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: locationGroupIds,
        metric_groups: [
          'Expo Module - Revenue Center Sales',
          'Expo Module - Revenue Center Check Count',
          'Expo Module - Revenue Center Check Average',
          'Expo Module - Fundamental Sales',
        ],
      },
    },
    skip: !startDate || !endDate || !locationGroupIds,
  })

  return {
    data: useMemo(() => {
      const nodes = data?.[QUERY_NAME].nodes as
        | {
            locationGroupId: string
            metricSummaryData: {
              [key: string]: {
                name: string
                unit: string
                value: number
              }
            }
          }[]
        | undefined

      if (!nodes) return null
      const mappedData: {
        [revCenter: string]: {
          [kpi: string]: number | string
        }
      } = {}
      const metricSummaryData = nodes.find(
        (n) => n.metricSummaryData,
      )?.metricSummaryData

      let netSales = 0
      for (const key in metricSummaryData) {
        if (key === 'net_sales') {
          if (metricSummaryData[key].unit === 'CENT') {
            netSales = metricSummaryData[key].value / 100
          } else {
            netSales = metricSummaryData[key].value
          }
          continue
        }

        const { kpi, revCenter } = (() => {
          const kpiAndRevCenterSales = key.split('revenue_center_sales_')?.[1]
          if (kpiAndRevCenterSales)
            return {
              kpi: 'sales',
              revCenter: kpiAndRevCenterSales,
            }

          const kpiAndRevCenterCheckCount = key.split(
            'revenue_center_check_count_',
          )?.[1]
          if (kpiAndRevCenterCheckCount)
            return {
              kpi: 'checkCount',
              revCenter: kpiAndRevCenterCheckCount,
            }

          const kpiAndRevCenterCheckAvg = key.split(
            'revenue_center_check_average_',
          )?.[1]
          if (kpiAndRevCenterCheckAvg)
            return {
              kpi: 'checkAverage',
              revCenter: kpiAndRevCenterCheckAvg,
            }

          return {
            kpi: '',
            revCenter: '',
          }
        })()

        if (!mappedData[revCenter]) {
          mappedData[revCenter] = {
            name: metricSummaryData[key].name.split(' - ')[1],
            [kpi]: metricSummaryData[key].value,
          }
        } else {
          mappedData[revCenter][kpi] = metricSummaryData[key].value
        }
      }

      const rows = Object.values(mappedData)
        ?.filter((n) => n.sales)
        ?.map((n) => {
          return {
            ...n,
            percentSales: (100 * (n.sales as number)) / netSales,
            id: n.name as string,
            parentId: 'root',
          }
        })

      return rows
    }, [groupFilter, data]),
    loading,
  }
}

export default useLocationGroupRevenueCenter
