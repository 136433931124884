import { useMemo } from 'react'

import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

export const r365LocationsConfigs = {
  legalEntityName: 'string',
  locationNumber: 'string',
  locationName: 'string',
} as const

const useR365Locations = (): IApiType => {
  const { variables } = useVariables()
  const r365Locations = variables?.pnlConfig?.r365Locations ?? []
  const loading = variables?.pnlConfig?.isLoading
  const locations = r365Locations
    .map(({ locationId, name, locationNumber, legalEntityName }) => ({
      id: locationId,
      parentId: 'root',
      legalEntityName: legalEntityName,
      locationNumber,
      locationName: name,
    }))
    .sort((a, b) => a.locationNumber.localeCompare(b.locationNumber))

  return {
    data: useMemo(() => {
      const rows = locations
      return rows
    }, [variables?.pnlConfig?.r365Locations]),
    loading,
  }
}

export default useR365Locations
