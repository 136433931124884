import { useMemo, useState } from 'react'

export interface IOptionType {
  options: Record<
    string,
    {
      defaultId: string[][]
      options: {
        id: string
        displayName: string
      }[]
    }
  >
}

export interface IDataType {
  selectOptions?: Record<
    string,
    {
      value: string[][]
      displayName: string[]
      options: {
        id: string
        parentId: string
        displayName: string
      }[]
      onChange: (value: string[][]) => void
    }
  >
}

const useSelectOptions = ({ options }: IOptionType): IDataType => {
  const defaultOptions = useMemo(
    () =>
      Object.keys(options).reduce(
        (result, key) => ({
          ...result,
          [key]: options[key].defaultId,
        }),
        {} as Record<string, string[][]>,
      ),
    [options],
  )
  const selectOptionsHook = useState(defaultOptions)

  return {
    selectOptions: useMemo(
      () =>
        Object.keys(options).reduce(
          (result, key) => ({
            ...result,
            [key]: {
              value: selectOptionsHook[0][key],
              displayName: selectOptionsHook[0][key].map(
                (s) =>
                  options[key].options.find((o) => o.id === s[0])?.displayName,
              ),
              options: options[key].options.map((o) => ({
                ...o,
                parentId: 'root',
              })),
              onChange: (value: string[][]) =>
                selectOptionsHook[1]((prev) => ({
                  ...prev,
                  [key]: value,
                })),
            },
          }),
          {},
        ),
      [options, selectOptionsHook],
    ),
  }
}

export default useSelectOptions
