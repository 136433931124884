import axios from 'axios'
import { useEffect, useState } from 'react'

import { getBrand } from 'pared/utils/brand'

export interface IDataType {
  pnlConfig: {
    status: string
    company: string
    setCompany: (company: string) => void
    username: string
    setUsername: (username: string) => void
    password: string
    setPassword: (password: string) => void
    fetchLegalEntities: () => Promise<void>
    r365Locations: {
      legalEntityId: string
      legalEntityNumber: string
      legalEntityName: string
      locationId: string
      name: string
      locationNumber: string
    }[]
    needCredentials: boolean
    isLoading: boolean
  }
}

const usePnlConfig = (): IDataType => {
  const [company, setCompany] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [r365Locations, setR365Locations] = useState([])
  const [needCredentials, setNeedCredentials] = useState(false)
  const [initialFetch, setInitialFetch] = useState(false)
  const [status, setStatus] = useState('Connecting to R365...')
  const [isLoading, setIsLoading] = useState(false)
  const brand = getBrand()

  const fetchLegalEntities = async () => {
    try {
      setIsLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_BE_BASE_URL}/r365/location`,
        {
          brand,
          company,
          username: company && username ? `${company}/${username}` : '',
          password,
          initialFetch,
        },
      )

      const locations = response?.data ?? []
      setR365Locations(locations)
      setNeedCredentials(false)
      setStatus('Successful connection to R365')
      setCompany('')
      setUsername('')
      setPassword('')
    } catch (error: any) {
      if (error?.response?.data?.initialFetch) {
        setInitialFetch(true)
      }

      setStatus(error?.response?.data?.message)
      setNeedCredentials(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchLegalEntities()
  }, [])

  return {
    pnlConfig: {
      status,
      company,
      setCompany,
      username,
      setUsername,
      password,
      setPassword,
      fetchLegalEntities,
      r365Locations,
      needCredentials,
      isLoading,
    },
  }
}

export default usePnlConfig
