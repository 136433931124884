import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { isEqual } from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'
import styled from 'styled-components'

import ExpoSelect from 'pared/components/basicUi/select'

import GroupFilterProvider, {
  GroupFilterContext,
  useGroupFilter,
} from './GroupFilterProvider'
import useApi, { IApiKeyType } from './hooks/useApi'
import { IDataType } from './types'

export type IPropsType<K extends IApiKeyType = IApiKeyType> =
  | {
      api: K
      defaultValue?: string[]
      hide?: true
    }
  | {
      api: K
      defaultValue?: string[]
      hide?: true
      autoComplete: true
      // FIXME: should auto detect option width
      width: number
    }

const StyledFormControl = styled(FormControl)`
  min-width: 150px;
`

const Loading = styled(TextField)`
  font-family: Lexend-Regular;

  .MuiInputBase-root {
    border-radius: 0;
  }
`

const GroupFilter = ({
  api,
  hide,
  defaultValue: orginDefaultValue,
  ...props
}: IPropsType<IApiKeyType>) => {
  const { data: dataSource } = useApi(api)
  const defaultValue = useMemo(
    () => orginDefaultValue || dataSource?.defaultValue || [],
    [dataSource, orginDefaultValue],
  )
  const data = useMemo(() => {
    if ('autoComplete' in props) {
      return dataSource?.values
    }

    return !dataSource
      ? null
      : [
          {
            id: 'root',
            parentId: null,
            label: 'root',
          },
          ...(dataSource?.values || []),
        ]
  }, [dataSource])

  const [selectedGroup, setSelectedGroup] = useContext(GroupFilterContext)
  const [selectedGroups, setSelectedGroups] = useState(defaultValue)
  const location = useLocation()
  const prevLocationPath = usePrevious(location.pathname)

  useEffect(() => {
    if (prevLocationPath !== location.pathname) {
      const defaultSelectGroup =
        data?.find((d) => d.id === defaultValue[defaultValue.length - 1]) ||
        null

      setSelectedGroup(defaultSelectGroup as IDataType)
    } else {
      const queryObject = new URLSearchParams(location.search)
      const store = queryObject.get('store')
      const selectedGroup = data?.find((d) => d.id === store)
      const selectedGroups =
        data?.filter(({ id }) => id === store).map((sg) => sg.id) ?? []
      if (selectedGroup) setSelectedGroup(selectedGroup as IDataType)
      if (selectedGroups.length > 0) setSelectedGroups(selectedGroups)
    }
  }, [location, defaultValue, setSelectedGroup, setSelectedGroups])

  useEffect(() => {
    if ('autoComplete' in props) return

    const currentSelectedGroups = ((filteredGroups) =>
      (filteredGroups || []).length === 0
        ? defaultValue
        : (filteredGroups as string[]))(
      data?.filter((d) => selectedGroups.includes(d.id))?.map((sg) => sg.id),
    )

    if (!isEqual(currentSelectedGroups, selectedGroups))
      setSelectedGroups(currentSelectedGroups)
  }, [data, defaultValue, selectedGroups])

  useEffect(() => {
    const currentSelectedGroup =
      data?.find((d) => d.id === selectedGroup?.id) ||
      data?.find((d) => d.id === defaultValue[defaultValue.length - 1]) ||
      null

    if (!isEqual(currentSelectedGroup, selectedGroup))
      setSelectedGroup(currentSelectedGroup as IDataType)
  }, [data, defaultValue, selectedGroup])

  if (hide) return null

  if ((data || []).length === 0)
    return (
      <StyledFormControl variant="outlined" margin="dense">
        <Loading
          placeholder="Loading..."
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ),
          }}
          disabled
        />
      </StyledFormControl>
    )

  if ('autoComplete' in props) {
    return (
      <StyledFormControl variant="outlined" margin="dense">
        <Autocomplete
          style={{ width: props.width }}
          value={selectedGroup}
          onChange={(_, value, reason) => {
            if (reason === 'selectOption') setSelectedGroup(value)
          }}
          options={data as IDataType[]}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => <TextField {...params} />}
          autoHighlight
          size="small"
        />
      </StyledFormControl>
    )
  }

  return (
    <StyledFormControl variant="outlined" margin="dense">
      <ExpoSelect
        value={selectedGroups}
        onChange={(v) => {
          setSelectedGroups(v)
          setSelectedGroup(
            (data?.find((d) => d.id === v.slice(-1)[0]) as IDataType) || null,
          )
        }}
        dataSource={
          data?.map((d) => ({
            ...d,
            text: d.label,
            value: d.id,
          })) || []
        }
        displayEmpty
      />
    </StyledFormControl>
  )
}

export type { IDataType }
export { GroupFilterProvider, useGroupFilter }
export default GroupFilter
