import { gql, useQuery } from '@apollo/client'
import * as _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../../../../dateFilter'
import { useGroupFilter } from '../../../../groupFilter'
import { IApiDataType } from '../../../types'

interface IRawOutliersType {
  listEmployeesLossPreventionOutliers: {
    nodes: {
      locationId: number
      employeeName: string
      outliersType: string
      outliersValue: number
      outliersTime: string
      checkNumber: string
    }[]
  }
}

const query = gql`
  query ListFarwestLossPreventionOutliers(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listEmployeesLossPreventionOutliers(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        employeeName
        outliersType
        outliersValue
        outliersTime
        checkNumber
      }
    }
  }
`

export const fwWingstopLossPreventionOutliersConfigs = {
  employeeName: 'string',
  type: 'string',
  date: 'date',
  time: 'date',
  value: 'price',
  checkNumber: 'string',
} as const

const useListEmployeeOutliers = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()

  const { data, loading } = useQuery<IRawOutliersType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_ids: groupFilter?.ids,
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const employeeData = data?.listEmployeesLossPreventionOutliers?.nodes

      if (!employeeData) return null

      return employeeData.map((ed, index) => {
        const queryDate = moment(ed.outliersTime)

        return {
          parentId: 'root',
          id: `${index}`,
          employeeName: ed.employeeName,
          type: `${_.upperFirst(ed.outliersType)} > $50`,
          date: queryDate,
          time: queryDate,
          value: ed.outliersValue,
          checkNumber: ed.checkNumber,
        }
      })
    }, [groupFilter, data]),
    loading,
  }
}

export default useListEmployeeOutliers
