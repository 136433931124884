import _ from 'lodash'
import { useMemo } from 'react'

import useLocationMetricValuesQuery, {
  IGroupFilterDataType,
  IMetricType,
  format,
} from '../../../../hooks/useLocationMetricValuesQuery'
import { IApiDataType } from '../../../types'

const buildMetricValuesHook = ({
  handler = (data) => data,
  ...options
}: {
  groupFilterTypes: IGroupFilterDataType['type'][]
  metrics?: IMetricType[]
  metricGroups?: IMetricType[]
  handler?: (data: Record<string, unknown>) => Record<string, unknown>
}) => {
  const useMetricValues = () => {
    const { data, yoyData, loading } = useLocationMetricValuesQuery({
      ...options,
      fields: ['metricSummaryData'],
    })

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const summary = data.find((d) => d.metricSummaryData)?.metricSummaryData
        const yoySummary = yoyData?.find(
          (d) => d.metricSummaryData,
        )?.metricSummaryData

        return {
          ...handler(
            format({
              ...(summary || {}),
              ..._.mapKeys(yoySummary || {}, (_, key) => `yoy_${key}`),
            }),
          ),
        }
      }, [data, yoyData]),
      loading,
    }
  }

  return useMetricValues
}

export default buildMetricValuesHook
