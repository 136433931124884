import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import useLocationMetricValuesQuery, {
  IMetricType,
  IOptionsType,
} from '../../hooks/useLocationMetricValuesQuery'
import calc from '../../utils/calc'
import { IApiDataType } from '../types'

interface IQueryData {
  glCodes: {
    nodes: {
      id: number
      code: string
      name: string
      parentGroupId: number
    }[]
  }

  glGroups: {
    nodes: {
      id: number
      name: string
      parentGroupId: number
      exrayCategory: string | null
    }[]
  }

  locationMetricDefs: {
    nodes: {
      id: number
      code: string
      name: string
    }[]
  }
}

const query = gql`
  query ListGlCodesAndGroups {
    glCodes {
      nodes {
        id
        code
        name
        parentGroupId
      }
    }

    glGroups {
      nodes {
        id
        name
        parentGroupId
        exrayCategory
      }
    }

    locationMetricDefs(condition: { metricGroup: "PnL" }) {
      nodes {
        id
        code
        name
      }
    }
  }
`

const findAllGlGroups = (
  glGroups: IQueryData['glGroups']['nodes'],
  parentGroups: IQueryData['glGroups']['nodes'],
): IQueryData['glGroups']['nodes'] => {
  if (parentGroups.length === 0) return []

  const ids = parentGroups.map((p) => p.id)
  const childGroups = glGroups.filter((g) => ids.includes(g.parentGroupId))

  return [
    ...parentGroups,
    ...findAllGlGroups(glGroups, childGroups).filter(
      (g) => !ids.includes(g.id),
    ),
  ]
}

const useGlCodes = (
  exrayCategory:
    | 'Sales'
    | 'Expenses'
    | 'Food Cost'
    | 'Labor Cost'
    | 'Net Profit',
) => {
  const { data, loading } = useQuery<IQueryData>(query)

  const glCodes = useMemo(() => {
    const glGroups = data?.glGroups.nodes || []
    const requiredGlGroups = findAllGlGroups(
      glGroups,
      glGroups.filter((n) => n.exrayCategory === exrayCategory),
    )
    const requiredGlGroupIds = requiredGlGroups.map((r) => r.id)
    const glCodesData = (data?.glCodes.nodes || []).filter((d) =>
      requiredGlGroupIds.includes(d.parentGroupId),
    )
    const glCodes = glCodesData.map((g) => g.name)
    const metricDefs = data?.locationMetricDefs.nodes.filter((n) =>
      glCodes.includes(n.name),
    )

    return glCodesData.map((g) => ({
      code: g.code,
      name: g.name,
      metricCode: metricDefs?.find((m) => m.name === g.name)?.code,
    }))
  }, [data])

  return {
    glCodes,
    loading,
  }
}

const buildCorporatePnl = (
  exrayCategory:
    | 'Sales'
    | 'Expenses'
    | 'Food Cost'
    | 'Labor Cost'
    | 'Net Profit',
) => {
  const useCorporatePnl = () => {
    const { groupFilter, hasGroupBy } = useGroupFilter()
    const { glCodes, loading: glCodesLoading } = useGlCodes(exrayCategory)
    const { glCodes: salesGlCodes, loading: salesGlCodesLoading } =
      useGlCodes('Sales')
    const createOptions = (
      selectedGlCodes: { code: string; name: string; metricCode?: string }[],
    ): IOptionsType => ({
      groupFilterTypes: ['listLocation', 'listLocationGroup'],
      metrics: [
        ...selectedGlCodes.map((g) => g.metricCode).filter(Boolean),
        ...selectedGlCodes
          .map((g) => g.metricCode && { key: g.metricCode, type: 'yoy' })
          .filter(Boolean),
      ] as IMetricType[],
      fields: ['metricData'],
      handler: (variables) => {
        const newVariables = {
          ...variables,
          iFilter: {
            ...variables.iFilter,
            location_group_ids: groupFilter?.ids,
          },
          isLocationGroup: !hasGroupBy,
        }

        return {
          variables: newVariables,
          skip:
            !groupFilter ||
            !newVariables.iStartDate ||
            !newVariables.iEndDate ||
            (newVariables.iFilter.metrics || []).length === 0,
        }
      },
    })

    const {
      data,
      yoyData,
      loading: metricValuesLoading,
    } = useLocationMetricValuesQuery(createOptions(glCodes))

    const {
      data: salesData,
      yoyData: salesYoyData,
      loading: salesMetricValuesLoading,
    } = useLocationMetricValuesQuery(createOptions(salesGlCodes))

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        return {
          source: data
            .map((d) => {
              const groupInfo = groupFilter?.list?.find((g) =>
                'locationId' in d
                  ? d.locationId === g.id
                  : d.locationGroupId === g.id,
              )
              const yoyD = yoyData?.find((p) => {
                if ('locationId' in d && 'locationId' in p)
                  return d.locationId === p.locationId
                if ('locationGroupId' in d && 'locationGroupId' in p)
                  return d.locationGroupId === p.locationGroupId
              })

              const salesD = salesData?.find((p) => {
                if ('locationId' in d && 'locationId' in p)
                  return d.locationId === p.locationId
                if ('locationGroupId' in d && 'locationGroupId' in p)
                  return d.locationGroupId === p.locationGroupId
              })

              const salesYoyD = salesYoyData?.find((p) => {
                if ('locationId' in d && 'locationId' in p)
                  return d.locationId === p.locationId
                if ('locationGroupId' in d && 'locationGroupId' in p)
                  return d.locationGroupId === p.locationGroupId
              })

              const total = Object.values(d.metricData).reduce(
                (subResult, { value }) => subResult + (value || 0),
                0,
              )
              const yoyTotal = Object.values(yoyD?.metricData || {}).reduce(
                (subResult, { value }) => subResult + (value || 0),
                0,
              )

              const salesTotal = Object.values(salesD?.metricData || {}).reduce(
                (subResult, { value }) => subResult + (value || 0),
                0,
              )
              const salesYoyTotal = Object.values(
                salesYoyD?.metricData || {},
              ).reduce((subResult, { value }) => subResult + (value || 0), 0)

              const percentOfSales = calc(
                calc(total, 'percentageOf', salesTotal),
                '*',
                exrayCategory === 'Net Profit' ? 1 : -1,
              )
              const yoyPercentOfSales = calc(
                calc(yoyTotal, 'percentageOf', salesYoyTotal),
                '*',
                exrayCategory === 'Net Profit' ? 1 : -1,
              )
              const basisPoints = Math.round(
                calc(
                  calc(percentOfSales, '-', yoyPercentOfSales),
                  '*',
                  100,
                ) as number,
              )
              const [isCurrentNotEmpty, isYoyNotEmpty] = [d, yoyD].map((data) =>
                Object.values(data?.metricData || {}).some(
                  ({ value }) => !_.isNil(value),
                ),
              )

              if (!isCurrentNotEmpty) return { groupInfo }

              const variance = calc(total, '-', yoyTotal)
              return {
                groupInfo,
                total: percentOfSales,
                yoyTotal: yoyPercentOfSales,
                basisPoints: isYoyNotEmpty ? basisPoints : null,
                variance,
                variancePercent: calc(
                  variance,
                  'percentageOf',
                  Math.abs(yoyTotal),
                ),
              }
            })
            .filter((d) => d.total)
            .sort((a, b) => {
              switch (exrayCategory) {
                case 'Sales':
                  return (
                    ((a.variance as number) || 0) -
                    ((b.variance as number) || 0)
                  )
                default: {
                  if (b.basisPoints === null) return -1
                  if (a.basisPoints === null) return 1
                  return (
                    ((b.basisPoints as number) || 0) -
                    ((a.basisPoints as number) || 0)
                  )
                }
              }
            }),
        }
      }, [data, yoyData, salesData, salesYoyData, groupFilter, hasGroupBy]),
      loading:
        glCodesLoading ||
        salesGlCodesLoading ||
        metricValuesLoading ||
        salesMetricValuesLoading,
    }
  }

  return useCorporatePnl
}

export const corporatePnlConfigs = {
  variance: 'price',
  variancePercent: 'percent',
  total: 'percent',
  yoyTotal: 'percent',
  basisPoints: 'basisPoint',
} as const

export const useCorporatePnlSales = buildCorporatePnl('Sales')
export const useCorporatePnlExpenses = buildCorporatePnl('Expenses')
export const useCorporatePnlLaborCost = buildCorporatePnl('Labor Cost')
export const useCorporatePnlFoodCost = buildCorporatePnl('Food Cost')
export const useCorporatePnLNetProfit = buildCorporatePnl('Net Profit')
