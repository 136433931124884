import useBurgerworksSalesByOrigin, {
  IDataType as IBurgerworksSalesByOriginDataType,
} from './useBurgerworksSalesByOrigin'

export type IVariablesType = 'burgerworksSalesByOrigin'
export type IDataType = IBurgerworksSalesByOriginDataType

export const hooks = [
  ['burgerworksSalesByOrigin', useBurgerworksSalesByOrigin],
] as const
