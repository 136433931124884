import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import DatePicker from '../datePicker/DatePicker'
import { useVariables } from '../variables'

export interface IPropsType {
  type: 'timeline-editor'
  values: string
  onChange: string
  options: string
  errorMessages?: string
}

interface ITimelineEditorType {
  values: {
    type: string
    date: moment.Moment
  }[]
  onChange: (values: ITimelineEditorType['values']) => void
  options: {
    value: string
    displayName: string
  }[]
  errorMessages: (string | undefined)[]
}

const StyledTimelineOppositeContent = styled(TimelineOppositeContent)`
  font-family: Lexend-Regular;
`

const StyledTimelineContent = styled(TimelineContent)`
  font-family: Lexend-Regular;

  & > div {
    display: flex;
    gap: 8px;
  }

  .MuiOutlinedInput-root {
    height: 40px;
  }

  .MuiSelect-select:focus {
    background: initial;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
`

const ErrorMessage = styled.div`
  margin: 5px 0px 0px;
  font-size: 0.75rem;
  color: ${COLORS.Pomodoro};
`

const StyledAddCircleIcon = styled(AddCircleIcon)`
  cursor: pointer;
`

const TimelineEditor = (props: IPropsType) => {
  const { variables } = useVariables()
  const { values, onChange, options, errorMessages } = useMemo(
    (): ITimelineEditorType => ({
      values: _.get(variables, props.values),
      onChange: _.get(variables, props.onChange),
      options: _.get(variables, props.options),
      errorMessages:
        (props.errorMessages && _.get(variables, props.errorMessages)) || [],
    }),
    [variables],
  )

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.3,
        },
      }}
    >
      <TimelineItem>
        <StyledTimelineOppositeContent>Future</StyledTimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot />

          <TimelineConnector />
        </TimelineSeparator>

        <StyledTimelineContent>
          <StyledAddCircleIcon
            onClick={() =>
              onChange([
                {
                  type:
                    options.find((o) => o.value !== values[0].type)?.value ??
                    values[0].type,
                  date: values[0].date.clone().add(1, 'days'),
                },
                ...values,
              ])
            }
          />
        </StyledTimelineContent>
      </TimelineItem>

      {values.map((value, index) => (
        <TimelineItem key={`${value.type}-${value.date}-${index}`}>
          <StyledTimelineOppositeContent>
            {index !== values.length - 1 ? null : 'Open Date'}
          </StyledTimelineOppositeContent>

          <TimelineSeparator>
            <TimelineDot />

            {index === values.length - 1 ? null : <TimelineConnector />}
          </TimelineSeparator>

          <StyledTimelineContent>
            <div>
              <Select
                value={value.type}
                onChange={(e) => {
                  const newvalues = [...values]

                  newvalues[index] = {
                    ...newvalues[index],
                    type: e.target.value as string,
                  }
                  onChange(newvalues)
                }}
                error={Boolean(errorMessages[index])}
                variant="outlined"
              >
                {options.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.displayName}
                  </MenuItem>
                ))}
              </Select>

              <DatePicker
                value={value.date}
                onChange={(date) => {
                  const newvalues = [...values]

                  newvalues[index] = {
                    ...newvalues[index],
                    date,
                  }
                  onChange(newvalues)
                }}
                error={Boolean(errorMessages[index])}
                margin="0px"
              />

              {values.length <= 1 ? null : (
                <IconContainer
                  onClick={() => {
                    const newvalues = [...values]

                    newvalues.splice(index, 1)
                    onChange(newvalues)
                  }}
                >
                  <DeleteIcon />
                </IconContainer>
              )}
            </div>

            {errorMessages[index] && (
              <ErrorMessage>{errorMessages[index]}</ErrorMessage>
            )}
          </StyledTimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}

export default React.memo(TimelineEditor) as unknown as typeof TimelineEditor
