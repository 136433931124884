import { gql, useLazyQuery } from '@apollo/client'
import axios from 'axios'
import moment from 'moment'
import { useCallback, useMemo, useRef, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

import useBrands from 'pared/layouts/hooks/useBrands'

import useInputValue, {
  IDataType as IInputValueDataType,
} from '../common/useInputValue'
import useSelectOptions, {
  IDataType as ISelectOptionsDataType,
} from '../common/useSelectOptions'

export interface IDataType extends IInputValueDataType, ISelectOptionsDataType {
  uploadReport: {
    disable: boolean
    loading: boolean
    onClick: () => Promise<void>
    errorMessage?: string
    overwriteConfirm: {
      open: boolean
      onClose: () => void
      submit: () => Promise<void>
    }
  }
}

interface IQueryDataType {
  customizedData: {
    nodes: {
      data: Record<string, string> & { uploadId: string }
    }[]
  }
}

const query = gql`
  query CheckReportStatus($iCustomizationType: String!) {
    customizedData(
      condition: { customizationType: $iCustomizationType }
      last: 1
    ) {
      nodes {
        data
      }
    }
  }
`

const useChecReportExist = () => {
  const [loading, setLoading] = useState(false)
  const { brand } = useBrands()

  return {
    loading,
    isReportExist: useCallback(
      async (filename) => {
        if (loading) return

        setLoading(true)

        const queryObject = new URLSearchParams()

        queryObject.append('filename', filename)
        queryObject.append('customerCode', brand)

        try {
          const response = await axios.head(
            `${
              process.env.REACT_APP_BE_BASE_URL
            }/upload?${queryObject.toString()}`,
            {
              validateStatus: (status) => status >= 200 && status < 500,
            },
          )

          setLoading(false)

          return response.status === 200
        } catch (e) {
          setLoading(false)

          return null
        }
      },
      [loading, setLoading, brand],
    ),
  }
}

const checkReportStatusLoop = async (
  checker: () => Promise<boolean>,
  count = 0,
) => {
  if (count > 10)
    throw new Error(
      "couldn't check the report upload status, please reload later",
    )

  const result = await checker()

  if (!result)
    await new Promise<void>((resolve, reject) => {
      setTimeout(async () => {
        try {
          await checkReportStatusLoop(checker, count + 1)
          resolve()
        } catch (e) {
          reject(e)
        }
      }, [500])
    })
}

const useUpload = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const { brand } = useBrands()
  const [checkReportStatus, { client }] = useLazyQuery<IQueryDataType>(query, {
    variables: {
      iCustomizationType: 'UPLOAD_REPORT_LOGGER_INFO',
    },
    fetchPolicy: 'network-only',
  })

  return {
    loading,
    error,
    upload: useCallback(
      async (data: Record<string, string | Blob> & { uploadId: string }) => {
        if (loading) return

        setLoading(true)

        const formData = new FormData()

        Object.keys(data).forEach((key) => {
          formData.append(key, data[key])
        })
        formData.append('customerCode', brand)

        try {
          await axios.post(
            `${process.env.REACT_APP_BE_BASE_URL}/upload`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )
          await checkReportStatusLoop(
            async (): Promise<boolean> =>
              data.uploadId ===
              (
                await checkReportStatus()
              ).data?.customizedData.nodes[0]?.data.uploadId,
          )
          await client.refetchQueries({
            include: ['UploadReportStatus'],
          })
        } catch (e) {
          setError((e as Error).message)
        }

        setLoading(false)
      },
      [loading, setLoading, setError, brand, checkReportStatus, client],
    ),
  }
}

const useUploadReport = () => {
  const { selectOptions } = useSelectOptions({
    options: {
      uploadReportType: {
        defaultId: [['Labor Tracker Roster']],
        options: [
          {
            id: 'Labor Tracker Roster',
            displayName: 'Labor Tracker Roster',
          },
          {
            id: 'Order Refunds',
            displayName: 'Order Refunds',
          },
        ],
      },
    },
  })
  const { inputValue } = useInputValue({
    fields: ['file'],
  })
  const { loading: checkReportExistLoading, isReportExist } =
    useChecReportExist()
  const { loading: uploadLoading, error, upload } = useUpload()
  const loading = checkReportExistLoading || uploadLoading
  const [showOverwriteModal, setShowOverwriteModal] = useState(false)
  const skipCheckRef = useRef(false)

  return {
    selectOptions,
    inputValue,
    uploadReport: useMemo((): IDataType['uploadReport'] => {
      const file = inputValue?.file.value?.[0]
      const disable =
        !selectOptions?.uploadReportType.displayName || !file || loading
      const onClick = async () => {
        if (disable) return

        const uploadReportType = selectOptions.uploadReportType.displayName[0]
        const filename = `${uploadReportType} - ${moment().format(
          'YYYY-MM-DD',
        )}.xlsx`

        if (
          !skipCheckRef.current &&
          (await isReportExist(filename)) !== false
        ) {
          setShowOverwriteModal(true)
          return
        }

        await upload({
          uploadId: uuidV4(),
          filename,
          type: uploadReportType,
          uploadDate: moment.utc().format('YYYY-MM-DD'),
          file,
        })
      }

      return {
        disable,
        loading,
        onClick,
        errorMessage: error,
        overwriteConfirm: {
          open: showOverwriteModal,
          onClose: () => setShowOverwriteModal(false),
          submit: async () => {
            skipCheckRef.current = true
            setShowOverwriteModal(false)
            await onClick()
            skipCheckRef.current = false
          },
        },
      }
    }, [
      selectOptions,
      inputValue,
      loading,
      error,
      isReportExist,
      upload,
      showOverwriteModal,
      setShowOverwriteModal,
      skipCheckRef,
    ]),
  }
}

export default useUploadReport
