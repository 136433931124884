import { IConfigsType } from '../../types'

const fwWingstopFlashSalesByOrderMode: IConfigsType = {
  'fw_wingstop-customized-reports-7375b6fb': {
    '/:brand/sales_by_order_mode': {
      variables: ['date'],
      dateFilter: {
        types: ['week', 'this_week', 'period', 'quarter'],
        defaultType: 'this_week',
      },
      groupFilter: {
        api: 'corporateFilter',
        hide: true,
      },
      reportTitle: {
        type: 'title',
        title: 'Sales by Order Mode',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'fwWingstopFlashSalesByOrderMode',
        fileName: 'getexpo-far-west-sales-by-order-mode',
        message: 'Download as CSV',
        fields: [
          {
            key: 'kpiName',
            title: 'Name',
          },
          {
            key: 'farwestFlashSalesByOrderModeWeek8InPast',
            title:
              '<%- date?.getInfo(-7)?.businessYear + " " + date?.getInfo(-7)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-7)?.displayName) %>',
          },
          {
            key: 'farwestFlashSalesByOrderModeWeek7InPast',
            title:
              '<%- date?.getInfo(-6)?.businessYear + " " + date?.getInfo(-6)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-6)?.displayName) %>',
          },
          {
            key: 'farwestFlashSalesByOrderModeWeek6InPast',
            title:
              '<%- date?.getInfo(-5)?.businessYear + " " + date?.getInfo(-5)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-5)?.displayName) %>',
          },
          {
            key: 'farwestFlashSalesByOrderModeWeek5InPast',
            title:
              '<%- date?.getInfo(-4)?.businessYear + " " + date?.getInfo(-4)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-4)?.displayName) %>',
          },
          {
            key: 'farwestFlashSalesByOrderModeWeek4InPast',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
          },
          {
            key: 'farwestFlashSalesByOrderModeWeek3InPast',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
          },
          {
            key: 'farwestFlashSalesByOrderModeWeek2InPast',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
          },
          {
            key: 'farwestFlashSalesByOrderModeWeek1InPast',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
          },
        ],
      },
      averageWeeklySalesbyOrderModeTable: {
        type: 'table',
        title: 'Average Weekly Sales by Order Mode',
        api: 'farwestAverageWeeklySalesbyOrderMode',
        columns: [
          {
            key: 'avgOrderPriceName',
            title: 'Order Mode',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'avgOrderPriceWeek8InPast',
            title:
              '<%- date?.getInfo(-7)?.businessYear + " " + date?.getInfo(-7)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-7)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'avgOrderPriceWeek7InPast',
            title:
              '<%- date?.getInfo(-6)?.businessYear + " " + date?.getInfo(-6)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-6)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'avgOrderPriceWeek6InPast',
            title:
              '<%- date?.getInfo(-5)?.businessYear + " " + date?.getInfo(-5)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-5)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'avgOrderPriceWeek5InPast',
            title:
              '<%- date?.getInfo(-4)?.businessYear + " " + date?.getInfo(-4)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-4)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'avgOrderPriceWeek4InPast',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'avgOrderPriceWeek3InPast',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'avgOrderPriceWeek2InPast',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'avgOrderPriceWeek1InPast',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
        ],
      },
      totalSalesbyOrderModeTable: {
        type: 'table',
        title: 'Total Sales by Order Mode',
        api: 'farwestTotalSalesbyOrderMode',
        columns: [
          {
            key: 'orderPriceName',
            title: 'Order Mode',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'orderPriceWeek8InPast',
            title:
              '<%- date?.getInfo(-7)?.businessYear + " " + date?.getInfo(-7)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-7)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'orderPriceWeek7InPast',
            title:
              '<%- date?.getInfo(-6)?.businessYear + " " + date?.getInfo(-6)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-6)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'orderPriceWeek6InPast',
            title:
              '<%- date?.getInfo(-5)?.businessYear + " " + date?.getInfo(-5)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-5)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'orderPriceWeek5InPast',
            title:
              '<%- date?.getInfo(-4)?.businessYear + " " + date?.getInfo(-4)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-4)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'orderPriceWeek4InPast',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'orderPriceWeek3InPast',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'orderPriceWeek2InPast',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'orderPriceWeek1InPast',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
        ],
      },
      totalSalesPercentbyOrderModeTable: {
        type: 'table',
        title: 'Total Sales Percent by Order Mode',
        api: 'farwestTotalSalesPercentbyOrderMode',
        columns: [
          {
            key: 'orderPercentName',
            title: 'Order Mode',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'orderPercentWeek8InPast',
            title:
              '<%- date?.getInfo(-7)?.businessYear + " " + date?.getInfo(-7)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-7)?.displayName) %>',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'orderPercentWeek7InPast',
            title:
              '<%- date?.getInfo(-6)?.businessYear + " " + date?.getInfo(-6)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-6)?.displayName) %>',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'orderPercentWeek6InPast',
            title:
              '<%- date?.getInfo(-5)?.businessYear + " " + date?.getInfo(-5)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-5)?.displayName) %>',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'orderPercentWeek5InPast',
            title:
              '<%- date?.getInfo(-4)?.businessYear + " " + date?.getInfo(-4)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-4)?.displayName) %>',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'orderPercentWeek4InPast',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'orderPercentWeek3InPast',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'orderPercentWeek2InPast',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
          {
            key: 'orderPercentWeek1InPast',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
            align: 'center',
            decimal: 1,
            sortable: true,
          },
        ],
      },
      equivalentUnitCountTable: {
        type: 'table',
        title: 'Equivalent Unit Count',
        api: 'farwestEquivalentUnitCount',
        columns: [
          {
            key: 'equivalentUnitCountName',
            title: 'Name',
            align: 'center',
            width: '250px',
            sortable: true,
          },
          {
            key: 'equivalentUnitCountWeek8InPast',
            title:
              '<%- date?.getInfo(-7)?.businessYear + " " + date?.getInfo(-7)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-7)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'equivalentUnitCountWeek7InPast',
            title:
              '<%- date?.getInfo(-6)?.businessYear + " " + date?.getInfo(-6)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-6)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'equivalentUnitCountWeek6InPast',
            title:
              '<%- date?.getInfo(-5)?.businessYear + " " + date?.getInfo(-5)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-5)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'equivalentUnitCountWeek5InPast',
            title:
              '<%- date?.getInfo(-4)?.businessYear + " " + date?.getInfo(-4)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-4)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'equivalentUnitCountWeek4InPast',
            title:
              '<%- date?.getInfo(-3)?.businessYear + " " + date?.getInfo(-3)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-3)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'equivalentUnitCountWeek3InPast',
            title:
              '<%- date?.getInfo(-2)?.businessYear + " " + date?.getInfo(-2)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-2)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'equivalentUnitCountWeek2InPast',
            title:
              '<%- date?.getInfo(-1)?.businessYear + " " + date?.getInfo(-1)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(-1)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'equivalentUnitCountWeek1InPast',
            title:
              '<%- date?.getInfo(0)?.businessYear + " " + date?.getInfo(0)?.displayName %>',
            hide: '<%- _.isUndefined(date?.getInfo(0)?.displayName) %>',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashSalesByOrderMode
