import { gql, useQuery } from '@apollo/client'
import moment from 'moment'

interface IQueryDataType {
  getFirstDateOfYear: {
    nodes: {
      date: string
    }[]
  }
}

interface IQueryVariablesType {
  year: number
}

const query = gql`
  query firstDateOfYear($year: Int!) {
    getFirstDateOfYear(iYear: $year) {
      nodes {
        date
      }
    }
  }
`

const useFirstDateOfYear = (date: moment.Moment = moment(), skip?: boolean) => {
  const { data } = useQuery<IQueryDataType, IQueryVariablesType>(query, {
    variables: {
      year: date.year(),
    },
    skip,
  })

  return data?.getFirstDateOfYear.nodes[0].date
}

export default useFirstDateOfYear
