import { useMemo } from 'react'

import { toUsdString } from 'pared/utils/number'

import {
  fwWingstopCheckAvgDigitalNondigitalConfigs,
  useFwWingstopDigitalNondigitalCheckAvg,
  useFwWingstopDigitalNondigitalChecks,
  useFwWingstopDigitalNondigitalSales,
} from '../../tableV2/hooks/fwWingstop/useDigitalNondigital'

export const fwWingstopDigitalAndNondigitalConfigs = {
  ...fwWingstopCheckAvgDigitalNondigitalConfigs,
} as const

const useFwWingstopDigitalAndNondigital = () => {
  const { data: salesData, loading: salesLoading } =
    useFwWingstopDigitalNondigitalSales()
  const { data: checksData, loading: checksLoading } =
    useFwWingstopDigitalNondigitalChecks()
  const { data: checkAvgData, loading: checkAvgLoading } =
    useFwWingstopDigitalNondigitalCheckAvg()

  const loading = salesLoading || checksLoading || checkAvgLoading

  return useMemo(() => {
    if (!salesData || !checksData || !checkAvgData)
      return {
        source: null,
      }

    const data = [...salesData, ...checksData, ...checkAvgData].filter(
      (d) => d.id !== 'total',
    )

    return {
      data: {
        source: data.map((d) => {
          if (d.id.includes('sales')) {
            return {
              ...d,
              orderMode: `Sales - ${d.orderMode}`,
              valueForDateRange1InPast: toUsdString(
                (d.valueForDateRange1InPast as number) / 100,
                0,
              ),
              valueForDateRange2InPast: toUsdString(
                (d.valueForDateRange2InPast as number) / 100,
                0,
              ),
              valueForDateRange3InPast: toUsdString(
                (d.valueForDateRange3InPast as number) / 100,
                0,
              ),
              valueForDateRange4InPast: toUsdString(
                (d.valueForDateRange4InPast as number) / 100,
                0,
              ),
            }
          } else if (d.id.includes('checks')) {
            return {
              ...d,
              orderMode: `Checks - ${d.orderMode}`,
              valueForDateRange1InPast: d.valueForDateRange1InPast
                ? (d.valueForDateRange1InPast as number).toLocaleString('en-US')
                : null,
              valueForDateRange2InPast: d.valueForDateRange2InPast
                ? (d.valueForDateRange2InPast as number).toLocaleString('en-US')
                : null,
              valueForDateRange3InPast: d.valueForDateRange3InPast
                ? (d.valueForDateRange3InPast as number).toLocaleString('en-US')
                : null,
              valueForDateRange4InPast: d.valueForDateRange4InPast
                ? (d.valueForDateRange4InPast as number).toLocaleString('en-US')
                : null,
            }
          } else if (d.id.includes('check_avg')) {
            return {
              ...d,
              orderMode: `Check Avg - ${d.orderMode}`,
            }
          } else {
            return {
              ...d,
              orderMode: 'Dig Vs Non-Dig',
            }
          }
        }),
      },
      loading,
    }
  }, [salesData, checksData, checkAvgData, loading])
}

export default useFwWingstopDigitalAndNondigital
