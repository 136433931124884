import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiType } from '../../types'

const QUERY_NAME = 'listLocationMetricValues'
const query = gql`
  query (
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    ${QUERY_NAME}(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

export const locationRevenueCenterConfigs = {
  name: 'string',
  sales: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  percentSales: 'percent',
} as const

const useLocationRevenueCenter = (): IApiType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()

  const iFilter = {
    location_ids: groupFilter?.ids,
    metric_groups: [
      'Expo Module - Revenue Center Sales',
      'Expo Module - Revenue Center Check Count',
      'Expo Module - Revenue Center Check Average',
      'Expo Module - Fundamental Sales',
    ],
  }

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const nodes = data?.[QUERY_NAME].nodes as
        | {
            locationId: string
            metricData: {
              [key: string]: {
                name: string
                unit: string
                value: number
              }
            }
          }[]
        | undefined

      if (!nodes) return null
      const mappedData: {
        [revCenter: string]: {
          [kpi: string]: number | string
        }
      } = {}
      const metricData = nodes[0]?.metricData

      let netSales = 0
      for (const key in metricData) {
        if (key === 'net_sales') {
          if (metricData[key].unit === 'CENT') {
            netSales = metricData[key].value / 100
          } else {
            netSales = metricData[key].value
          }
          continue
        }

        const { kpi, revCenter } = (() => {
          const kpiAndRevCenterSales = key.split('revenue_center_sales_')?.[1]
          if (kpiAndRevCenterSales)
            return {
              kpi: 'sales',
              revCenter: kpiAndRevCenterSales,
            }

          const kpiAndRevCenterCheckCount = key.split(
            'revenue_center_check_count_',
          )?.[1]
          if (kpiAndRevCenterCheckCount)
            return {
              kpi: 'checkCount',
              revCenter: kpiAndRevCenterCheckCount,
            }

          const kpiAndRevCenterCheckAvg = key.split(
            'revenue_center_check_average_',
          )?.[1]
          if (kpiAndRevCenterCheckAvg)
            return {
              kpi: 'checkAverage',
              revCenter: kpiAndRevCenterCheckAvg,
            }

          return {
            kpi: '',
            revCenter: '',
          }
        })()

        if (!mappedData[revCenter]) {
          mappedData[revCenter] = {
            name: metricData[key].name.split(' - ')[1],
            [kpi]: metricData[key].value,
          }
        } else {
          mappedData[revCenter][kpi] = metricData[key].value
        }
      }

      const rows = Object.values(mappedData)
        ?.filter((n) => n.sales)
        ?.map((n) => {
          return {
            ...n,
            percentSales: (100 * (n.sales as number)) / netSales,
            id: n.name as string,
            parentId: 'root',
          }
        })

      return rows
    }, [groupFilter, data]),
    loading,
  }
}

export default useLocationRevenueCenter
