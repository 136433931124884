import { IConfigsType } from '../../types'

const fwWingstopDailyOps: IConfigsType = {
  fw_wingstop: {
    '/:brand/ops_report': {
      groupFilter: {
        api: 'fwWingstopCorporateFilter',
      },
      dateFilter: {
        types: ['yesterday', 'custom'],
        defaultType: 'yesterday',
        isDailyCustom: true,
        endDate: 'yesterday',
      },
      reportTitle: {
        type: 'title',
        title: 'Ops Report',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'farwestCustomizedFlash',
        fileName: 'getexpo-far-west-daily-ops',
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'salesForecast',
            title: 'Sales Forecast',
          },
          {
            key: 'salesActual',
            title: 'Sales Actual',
          },
          {
            key: 'salesVariance',
            title: 'Sales Variance',
          },
          {
            key: 'spmh',
            title: 'SPMH by Date',
          },
          {
            key: 'laborScheduledHours',
            title: 'Labor Scheduled Hours',
          },
          {
            key: 'laborActualHours',
            title: 'Labor Actual Hours',
          },
          {
            key: 'laborVarianceHours',
            title: 'Labor Variance Hours',
          },
          {
            key: 'otScheduledHours',
            title: 'OT Scheduled Hours',
          },
          {
            key: 'otActualHours',
            title: 'OT Actual Hours',
          },
          {
            key: 'laborScheduledPercent',
            title: 'Labor Scheduled Percent',
          },
          {
            key: 'laborActualPercent',
            title: 'Labor Actual Percent',
          },
          {
            key: 'laborVariancePercent',
            title: 'Labor Variance Percent',
          },
          {
            key: 'cashDeposit',
            title: 'Cash Deposit',
          },
          {
            key: 'cashOs',
            title: 'Cash O/S',
          },
          {
            key: 'totalCards',
            title: 'Total Cards',
          },
          {
            key: 'paidIn',
            title: 'Paid In',
          },
          {
            key: 'paidOut',
            title: 'Paid Out',
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
          },
          {
            key: 'refund',
            title: 'Refund $',
          },
          {
            key: 'voidSales',
            title: 'Void $',
          },
          {
            key: 'comps',
            title: 'Comps $',
          },
          {
            key: 'promos',
            title: 'Promos',
          },
          {
            key: 'netSales',
            title: 'Net Sales',
          },
          {
            key: 'classicCaseCount',
            title: 'Classic Case Count',
          },
          {
            key: 'tendersCaseCount',
            title: 'Tenders Case Count',
          },
          {
            key: 'bonelessCaseCount',
            title: 'Boneless Case Count',
          },
        ],
      },
      flashTablePart1: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'salesForecast',
            title: 'Sales Forecast',
            sortable: true,
            highlight: true,
          },
          {
            key: 'salesActual',
            title: 'Sales Actual',
            sortable: true,
          },
          {
            key: 'salesVariance',
            title: 'Sales Variance',
            sortable: true,
            highlight: true,
          },
          {
            key: 'spmh',
            title: 'SPMH by Date',
            sortable: true,
          },
          {
            key: 'laborScheduledHours',
            title: 'Labor Scheduled Hours',
            decimal: 2,
            sortable: true,
            highlight: true,
          },
          {
            key: 'laborActualHours',
            title: 'Labor Actual Hours',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'laborVarianceHours',
            title: 'Labor Variance Hours',
            decimal: 2,
            sortable: true,
            highlight: true,
          },
          {
            key: 'otScheduledHours',
            title: 'OT Scheduled Hours',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'otActualHours',
            title: 'OT Actual Hours',
            decimal: 2,
            sortable: true,
            highlight: true,
          },
          {
            key: 'laborScheduledPercent',
            title: 'Labor Scheduled Percent',
            sortable: true,
          },
          {
            key: 'laborActualPercent',
            title: 'Labor Actual Percent',
            sortable: true,
            highlight: true,
          },
          {
            key: 'laborVariancePercent',
            title: 'Labor Variance Percent',
            sortable: true,
          },
        ],
      },
      flashTablePart2: {
        type: 'table',
        api: 'farwestCustomizedFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'cashDeposit',
            title: 'Cash Deposit',
            sortable: true,
            highlight: true,
          },
          {
            key: 'cashOs',
            title: 'Cash O/S',
            sortable: true,
          },
          {
            key: 'totalCards',
            title: 'Total Cards',
            sortable: true,
            highlight: true,
          },
          {
            key: 'paidIn',
            title: 'Paid In',
            sortable: true,
          },
          {
            key: 'paidOut',
            title: 'Paid Out',
            sortable: true,
            highlight: true,
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'refund',
            title: 'Refund $',
            sortable: true,
            highlight: true,
          },
          {
            key: 'voidSales',
            title: 'Void $',
            sortable: true,
          },
          {
            key: 'comps',
            title: 'Comps $',
            sortable: true,
            highlight: true,
          },
          {
            key: 'promos',
            title: 'Promos',
            sortable: true,
          },
          {
            key: 'netSales',
            title: 'Net Sales',
            sortable: true,
            highlight: true,
          },
          {
            key: 'classicCaseCount',
            title: 'Classic Case Count Avg',
            sortable: true,
          },
          {
            key: 'tendersCaseCount',
            title: 'Tenders Case Count Avg',
            sortable: true,
            highlight: true,
          },
          {
            key: 'bonelessCaseCount',
            title: 'Boneless Case Count Avg',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default fwWingstopDailyOps
