import buildPnl from './buildPnl'

const useSales = buildPnl('Sales')
const useExpenses = buildPnl('Expenses')
const useFoodCost = buildPnl('Food Cost')
const useLaborCost = buildPnl('Labor Cost')
const useNetProfit = buildPnl('Net Profit')

export type IAiSummaryType =
  | 'pnlSales'
  | 'pnlExpenses'
  | 'pnlFoodCost'
  | 'pnlLaborCost'
  | 'pnlNetProfit'

export const hooks = [
  ['pnlSales', useSales],
  ['pnlExpenses', useExpenses],
  ['pnlFoodCost', useFoodCost],
  ['pnlLaborCost', useLaborCost],
  ['pnlNetProfit', useNetProfit],
] as const
