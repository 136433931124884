import useLocationDeliveryAiSummary from './useLocationDeliveryAiSummary'
import useLocationGuestAiSummary from './useLocationGuestAiSummary'
import useLocationLossPreventionAiSummary from './useLocationLossPreventionAiSummary'
import useLocationPurchaseAiSummary from './useLocationPurchaseAiSummary'

export const api = {
  locationLossPreventionAiSummary: useLocationLossPreventionAiSummary,
  locationGuestAiSummary: useLocationGuestAiSummary,
  locationDeliveryAiSummary: useLocationDeliveryAiSummary,
  locationPurchaseAiSummary: useLocationPurchaseAiSummary,
}
