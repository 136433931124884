import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import * as d3 from 'd3-hierarchy'
import moment from 'moment'
import styled from 'styled-components'

import CustomDatePicker from '../datePicker/DatePicker'
import { IDateDataType } from '../variables'
import CustomDateRangePicker from './CustomDateRangePicker'

type IOptionType = IDateDataType['options'][number]

interface IPropsType {
  startDate?: moment.Moment
  endDate?: moment.Moment
  optionsTree: d3.HierarchyNode<IOptionType>
  onChange: (ids: (IOptionType['id'] | moment.Moment)[]) => void
}

const StyledSelect = styled(Select)`
  margin: 15px 0px;
  width: 80%;
  text-align: center;
`

const getParentIds = ({
  parent,
}: IPropsType['optionsTree']): IOptionType['id'][] =>
  !parent?.data.id || parent.data.id === 'root'
    ? []
    : [...getParentIds(parent), parent.data.id]

const Item = ({ startDate, endDate, optionsTree, onChange }: IPropsType) => {
  const { data, parent } = optionsTree

  if (data.id === 'root') return null

  return (
    <>
      {parent && (
        <Item
          startDate={startDate}
          endDate={endDate}
          optionsTree={parent}
          onChange={(ids) => onChange([...ids, data.id])}
        />
      )}

      <StyledSelect
        value={data.id}
        onChange={({ target: { value } }) =>
          onChange([...getParentIds(optionsTree), value as IOptionType['id']])
        }
        variant="outlined"
      >
        {parent?.children?.map((d) =>
          d.data.id === 'root' ? null : (
            <MenuItem key={d.id} value={d.id}>
              {d.data.displayName}
            </MenuItem>
          ),
        )}
      </StyledSelect>

      {!('type' in data) || data.type !== 'custom_date' ? null : (
        <CustomDatePicker
          startDate={startDate}
          endDate={!endDate ? moment.utc() : endDate}
          value={data.dateRange.startDate}
          onChange={(date) => onChange([date])}
        />
      )}

      {!('type' in data) || data.type !== 'custom_date_range' ? null : (
        <CustomDateRangePicker
          startDate={startDate}
          endDate={endDate}
          value={[data.dateRange.startDate, data.dateRange.endDate]}
          onChange={(dates) => onChange([...dates])}
        />
      )}
    </>
  )
}

export default Item
