import { gql, useQuery } from '@apollo/client'
import * as d3 from 'd3-scale'
import { useMemo } from 'react'
import stringSimilarity from 'string-similarity'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'

import { useDateFilter } from '../../../dateFilter'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

interface IListLocationPurchaseDataNodeType {
  itemName: string
  itemCode: string
  displayParentCategoryName: string
  actualCogs: number
  idealCogs: number
  cogsVariance: number
}

interface IListLocationPurchaseDataType {
  listLocationCogsData: {
    nodes: IListLocationPurchaseDataNodeType[]
  }
}

const query = gql`
  query ListLocationPurchaseData(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listLocationCogsData(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemName
        itemCode
        displayParentCategoryName
        actualCogs
        idealCogs
        cogsVariance
      }
    }
  }
`

export const locationPurchaseConfigs = {
  itemName: 'string',
  displayParentCategoryName: 'string',
  actualCogs: 'price',
  idealCogs: 'price',
  cogsVariance: 'price',
} as const

const useLocationPurchase = (): IApiType => {
  const metricCodes = ['actual_cogs', 'ideal_cogs', 'cogs_variance']
  const { groupFilter } = useGroupFilter()
  const dateFilter = useDateFilter()
  const startDate = dateFilter.startDate
  const endDate = dateFilter.endDate
  const locationId = groupFilter?.ids?.[0]
  const { variables } = useVariables()
  const { selectedItemOrCategory, searchInput } = useMemo(
    () => ({
      selectedItemOrCategory: variables.items?.value,
      searchInput: variables.inputValue?.itemSearch.value as string,
    }),
    [variables],
  )
  const itemCategoryIds = parseInt(selectedItemOrCategory?.[0]?.[0]) || null
  const { data, loading } = useQuery<IListLocationPurchaseDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: 'ITEM',
      iFilter: {
        location_ids: [locationId],
        metrics: metricCodes,
        use_location_details: true,
        item_category_ids: [itemCategoryIds],
        bypass_row_level_security: true,
      },
    },
    skip: !startDate || !endDate || !groupFilter || !itemCategoryIds,
  })

  return {
    data: useMemo(() => {
      const itemData = data?.listLocationCogsData.nodes
      if (!itemData) return null

      const minOpportunityCost = Math.min(
        ...itemData.map((n) => n.cogsVariance),
      )
      const maxOpportunityCost = Math.max(
        ...itemData.map((n) => n.cogsVariance),
      )
      const x = d3.scaleLinear(
        [0, 100],
        [minOpportunityCost, maxOpportunityCost],
      )
      const final = itemData.map((n) => {
        return {
          ...n,
          parentId: 'root',
          id: n.itemCode,
          cogsVarianceHeatMap: x.invert(n.cogsVariance),
        }
      })
      const finalTotal = final.reduce(
        (prev, curr) => {
          return {
            idealCogs: prev.idealCogs + curr?.idealCogs || 0,
            cogsVariance: prev.cogsVariance + curr?.cogsVariance || 0,
            actualCogs: prev.actualCogs + curr?.actualCogs || 0,
          }
        },
        {
          idealCogs: 0,
          cogsVariance: 0,
          actualCogs: 0,
        },
      )

      if (!searchInput)
        return [
          ...final,
          {
            parentId: 'root',
            id: 'summary',
            itemName: 'Total',
            displayParentCategoryName: '',
            idealCogs: finalTotal.idealCogs,
            cogsVariance: finalTotal.cogsVariance,
            actualCogs: finalTotal.actualCogs,
          },
        ]

      const itemFilterArray = searchInput
        .split(';')
        .map((item) => {
          return item.trim().toLowerCase()
        })
        .filter((item) => {
          return !!item
        })

      const allItemNames = final.map(({ itemName }) => itemName)
      const bestMatchItemSet = new Set<string>()

      itemFilterArray.forEach((filter) => {
        const bestMatchResults = stringSimilarity.findBestMatch(
          filter,
          allItemNames,
        )

        const bestMatchRating = bestMatchResults?.bestMatch?.rating
        if (!bestMatchRating || bestMatchRating < 0.5) return

        const bestMatchItem = bestMatchResults?.bestMatch?.target
        if (bestMatchItem) {
          bestMatchItemSet.add(bestMatchItem.toLowerCase())
        }
      })

      const filteredTableData = final.filter(({ itemName }) => {
        const lowerCaseItem = itemName.toLowerCase()
        let isMatched = false
        const filterCount = itemFilterArray.length

        for (let ii = 0; ii < filterCount; ++ii) {
          const filter = itemFilterArray[ii]

          if (
            bestMatchItemSet.has(lowerCaseItem) ||
            lowerCaseItem.search(filter) !== -1
          ) {
            isMatched = true
            break
          }
        }

        return isMatched
      })

      return filteredTableData
    }, [data, searchInput]),
    loading,
  }
}

export default useLocationPurchase
