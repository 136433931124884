import { IConfigsType } from '../../types'

const originalCorporatePagesConfig: IConfigsType = {
  fw_wingstop: {
    '/:brand/corporate_guest': {},
    '/:brand/corporate_delivery': {},
  },
}

export default originalCorporatePagesConfig
