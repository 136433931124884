import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import useFarwestPmixCategoryData from './useFarwestPmixCategoryData'
import useTrendUnifiedData from './useTrendUnifiedData'
import useUnifiedData from './useUnifiedData'

export const farwestPmixSubcategoryDataConfigs = {
  categoryName: 'string',
  subcategoryName: 'string',
  itemName: 'string',
  totalSales: 'price',
  quantitySold: 'number',
  salesPercentage: 'percent',

  currentWeekEndDate: 'date-string',
  salesPercentageWeek1InPast: 'percent',
  salesPercentageWeek2InPast: 'percent',
  salesPercentageWeek3InPast: 'percent',
  salesPercentageWeek4InPast: 'percent',

  quantitySoldWeek1InPast: 'number',
  quantitySoldWeek2InPast: 'number',
  quantitySoldWeek3InPast: 'number',
  quantitySoldWeek4InPast: 'number',

  totalSalesWeek1InPast: 'price',
  totalSalesWeek2InPast: 'price',
  totalSalesWeek3InPast: 'price',
  totalSalesWeek4InPast: 'price',
} as const

const useFarwestPmixSubcategoryData = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data: categoryPmixData, loading: categoryLoading } =
    useFarwestPmixCategoryData()
  const {
    variables: { isTrend },
  } = useVariables()

  const allCategoryCodeSet = new Set<string>()
  if (categoryPmixData && categoryPmixData.source) {
    categoryPmixData.source.forEach((rowData) => {
      if (rowData?.categoryCode) {
        allCategoryCodeSet.add(rowData.categoryCode as string)
      }
    })
  }

  const { data: trendData, loading: trendLoading } = useTrendUnifiedData({
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: 'SUBCATEGORY',
      iFilter: {
        location_group_ids: groupFilter?.ids,
        intersected_location_group_ids: groupFilter?.intersectedIds,
        item_category_codes: Array.from(allCategoryCodeSet),
        metrics: [
          'total_sales',
          'quantity_sold',
          'sales_percentage',
          'location_total_sales',
        ],
      },
    },
    skip: !isTrend || !endDate || !groupFilter,
  })

  const { data: subcategoryData, loading: subcategoryLoading } = useUnifiedData(
    {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iQueryType: 'SUBCATEGORY',
        iFilter: {
          location_group_ids: groupFilter?.ids,
          intersected_location_group_ids: groupFilter?.intersectedIds,
          item_category_codes: Array.from(allCategoryCodeSet),
          metrics: [
            'total_sales',
            'quantity_sold',
            'sales_percentage',
            'location_total_sales',
          ],
        },
      },
      skip:
        isTrend ||
        !categoryPmixData ||
        !startDate ||
        !endDate ||
        !groupFilter ||
        !hasGroupBy,
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      const source = (isTrend ? trendData : subcategoryData)?.source?.reduce(
        (s: any, r: any) =>
          !r.itemCode
            ? s
            : [
                ...s,
                {
                  ...r,
                  subcategoryName: r.itemName,
                  subcategoryCode: r.itemCode,
                  categoryName: r.displayParentCategoryName,
                  categoryCode: r.displayParentCategoryCode,
                },
              ],
        [] as { [key: string]: unknown }[],
      )

      if (!source) return null

      return {
        source: _.orderBy(source, [
          'categoryName',
          'subcategoryName',
          'itemName',
        ]),
        summary: (isTrend ? trendData : subcategoryData)?.summary,
      }
    }, [subcategoryData, trendData, categoryPmixData]),
    loading: categoryLoading || subcategoryLoading || trendLoading,
    trendLoading,
  }
}

export default useFarwestPmixSubcategoryData
