import { useMemo } from 'react'

import { IApiDataType } from '../../types'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const fwWingstopSystemwideOverviewConfigs = {
  netSales: 'price',
  netSalesPercentageDiffToLastYear: 'percent',
  salesVariancePercentage: 'percent',
  checkCount: 'number',
  checkAverage: 'price',
  laborActualPercent: 'percent',
  laborVariancePercent: 'percent',
  actualFoodCostPercentage: 'percent',
  varFoodCostPercentage: 'percent',
} as const

const useCommonFwWingstopSystemwideOverview = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'net_sales',
      'net_sales_percentage_diff_to_last_year',
      'sales_variance_percentage',
      'check_count',
      'check_average',
      'labor_actual_percent',
      'labor_variance_percent',
      'actual_food_cost_percentage',
      'var_food_cost_percentage',
    ],
  },
})

const useFwWingstopSystemwideOverview = () => {
  const { data, loading } = useCommonFwWingstopSystemwideOverview()

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return data

      return {
        ...data,
        laborVariancePercent: (data.laborVariancePercent as number) * -1,
      }
    }, [data]),
    loading: loading,
  }
}

export default useFwWingstopSystemwideOverview
