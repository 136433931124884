import { useSortable } from '@dnd-kit/sortable'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { flexRender } from '@tanstack/react-table'
import * as _ from 'lodash'
import React, { useContext, useEffect, useMemo } from 'react'
import { usePrevious } from 'react-use'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import { useVariables } from '../variables'
import { DragMoveContext, NullContext } from './DragMoveContext'
import { Cell } from './hooks/useColumns'
import useRowStyle, {
  IOtherStyleKeyType,
  IRowStyleType,
  IRowType,
} from './hooks/useRowStyle'

export interface IPropsType extends Omit<IRowType, 'rowData'> {
  rowData: NonNullable<IRowType['rowData']>
}

const getStyles = (
  { background, fontSize, bold, opacity }: IRowStyleType,
  hasDefault: boolean = true,
) => {
  const styles: Record<string, string | undefined> = {
    background: !background && hasDefault ? COLORS.background : background,
    'font-size': !fontSize && hasDefault ? 'inherit' : fontSize,
    'font-family': (() => {
      if (bold === 'true') return 'Lexend-SemiBold'

      if (hasDefault) return 'Lexend-Regular'
    })(),
    opacity,
  }

  return Object.keys(styles)
    .reduce(
      (result, key) =>
        !styles[key] ? result : [...result, `${key}: ${styles[key]};`],
      [] as string[],
    )
    .join('\n')
}

const StyledTr = styled.tr<IRowStyleType & { isDragging: boolean }>`
  ${getStyles}
  opacity: ${({ isDragging }) => (isDragging ? 0.8 : 1)};
  z-index: ${({ isDragging }) => (isDragging ? 1 : 0)};

  ${(styles) =>
    (Object.keys(styles) as IOtherStyleKeyType[])
      .filter((key) => styles[key] instanceof Object)
      .map(
        (key) => `${key} { ${getStyles(styles[key] as IRowStyleType, false)} }`,
      )
      .join('\n')}

  ${({ isDragging }) =>
    !isDragging
      ? ''
      : `
        position: relative;
        height: 2px;

        &, td {
          padding: 0px;
          background-color: #56a1f8;
          font-size: 0px;
        }

        svg, button {
          display: none;
        }

        td:first-child:before {
          position: absolute;
          left: -8px;
          top: -2px;
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid #2389ff;
          background-color: #ffffff;
        }
      `}
`

export const DragIconWrapper = styled.div`
  display: flex;
  padding: 4px 10px;

  &:hover {
    cursor: grab;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .MuiSvgIcon-root {
    font-size: 18px;
    fill: #919eab;
  }
`

const Row = ({ row, table, rowData }: IPropsType) => {
  const { variables } = useVariables()
  const draggable = useMemo(
    () => Boolean(row.onDrop && _.get(variables, row.onDrop)),
    [row, variables],
  )
  const {
    transform,
    transition,
    setDroppableNodeRef,
    setDraggableNodeRef,
    listeners,
    active,
    isDragging,
  } = useSortable({
    id: rowData.id as string,
    data: rowData,
    disabled: !draggable || (rowData.original.isFrozen as boolean),
  })
  const dragMove = useContext(isDragging ? DragMoveContext : NullContext)
  const styles = useRowStyle({
    row,
    table,
    rowData,
  })
  const isActive = useMemo(
    () => (rowData?.getParentRows() || []).some((r) => r.id === active?.id),
    [active, rowData],
  )
  const isDraggingReady = isDragging && Boolean(transform) && Boolean(dragMove)
  const prevIsDraggingReady = usePrevious(isDraggingReady)

  useEffect(() => {
    const dom = document.createElement('style')

    if (isDragging) {
      dom.innerHTML = 'body, body * { cursor: grabbing !important; }'
      document.body.appendChild(dom)
    }

    return () => {
      dom.remove()
    }
  }, [isDragging])

  if (isActive) return null

  return (
    <StyledTr
      {...styles}
      ref={rowData.original.isFrozen ? null : setDroppableNodeRef}
      isDragging={isDraggingReady}
      style={{
        transition:
          isDragging &&
          (!isDraggingReady || isDraggingReady !== prevIsDraggingReady)
            ? ''
            : transition,
        transform: !transform
          ? ''
          : `translate(${dragMove?.x || transform.x}px, ${transform.y}px)`,
      }}
    >
      {!draggable ? null : (
        <Cell isPinned={false}>
          {rowData.original.isFrozen ? null : (
            <DragIconWrapper {...listeners} ref={setDraggableNodeRef}>
              <DragIndicatorIcon />
            </DragIconWrapper>
          )}
        </Cell>
      )}

      {rowData.getVisibleCells().map((cell) => (
        <React.Fragment key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </React.Fragment>
      ))}
    </StyledTr>
  )
}

export default Row
