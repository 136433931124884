import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { corporateGroupTableConfigs } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

type IStoreDetailsType<T extends string = 'listLocationMarketAndOpenDate'> =
  Record<
    T,
    {
      nodes: {
        locationId: number
        openedAt: string
        market: string
        ro: string
      }[]
    }
  >

export const fwWingstopSystemwideOverviewConfigs = {
  ...corporateGroupTableConfigs,
  netSales: 'price',
  netSalesPercentageDiffToLastYear: 'percent',
  salesVariancePercentage: 'percent',
  laborActualPercent: 'percent',
  laborVariancePercent: 'percent',
  actualFoodCostPercentage: 'percent',
  varFoodCostPercentage: 'percent',
  openedAt: 'string',
  market: 'string',
} as const

const query = gql`
  query ListFarwestLocationsDetail(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationMarketAndOpenDate(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        openedAt
        market
        ro
      }
    }
  }
`

const useCommonFwWingstopSystemwideOverview = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'net_sales',
      'sales_variance_percentage',
      'net_sales_percentage_diff_to_last_year',
      'labor_actual_percent',
      'labor_variance_percent',
      'actual_food_cost_percentage',
      'var_food_cost_percentage',
    ],
  },
})

const useFwWingstopSystemwideOverview = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useCommonFwWingstopSystemwideOverview()
  const { data: storeDetailsData, loading: storeDetailLoading } =
    useQuery<IStoreDetailsType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_group_ids: hasGroupBy
            ? groupFilter?.ids
            : groupFilter?.list?.map((g) => g.id),
          intersected_location_group_ids: groupFilter?.intersectedIds,
        },
        hasGroupBy,
      },
      skip: !startDate || !endDate || !groupFilter,
    })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return data

      const locationDetails =
        storeDetailsData?.listLocationMarketAndOpenDate?.nodes

      const adjustedData = data.map((d) => {
        const marketDetail = locationDetails?.find(
          (l) => l.locationId === (d.location as { id: number })?.id,
        )

        const storeOpenDate = moment(marketDetail?.openedAt)
        return {
          ...d,
          openedAt: storeOpenDate.isValid()
            ? storeOpenDate.format('YYYY/MM/DD')
            : '-',
          market: marketDetail?.market,
          laborVariancePercent: (d.laborVariancePercent as number) * -1,
        }
      })

      return adjustedData
    }, [data, storeDetailsData]),
    loading: loading || storeDetailLoading,
  }
}

export default useFwWingstopSystemwideOverview
