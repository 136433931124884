import { useMemo } from 'react'

import {
  IDataType as IBbbDataType,
  IVariablesType as IBbbVariablesType,
  hooks as bbbHooks,
} from './bbb'
import {
  IDataType as IBurgerworksDataType,
  IVariablesType as IBurgerworksVariablesType,
  hooks as burgerworksHooks,
} from './burgerworks'
import {
  IDataType as ICommonDataType,
  IVariablesType as ICommonVariablesType,
  hooks as commonHooks,
} from './common'
import {
  IDataType as IFwWingStopDataType,
  IVariablesType as IFwWingStopVariablesType,
  hooks as fwWingStopHooks,
} from './fw_wingstop'
import {
  IDataType as IGroupFilterDataType,
  IVariablesType as IGroupFilterVariablesType,
  hooks as groupFilterHooks,
} from './groupFilter'

export type IVariablesType =
  | ICommonVariablesType
  | IGroupFilterVariablesType
  | IFwWingStopVariablesType
  | IBbbVariablesType
  | IBurgerworksVariablesType
export type IDataType = ICommonDataType &
  IGroupFilterDataType &
  IFwWingStopDataType &
  IBbbDataType &
  IBurgerworksDataType

const DEFAULT_HOOKS = ['track']

const hooks = [
  ...commonHooks,
  ...groupFilterHooks,
  ...fwWingStopHooks,
  ...bbbHooks,
  ...burgerworksHooks,
] as [
  Extract<IVariablesType, string>,
  (options?: IVariablesType) => IDataType,
][]

const useVariables = (types: IVariablesType[]): IDataType => {
  const variables: IDataType[] = []

  for (const [type, hook] of hooks) {
    const option = types.find((t) =>
      typeof t === 'string' ? t === type : t.type === type,
    )

    if (DEFAULT_HOOKS.includes(type) || option) variables.push(hook(option))
  }

  return useMemo(
    () =>
      variables.reduce(
        (result, data) => ({
          ...result,
          ...data,
        }),
        {} as IDataType,
      ),
    variables,
  )
}

export default useVariables
