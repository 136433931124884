import _ from 'lodash'
import { useMemo } from 'react'

import { useGroupFilter } from '../../../../groupFilter'
import useLocationMetricValuesQuery, {
  IMetricType,
} from '../../../../hooks/useLocationMetricValuesQuery'
import useGlCodes from './useGlCodes'

const useGlTotal = (
  exrayCategory:
    | 'Sales'
    | 'Expenses'
    | 'Food Cost'
    | 'Labor Cost'
    | 'Net Profit',
  types: ('current' | 'yoy' | 'ytd' | 'prior' | 'prior ytd')[],
  skip?: boolean,
) => {
  const { groupFilter } = useGroupFilter()
  const isCorporate = Boolean(groupFilter?.list)
  const { glCodes, loading: glCodesLoading } = useGlCodes(exrayCategory, skip)
  const locationMetricValues = useLocationMetricValuesQuery({
    groupFilterTypes: ['location', 'locationGroup'],
    metrics: types
      .map(
        (type) =>
          glCodes
            .map((g) =>
              type === 'current'
                ? g.metricCode
                : g.metricCode && ({ key: g.metricCode, type } as IMetricType),
            )
            .filter(Boolean) as IMetricType[],
      )
      .flat(),
    fields: ['metricSummaryData'],
    handler: (variables) => {
      const newVariables = {
        ...variables,
        iFilter: {
          ...variables.iFilter,
          ...(isCorporate
            ? {
                location_group_ids: groupFilter?.ids,
              }
            : {
                location_ids: groupFilter?.ids,
              }),
        },
      }

      return {
        variables: newVariables,
        skip:
          skip ||
          !groupFilter ||
          !newVariables.iStartDate ||
          !newVariables.iEndDate ||
          (newVariables.iFilter.metrics || []).length === 0,
      }
    },
  })

  return useMemo(() => {
    const { data, yoyData, ytdData, priorData, priorYtdData, loading } =
      locationMetricValues
    const [total, yoyTotal, ytdTotal, priorTotal, priorYtdTotal] = [
      data,
      yoyData,
      ytdData,
      priorData,
      priorYtdData,
    ].map(
      (data) =>
        (data || []).reduce(
          (result, d) =>
            Object.values(d.metricSummaryData || {}).reduce(
              (subResult, { value }) => subResult + (value || 0),
              result,
            ),
          0,
        ) * (['Sales', 'Net Profit'].includes(exrayCategory) ? 1 : -1),
    )
    const [
      isCurrentNotEmpty,
      isYoyNotEmpty,
      isYtdNotEmpty,
      isPriorNotEmpty,
      isPriorYtdNotEmpty,
    ] = [data, yoyData, ytdData, priorData, priorYtdData].map((data) =>
      (data || []).reduce(
        (result, d) =>
          result ||
          Object.values(d.metricSummaryData || {}).some(
            ({ value }) => !_.isNil(value),
          ),
        false,
      ),
    )

    return {
      total: isCurrentNotEmpty ? total : null,
      yoyTotal: isYoyNotEmpty ? yoyTotal : null,
      ytdTotal: isYtdNotEmpty ? ytdTotal : null,
      priorTotal: isPriorNotEmpty ? priorTotal : null,
      priorYtdTotal: isPriorYtdNotEmpty ? priorYtdTotal : null,
      loading: glCodesLoading || loading,
    }
  }, [exrayCategory, glCodesLoading, locationMetricValues])
}

export default useGlTotal
