import { IConfigsType } from '../../types'

const fwWingstopLocationManagementConfig: IConfigsType = {
  'fw_wingstop-admin': {
    '/:brand/locations': {
      variables: ['manageLocations'],
      title: {
        type: 'typography',
        apiOrContent: 'Manage Locations',
      },
      locations: {
        type: 'table-v2',
        api: 'manageLocations',
        sorting: [
          {
            id: 'code',
            desc: false,
          },
        ],
        columns: [
          {
            key: 'code',
            title: 'Code',
            align: 'center',
            sortable: true,
          },
          {
            key: 'name',
            title: 'Name',
            sortable: true,
          },
          {
            key: 'startAt',
            title: 'Start At',
            sortable: true,
            align: 'center',
          },
          {
            key: 'endAt',
            title: 'End At',
            sortable: true,
            align: 'center',
          },
          {
            key: 'action',
            align: 'center',
            onClick: 'manageLocations.onOpen',
            hide: '<%- manageLocations.loading %>',
          },
        ],
      },
      editLocationGroupHistory: {
        type: 'modal',
        open: 'manageLocations.editLocationGroupHistoryOpen',
        onClose: 'manageLocations.onClose',
        children: {
          title: {
            type: 'typography',
            apiOrContent: '<%- editLocationGroupHistory.title %>',
          },
          locationGroupHistory: {
            type: 'timeline-editor',
            values: 'editLocationGroupHistory.values',
            onChange: 'editLocationGroupHistory.onChange',
            options: 'editLocationGroupHistory.options',
            errorMessages: 'editLocationGroupHistory.errorMessages',
          },
          buttons: {
            type: 'grid',
            margin: '40px 0px 0px',
            gap: '8px',
            children: {
              goBack: {
                type: 'button',
                label: 'Go Back',
                backgroundColor: '#cfcece',
                color: 'black',
                onClick: 'manageLocations.onClose',
              },
              submit: {
                type: 'button',
                label: 'Submit',
                loading: 'editLocationGroupHistory.loading',
                onClick: 'editLocationGroupHistory.submit',
              },
            },
          },
        },
      },
      changeLocationGroup: {
        type: 'modal',
        open: 'manageLocations.changeLocationGroupOpen',
        onClose: 'manageLocations.onClose',
        children: {
          title: {
            type: 'typography',
            apiOrContent:
              'Editing: <%- changeLocationGroup.locationGroupType %>',
          },
          locationGroups: {
            type: 'select',
            value: 'changeLocationGroup.value',
            onChange: 'changeLocationGroup.onChange',
            options: 'changeLocationGroup.options',
            label: 'Select <%- changeLocationGroup.locationGroupType %>',
            margin: '40px 0px 0px',
          },
          buttons: {
            type: 'grid',
            margin: '40px 0px 0px',
            gap: '8px',
            children: {
              goBack: {
                type: 'button',
                label: 'Go Back',
                backgroundColor: '#cfcece',
                color: 'black',
                onClick: 'manageLocations.onClose',
              },
              submit: {
                type: 'button',
                label: 'Submit',
                loading: 'changeLocationGroup.loading',
                onClick: 'changeLocationGroup.submit',
              },
            },
          },
        },
      },
      editLocation: {
        type: 'modal',
        open: 'manageLocations.editLocationOpen',
        onClose: 'manageLocations.onClose',
        children: {
          title: {
            type: 'typography',
            apiOrContent: '<%- editLocation.title %>',
          },
          location: {
            type: 'date-picker',
            value: 'editLocation.value',
            onChange: 'editLocation.onChange',
            label: 'Open Date',
            margin: '40px 0px 0px',
          },
          buttons: {
            type: 'grid',
            margin: '40px 0px 0px',
            gap: '8px',
            children: {
              goBack: {
                type: 'button',
                label: 'Go Back',
                backgroundColor: '#cfcece',
                color: 'black',
                onClick: 'manageLocations.onClose',
              },
              submit: {
                type: 'button',
                label: 'Submit',
                loading: 'editLocation.loading',
                onClick: 'editLocation.submit',
              },
            },
          },
        },
      },
    },
  },
}

export default fwWingstopLocationManagementConfig
