import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType } from '../../types'

interface IQueryData {
  listLocationGroupMapping: {
    nodes: {
      locationId: number
      locationCode: string
      locationName: string
      locationOpenedAt: string
      locationClosedAt: string
      locationGroupId: number
      locationGroupName: string
      locationGroupEmail: string
      locationGroupType: string
      locationGroupStartDate: string
      locationGroupEndDate: string
    }[]
  }
}

interface IQueryVariables {
  iBrandId: number
}

const query = gql`
  query listLocationGroupMapping($iBrandId: Int!) {
    listLocationGroupMapping(iBrandId: $iBrandId) {
      nodes {
        locationId
        locationCode
        locationName
        locationOpenedAt
        locationClosedAt
        locationGroupId
        locationGroupName
        locationGroupEmail
        locationGroupType
        locationGroupStartDate
        locationGroupEndDate
      }
    }
  }
`

export const manageLocationGroupsConfigs = {
  name: 'string',
  locationGroupEmail: 'string',
  startAt: 'string',
  endAt: 'string',
  action: 'button',
} as const

const formatDate = (date: string) =>
  date && moment.utc(date).format('YYYY-MM-DD')

const useManageLocationGroups = () => {
  const { brand } = useBrands()
  const { data, loading } = useQuery<IQueryData, IQueryVariables>(query, {
    variables: {
      iBrandId: BRAND_ID[brand],
    },
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const locationGroupTypes: IApiDataType = []
      const locationGroups: IApiDataType = []
      const locations: IApiDataType = []

      data.listLocationGroupMapping.nodes.forEach((n) => {
        if (
          [
            'Brand',
            'Comp or Non-Comp',
            'Core or Non-Core',
            'Open Status',
            'Legal Entity',
          ].includes(n.locationGroupType)
        )
          return

        if (!locationGroupTypes.some((lg) => lg.id === n.locationGroupType))
          locationGroupTypes.push({
            id: n.locationGroupType,
            parentId: 'root',
            name: n.locationGroupType,
            action: 'ADD',
            manageType: 'addLocationGroup',
            locationGroupType: n.locationGroupType,
          })

        const locationGroupsId = `${n.locationGroupType}${n.locationGroupName}`
        const existingLocationGroup = locationGroups.find(
          (lg) => lg.id === locationGroupsId,
        )

        if (!existingLocationGroup)
          locationGroups.push({
            id: locationGroupsId,
            parentId: n.locationGroupType,
            name: n.locationGroupName,
            action: 'EDIT',
            manageType: 'editLocationGroup',
            locationGroupId: n.locationGroupId,
            locationGroupType: n.locationGroupType,
            locationGroupEmail: n.locationGroupEmail,
            locationGroupName: n.locationGroupName,
            locationIds: [n.locationId].filter(Boolean),
          })
        else (existingLocationGroup.locationIds as number[]).push(n.locationId)

        if (!n.locationId) return

        const locationId = [
          n.locationCode,
          n.locationGroupName,
          n.locationGroupType,
        ].join('-')

        if (!locations.some((l) => l.id === locationId)) {
          locations.push({
            id: locationId,
            parentId: locationGroupsId,
            name: `${n.locationCode} - ${n.locationName}`,
            startAt: formatDate(n.locationGroupStartDate),
            endAt: formatDate(n.locationGroupEndDate),
          })
          return
        }

        locations.push({
          id: [
            n.locationCode,
            n.locationGroupName,
            n.locationGroupType,
            n.locationGroupStartDate,
            n.locationGroupEndDate,
          ].join('-'),
          parentId: locationId,
          name: ' ',
          startAt: formatDate(n.locationGroupStartDate),
          endAt: formatDate(n.locationGroupEndDate),
        })
      })

      return [...locationGroupTypes, ...locationGroups, ...locations]
    }, [data]),
    loading,
  }
}

export default useManageLocationGroups
