import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query TrendFarwestDailySplh(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    trendLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        startDate
        endDate
        metricData
        metricSummaryData
      }
    }

    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        startDate
        endDate
        metricData
        metricSummaryData
      }
    }

    listLocationMarketAndOpenDate(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        openedAt
        market
        ro
      }
    }
  }
`

export const farwestTrendDailySplhConfigs = {
  market: 'string',
  ro: 'string',
  openedAt: 'date-string',
  sun: 'price',
  mon: 'price',
  tue: 'price',
  wed: 'price',
  thu: 'price',
  fri: 'price',
  sat: 'price',
} as const

const weekDayMapping = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

const useFarwestTrendDailySplh = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iGroupBy: 'last_x_days',
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: ['splh'],
      },
      hasGroupBy,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: any =
        data?.[
          hasGroupBy
            ? 'trendLocationMetricValues'
            : 'trendLocationGroupMetricValues'
        ]?.nodes

      if (!customizedData) return null

      const locationDetails: any = data?.listLocationMarketAndOpenDate?.nodes

      const source = groupFilter?.list
        ?.reduce(
          (result: any[], groupInfo: any) =>
            (
              customizedData?.filter(
                (c: any) =>
                  groupInfo.id ===
                  (hasGroupBy ? c.locationId : c.locationGroupId),
              ) || []
            ).reduce((subResult: any[], current: any) => {
              const marketDetail = locationDetails?.find(
                (l: any) => l.locationId === current.locationId,
              )
              let existingEntry = subResult.find(
                (entry) =>
                  entry.groupInfo.id ===
                  (hasGroupBy ? current?.locationId : current?.locationGroupId),
              )

              if (!existingEntry) {
                existingEntry = {
                  groupInfo,
                  market: marketDetail?.market,
                  openedAt: marketDetail?.openedAt,
                  ro: marketDetail?.ro,
                }
                subResult.push(existingEntry)
              }

              if (!current) return subResult

              existingEntry[
                weekDayMapping[moment(current.startDate).isoWeekday() % 7]
              ] = _.isNil(current.metricData.splh.value)
                ? null
                : current.metricData.splh.value * 100

              return subResult
            }, result),
          [],
        )
        .filter((s) =>
          Object.entries(s).some(
            ([key, value]) => key !== 'groupInfo' && !_.isNil(value),
          ),
        ) as NonNullable<IApiDataType>['source']

      const summary = customizedData
        .filter((cd: any) => cd.metricSummaryData)
        ?.reduce((result: { [key: string]: number }, current: any) => {
          return {
            ...result,
            [weekDayMapping[moment(current.startDate).isoWeekday() % 7]]:
              _.isNil(current.metricSummaryData.splh.value)
                ? null
                : current.metricSummaryData.splh.value * 100,
          }
        }, {})

      return {
        source,
        summary,
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useFarwestTrendDailySplh
