import { IConfigsType } from '../../types'

const fwWingstopGroupManagementConfig: IConfigsType = {
  'fw_wingstop-admin': {
    '/:brand/groups': {
      variables: ['manageLocationGroups'],
      title: {
        type: 'typography',
        apiOrContent: 'Manage Groups',
      },
      timeClockTable: {
        type: 'table-v2',
        api: 'manageLocationGroups',
        sorting: [
          {
            id: 'name',
            desc: false,
          },
        ],
        columns: [
          {
            key: 'name',
            title: 'Name',
            sortable: true,
          },
          {
            key: 'locationGroupEmail',
            title: 'Email',
          },
          {
            key: 'startAt',
            title: 'Open Date',
            sortable: true,
          },
          {
            key: 'action',
            align: 'center',
            onClick: 'manageLocationGroups.onOpen',
            hide: '<%- manageLocationGroups.loading %>',
          },
        ],
      },
      addLocationGroup: {
        type: 'modal',
        open: 'manageLocationGroups.addLocationGroupOpen',
        onClose: 'manageLocationGroups.onClose',
        children: {
          title: {
            type: 'typography',
            apiOrContent: 'Adding: <%- addLocationGroup.locationGroupType %>',
          },
          locationGroup: {
            type: 'input',
            value: 'addLocationGroup.value',
            onChange: 'addLocationGroup.onChange',
            errorMessage: 'addLocationGroup.errorMessage',
            label: 'New <%- addLocationGroup.locationGroupType %>',
            margin: '40px 0px 0px',
            width: '250px',
          },
          email: {
            type: 'input',
            value: 'addLocationGroup.email',
            onChange: 'addLocationGroup.onChangeEmail',
            errorMessage: 'addLocationGroup.errorMessage',
            label: 'Email',
            margin: '40px 0px 0px',
            width: '250px',
          },
          buttons: {
            type: 'grid',
            margin: '40px 0px 0px',
            gap: '8px',
            children: {
              goBack: {
                type: 'button',
                label: 'Go Back',
                backgroundColor: '#cfcece',
                color: 'black',
                onClick: 'manageLocationGroups.onClose',
              },
              submit: {
                type: 'button',
                label: 'Submit',
                loading: 'addLocationGroup.loading',
                onClick: 'addLocationGroup.submit',
              },
            },
          },
        },
      },
      editLocationGroup: {
        type: 'modal',
        open: 'manageLocationGroups.editLocationGroupOpen',
        onClose: 'manageLocationGroups.onClose',
        children: {
          title: {
            type: 'typography',
            apiOrContent: 'Editing: <%- editLocationGroup.locationGroupType %>',
          },
          locationGroup: {
            type: 'input',
            value: 'editLocationGroup.value',
            onChange: 'editLocationGroup.onChange',
            errorMessage: 'editLocationGroup.errorMessage',
            label: '<%- editLocationGroup.locationGroupType %>',
            margin: '40px 0px 0px',
            width: '250px',
          },
          email: {
            type: 'input',
            value: 'editLocationGroup.email',
            onChange: 'editLocationGroup.onChangeEmail',
            errorMessage: 'editLocationGroup.errorMessage',
            label: 'Email',
            margin: '40px 0px 0px',
            width: '250px',
          },
          buttons: {
            type: 'grid',
            margin: '40px 0px 0px',
            gap: '8px',
            children: {
              goBack: {
                type: 'button',
                label: 'Go Back',
                backgroundColor: '#cfcece',
                color: 'black',
                onClick: 'manageLocationGroups.onClose',
              },
              submit: {
                type: 'button',
                label: 'Submit',
                loading: 'editLocationGroup.loading',
                onClick: 'editLocationGroup.submit',
              },
            },
          },
          delete: {
            type: 'button',
            label: 'Delete',
            hide: '<%- !editLocationGroup.deletable %>',
            loading: 'editLocationGroup.loading',
            onClick: 'editLocationGroup.delete',
            margin: '8px 0px 0px',
            backgroundColor: '<%- colors.Pomodoro %>',
            color: 'white',
          },
        },
      },
    },
  },
}

export default fwWingstopGroupManagementConfig
