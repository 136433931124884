import { useMemo } from 'react'

import useFarwestTimeInterval, {
  IDataType as IFarwestTimeIntervalDataType,
} from './useFarwestTimeinterval'
import useFarwestFinancialCheckAverageCharts, {
  IDataType as IFarwestFinancialCheckAverageChartsDataType,
} from './useFinancialCheckAverageCharts'
import useFarwestFinancialSalesCharts, {
  IDataType as IFarwestFinancialSalesChartsDataType,
} from './useFinancialSalesCharts'
import useFinancialSssCharts, {
  IDataType as IFinancialSssChartsDataType,
} from './useFinancialSssCharts'
import useUploadReport, {
  IDataType as IUploadReportDataType,
} from './useUploadReport'

interface ITrendDataType {
  isTrend?: true
}

export type IVariablesType =
  | 'trend'
  | 'farwestTimeinterval'
  | 'farwestFinancialSalesCharts'
  | 'farwestFinancialCheckAverageCharts'
  | 'farwestFinancialSssCharts'
  | 'farwestUploadReport'

export type IDataType = ITrendDataType &
  IFarwestTimeIntervalDataType &
  IFarwestFinancialSalesChartsDataType &
  IFarwestFinancialCheckAverageChartsDataType &
  IFinancialSssChartsDataType &
  IUploadReportDataType

const useTrend = (): ITrendDataType =>
  useMemo(
    () => ({
      isTrend: true,
    }),
    [],
  )

export const hooks = [
  ['trend', useTrend],
  ['farwestTimeinterval', useFarwestTimeInterval],
  ['farwestFinancialSalesCharts', useFarwestFinancialSalesCharts],
  ['farwestFinancialCheckAverageCharts', useFarwestFinancialCheckAverageCharts],
  ['farwestFinancialSssCharts', useFinancialSssCharts],
  ['farwestUploadReport', useUploadReport],
] as const
