import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'

import { IApiDataType, IExrayDataType } from '../../types'

interface IDeliverySummaryNodeType {
  locationId: number
  locationCorrectOrderPercent: number
  systemwideCorrectOrderPercent: number
  locationInaccurateOrderPercent: number
  systemwideInaccurateOrderPercent: number
  locationCancelledOrderPercent: number
  systemwideCancelledOrderPercent: number
  locationDelayedOrderPercent: number
  systemwideDelayedOrderPercent: number
}

interface IDeliveryDayTrendNodeType {
  dayOfWeek: number
  dayPart: string
  totalOrderCount: number
  inaccurateOrderPercent: number
  cancelledOrderPercent: number
  delayedOrderPercent: number
  avgCustomerReviewScore: number
  reviewedOrderCount: number
  averageTripTime: number
}

interface IDeliveryStaffKpiNodeType {
  locationId: number
  employeeInfo: {
    id: number
    firstName: string
    lastName: string
  }
  employeeRoleName: string
  employeeRoleCode: string
  totalOrderCount: number
  inaccurateOrderPercent: number
  cancelledOrderPercent: number
  delayedOrderPercent: number
  avgCustomerReviewScore: number
  totalReviewCount: number
  averageTripTime: number
}

interface IDeliverySummaryType {
  listLocationDeliveryStaffKpi: {
    nodes: IDeliveryStaffKpiNodeType[]
  }
  listLocationDeliveryDayTrends: {
    nodes: IDeliveryDayTrendNodeType[]
  }
  getLocationVsSystemwideDeliveryKpi: {
    nodes: IDeliverySummaryNodeType[]
  }
}

const query = gql`
  query LocationDeliverySummary(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    listLocationDeliveryStaffKpi(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationId
        employeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
        employeeRoleName
        employeeRoleCode
        totalOrderCount
        inaccurateOrderPercent
        cancelledOrderPercent
        delayedOrderPercent
        avgCustomerReviewScore
        totalReviewCount
        averageTripTime
      }
    }

    listLocationDeliveryDayTrends(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        dayOfWeek
        dayPart
        totalOrderCount
        inaccurateOrderPercent
        cancelledOrderPercent
        delayedOrderPercent
        avgCustomerReviewScore
        reviewedOrderCount
        averageTripTime
      }
    }

    getLocationVsSystemwideDeliveryKpi(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationId
        locationCorrectOrderPercent
        systemwideCorrectOrderPercent
        locationInaccurateOrderPercent
        systemwideInaccurateOrderPercent
        locationCancelledOrderPercent
        systemwideCancelledOrderPercent
        locationDelayedOrderPercent
        systemwideDelayedOrderPercent
      }
    }
  }
`

const DAYS = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday',
}

const getDeliverySummaryData = (
  locationName: string,
  data: IDeliverySummaryType,
): IExrayDataType => {
  const deliveryAction = {
    isBetterThanCompany: false,
    issue: '',
    worstDay: '',
    name: ',',
  }
  const deliverySummary = data?.getLocationVsSystemwideDeliveryKpi?.nodes[0]
  let accuracy = false
  let cancellations = false
  let lateness = false

  if (deliverySummary) {
    const {
      locationInaccurateOrderPercent,
      systemwideInaccurateOrderPercent,
      locationCancelledOrderPercent,
      systemwideCancelledOrderPercent,
      locationDelayedOrderPercent,
      systemwideDelayedOrderPercent,
    } = deliverySummary

    if (locationInaccurateOrderPercent > systemwideInaccurateOrderPercent) {
      accuracy = true
    }

    if (locationCancelledOrderPercent > systemwideCancelledOrderPercent) {
      cancellations = true
    }

    if (locationDelayedOrderPercent > systemwideDelayedOrderPercent) {
      lateness = true
    }
  }

  const dayTrendData = data?.listLocationDeliveryDayTrends?.nodes || []

  const deliveryStaffData = data?.listLocationDeliveryStaffKpi?.nodes || []

  if (dayTrendData?.length > 0 && deliveryStaffData?.length > 0) {
    const fullDays = dayTrendData.filter(({ dayPart }) => dayPart === 'ALL')
    let worstAccuracyDay = null
    let worstLatenessDay = null
    let worstCancellationDay = null

    fullDays.reduce(
      (
        acc,
        {
          dayOfWeek,
          inaccurateOrderPercent,
          delayedOrderPercent,
          cancelledOrderPercent,
        },
      ) => {
        if (inaccurateOrderPercent > acc.inaccurateOrderPercent) {
          worstAccuracyDay = dayOfWeek
          acc.inaccurateOrderPercent = inaccurateOrderPercent
        }

        if (delayedOrderPercent > acc.delayedOrderPercent) {
          worstLatenessDay = dayOfWeek
          acc.delayedOrderPercent = delayedOrderPercent
        }

        if (cancelledOrderPercent > acc.cancelledOrderPercent) {
          worstCancellationDay = dayOfWeek
          acc.cancelledOrderPercent = cancelledOrderPercent
        }

        return acc
      },
      {
        inaccurateOrderPercent: 0,
        delayedOrderPercent: 0,
        cancelledOrderPercent: 0,
      },
    )

    let employees: IDeliveryStaffKpiNodeType[] = []

    if (accuracy) {
      if (worstAccuracyDay) {
        employees = [...deliveryStaffData].sort(
          (a, b) => b.inaccurateOrderPercent - a.inaccurateOrderPercent,
        )

        deliveryAction.issue = 'Accuracy'
        deliveryAction.worstDay = DAYS[worstAccuracyDay]
      }
    } else if (lateness) {
      if (worstLatenessDay) {
        employees = [...deliveryStaffData].sort(
          (a, b) => b.delayedOrderPercent - a.delayedOrderPercent,
        )

        deliveryAction.issue = 'Lateness'
        deliveryAction.worstDay = DAYS[worstLatenessDay]
      }
    } else if (cancellations) {
      if (worstCancellationDay) {
        employees = [...deliveryStaffData].sort(
          (a, b) => b.cancelledOrderPercent - a.cancelledOrderPercent,
        )

        deliveryAction.issue = 'Cancellations'
        deliveryAction.worstDay = DAYS[worstCancellationDay]
      }
    }

    if (employees.length > 0) {
      deliveryAction.name = `${employees[0].employeeInfo.firstName} ${employees[0].employeeInfo.lastName}`
    }

    if (!accuracy && !lateness && !cancellations) {
      deliveryAction.isBetterThanCompany = true

      if (worstAccuracyDay) {
        deliveryAction.issue = 'Accuracy'
        deliveryAction.worstDay = DAYS[worstAccuracyDay]
      } else if (worstLatenessDay) {
        deliveryAction.issue = 'Lateness'
        deliveryAction.worstDay = DAYS[worstLatenessDay]
      } else if (worstCancellationDay) {
        deliveryAction.issue = 'Cancellations'
        deliveryAction.worstDay = DAYS[worstCancellationDay]
      }
    }
  } else {
    return {
      title: 'Delivery',
      total: null,
      detail: 'No data available for the selected time period.',
    }
  }

  return {
    title: 'Delivery',
    total: null,
    detail: deliveryAction.isBetterThanCompany
      ? `${locationName} performs better than company averages, but to keep improving: ${deliveryAction.worstDay} is the worst day for ${deliveryAction.issue}.`
      : deliveryAction.issue &&
        `${deliveryAction.issue} is the top area to improve upon at ${locationName}. ${deliveryAction.worstDay} is the worst day. ${deliveryAction.name} is on shift when ${deliveryAction.issue} is worst.  Consider retraining your employees or repositioning them to reduce delivery issues.`,
  }
}

const useDelivery = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { data, loading } = useQuery<IDeliverySummaryType>(query, {
    variables: {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !groupFilter,
  })

  return {
    data: useMemo(() => {
      if (!data) {
        return {
          title: 'Delivery',
          total: null,
          detail: 'No data available for the selected time period.',
        }
      }

      return getDeliverySummaryData(locationName, data)
    }, [locationName, data]),
    loading,
  }
}

export default useDelivery
