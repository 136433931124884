import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const fwWingstopFinancialChartConfig: IConfigsType = {
  'fw_wingstop-financial-chart-239cbabf': {
    '/:brand/sales_chart': {
      variables: ['date', 'farwestTimeinterval', 'farwestFinancialSalesCharts'],
      groupFilter: {
        api: 'fwWingstopCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['year'],
        defaultType: 'year',
      },
      pageTitle: {
        type: 'title',
        title: 'Total Sales Charts',
      },
      salesChartFilters: {
        type: 'grid',
        children: {
          downloadReport: {
            type: 'download-csv',
            api: 'farwestSalesCharts',
            fileName: 'getexpo-far-west-sales-charts',
            message: 'Download as CSV',
            fields: [
              {
                key: 'period',
                title: 'Date Period - Metric',
              },
              {
                key: 'currentValue',
                title: 'Data for <%- date?.getInfo(0)?.displayName %>',
              },
              {
                key: 'lastYearValue',
                title:
                  'Data for <%- (parseInt(date?.getInfo(0)?.displayName) - 1).toString() %>',
              },
            ],
          },
          timeColumns: {
            type: 'select',
            value: 'farwestTimeinterval.value',
            onChange: 'farwestTimeinterval.onChange',
            options: 'farwestTimeinterval.options',
            label: 'Time Interval:',
          },
          revenueColumns: {
            type: 'select',
            value: 'farwestFinancialSalesCharts.value',
            onChange: 'farwestFinancialSalesCharts.onChange',
            options: 'farwestFinancialSalesCharts.options',
            label: 'Channel:',
          },
        },
      },
      salesChart: {
        type: 'bar-chart',
        api: 'farwestSales',
        width: 1500,
        height: 800,
        label: 'period',
        datasets: [
          {
            chartType: 'line',
            key: 'value',
            label: 'Current Year Sales',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            key: 'valueLastYear',
            label: 'Prior Year Sales',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
            beginAtZero: true,
          },
        },
        options: {
          scales: {
            x: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
          },
        },
      },
    },
    '/:brand/sss_chart': {
      variables: ['date', 'farwestTimeinterval', 'farwestFinancialSssCharts'],
      groupFilter: {
        api: 'fwWingstopCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['year'],
        defaultType: 'year',
      },
      pageTitle: {
        type: 'title',
        title: 'SSS/SST/SSC Charts',
      },
      salesChartFilters: {
        type: 'grid',
        children: {
          downloadReport: {
            type: 'download-csv',
            api: 'farwestSssCharts',
            fileName: 'getexpo-far-west-sss-sst-ssc-charts',
            message: 'Download as CSV',
            fields: [
              {
                key: 'period',
                title: 'Date Period - Metric',
              },
              {
                key: 'currentValue',
                title: '1Y Data for <%- date?.getInfo(0)?.displayName %>',
              },
              {
                key: 'twoYearsValue',
                title: '2Y Data for <%- date?.getInfo(0)?.displayName %>',
              },
              {
                key: 'lastYearValue',
                title:
                  'Data for <%- (parseInt(date?.getInfo(0)?.displayName) - 1).toString() %>',
              },
            ],
          },
          timeColumns: {
            type: 'select',
            value: 'farwestTimeinterval.value',
            onChange: 'farwestTimeinterval.onChange',
            options: 'farwestTimeinterval.options',
            label: 'Time Interval:',
          },
          revenueColumns: {
            type: 'select',
            value: 'farwestFinancialSssCharts.value',
            onChange: 'farwestFinancialSssCharts.onChange',
            options: 'farwestFinancialSssCharts.options',
            label: 'Channel:',
          },
        },
      },
      sssChart: {
        type: 'bar-chart',
        api: 'farwestSss',
        width: 1500,
        height: 800,
        label: 'period',
        datasets: [
          {
            chartType: 'line',
            key: 'value',
            label: '1 Year',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            chartType: 'line',
            key: 'valueTwoYears',
            label: '2 Years',
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
          },
          {
            key: 'valueLastYear',
            label: 'Prior Year',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ],
        scaleTypes: {
          y: {
            type: 'percent',
            beginAtZero: true,
          },
        },
        options: {
          scales: {
            x: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
          },
        },
      },
    },
    '/:brand/check_average_chart': {
      variables: [
        'date',
        'farwestTimeinterval',
        'farwestFinancialCheckAverageCharts',
      ],
      groupFilter: {
        api: 'fwWingstopCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['year'],
        defaultType: 'year',
      },
      pageTitle: {
        type: 'title',
        title: 'Check Average Charts',
      },
      checkAverageChartFilters: {
        type: 'grid',
        children: {
          downloadReport: {
            type: 'download-csv',
            api: 'farwestCheckAverageCharts',
            fileName: 'getexpo-far-west-check-average-charts',
            message: 'Download as CSV',
            fields: [
              {
                key: 'period',
                title: 'Date Period - Metric',
              },
              {
                key: 'currentValue',
                title: 'Data for <%- date?.getInfo(0)?.displayName %>',
              },
              {
                key: 'lastYearValue',
                title:
                  'Data for <%- (parseInt(date?.getInfo(0)?.displayName) - 1).toString() %>',
              },
            ],
          },
          timeColumns: {
            type: 'select',
            value: 'farwestTimeinterval.value',
            onChange: 'farwestTimeinterval.onChange',
            options: 'farwestTimeinterval.options',
            label: 'Time Interval:',
          },
          revenueColumns: {
            type: 'select',
            value: 'farwestFinancialCheckAverageCharts.value',
            onChange: 'farwestFinancialCheckAverageCharts.onChange',
            options: 'farwestFinancialCheckAverageCharts.options',
            label: 'Channel:',
          },
        },
      },
      checkAverageChart: {
        type: 'bar-chart',
        api: 'farwestCheckAverage',
        width: 1500,
        height: 800,
        label: 'period',
        datasets: [
          {
            chartType: 'line',
            key: 'value',
            label: 'Current Year Check Average',
            decimal: 2,
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            key: 'valueLastYear',
            label: 'Prior Year Check Average',
            decimal: 2,
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
            decimal: 2,
            min: 1500,
          },
        },
        options: {
          scales: {
            x: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
          },
        },
      },
    },
  },
}

export default fwWingstopFinancialChartConfig
