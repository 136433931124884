import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import {
  api as tableApi,
  configs as tableConfigs,
} from '../../table/hooks/useApi'
import useBbbFlash, { bbbFlashConfigs } from '../../tableV2/hooks/bbb/useFlash'
import useBbbPnlRankings, {
  bbbPnlRankingsConfigs,
} from '../../tableV2/hooks/bbb/usePnLRankings'
import useBibibopRankings, {
  bibibopRankingsConfigs,
} from '../../tableV2/hooks/bbb/useRankings'
import useBurgerworksFlash, {
  burgerworksFlashConfigs,
} from '../../tableV2/hooks/burgerworks/useFlash'
import useBurgerworksRankings, {
  burgerworksRankingsConfigs,
} from '../../tableV2/hooks/burgerworks/useRankings'
import useDhRankings, {
  dhRankingsConfigs,
} from '../../tableV2/hooks/dhWb/useRankings'
import useFwWingstopDiscountAndVoid, {
  fwWingstopDiscountAndVoidConfigs,
} from '../../tableV2/hooks/fwWingstop/useDiscountAndVoid'
import useIrmgBkTrendEodCompleted, {
  irmgBkTrendEodCompletedConfigs,
} from '../../tableV2/hooks/irmgBk/useTrendEodCompleted'
import { IApiDataType } from '../../tableV2/types'
import useBbbOpsDashboard, {
  bbbOpsDashboardConfigs,
} from './useBbbOpsDashboard'
import useDhWbFlashV2, { dhWbFlashV2Configs } from './useDhWbFlash'
import useFwWingstopDigitalAndNondigital, {
  fwWingstopDigitalAndNondigitalConfigs,
} from './useFwWingstopDigitalAndNondigital'
import useFwWingstopSalesAlertDashboard, {
  fwWingstopSalesAlertDashboardConfig,
} from './useFwWingstopSalesAlertDashboard'
import useFwWingstopSalesbyOrderMode, {
  fwWingstopFlashSalesByOrderModeConfigs,
} from './useFwWingstopSalesbyOrderMode'
import useFwWingstopTimeClock, {
  fwWingstopTimeClockConfigs,
} from './useFwWingstopTimeClock'
import useItem, { itemV2Configs } from './useItem'
import useLfrBusinessReviewAndGuest, {
  lfrBusinessReviewAndGuestConfigs,
} from './useLfrBusinessReviewAndGuest'
import useMadeFromScratchCsv, {
  madeFromScratchConfigs,
} from './useMadeFromScratch'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...tableConfigs,
  lfrBusinessReviewAndGuest: lfrBusinessReviewAndGuestConfigs,
  fwWingstopTimeClock: fwWingstopTimeClockConfigs,
  fwWingstopFlashSalesByOrderMode: fwWingstopFlashSalesByOrderModeConfigs,
  bbbFlashV2: bbbFlashConfigs,
  bbbPnlRankingsV2: bbbPnlRankingsConfigs,
  madeFromScratch: madeFromScratchConfigs,
  burgerworksFlashV2: burgerworksFlashConfigs,
  item: itemV2Configs,
  bbbOpsDashboard: bbbOpsDashboardConfigs,
  burgerworksRankings: burgerworksRankingsConfigs,
  dhWbFlashV2: dhWbFlashV2Configs,
  dhRankings: dhRankingsConfigs,
  irmgBkEodReport: irmgBkTrendEodCompletedConfigs,
  bbbRankings: bibibopRankingsConfigs,
  fwWingstopDiscountAndVoid: fwWingstopDiscountAndVoidConfigs,
  fwWingstopSalesAlertDashboard: fwWingstopSalesAlertDashboardConfig,
  fwWingstopDigitalAndNondigital: fwWingstopDigitalAndNondigitalConfigs,
}

// WARNING: it just a simple wrapper, maybe not match all case
const buildTableV2Wrapper = (
  useApi: () => { data: IApiDataType; loading: boolean },
  withoutGroupBy?: boolean,
) => {
  const useTableV2Wrapper = () => {
    const { data, loading } = useApi()
    const { groupFilter } = useGroupFilter()

    return {
      data: useMemo((): {
        source: Record<string, unknown>[]
        summary: Record<string, unknown> | null
      } | null => {
        if (!data || !groupFilter?.list) return null

        // Add orderIndex to data
        const dataWithIndex = data.map((d, index) => ({
          ...d,
          rowIndex: index + 1,
        }))

        if (!dataWithIndex.some((d) => d.id === 'summary'))
          return {
            source: groupFilter.list
              .map((l) => {
                const locationData = dataWithIndex.find(
                  (d) => d.id === l.id.toString(),
                )
                const copiedGroupInfo = { ...l }

                if (withoutGroupBy) delete copiedGroupInfo.groupBy

                return {
                  ...locationData,
                  groupInfo: copiedGroupInfo,
                }
              })
              .sort((a, b) => (a.rowIndex || 0) - (b.rowIndex || 0)),
            summary: null,
          }

        return {
          source: dataWithIndex
            .filter((d) => d.parentId === 'summary')
            .map((d) => {
              const groupInfo = groupFilter?.list?.find(
                (l) => l.id.toString() === d.id,
              )

              return {
                ...d,
                groupInfo,
              }
            })
            .sort((a, b) => a.rowIndex - b.rowIndex),
          summary:
            dataWithIndex.filter((d) => d.parentId === 'root')?.[0] || null,
        }
      }, [data, groupFilter]),
      loading,
    }
  }

  return useTableV2Wrapper
}

const api = {
  ...tableApi,
  lfrBusinessReviewAndGuest: useLfrBusinessReviewAndGuest,
  fwWingstopTimeClock: useFwWingstopTimeClock,
  fwWingstopFlashSalesByOrderMode: useFwWingstopSalesbyOrderMode,
  bbbFlashV2: buildTableV2Wrapper(useBbbFlash),
  bbbPnlRankingsV2: buildTableV2Wrapper(useBbbPnlRankings, true),
  madeFromScratch: useMadeFromScratchCsv,
  burgerworksFlashV2: buildTableV2Wrapper(useBurgerworksFlash),
  item: useItem,
  bbbOpsDashboard: useBbbOpsDashboard,
  burgerworksRankings: buildTableV2Wrapper(useBurgerworksRankings, true),
  dhWbFlashV2: useDhWbFlashV2,
  dhRankings: buildTableV2Wrapper(useDhRankings, true),
  irmgBkEodReport: buildTableV2Wrapper(useIrmgBkTrendEodCompleted, true),
  bbbRankings: buildTableV2Wrapper(useBibibopRankings, true),
  fwWingstopDiscountAndVoid: buildTableV2Wrapper(useFwWingstopDiscountAndVoid),
  fwWingstopSalesAlertDashboard: useFwWingstopSalesAlertDashboard,
  fwWingstopDigitalAndNondigital: useFwWingstopDigitalAndNondigital,
}

const useApi = (apiName: IApiKeyType) => api[apiName]()

export default useApi
