import * as d3 from 'd3-scale'
import { useMemo } from 'react'

import { IApiDataType } from '../../../types'
import buildLocationEmployeeMetricValuesHook from '../../utils/buildLocationEmployeeMetricValuesHook'
import { DISCOUNT_TYPES } from './constants'

export const dhWbListEmployeeDiscountDataConfigs = {
  employeeName: 'string',
  employeeRole: 'string',
  grossSales: 'price',
  discountCompsTotal: 'price',
  discountCheckCountTotal: 'number',
  discountCompsPercentTotal: 'percent',
  discountTypes: 'string',
  discountTypeSummary: 'string',
  discountTotalPercentRank: 'number',
} as const

const useCommonEmployeeDiscountData = buildLocationEmployeeMetricValuesHook({
  codes: [
    'gross_sales',
    'discount_comps_total',
    'discount_comps_percent_total',
    'discount_check_count_total',
  ],
  groups: [
    'Expo Module - Discount Comps',
    'Expo Module - Discount Check Count',
  ],
})

const useListEmployeeDiscountData = () => {
  const { data: commonData, loading } = useCommonEmployeeDiscountData()

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return null

      const filteredData = commonData.filter((d) => d.grossSales)

      const minDiscountTotalPercent = Math.min(
        ...filteredData.map((n) => n['discountCompsPercentTotal'] as number),
      )
      const maxDiscountTotalPercent = Math.max(
        ...filteredData.map((n) => n['discountCompsPercentTotal'] as number),
      )
      const discountCompsPercentScaler = d3.scaleLinear(
        [0, 100],
        [minDiscountTotalPercent, maxDiscountTotalPercent],
      )
      const rankScaler = d3.scaleLinear(
        [minDiscountTotalPercent, maxDiscountTotalPercent],
        [1, filteredData.length],
      )

      const newData = filteredData.map((n) => {
        const discountTypeSummary = DISCOUNT_TYPES.reduce((sum, d) => {
          const discountDetailCheckCount = n?.[d.checkCountKey]
          if (discountDetailCheckCount) {
            return [...sum, `${d.discountName} (${discountDetailCheckCount})`]
          }

          return sum
        }, [] as string[])

        return {
          ...n,
          discountTypeSummary: discountTypeSummary?.join(', '),
          discountTotalPercentHeatmap: discountCompsPercentScaler.invert(
            n['discountCompsPercentTotal'] as number,
          ),
          discountTotalPercentRank: rankScaler(
            n['discountCompsPercentTotal'] as number,
          ),
        }
      })

      const discountDetails = newData.reduce((result, nd) => {
        const details = DISCOUNT_TYPES.reduce((sum, dt) => {
          const employeeIdStr = nd.id
          const discountTypes = dt.discountName
          const discountDetailComps = nd?.[dt.compsKey]
          const discountDetailCheckCount = nd?.[dt.checkCountKey]
          if (discountDetailComps || discountDetailCheckCount) {
            return [
              ...sum,
              {
                id: `${employeeIdStr}-${discountTypes}`,
                parentId: employeeIdStr,
                discountTypes,
                discountCheckCountTotal: discountDetailCheckCount,
                discountCompsTotal: discountDetailComps,
              },
            ]
          }

          return sum
        }, [] as Exclude<IApiDataType, null>)

        return [...result, ...details]
      }, [] as Exclude<IApiDataType, null>)

      return [...newData, ...discountDetails]
    }, [commonData, loading]),
    loading: loading,
  }
}

export default useListEmployeeDiscountData
