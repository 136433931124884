import { useReactiveVar } from '@apollo/client'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/icons/Menu'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'
import FeedbackLink from 'pared/components/FeedbackLink'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { getIsProductionBrand } from 'pared/customer/general'
import { decodedUserJwtToken } from 'pared/reactiveVariables/user'
import { signOutUser } from 'pared/utils/user'

import { INavParams } from '../Routes/navParams'
import RouteList from './RouteList'
import { leftDrawerWidthLarge } from './constant'
import useBrands from './hooks/useBrands'
import useConfig from './hooks/useConfig'
import useRoutes from './hooks/useRoutes'

interface IProps {
  navParams: INavParams
  handleDrawerClose: () => void
}

const StyleList = styled(List)`
  width: ${leftDrawerWidthLarge}px;
  height: 100vh;
  overflow: auto;
  flex: 1 0 ${leftDrawerWidthLarge}px;
  background-color: black;
  color: ${COLORS.Salt};
  padding: 20px 25px;
`

const StyleListItemTextSignOut = styled(ListItemText)`
  padding-top: 60px;
  color: #00bfff;
  cursor: pointer;
  span {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  :hover {
    text-decoration: underline;
  }
`

const StyleListItemText = styled(ListItemText)`
  padding: 8px 0px;
  cursor: pointer;
  span {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  :hover {
    text-decoration: underline;
  }
`

const StyleNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  cursor: inherit;

  :hover {
    text-decoration: inherit;
  }

  svg {
    padding-right: 8px;
    visibility: hidden;
  }

  &.active svg {
    visibility: visible;
  }
`

const StyleIconButton = styled(IconButton)`
  color: ${COLORS.Salt};
  padding: 5px 12px 50px 7px;
`

const NavigationList = ({ navParams, handleDrawerClose }: IProps) => {
  const routes = useRoutes()
  const { brand } = useBrands()
  const brandSettings = getBrandSettings()
  const config = useConfig()
  const jwtToken = useReactiveVar(decodedUserJwtToken)
  const accessiblePages: string[] = jwtToken?.accessible_pages || []

  return (
    <StyleList>
      <StyleIconButton
        edge="start"
        aria-label="menu"
        onClick={handleDrawerClose}
      >
        <Menu fontSize="large" />
      </StyleIconButton>

      {routes.map((route) => (
        <RouteList
          key={route.title}
          route={route}
          onClose={handleDrawerClose}
          navParams={navParams}
          first
        />
      ))}

      {!brandSettings.modules.customs?.length ? null : (
        <>
          {brandSettings.modules.customs.map((module) => {
            if (module.accessPage) {
              if (!accessiblePages.includes(module.accessPage)) return
            }

            return (
              <StyleListItemText key={module.title}>
                <StyleNavLink to={module.url.replace(':brand', brand)}>
                  {module.title}
                </StyleNavLink>
              </StyleListItemText>
            )
          })}
        </>
      )}

      {navParams.canManageUsers ? (
        <StyleListItemText>
          <StyleNavLink to={`/${brand}/user_management`}>
            Manage Users
          </StyleNavLink>
        </StyleListItemText>
      ) : null}
      <StyleListItemText>
        <FeedbackLink />
      </StyleListItemText>
      {config.hideChangePassword ? null : (
        <StyleListItemText>
          <StyleNavLink
            to={`/${brand}/change_password`}
            onClick={() => {
              paredAnalytics.track('LeftNavigation - Change Password Clicked')
            }}
          >
            Change Password
          </StyleNavLink>
        </StyleListItemText>
      )}
      <StyleListItemTextSignOut
        onClick={() => {
          signOutUser()
        }}
      >
        Sign Out
      </StyleListItemTextSignOut>
    </StyleList>
  )
}

export default React.memo(NavigationList)
