import { IApiType } from '../types'
import { api as bbbApi, configs as bbbConfigs } from './bbb'
import {
  api as burgerworksApi,
  configs as burgerworksConfigs,
} from './burgerworks'
import { api as commonApi, configs as commonConfigs } from './common'
import { api as dhWbApi, configs as dhWbConfigs } from './dhWb'
import {
  api as fwWingstopApi,
  configs as fwWingstopConfigs,
} from './fwWingstop'
import { api as irmgBkApi, configs as irmgBkConfigs } from './irmgBk'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...commonConfigs,
  ...bbbConfigs,
  ...fwWingstopConfigs,
  ...burgerworksConfigs,
  ...dhWbConfigs,
  ...irmgBkConfigs,
}

export const api = {
  ...commonApi,
  ...bbbApi,
  ...fwWingstopApi,
  ...burgerworksApi,
  ...dhWbApi,
  ...irmgBkApi,
}

const useApi = (apiName: IApiKeyType): IApiType => api[apiName]()

export default useApi
