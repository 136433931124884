import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { BRAND_ID, BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType } from '../types'

interface IQueryDataType {
  listDirectors: {
    nodes: {
      employeeId: number
      locationGroupId: number
      firstName: string
      lastName: string
    }[]
  }
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
      }[]
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids: [number]
    brand_ids: [number]
  }
}

const query = gql`
  query corporateGroupFilter($iFilter: JSON!) {
    listDirectors(iFilter: $iFilter) {
      nodes {
        employeeId
        locationGroupId
        firstName
        lastName
      }
    }

    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }
  }
`

const useCorporateGroupFilter = () => {
  const { brand } = useBrands()
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        iFilter: {
          location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
          brand_ids: [BRAND_ID[brand]],
        },
      },
    },
  )
  const { search } = useLocation()

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const allStores = data.listLocationDetails.nodes.map((l) => {
        const director = data.listDirectors.nodes.find((d) =>
          l.locationGroups.some((lg) => lg.id === d.locationGroupId),
        )
        const queryObject = new URLSearchParams(search)

        queryObject.set('store', l.id.toString())

        const link = `/${brand}/store_detail?${queryObject.toString()}`

        return {
          id: l.id,
          name: `${l.code} - ${l.name}`,
          header: 'Stores',
          link,
          groupBy: director && {
            id: director.employeeId,
            header: 'DO/MGP',
            name: `${director.firstName} ${director.lastName}`.trim(),
            link: `/${brand}/employee_profile/${director.employeeId}`,
          },
        }
      })

      const values = [
        {
          id: 'all-stores',
          parentId: 'root',
          ids: [BRAND_LOCATION_GROUP_ID[brand]],
          label: 'All Stores',
          list: allStores,
        },
        {
          id: 'breakdown-by-director',
          parentId: 'root',
          ids: [BRAND_LOCATION_GROUP_ID[brand]],
          label: 'Breakdown By DO/MGP',
          list: data.listDirectors.nodes.map((d) => ({
            id: d.locationGroupId,
            name: `${d.firstName} ${d.lastName}`,
            header: 'DO/MGP',
            link: `/${brand}/employee_profile/${d.employeeId}`,
          })),
        },
        ...data.listDirectors.nodes.map((d, index) => ({
          id: index.toString(),
          parentId: 'root',
          ids: [d.locationGroupId],
          label: `${d.firstName} ${d.lastName}`,
          list: allStores.filter((s) => s.groupBy?.id === d.employeeId),
        })),
      ]

      return {
        values,
        defaultValue: [values[0].id],
      }
    }, [data, brand, search]),
    loading,
  }
}

export default useCorporateGroupFilter
