import { getBrandSettings } from 'pared/customer'

export function getRankByDisplayNames(): { [rankBy: string]: string } {
  const brandSettings = getBrandSettings()
  const financialLabel = brandSettings.labels.financial
  const salesmanshipLabels = brandSettings.labels.salesmanship

  // DEPRECATE: Add name to useRankingConfig instead
  return {
    scorecard: 'Scorecard',
    net_sales: 'Net Sales',
    gross_sales: 'Gross Sales',
    cogs: financialLabel.cogs,
    cogs_budget_variance: `${financialLabel.cogs} ${financialLabel.variance}`,
    avt: 'AvT',
    rcp: financialLabel.rcp,
    rcp_budget_variance: `${financialLabel.rcp} ${financialLabel.variance}`,
    net_sales_budget_variance: `Net Sales ${financialLabel.variance}`,
    gross_sales_budget_variance: `Gross Sales ${financialLabel.variance}`,
    net_sales_sss: financialLabel.sssAmount,
    gross_sales_sss: financialLabel.sssAmount,
    hourly_labor_budget_variance: `${financialLabel.hourlyLabor} ${financialLabel.variance}`,
    plv_percent: 'PLV',
    ops_comps: 'Ops Comps',
    annualizedHourlyTurnover: 'Annualized Hourly Turnover',
    annualizedHourlyTurnoverV2: 'Annualized Hourly Turnover',
    annualizedManagerTurnover: 'Annualized Manager Turnover',
    hourly_labor_percent: `${financialLabel.hourlyLabor} Percent`,
    hourlyTurnoverRateAndCount: 'Hourly Turnover Rate and Count',
    managerTurnoverRateAndCount: 'Manager Turnover Rate and Count',
    avgHourlyRate: 'Average Hourly Rates',
    salesPerLaborHour: salesmanshipLabels.salesPerLaborHour,
    ppa: salesmanshipLabels.ppa,
    appetizerPpa: salesmanshipLabels.appetizerPpa,
    lbwPpa: salesmanshipLabels.lbwPpa,
    dessertPpa: salesmanshipLabels.dessertPpa,
    productivityCoefficient: 'Productivity Coefficient',
    momentfeed: 'Momentfeed',
    delivery: 'Delivery',
    opentable: 'Opentable',
    deliveryOrders: 'Delivery Orders',
    deliverySales: 'Delivery Sales',
    averageTripTime: 'Average Driver Trip Time',
    orderWithAnyIssues: '% Orders w/ Any Issues',
    combinedStarRating: 'Combined Star Rating',
    daypart: 'Daypart',
    marketingAnalysis: 'Marketing Analysis',
  }
}

export const METRICS_WITH_AVG = [
  'salesPerLaborHour',
  'ppa',
  'appetizerPpa',
  'lbwPpa',
  'dessertPpa',
]

export const SORTABLE_METRICS = [
  'rcp',
  'ops_comps',
  'cogs',
  'cogs_budget_variance',
  'net_sales',
  'net_sales_budget_variance',
  'gross_sales',
  'gross_sales_budget_variance',
  'rcp_budget_variance',
  'avt',
  'avt_percent_monthly',
  'plv_percent',
  'net_sales_sss',
  'gross_sales_sss',
  'hourly_labor_percent',
  'hourly_labor_budget_variance',
  'annualizedHourlyTurnover',
  'annualizedHourlyTurnoverV2',
  'annualizedManagerTurnover',
  'hourlyTurnoverRateAndCount',
  'managerTurnoverRateAndCount',
  'momentfeed',
  'google',
  'yelp',
  'delivery',
  'deliveryOrders',
  'deliverySales',
  'averageTripTime',
  'orderWithAnyIssues',
  'combinedStarRating',
  'daypart',
  'classicUsageVariancePercent',
  'bonelessUsageVariancePercent',
  'tendersUsageVariancePercent',
  'sales_per_labor_hour',
  'total_check_average',
  'total_guest_average',
  'total_check_count',
  'yoy_total_check_count',
  'yoy_check_count_growth',
  'totalPpa',
  'salesPerHour',
  'checkAvg',
  'totalSidesSales',
  'friesSales',
  'cornSales',
  'brownieSales',
  'entreePremiumIncidents',
  'curatedIncidents',
  'sideAttachmentRate',
  'dessertAttachmentRate',
  'beverageAttachmentRate',
  'baconAttachmentRate',
  'cheeseAttachmentRate',
  'treatAttachmentRate',
  'cornAttachmentRate',
  'waterAttachmentRate',
  'saucesAttachmentRate',
  'sticksAttachmentRate',
  'dessertsAttachmentRate',
  'beveragesAttachmentRate',
  'jalapenoAttachmentRate',
  'dessertRate',
  'comboRate',
  'drinkRate',
  'appetizerPpaV2',
  'lbwPpaV2',
  'dessertPpaV2',
  'naBeveragePpa',
  'sidesPpa',
  'sosPickup',
  'entreesPerLaborHour',
  'upsizePercent',
  'zenput',
  'cavayou',
  'inboundCalls',
  'cashOverOrShort',
  'discounts',
  'voids',
  'smg',
  'opentable',
  'marketingAnalysis',
  'hme',
  'mwbAvt',
]

export const SORTABLE_METRICS_ASC = [
  'cogs',
  'cogs_budget_variance',
  'hourly_labor_percent',
  'hourly_labor_budget_variance',
  'annualizedHourlyTurnover',
  'annualizedHourlyTurnoverV2',
  'annualizedManagerTurnover',
  'hourlyTurnoverRateAndCount',
  'managerTurnoverRateAndCount',
  'orderWithAnyIssues',
  'averageTripTime',
  'sosPickup',
  'plv_percent',
  'avt',
  'inboundCalls',
  'discounts',
  'voids',
  'smg',
  'hme',
  'mwbAvt',
]

export const COLORABLE_METRICS_POSITIVE_RED = [
  'cogs_budget_variance',
  'hourly_labor_budget_variance',
  'rcpBudgetVarianceAmount',
  'hourly_labor_budget_variance_amount',
  'cogs_budget_variance_amount',
]

export const COLORABLE_METRICS_NEGATIVE_RED = [
  'net_sales_budget_variance',
  'gross_sales_budget_variance',
  'rcp_budget_variance',
]

export const UNFAVORABLE_VARIANCE_HIDE_POSITIVE = ['rcpBudgetVarianceAmount']

export const UNFAVORABLE_VARIANCE_HIDE_NEGATIVE = [
  'hourly_labor_budget_variance_amount',
  'cogs_budget_variance_amount',
]

export const SORTABLE_METRICS_DEFAULT_SORTING_COLUMNS = [
  {
    metricsName: 'rcp',
    defaultSortingColumn: 'rcp_percent',
  },
  {
    metricsName: 'ops_comps',
    defaultSortingColumn: 'ops_comps',
  },
  {
    metricsName: 'cogs',
    defaultSortingColumn: 'cogs_percent',
  },
  {
    metricsName: 'cogs_budget_variance',
    defaultSortingColumn: 'cogs_budget_variance',
  },
  {
    metricsName: 'net_sales_budget_variance',
    defaultSortingColumn: 'net_sales_budget_variance',
  },
  {
    metricsName: 'gross_sales_budget_variance',
    defaultSortingColumn: 'gross_sales_budget_variance',
  },
  {
    metricsName: 'rcp_budget_variance',
    defaultSortingColumn: 'rcp_budget_variance',
  },
  {
    metricsName: 'hourly_labor_percent',
    defaultSortingColumn: 'hourly_labor_percent',
  },
  {
    metricsName: 'hourly_labor_budget_variance',
    defaultSortingColumn: 'hourly_labor_budget_variance',
  },
  {
    metricsName: 'avt',
    defaultSortingColumn: 'avt_percent',
  },
  {
    metricsName: 'avt_percent_monthly',
    defaultSortingColumn: 'avt_percent_monthly',
  },
  {
    metricsName: 'net_sales_sss',
    defaultSortingColumn: 'net_sales_sss_percent',
  },
  {
    metricsName: 'gross_sales_sss',
    defaultSortingColumn: 'gross_sales_sss_percent',
  },
  {
    metricsName: 'total_check_count',
    defaultSortingColumn: 'yoy_check_count_growth',
  },
  {
    metricsName: 'annualizedHourlyTurnover',
    defaultSortingColumn: 'annualizedHourlyTurnoverPercent',
  },
  {
    metricsName: 'annualizedHourlyTurnoverV2',
    defaultSortingColumn: 'annualizedHourlyTurnoverPercent',
  },
  {
    metricsName: 'annualizedManagerTurnover',
    defaultSortingColumn: 'annualizedSalariedTurnoverPercent',
  },
  {
    metricsName: 'hourlyTurnoverRateAndCount',
    defaultSortingColumn: 'avgHourlyTurnoverPercent',
  },
  {
    metricsName: 'managerTurnoverRateAndCount',
    defaultSortingColumn: 'avgSalariedTurnoverPercent',
  },
  {
    metricsName: 'momentfeed',
    defaultSortingColumn: 'ratingScore',
  },
  {
    metricsName: 'google',
    defaultSortingColumn: 'ratingScore',
  },
  {
    metricsName: 'yelp',
    defaultSortingColumn: 'ratingScore',
  },
  {
    metricsName: 'delivery',
    defaultSortingColumn: 'avgRatingScore',
  },
  {
    metricsName: 'deliveryOrders',
    defaultSortingColumn: 'totalOrderCount',
  },
  {
    metricsName: 'deliverySales',
    defaultSortingColumn: 'sumSubtotal',
  },
  {
    metricsName: 'averageTripTime',
    defaultSortingColumn: 'averageTripTime',
  },
  {
    metricsName: 'orderWithAnyIssues',
    defaultSortingColumn: 'orderWithAnyIssuePercent',
  },
  {
    metricsName: 'combinedStarRating',
    defaultSortingColumn: 'avgCustomerReviewScore',
  },
  {
    metricsName: 'daypart',
    defaultSortingColumn: 'lunch_sales',
  },
  {
    metricsName: 'classicUsageVariancePercent',
    defaultSortingColumn: 'classicUsageVariancePercent',
  },
  {
    metricsName: 'bonelessUsageVariancePercent',
    defaultSortingColumn: 'bonelessUsageVariancePercent',
  },
  {
    metricsName: 'tendersUsageVariancePercent',
    defaultSortingColumn: 'tendersUsageVariancePercent',
  },
  {
    metricsName: 'entreesPerLaborHour',
    defaultSortingColumn: 'entreesPerLaborHourAt12',
  },
  {
    metricsName: 'zenput',
    defaultSortingColumn: 'zenputScore',
  },
  {
    metricsName: 'cavayou',
    defaultSortingColumn: 'cavayouScore',
  },
  {
    metricsName: 'inboundCalls',
    defaultSortingColumn: 'percentMissed',
  },
  {
    metricsName: 'cashOverOrShort',
    defaultSortingColumn: 'cashOverOrShort',
  },
  {
    metricsName: 'discounts',
    defaultSortingColumn: 'compsPercent',
  },
  {
    metricsName: 'voids',
    defaultSortingColumn: 'overRingPercent',
  },
  {
    metricsName: 'smg',
    defaultSortingColumn: 'dissatisfactionPercent',
  },
  {
    metricsName: 'opentable',
    defaultSortingColumn: 'ratingScore',
  },
  {
    metricsName: 'marketingAnalysis',
    defaultSortingColumn: 'totalCounts',
  },
  {
    metricsName: 'hme',
    defaultSortingColumn: 'speedOfService',
  },
  {
    metricsName: 'mwbAvt',
    defaultSortingColumn: 'avtFbPercent',
  },
]

export const SORTABLE_METRICS_COLUMN_KEYS = [
  'rcp',
  'rcp_percent',
  'ops_comps',
  'ops_comps_percent',
  'cogs',
  'cogs_percent',
  'cogs_budget_variance',
  'cogs_budget_variance_amount',
  'net_sales_budget_variance',
  'net_sales_budget_variance_amount',
  'gross_sales_budget_variance',
  'gross_sales_budget_variance_amount',
  'rcp_budget_variance',
  'rcpBudgetVarianceAmount',
  'hourly_labor_percent',
  'hourly_labor_budget_variance',
  'hourly_labor_budget_variance_amount',
  'avt_waste',
  'avt_percent',
  'avt_percent_monthly',
  'net_sales_sss',
  'gross_sales_sss',
  'net_sales_sss_percent',
  'gross_sales_sss_percent',
  'lunch_sales',
  'snack_sales',
  'dinner_sales',
  'late_night_sales',
  'early_bird_sales',
  'breakfast_sales',
  'afternoon_snack_sales',
  'evening_snack_sales',
  'sales_per_labor_hour',
  'total_check_average',
  'total_guest_average',
  'total_check_count',
  'yoy_total_check_count',
  'yoy_check_count_growth',

  'hourlyTurnoverCount',
  'salariedTurnoverCount',
  'hourlyTurnoverCountInLast12Months',
  'salariedTurnoverCountInLast12Months',
  'avgHourlyTurnoverPercent',
  'avgSalariedTurnoverPercent',
  'annualizedHourlyTurnoverPercent',
  'annualizedSalariedTurnoverPercent',

  'ratingScore',
  'avgRatingScore',
  'totalOrderCount',
  'sumSubtotal',
  'averageTripTime',
  'orderWithAnyIssuePercent',
  'avgCustomerReviewScore',

  'totalPpa',
  'salesPerHour',
  'checkAvg',

  'classicUsageVariancePercent',
  'bonelessUsageVariancePercent',
  'tendersUsageVariancePercent',
  'totalSidesSales',
  'friesSales',
  'cornSales',
  'brownieSales',
  'entreePremiumIncidents',
  'curatedIncidents',
  'sideAttachmentRate',
  'dessertAttachmentRate',
  'beverageAttachmentRate',
  'baconAttachmentRate',
  'cheeseAttachmentRate',
  'treatAttachmentRate',
  'cornAttachmentRate',
  'waterAttachmentRate',
  'saucesAttachmentRate',
  'sticksAttachmentRate',
  'dessertsAttachmentRate',
  'beveragesAttachmentRate',
  'jalapenoAttachmentRate',
  'dessertRate',
  'comboRate',
  'drinkRate',
  'appetizerPpaV2',
  'lbwPpaV2',
  'dessertPpaV2',
  'naBeveragePpa',
  'sidesPpa',

  'sosPickup',

  'entreesPerLaborHourAt11',
  'entreesPerLaborHourAt12',
  'entreesPerLaborHourAt13',
  'entreesPerLaborHourAt17',
  'entreesPerLaborHourAt18',
  'entreesPerLaborHourAt19',
  'upsizePercent',
  'zenputScore',
  'cavayouScore',
  'inboundTotal',
  'percentAnswered',
  'percentMissed',
  'percentAbandoned',
  'missedOpportunity',
  'cashOverOrShort',
  'compsPercent',
  'totalComps',
  'compsTotalAmount',
  'overRingAmount',
  'overRingPercent',
  'totalSurveys',
  'dissatisfactionPercent',
  'inaccuratePercent',

  'seatedCovers',
  'opentableRatingCount',
  'phoneCovers',
  'walkinCovers',
  'yourNetworkCovers',
  'opentableCovers',
  'noShowRate',

  'totalComps',
  'totalCounts',
  'discountCheckAvg',
  'discountPpa',
  'speedOfService',
  'goalPercent',
  'actualFbPercent',
  'theoreticalFbPercent',
  'avtFbPercent',
]
