import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useState } from 'react'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { useRenderInfo } from 'pared/Routes/renderer'
import { track } from 'pared/analytics/user'
import COLORS from 'pared/constants/colors'
import { generateEnterKeyPressedHandler } from 'pared/utils/keyboard'

import { IChangePasswordParams } from './index'

interface IProps {
  isLoading: boolean
  onChangePassword: (
    changePasswordParams: IChangePasswordParams,
  ) => Promise<boolean>
  errorMessage: string
}

function WebLargeMain({ isLoading, onChangePassword, errorMessage }: IProps) {
  const [newPassword, setNewPassword] = useState<string>('')
  const [repeatedPassword, setRepeatedPassword] = useState<string>('')
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const { defaultPath } = useRenderInfo()

  const onNewPasswordChange = (event: any) => {
    setNewPassword(event.target.value)
  }

  const onRepeatedPasswordChange = (event: any) => {
    setRepeatedPassword(event.target.value)
  }

  const onClickChangePasswordButton = async () => {
    track.changePasswordButtonClicked()
    const isSuccessForChangingPassword = await onChangePassword({
      newPassword,
      repeatedPassword,
    })
    setIsSuccess(isSuccessForChangingPassword)
  }

  const onEnterKeyPressed = generateEnterKeyPressedHandler(
    onClickChangePasswordButton,
  )

  return (
    <OuterContainer>
      {isSuccess ? (
        <InnerContainer>
          <SuccessMessageContainer>
            Your password has been successfully changed!
          </SuccessMessageContainer>
          <BackLinkContainer>
            <BackLink
              onClick={() => {
                if (defaultPath) {
                  navigator.go(defaultPath)
                } else {
                  navigator.goToDefaultLandingPage()
                }
              }}
            >
              Go Back
            </BackLink>
          </BackLinkContainer>
        </InnerContainer>
      ) : (
        <InnerContainer>
          <ChangePasswordForm>
            <TextField
              required
              type="password"
              label="New Password"
              size="medium"
              fullWidth={true}
              onChange={onNewPasswordChange}
              onKeyPress={onEnterKeyPressed}
            />
            <br />
            <br />
            <TextField
              required
              type="password"
              label="Confirm Password"
              size="medium"
              fullWidth={true}
              onChange={onRepeatedPasswordChange}
              onKeyPress={onEnterKeyPressed}
            />
            <br />
            <br />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              size="medium"
              fullWidth={true}
              disabled={isLoading}
              onClick={onClickChangePasswordButton}
            >
              Change Password
            </Button>
          </ChangePasswordForm>

          {errorMessage ? (
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          ) : null}
        </InnerContainer>
      )}
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  margin: 50px 0 0 0;
  padding: 0;
  width: 100%;
  display: table;
`

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`

const ChangePasswordForm = styled.div`
  display: inline-block;
  width: 300px;
`

const ErrorMessageContainer = styled.div`
  color: red;
  padding: 20px 0 0 0;
`

const SuccessMessageContainer = styled.div`
  color: ${COLORS.Basil};
  font-size: 16px;
  padding: 50px 0 0 0;
`

const BackLinkContainer = styled.div`
  padding: 30px 0 0 0;
`

const BackLink = styled.span`
  color: ${COLORS.Link};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

export default WebLargeMain
