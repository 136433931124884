import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useGroupFilter } from '../../groupFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'
import { IGroupInfoType } from './useGroupInfoColumns'

interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

interface ILocationInfo {
  id: number
  code: string
  name: string
  director: IDirectorInfo
}
interface ICorporateLossPreventionLocations extends ILocationInfo {
  discount: number
  expected: number
  opportunityCost: number
  annualOpportunityCost?: number
  voidCount?: number
  voidCountPercent?: number
  cashOverOrShort?: number
}

interface ICorporateLossPreventionSummaryDataType {
  type: string
  locations: ICorporateLossPreventionLocations[]
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
}

interface ILossPreventionSummaryType {
  corporateULossPreventionSummary: {
    nodes: ICorporateLossPreventionSummaryDataType[]
  }
}

interface IHookOptions {
  issueTypesVisible?: string[]
}

const query = gql`
  query getCorporateLossPreventionSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateULossPreventionSummary(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        type
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

export const corporateExpoAiLossPreventionSummaryConfigs = {
  storeCount: 'string',
  issueType: 'string',
  type: 'string',
  annualOpportunityCost: 'price',
  voidCount: 'number',
  voidCountPercent: 'percent',
  cashOverOrShort: 'price',
} as const

const createCorporateExpoAiLossPreventionSummaryHook = (
  isLocationDetails: boolean,
  issueTypesVisible?: string[],
) => {
  const useCorporateExpoAiLossPreventionSummaryData = () => {
    const { variables } = useVariables()
    const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
    const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
    const { groupFilter } = useGroupFilter()
    const locationGroupId = groupFilter?.ids[0] || 0
    const { brand } = useBrands()
    const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
    const { data, loading } = useQuery<ILossPreventionSummaryType>(query, {
      variables: {
        iLocationGroupId: locationGroupId,
        iBrandLocationGroupId: brandLocationGroupId,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !groupFilter,
    })

    return {
      data: useMemo((): IApiDataType => {
        if (isLocationDetails) {
          const summary = data?.corporateULossPreventionSummary?.nodes
          if (!summary) return null

          const dicountingType =
            variables?.aiSummary['uLossPrevention']?.clickedId
          if (!dicountingType) return null

          const locationData = summary.find((s) => s.type === dicountingType)

          return {
            source:
              locationData?.locations?.map((l) => {
                const locationDetail = groupFilter?.list?.find(
                  (g) => g.id == l.id,
                )

                const adjustLink = (() => {
                  const originLink = locationDetail?.link?.replace(
                    'store_detail',
                    'loss_prevention',
                  )

                  if (!originLink) return originLink

                  const [urlStr, queryString] = originLink.split(/\?/)
                  const urlParams = new URLSearchParams(queryString || '')

                  urlParams.delete('date_range')

                  return `${urlStr}?${urlParams.toString()}`
                })()

                const groupInfo = {
                  ...locationDetail,
                  link: adjustLink,
                } as IGroupInfoType

                return {
                  groupInfo,
                  annualOpportunityCost:
                    l.annualOpportunityCost != null
                      ? l.annualOpportunityCost * 100.0
                      : null,
                  voidCount: l.voidCount,
                  voidCountPercent: l.voidCountPercent,
                  cashOverOrShort: (l.cashOverOrShort || 0) * 100.0,
                }
              }) || [],
          }
        } else {
          const summary = data?.corporateULossPreventionSummary?.nodes
          if (!summary) return null

          const source: { [key: string]: any }[] = []
          const issueTypesVisibleSet = new Set(issueTypesVisible || [])

          summary.forEach((n) => {
            const rowType = n?.type

            if (!issueTypesVisible || issueTypesVisibleSet.has(rowType)) {
              let issueType = ''
              let annualOpportunityCost: number | null = null

              if (rowType === '20%' || rowType === '10%' || rowType === '5%') {
                issueType = `Discounting ${n.type} more than expected`
                annualOpportunityCost = n.annualOpportunityCost
                  ? n.annualOpportunityCost * 100.0
                  : null
              } else if (rowType === 'void') {
                issueType = `Most Voids`
              } else if (rowType === 'cash_over_or_short') {
                issueType = `Large Cash Over/Short`
              }

              source.push({
                ...n,
                issueType,
                annualOpportunityCost,
                storeCount: `${n.locations.length} stores`,
              })
            }
          })

          return {
            source,
          }
        }
      }, [data, variables, groupFilter]),
      loading,
    }
  }

  return useCorporateExpoAiLossPreventionSummaryData
}

export const useCorporateExpoAiLossPreventionSummary =
  createCorporateExpoAiLossPreventionSummaryHook(false, ['20%', '10%', '5%'])
export const useCorporateExpoAiLossPreventionSummaryFormat2 =
  createCorporateExpoAiLossPreventionSummaryHook(false, [
    '20%',
    '10%',
    '5%',
    'void',
    'cash_over_or_short',
  ])
export const useCorporateExpoAiLossPreventionLocationDetails =
  createCorporateExpoAiLossPreventionSummaryHook(true)
