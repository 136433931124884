import buildItemMetricValuesHook from '../utils/buildItemMetricValuesHook'

export const burgerworksListItemMetricValuesConfigs = {
  actualCogs: 'price',
  idealCogs: 'price',
  totalNetSales: 'price',
  actualCogsPercent: 'percent',
  idealCogsPercent: 'percent',
  cogsVariance: 'price',
  cogsVariancePercent: 'percent',
} as const

const useBurgerworksListItemMetricValues = buildItemMetricValuesHook({
  metrics: [
    'actual_cogs',
    'ideal_cogs',
    'total_net_sales',
    'actual_cogs_percent',
    'ideal_cogs_percent',
    'cogs_variance',
    'cogs_variance_percent',
  ],
  itemCategoryCodes: ['ALL_COGS_ITEMS'],
})

export default useBurgerworksListItemMetricValues
