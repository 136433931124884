import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../../dateFilter'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IDataType {
  trendLocationGroupMetricValues: {
    nodes: {
      businessQuarter: number
      businessMonth: number
      businessWeek: number
      businessWeekOfMonth: number
      metricData: Record<
        string,
        {
          name: string
          unit: string
          value: number
        }
      >
    }[]
  }
}

interface IReturnType {
  period: string
  value: number | null
}

const query = gql`
  query TrendFinancialCheckAverageData(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        metricData
      }
    }
  }
`

const priorYearQuery = gql`
  query TrendPriorFinancialCheckAverageData(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        metricData
      }
    }
  }
`

export const farwestCheckAverageConfigs = {
  period: 'string',
  value: 'price',
  valueLastYear: 'price',
} as const

const useFarwestCheckAverage = () => {
  const { variables } = useVariables()
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()

  const groupBy = variables.farwestTimeinterval?.value[0][0] || 'business_month'

  const dateList = ((groupBy: string) => {
    if (groupBy === 'business_week')
      return variables.date?.getCalendar('week', 'year')?.ids || [[]]
    else if (groupBy === 'business_quarter') return ['Q1', 'Q2', 'Q3', 'Q4']
    else return variables.date?.getCalendar('period', 'year')?.ids || [[]]
  })(groupBy)

  const metrics =
    variables.farwestFinancialCheckAverageCharts?.value[0][0] ||
    'revenue_center_check_average_total_this_year'

  const { data, loading: currentLoading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iGroupBy: groupBy,
      iFilter: {
        location_group_ids: !hasGroupBy
          ? [brandLocationGroupId]
          : groupFilter?.ids,
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: [metrics],
      },
    },
    skip: !startDate || !endDate || !variables.date,
  })

  const { data: priorData, loading: priorLoading } = useQuery<IDataType>(
    priorYearQuery,
    {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iGroupBy: groupBy,
        iFilter: {
          location_group_ids: !hasGroupBy
            ? [brandLocationGroupId]
            : groupFilter?.ids,
          intersected_location_group_ids: groupFilter?.intersectedIds,
          metrics: [metrics],
          use_yoy: true,
        },
        intersected_location_group_ids: groupFilter?.intersectedIds,
      },
      skip: !startDate || !endDate || !variables.date,
    },
  )

  const loading = currentLoading && priorLoading

  return {
    data: useMemo((): IApiDataType => {
      if (
        !data?.trendLocationGroupMetricValues.nodes ||
        !priorData?.trendLocationGroupMetricValues.nodes ||
        !metrics
      )
        return null

      const metricData = data?.trendLocationGroupMetricValues.nodes.map(
        (item): IReturnType => {
          const period = ((groupBy: string) => {
            if (groupBy === 'business_week')
              return `P${item.businessMonth}W${item.businessWeekOfMonth}`
            else if (groupBy === 'business_quarter')
              return `Q${item.businessQuarter}`
            else return `P${item.businessMonth}`
          })(groupBy)

          return {
            period,
            value: item.metricData?.[metrics].value
              ? item.metricData?.[metrics].value * 100
              : null,
          }
        },
      )
      const priorMetricData =
        priorData?.trendLocationGroupMetricValues.nodes.map(
          (item): IReturnType => {
            const period = ((groupBy: string) => {
              if (groupBy === 'business_week')
                return `P${item.businessMonth}W${item.businessWeekOfMonth}`
              else if (groupBy === 'business_quarter')
                return `Q${item.businessQuarter}`
              else return `P${item.businessMonth}`
            })(groupBy)

            return {
              period,
              value: item.metricData?.[metrics].value
                ? item.metricData?.[metrics].value * 100
                : null,
            }
          },
        )

      return dateList
        .map((date) => {
          const currentData = metricData.find((d) => d.period === date)
          const sameDaylyData = priorMetricData.find((d) => d.period === date)

          return {
            period: date,
            value: currentData?.value,
            valueLastYear: sameDaylyData?.value,
          }
        })
        .filter((d) => d.period)
    }, [data, priorData, startDate, endDate]),
    loading,
  }
}

export default useFarwestCheckAverage
