import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListFarwestFlashKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
        metricSummaryData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
        metricSummaryData
      }
    }

    listLocationMarketAndOpenDate(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        openedAt
        market
        ro
      }
    }
  }
`

export const farwestWeeklyLaborTrackerConfigs = {
  market: 'string',
  ro: 'string',
  openedAt: 'date-string',
  netSalesWtd: 'price',
  comparableNetSalesWtdLy: 'price',
  netSalesWtdPercentageDiffToLastYear: 'percent',
  gmMatrixSales: 'price',
  amMatrixSales: 'price',
  cashierMatrixSales: 'price',
  cookMatrixSales: 'price',
  shiftMatrixSales: 'price',
  totalMatrixSales: 'price',
  gmMatrixSalesPercentage: 'percent',
  amMatrixSalesPercentage: 'percent',
  cashierMatrixSalesPercentage: 'percent',
  cookMatrixSalesPercentage: 'percent',
  shiftMatrixSalesPercentage: 'percent',
  totalMatrixSalesPercentage: 'percent',
  gmTotalWage: 'price',
  amTotalWage: 'price',
  cashierTotalWage: 'price',
  cookTotalWage: 'price',
  shiftTotalWage: 'price',
  totalWage: 'price',
  gmTotalWagePercentage: 'percent',
  amTotalWagePercentage: 'percent',
  cashierTotalWagePercentage: 'percent',
  cookTotalWagePercentage: 'percent',
  shiftTotalWagePercentage: 'percent',
  totalWagePercentage: 'percent',
  gmMatrixHours: 'number',
  amMatrixHours: 'number',
  cashierMatrixHours: 'number',
  cookMatrixHours: 'number',
  shiftMatrixHours: 'number',
  totalMatrixHours: 'number',
  gmLaborActualHours: 'number',
  amLaborActualHours: 'number',
  cashierLaborActualHours: 'number',
  cookLaborActualHours: 'number',
  shiftLaborActualHours: 'number',
  totalLaborActualHours: 'number',
  gmLaborOtHours: 'number',
  amLaborOtHours: 'number',
  cashierLaborOtHours: 'number',
  cookLaborOtHours: 'number',
  shiftLaborOtHours: 'number',
  totalLaborOtHours: 'number',
  gmLaborTotalHours: 'number',
  amLaborTotalHours: 'number',
  cashierLaborTotalHours: 'number',
  cookLaborTotalHours: 'number',
  shiftLaborTotalHours: 'number',
  laborTotalHours: 'number',
  gmLaborHoursVariance: 'number',
  amLaborHoursVariance: 'number',
  cashierLaborHoursVariance: 'number',
  cookLaborHoursVariance: 'number',
  shiftLaborHoursVariance: 'number',
  totalLaborHoursVariance: 'number',
  laborHoursVariance: 'number',
  cookTrainingHours: 'number',
  shiftLeaderTrainingHours: 'number',
  amTrainingHours: 'number',
  gmTrainingHours: 'number',
  cashierTrainingHours: 'number',
  fieldTrainerTrainingHours: 'number',
  dmTrainingHours: 'number',
  totalTrainingHours: 'number',
  cookTrainingCost: 'price',
  shiftLeaderTrainingCost: 'price',
  amTrainingCost: 'price',
  gmTrainingCost: 'price',
  cashierTrainingCost: 'price',
  fieldTrainerTrainingCost: 'price',
  dmTrainingCost: 'price',
  totalTrainingCost: 'price',
  cookTrainingCostPercent: 'percent',
  shiftLeaderTrainingCostPercent: 'percent',
  amTrainingCostPercent: 'percent',
  gmTrainingCostPercent: 'percent',
  cashierTrainingCostPercent: 'percent',
  fieldTrainerTrainingCostPercent: 'percent',
  dmTrainingCostPercent: 'percent',
  totalTrainingCostPercent: 'percent',
} as const

const KPIS = [
  'net_sales_wtd',
  'comparable_net_sales_wtd_ly',
  'net_sales_wtd_last_year',
  'net_sales_wtd_percentage_diff_to_last_year',
  'gm_matrix_sales',
  'am_matrix_sales',
  'cashier_matrix_sales',
  'cook_matrix_sales',
  'shift_matrix_sales',
  'total_matrix_sales',
  'gm_matrix_sales_percentage',
  'am_matrix_sales_percentage',
  'cashier_matrix_sales_percentage',
  'cook_matrix_sales_percentage',
  'shift_matrix_sales_percentage',
  'total_matrix_sales_percentage',
  'gm_total_wage_percentage',
  'am_total_wage_percentage',
  'cashier_total_wage_percentage',
  'cook_total_wage_percentage',
  'shift_total_wage_percentage',
  'total_wage_percentage',
  'gm_matrix_hours',
  'am_matrix_hours',
  'cashier_matrix_hours',
  'cook_matrix_hours',
  'shift_matrix_hours',
  'total_matrix_hours',
  'gm_labor_hours_variance',
  'am_labor_hours_variance',
  'cashier_labor_hours_variance',
  'cook_labor_hours_variance',
  'shift_labor_hours_variance',
  'labor_hours_variance',
  'total_labor_hours_variance',
  'am_training_cost_percent',
  'gm_training_cost_percent',
  'cashier_training_cost_percent',
  'field_trainer_training_cost_percent',
  'dm_training_cost_percent',
  'total_training_cost_percent',
]

const DATA_START_DATE_KPIS = [
  'gm_total_wage',
  'am_total_wage',
  'cashier_total_wage',
  'cook_total_wage',
  'shift_total_wage',
  'total_wage',
  'gm_labor_actual_hours',
  'am_labor_actual_hours',
  'cashier_labor_actual_hours',
  'cook_labor_actual_hours',
  'shift_labor_actual_hours',
  'total_labor_actual_hours',
  'gm_labor_ot_hours',
  'am_labor_ot_hours',
  'cashier_labor_ot_hours',
  'cook_labor_ot_hours',
  'shift_labor_ot_hours',
  'total_labor_ot_hours',
  'gm_labor_total_hours',
  'am_labor_total_hours',
  'cashier_labor_total_hours',
  'cook_labor_total_hours',
  'shift_labor_total_hours',
  'labor_total_hours',
  'cook_training_hours',
  'shift_leader_training_hours',
  'am_training_hours',
  'gm_training_hours',
  'cashier_training_hours',
  'field_trainer_training_hours',
  'dm_training_hours',
  'total_training_hours',
  'cook_training_cost',
  'shift_leader_training_cost',
  'am_training_cost',
  'gm_training_cost',
  'cashier_training_cost',
  'field_trainer_training_cost',
  'dm_training_cost',
  'total_training_cost',
  'field_trainer_training_hours_percent',
  'dm_training_hours_percent',
  'cook_training_cost_percent',
  'shift_leader_training_cost_percent',
]

const SUMMARY_AVG_METRICS = [
  'classicCaseCount',
  'tendersCaseCount',
  'bonelessCaseCount',
]

const buildFarwestWeeklyLaborTracker = (hasNotOpenStores: boolean) => {
  const useFarwestWeeklyLaborTracker = () => {
    const { startDate, endDate } = useDateFilter()
    const { groupFilter, hasGroupBy } = useGroupFilter()
    const { data: normalData, loading: normalLoading } = useQuery(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          ...(groupFilter?.parentId !== 'Open Status'
            ? {}
            : {
                custom_algorithm: 'list_location_groups_with_data_start_date',
                group_type: 'Brand',
              }),
          location_group_ids: hasGroupBy
            ? groupFilter?.ids
            : groupFilter?.list?.map((g) => g.id),
          intersected_location_group_ids: groupFilter?.intersectedIds,
          metrics: KPIS,
        },
        hasGroupBy,
      },
      skip: !startDate || !endDate || !groupFilter,
    })
    const { data: dataStartDateData, loading: dataStartDateLoading } = useQuery(
      query,
      {
        variables: {
          iStartDate: startDate,
          iEndDate: endDate,
          iFilter: {
            location_group_ids: hasGroupBy
              ? groupFilter?.ids
              : groupFilter?.list?.map((g) => g.id),
            intersected_location_group_ids: groupFilter?.intersectedIds,
            metrics: DATA_START_DATE_KPIS,
            custom_algorithm: 'list_location_groups_with_data_start_date',
          },
          hasGroupBy,
        },
        skip: !startDate || !endDate || !groupFilter || !hasNotOpenStores,
      },
    )

    return {
      data: useMemo((): IApiDataType => {
        const normalCustomizedData: any =
          normalData?.[
            hasGroupBy
              ? 'listLocationMetricValues'
              : 'listLocationGroupMetricValues'
          ]?.nodes
        const dataStartDateCustomizedData: any =
          dataStartDateData?.[
            hasGroupBy
              ? 'listLocationMetricValues'
              : 'listLocationGroupMetricValues'
          ]?.nodes
        const locationDetails: any = (dataStartDateData || normalData)
          ?.listLocationMarketAndOpenDate?.nodes
        const customizedData =
          dataStartDateCustomizedData?.map((d: any) => {
            const nd = normalCustomizedData?.find(
              (l: any) =>
                (hasGroupBy ? l.locationId : l.locationGroupId) ===
                (hasGroupBy ? d.locationId : d.locationGroupId),
            )

            return {
              ...d,
              metricData: {
                ...d.metricData,
                ...nd?.metricData,
              },
            }
          }) || normalCustomizedData

        if (!customizedData) return null

        let avgCount = SUMMARY_AVG_METRICS.reduce((result, metric) => {
          result[metric] = 0
          return result
        }, {} as { [key: string]: number })

        const source = groupFilter?.list
          ?.map((groupInfo: any) => {
            const locationData = customizedData?.find(
              (locationData: any) =>
                groupInfo.id ===
                (hasGroupBy
                  ? locationData.locationId
                  : locationData.locationGroupId),
            )

            if (!locationData) return null

            const marketDetail = locationDetails?.find(
              (l: any) => l.locationId === locationData.locationId,
            )
            const kpisData: { [key: string]: number | null } = {}

            for (const key in locationData.metricData) {
              const newKey = key.replace(/_(.)/g, (_, char) =>
                char.toUpperCase(),
              )

              if (SUMMARY_AVG_METRICS.includes(newKey)) {
                if (locationData.metricData[key].value > 0) {
                  avgCount[newKey] = avgCount[newKey] + 1
                }
              }

              switch (locationData.metricData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  kpisData[newKey] =
                    locationData.metricData[key].value === null
                      ? null
                      : locationData.metricData[key].value * 100
                  break

                default:
                  kpisData[newKey] = locationData.metricData[key].value
                  break
              }
            }

            if (Object.keys(kpisData).length === 0) return null

            const openedAt = (() => {
              if (!hasGroupBy) return marketDetail?.openedAt

              if (groupFilter.label === 'Not Open') return null

              return marketDetail?.openedAt === `5000-01-01`
                ? 'NOT OPEN'
                : marketDetail?.openedAt
            })()

            return {
              ...kpisData,
              openedAt,
              market: marketDetail?.market,
              ro: marketDetail?.ro,
              groupInfo,
            }
          })
          .filter(Boolean) as NonNullable<IApiDataType>['source']

        const rawNormalSummary = normalCustomizedData?.find(
          (locationData: any) => locationData.metricSummaryData != null,
        )
        const rawDataStartDateSummary = dataStartDateCustomizedData?.find(
          (locationData: any) => locationData.metricSummaryData != null,
        )
        const metricSummaryData = {
          ...rawNormalSummary?.metricSummaryData,
          ...rawDataStartDateSummary?.metricSummaryData,
        }
        const summary: { [key: string]: number | null } = {}

        for (const key in metricSummaryData) {
          if (metricSummaryData.hasOwnProperty(key)) {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())

            if (SUMMARY_AVG_METRICS.includes(newKey)) {
              summary[newKey] =
                avgCount[newKey] > 0
                  ? metricSummaryData[key].value / avgCount[newKey]
                  : 0
            } else {
              switch (metricSummaryData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  summary[newKey] =
                    metricSummaryData[key].value === null
                      ? null
                      : metricSummaryData[key].value * 100
                  break

                default:
                  summary[newKey] = metricSummaryData[key].value
                  break
              }
            }
          }
        }

        return {
          source,
          summary: {
            ...summary,
            openedAt: '',
            market: '',
          },
        }
      }, [groupFilter, normalData, dataStartDateData]),
      loading: normalLoading || dataStartDateLoading,
    }
  }

  return useFarwestWeeklyLaborTracker
}

export const useFarwestWeeklyLaborTracker =
  buildFarwestWeeklyLaborTracker(false)
export const useFarwestWeeklyLaborTrackerWithNotOpenStores =
  buildFarwestWeeklyLaborTracker(true)
