import { gql, useQuery } from '@apollo/client'
import moment from 'moment'

interface IQueryDataType {
  getDateInfo: {
    nodes: {
      businessYearBeginDate: string
      businessYearEndDate: string
    }[]
  }
}

interface IQueryVariablesType {
  date: string
}

const query = gql`
  query getDateInfo($date: Date!) {
    getDateInfo(iQueryDate: $date) {
      nodes {
        businessYearBeginDate
        businessYearEndDate
      }
    }
  }
`

const useDateInfo = (date: moment.Moment = moment(), skip?: boolean) => {
  const { data } = useQuery<IQueryDataType, IQueryVariablesType>(query, {
    variables: {
      date: date.format('YYYY-MM-DD'),
    },
    skip,
  })

  return data?.getDateInfo.nodes[0]
}

export default useDateInfo
