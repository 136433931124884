import React from 'react'
import styled from 'styled-components'

import { BRAND_LOGOS, BRAND_NAME } from 'pared/constants/brands'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import useBrandConfig from './hooks/useBrandConfig'

interface IProps {
  brandCode: string
  isDark: boolean
}

const LogoImg = styled.img<{
  logoHeight: number
}>`
  margin: auto;
  height: ${({ logoHeight }) => logoHeight}px;

  @media ${MOBILE_WIDTH} {
    height: 35px;
  }
`

const BrandLogoImage = ({ brandCode, isDark }: IProps) => {
  const config = useBrandConfig()
  const name = BRAND_NAME[brandCode]
  const logo = isDark
    ? BRAND_LOGOS[brandCode].dark
    : BRAND_LOGOS[brandCode].white

  return logo ? (
    <LogoImg src={logo} alt={`${name} Logo`} logoHeight={config.logoHeight} />
  ) : null
}

export default React.memo(BrandLogoImage)
