import calc from '../../../utils/calc'
import buildRankingsHook, { rankingsConfigs } from '../utils/buildRankingsHook'

export const dhRankingsConfigs = {
  ...rankingsConfigs,
}

const useDhRankings = buildRankingsHook({
  metriceCodeMapping: {
    netSalesSssVariance: ['net_sales', { key: 'net_sales', type: 'yoy' }],
    netSalesSssPercent: ['net_sales', { key: 'net_sales', type: 'yoy' }],
  },
  handler: (data) => ({
    ...data,
    netSalesSssVariance: calc(data.netSales, '-', data.yoyNetSales),
    netSalesSssPercent: calc(
      data.netSales,
      'variancePercentageOf',
      data.yoyNetSales,
    ),
    sosAvgMinuteStr: data.sosAvg
      ? Math.floor(data.sosAvg as number).toString() +
        ':' +
        Math.floor(
          ((data.sosAvg as number) - Math.floor(data.sosAvg as number)) * 60,
        )
          .toString()
          .padStart(2, '0')
      : null,
    sosAvgLane2MinuteStr: data.sosAvgLane2
      ? Math.floor(data.sosAvgLane2 as number).toString() +
        ':' +
        Math.floor(
          ((data.sosAvgLane2 as number) -
            Math.floor(data.sosAvgLane2 as number)) *
            60,
        )
          .toString()
          .padStart(2, '0')
      : null,
  }),
})

export default useDhRankings
