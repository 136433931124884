import ExpoAiLogo from 'pared/images/Expo/expoai.png'

import { IConfigsType } from '../../types'

const mwbPAndL: IConfigsType = {
  'mwb-only-for-qa': {
    '/:brand/p-and-l': {
      disableAutoMargin: true,
      variables: [
        'corporateGroup',
        {
          type: 'pnl',
          dateOptions: {
            types: ['week', 'period', 'quarter', 'year'],
          },
        },
        'manageGls',
      ],
      groupFilter: {
        api: 'legalEntityFilter',
        autoComplete: true,
        width: 300,
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year'],
      },
      title: {
        type: 'title',
        title: ' ',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exray: {
        type: 'ai-summary',
        total: '',
        background: {
          colors: ['black', 'red'],
          range: [0, 1],
          value:
            '<%- (type === "pnlSales" || type === "pnlNetProfit") ? (total >= 0 ? 0 : 1) : (total >= 0 ? 1 : 0) %>',
        },
        fields: [
          {
            type: 'pnlSales',
            details: [
              {
                key: 'sales',
                hide: '<%- corporateGroup?.type === "listLocation" %>',
                api: 'pnlSales',
                pageSize: 20,
                rowKey: 'name',
                columns: [
                  {
                    key: 'name',
                    title: ' ',
                  },
                  {
                    key: 'variance',
                    title:
                      'Difference vs Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                      },
                      {
                        key: 'variancePercent',
                        title: '%',
                        align: 'center',
                      },
                    ],
                  },
                ],
              },
              {
                key: 'corporateSales',
                hide: '<%- corporateGroup?.type !== "listLocation" %>',
                api: 'corporatePnlSales',
                groupInfo: {
                  width: '250px',
                  groupBy: {
                    width: '250px',
                  },
                },
                pageSize: 20,
                columns: [
                  {
                    key: 'variance',
                    title:
                      'Difference vs Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                      },
                      {
                        key: 'variancePercent',
                        title: '%',
                        align: 'center',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'pnlFoodCost',
            details: [
              {
                key: 'foodCost',
                hide: '<%- corporateGroup?.type === "listLocation" %>',
                api: 'pnlFoodCost',
                pageSize: 20,
                rowKey: 'name',
                columns: [
                  {
                    key: 'name',
                    title: ' ',
                  },
                  {
                    key: 'variance',
                    title:
                      'Difference vs Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                      },
                      {
                        key: 'variancePercent',
                        title: '%',
                        align: 'center',
                      },
                    ],
                  },
                ],
              },
              {
                key: 'corporateFoodCost',
                hide: '<%- corporateGroup?.type !== "listLocation" %>',
                api: 'corporatePnlFoodCost',
                groupInfo: {
                  width: '250px',
                  groupBy: {
                    width: '250px',
                  },
                },
                pageSize: 20,
                columns: [
                  {
                    key: 'total',
                    title: 'Current <%- date.getInfo(0)?.displayType %>',
                  },
                  {
                    key: 'yoyTotal',
                    title:
                      'Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
                  },
                  {
                    key: 'basisPoints',
                    title: 'Basis Point Change',
                    reverse: true,
                  },
                ],
              },
            ],
          },
          {
            type: 'pnlLaborCost',
            details: [
              {
                key: 'laborCost',
                hide: '<%- corporateGroup?.type === "listLocation" %>',
                api: 'pnlLaborCost',
                pageSize: 20,
                rowKey: 'name',
                columns: [
                  {
                    key: 'name',
                    title: ' ',
                  },
                  {
                    key: 'variance',
                    title:
                      'Difference vs Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                      },
                      {
                        key: 'variancePercent',
                        title: '%',
                        align: 'center',
                      },
                    ],
                  },
                ],
              },
              {
                key: 'corporateLaborCost',
                hide: '<%- corporateGroup?.type !== "listLocation" %>',
                api: 'corporatePnlLaborCost',
                groupInfo: {
                  width: '250px',
                  groupBy: {
                    width: '250px',
                  },
                },
                pageSize: 20,
                columns: [
                  {
                    key: 'total',
                    title: 'Current <%- date.getInfo(0)?.displayType %>',
                  },
                  {
                    key: 'yoyTotal',
                    title:
                      'Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
                  },
                  {
                    key: 'basisPoints',
                    title: 'Basis Point Change',
                    reverse: true,
                  },
                ],
              },
            ],
          },
          {
            type: 'pnlExpenses',
            details: [
              {
                key: 'expenses',
                hide: '<%- corporateGroup?.type === "listLocation" %>',
                api: 'pnlExpenses',
                pageSize: 20,
                rowKey: 'name',
                columns: [
                  {
                    key: 'name',
                    title: ' ',
                  },
                  {
                    key: 'variance',
                    title:
                      'Difference vs Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
                    children: [
                      {
                        key: 'variance',
                        title: '$',
                        align: 'center',
                      },
                      {
                        key: 'variancePercent',
                        title: '%',
                        align: 'center',
                      },
                    ],
                  },
                ],
              },
              {
                key: 'corporateExpenses',
                hide: '<%- corporateGroup?.type !== "listLocation" %>',
                api: 'corporatePnlExpenses',
                groupInfo: {
                  width: '250px',
                  groupBy: {
                    width: '250px',
                  },
                },
                pageSize: 20,
                columns: [
                  {
                    key: 'total',
                    title: 'Current <%- date.getInfo(0)?.displayType %>',
                  },
                  {
                    key: 'yoyTotal',
                    title:
                      'Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
                  },
                  {
                    key: 'basisPoints',
                    title: 'Basis Point Change',
                    reverse: true,
                  },
                ],
              },
            ],
          },
          {
            type: 'pnlNetProfit',
            details: [
              {
                key: 'corporateNetProfit',
                hide: '<%- corporateGroup?.type !== "listLocation" %>',
                api: 'corporatePnlNetProfit',
                groupInfo: {
                  width: '250px',
                  groupBy: {
                    width: '250px',
                  },
                },
                pageSize: 20,
                columns: [
                  {
                    key: 'total',
                    title: 'Current <%- date.getInfo(0)?.displayType %>',
                  },
                  {
                    key: 'yoyTotal',
                    title:
                      'Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
                  },
                  {
                    key: 'basisPoints',
                    title: 'Basis Point Change',
                  },
                ],
              },
            ],
          },
        ],
      },
      filters: {
        type: 'grid',
        margin: '80px 0px 0px',
        children: {
          columns: {
            type: 'select',
            value: 'pnl.pnlColumns.value',
            onChange: 'pnl.pnlColumns.onChange',
            options: 'pnl.pnlColumns.options',
            label: 'Columns',
            multiple: true,
          },
          toggleEditMode: {
            type: 'typography',
            apiOrContent: `<%- manageGls.editMode ? "Switch to View Mode" : "Switch to Edit Mode" %>`,
            variant: 'link',
            margin: '0px',
            lineHeight: '52px',
            onClick: 'manageGls.onToggleEditMode',
          },
        },
      },
      addGlGroup: {
        type: 'modal',
        open: 'manageGls.addGlGroupOpen',
        onClose: 'manageGls.onClose',
        children: {
          title: {
            type: 'typography',
            apiOrContent: 'Adding to Category: <%- addGlGroup.glGroupName %>',
          },
          locationGroup: {
            type: 'input',
            value: 'addGlGroup.value',
            onChange: 'addGlGroup.onChange',
            errorMessage: 'addGlGroup.errorMessage',
            label: 'New category',
            margin: '40px 0px 0px',
            width: '250px',
          },
          buttons: {
            type: 'grid',
            margin: '40px 0px 0px',
            gap: '8px',
            children: {
              goBack: {
                type: 'button',
                label: 'Go Back',
                backgroundColor: '#cfcece',
                color: 'black',
                onClick: 'manageGls.onClose',
              },
              submit: {
                type: 'button',
                label: 'Submit',
                loading: 'addGlGroup.loading',
                onClick: 'addGlGroup.submit',
              },
            },
          },
        },
      },
      editGlGroup: {
        type: 'modal',
        open: 'manageGls.editGlGroupOpen',
        onClose: 'manageGls.onClose',
        children: {
          title: {
            type: 'typography',
            apiOrContent: 'Editing Category: <%- editGlGroup.glGroupName %>',
          },
          glGroup: {
            type: 'input',
            value: 'editGlGroup.value',
            onChange: 'editGlGroup.onChange',
            errorMessage: 'editGlGroup.errorMessage',
            label: 'New category name',
            margin: '40px 0px 0px',
            width: '250px',
          },
          buttons: {
            type: 'grid',
            margin: '40px 0px 0px',
            gap: '8px',
            children: {
              goBack: {
                type: 'button',
                label: 'Go Back',
                backgroundColor: '#cfcece',
                color: 'black',
                onClick: 'manageGls.onClose',
              },
              submit: {
                type: 'button',
                label: 'Submit',
                loading: 'editGlGroup.loading',
                onClick: 'editGlGroup.submit',
              },
            },
          },
          delete: {
            type: 'button',
            label: 'Delete',
            hide: '<%- !editGlGroup.deletable %>',
            loading: 'editGlGroup.loading',
            onClick: 'editGlGroup.delete',
            margin: '8px 0px 0px',
            backgroundColor: '<%- colors.Pomodoro %>',
            color: 'white',
          },
        },
      },
      pAndL: {
        type: 'table-v2',
        api: 'pAndL',
        margin: '20px 0 0 0',
        row: {
          background: '<%- depth === 1 ? colors.Smoke : colors.background %>',
          onDrop: 'pnlDrop.onDrop',
        },
        // Hard coded
        expanded: ['folder-41'],
        columns: [
          {
            key: 'pAndLName',
            title: ' ',
            align: 'left',
            fixed: true,
          },
          {
            key: 'edit',
            align: 'center',
            onClick: 'manageGls.onOpenEdit',
            hide: '<%- !manageGls.editMode %>',
          },
          {
            key: 'add',
            align: 'center',
            onClick: 'manageGls.onOpenAdd',
            hide: '<%- !manageGls.editMode %>',
          },
          {
            key: 'Current Period',
            title: 'Current <%- date.getInfo(0)?.displayType %>',
            align: 'center',
            background: '<%- depth === 1 ? "" : colors.Porcelain %>',
            hide: '<%- !pnl.pnlColumns.value.some(v => v.includes("pAndLSales")) %>',
            children: [
              {
                key: 'pAndLSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Prior Year PTD',
            title: 'Prior Year <%- date.getInfo(0)?.toDateDisplayType %>',
            align: 'center',
            hide: '<%- !pnl.pnlColumns.value.some(v => v.includes("pAndLYoySales")) %>',
            children: [
              {
                key: 'pAndLYoySales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYoySalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current Period vs PY PTD',
            title:
              'Current <%- date.getInfo(0)?.displayType %> vs PY <%- date.getInfo(0)?.toDateDisplayType %>',
            align: 'center',
            background: '<%- depth === 1 ? "" : colors.Porcelain %>',
            hide: '<%- !pnl.pnlColumns.value.some(v => v.includes("pAndLYoyCompare")) %>',
            children: [
              {
                key: 'pAndLYoyCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYoyComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD',
            title: 'Current YTD',
            align: 'center',
            hide: '<%- !pnl.pnlColumns.value.some(v => v.includes("pAndLYtdSales")) %>',
            children: [
              {
                key: 'pAndLYtdSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLYtdSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Prior YTD',
            title: 'Prior YTD',
            align: 'center',
            background: '<%- depth === 1 ? "" : colors.Porcelain %>',
            hide: '<%- !pnl.pnlColumns.value.some(v => v.includes("pAndLLYYtdSales")) %>',
            children: [
              {
                key: 'pAndLLYYtdSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLLYYtdSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current YTD vs Prior YTD',
            title: 'Current YTD vs Prior YTD',
            align: 'center',
            hide: '<%- !pnl.pnlColumns.value.some(v => v.includes("pAndLLYYtdCompare")) %>',
            children: [
              {
                key: 'pAndLLYYtdCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLLYYtdComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Prior Period',
            title: 'Prior <%- date.getInfo(0)?.displayType %>',
            align: 'center',
            background: '<%- depth === 1 ? "" : colors.Porcelain %>',
            hide: '<%- !pnl.pnlColumns.value.some(v => v.includes("pAndLPriorPeriodSales")) %>',
            children: [
              {
                key: 'pAndLPriorPeriodSales',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLPriorPeriodSalesPercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          {
            key: 'Current Period vs Prior Period',
            title:
              'Current <%- date.getInfo(0)?.displayType %> vs Prior <%- date.getInfo(0)?.displayType %>',
            align: 'center',
            hide: '<%- !pnl.pnlColumns.value.some(v => v.includes("pAndLPriorPeriodCompare")) %>',
            children: [
              {
                key: 'pAndLPriorPeriodCompare',
                title: '$',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
              {
                key: 'pAndLPriorPeriodComparePercent',
                title: '%',
                align: 'center',
                width: '150px',
                decimal: 2,
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default mwbPAndL
