import useIrmgBkTrendEodCompleted, {
  irmgBkTrendEodCompletedConfigs,
} from './useTrendEodCompleted'

export const configs = {
  irmgBkTrendEodCompleted: irmgBkTrendEodCompletedConfigs,
}

export const api = {
  irmgBkTrendEodCompleted: useIrmgBkTrendEodCompleted,
}
