import Checkbox from '@material-ui/core/Checkbox'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MaterialMenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import * as d3 from 'd3-hierarchy'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import icons from './icons'

export interface IValueType {
  id: string
  parentId: string | null
  displayName?: string
  icon?: keyof typeof icons
  groupBy?: string
}

export interface IPropsType {
  forwardRef?: React.Ref<HTMLLIElement> | null
  prevIds?: IValueType['id'][]
  onChange: (value: IValueType['id'][]) => void
  onClose: () => void
  option: d3.HierarchyNode<IValueType>
  selectedIds: IValueType['id'][][]
  multiple: boolean
}

const StyledMenuItem = styled(MaterialMenuItem)<{ hasGroupBy: boolean }>`
  padding-left: ${({ hasGroupBy }) => (!hasGroupBy ? '16px' : '24px')};
  gap: 10px;

  .MuiIconButton-root {
    padding: 0px;
  }

  .MuiListItemText-root {
    margin: 0px;
  }

  .MuiListItemText-primary {
    font-family: Lexend-Regular;
  }
`

const StyledListSubheader = styled(ListSubheader)`
  font-family: Lexend-Regular;
  line-height: 36px;
`

const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${COLORS.Plum};
  }
`

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: initial;
`

let SubMenuItem: typeof MenuItem

const withForwardRef = (Compoment: typeof MenuItem): typeof MenuItem => {
  const MemoComponent = React.memo(Compoment) as unknown as typeof MenuItem

  return React.forwardRef<HTMLLIElement, IPropsType>((props, ref) => (
    <MemoComponent {...props} forwardRef={ref} />
  )) as unknown as typeof MenuItem
}

const MenuItem = ({
  forwardRef,
  prevIds = [],
  onChange,
  onClose: prevOnClose,
  option: { data, children },
  selectedIds,
  multiple,
}: IPropsType) => {
  const { id, displayName, icon } = data
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const onClose = useCallback(() => {
    if (!multiple) prevOnClose()

    setAnchorEl(null)
  }, [prevOnClose, multiple])
  const checked = useMemo(
    () => selectedIds.some((v) => v.length === 1 && v[0] === id),
    [selectedIds],
  )
  let groupBy = ''

  SubMenuItem = SubMenuItem || withForwardRef(MenuItem)

  return (
    <>
      <StyledMenuItem
        ref={forwardRef}
        value={id}
        hasGroupBy={Boolean(data.groupBy)}
        onClick={({ target }) => {
          if (multiple) return

          if (!children) {
            onChange([...prevIds, id])
            onClose()
          } else setAnchorEl(target as HTMLElement)
        }}
      >
        {!multiple ? null : (
          <StyledCheckbox
            checked={checked}
            onClick={() => {
              if (multiple) onChange([...prevIds, id])
            }}
          />
        )}

        {!icon ? null : (
          <StyledListItemIcon>
            {React.createElement(icons[icon], {
              onClick: ({ target }) => {
                if (!multiple) return

                if (!children) onChange([...prevIds, id])
                else setAnchorEl(target as HTMLElement)
              },
            })}
          </StyledListItemIcon>
        )}

        {!displayName ? null : (
          <ListItemText
            primary={displayName}
            onClick={({ target }) => {
              if (!multiple) return

              if (!children) onChange([...prevIds, id])
              else setAnchorEl(target as HTMLElement)
            }}
          />
        )}
      </StyledMenuItem>

      {!children ? null : (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          variant="menu"
        >
          {children.map((option) => {
            const showGroupBy = (() => {
              if (groupBy !== option.data.groupBy) {
                groupBy = option.data.groupBy || ''

                if (option.data.groupBy) return true
              }

              return false
            })()

            return [
              !showGroupBy ? null : (
                <StyledListSubheader>{groupBy}</StyledListSubheader>
              ),
              <SubMenuItem
                prevIds={[...prevIds, id]}
                onChange={onChange}
                onClose={onClose}
                option={option}
                selectedIds={selectedIds
                  .filter((v) => v[1] === option.data.id)
                  .map((v) => v.slice(1))}
                multiple={multiple}
              />,
            ]
          })}
        </Menu>
      )}
    </>
  )
}

export default withForwardRef(MenuItem)
