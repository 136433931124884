import { useCallback, useEffect, useMemo, useState } from 'react'

import useAddGlGroup, {
  IDataType as IAddGlGroupDataType,
  IOptionType as IAddGlGroupOptionType,
} from './useAddGlGroup'
import useEditGlGroup, {
  IDataType as IEditGlGroupDataType,
  IOptionType as IEditGlGroupOptionType,
} from './useEditGlGroup'
import useListGlCodesAndGroups, {
  IQueryDataType,
} from './useListGlCodesAndGroups'
import usePnlDrop, { IDataType as IPnlDropDataType } from './usePnlDrop'

type FolderType = {
  type: 'Folder' | 'Item'
  id: string
  name: string
  parentId: string | null
  children: FolderType[]
}

type IStateType = IAddGlGroupOptionType | IEditGlGroupOptionType

export interface IDataType {
  addGlGroup: IAddGlGroupDataType | undefined
  editGlGroup: IEditGlGroupDataType | undefined
  pnlDrop: IPnlDropDataType | undefined
  manageGls: {
    addGlGroupOpen: boolean
    data: IQueryDataType | undefined
    editGlGroupOpen: boolean
    editMode: boolean
    loading: boolean
    onClose: () => void
    onOpenAdd: (options: { values: IAddGlGroupOptionType }) => void
    onOpenEdit: (options: { values: IEditGlGroupOptionType }) => void
    onToggleEditMode: () => void
  }
}

const useManageGls = (): IDataType => {
  const editModeHook = useState(false)
  const manageHook = useState<IStateType | null>(null)
  const onClose = useCallback(() => manageHook[1](null), [manageHook])
  const { data, loading } = useListGlCodesAndGroups()

  useEffect(() => {
    if (data) {
      const glGroups = data?.listGlGroups?.nodes ?? []
      const glCodes = data?.listGlCodes?.nodes ?? []
      const folders: FolderType[] = [
        ...glGroups.map(({ id, name, parentGroupId, displayOrder }) => ({
          id: `folder-${id}`,
          name,
          parentId: parentGroupId ? `folder-${parentGroupId}` : null,
          children: [],
          type: 'Folder' as const,
          displayOrder,
        })),
        ...glCodes.map(({ id, code, name, parentGroupId, displayOrder }) => ({
          id: `item-${id}`,
          name: `${code} - ${name}`,
          parentId: parentGroupId ? `folder-${parentGroupId}` : null,
          children: [],
          type: 'Item' as const,
          displayOrder,
        })),
      ]

      for (const folder of folders) {
        const parent = folders.find(({ id }) => id === folder.parentId)
        if (parent) {
          parent.children.push(folder)
        }
      }
    }
  }, [data])

  const addGlGroup = useAddGlGroup(
    data || null,
    manageHook[0] as IAddGlGroupOptionType,
    onClose,
  )

  const editGlGroup = useEditGlGroup(
    data || null,
    manageHook[0] as IEditGlGroupOptionType,
    onClose,
  )

  const pnlDrop = usePnlDrop(data || null)

  return {
    addGlGroup,
    editGlGroup,
    pnlDrop: editModeHook[0] ? pnlDrop : undefined,
    manageGls: useMemo((): IDataType['manageGls'] => {
      const [manageState, setManageState] = manageHook
      return {
        loading,
        addGlGroupOpen: manageState?.manageType === 'addGlGroup',
        editGlGroupOpen: manageState?.manageType === 'editGlGroup',
        onOpenAdd: ({ values }) => {
          setManageState({
            ...values,
            manageType: 'addGlGroup',
          })
        },
        onOpenEdit: ({ values }) => {
          setManageState({
            ...values,
            manageType: 'editGlGroup',
          })
        },
        onClose,
        data,
        editMode: editModeHook[0],
        onToggleEditMode: () => {
          editModeHook[1](!editModeHook[0])
        },
      }
    }, [manageHook, onClose]),
  }
}

export default useManageGls
