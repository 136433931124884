import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_DATABASE_FORMAT } from 'pared/constants'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'

const query = gql`
  query ListFarwestWeekProduceTrendData(
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendItemMetricValuesByDateRangeNumber(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iQueryType: "SUBCATEGORY"
      iFilter: $iFilter
      iDateRangeNumber: 4
    ) {
      nodes {
        itemId
        itemCode
        itemName
        displayParentCategoryName
        displayParentCategoryCode
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        startDate
        endDate
        dateIndex
        metricData
        metricSummaryData
      }
    }
  }
`

export const farwestFiveWeekItemTrendDataConfigs = {
  currentWeekEndDate: 'date-string',

  //flash_cogs_tracker
  cogsCategoryName: 'string',
  actualCostOfSalesPercentageWeek1InPast: 'percent',
  actualCostOfSalesPercentageWeek2InPast: 'percent',
  actualCostOfSalesPercentageWeek3InPast: 'percent',
  actualCostOfSalesPercentageWeek4InPast: 'percent',
  actualCostOfSalesPercentageWeek5InPast: 'percent',

  theoreticalCostOfSalesPercentageWeek1InPast: 'percent',
  theoreticalCostOfSalesPercentageWeek2InPast: 'percent',
  theoreticalCostOfSalesPercentageWeek3InPast: 'percent',
  theoreticalCostOfSalesPercentageWeek4InPast: 'percent',
  theoreticalCostOfSalesPercentageWeek5InPast: 'percent',

  costOfSalesVarianceWeek1InPast: 'percent',
  costOfSalesVarianceWeek2InPast: 'percent',
  costOfSalesVarianceWeek3InPast: 'percent',
  costOfSalesVarianceWeek4InPast: 'percent',
  costOfSalesVarianceWeek5InPast: 'percent',
} as const

const KPIS = [
  //flash_cogs_tracker
  'actual_cost_of_sales_percentage',
  'theoretical_cost_of_sales_percentage',
  'cost_of_sales_variance',
]

const useFarwestFiveWeekItemTrendData = () => {
  const { endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { variables } = useVariables()
  const momentEndDate = moment.utc(endDate, DATE_DATABASE_FORMAT, true)

  const dateType = variables.date?.value.type
  const iGroupBy = ((dateType?: string) => {
    if (dateType === 'period') {
      return 'last_x_periods'
    } else if (dateType === 'quarter') {
      return 'last_x_quarters'
    } else if (dateType === 'year') {
      return 'last_x_years'
    } else {
      return 'last_x_weeks'
    }
  })(dateType)

  const { data, loading } = useQuery(query, {
    variables: {
      iEndDate: endDate,
      iGroupBy,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        intersected_location_group_ids: groupFilter?.intersectedIds,
        item_category_codes: ['EXPO_COGS_CATEGORY_ROOT'],
        metrics: KPIS,
      },
      hasGroupBy,
    },
    skip: !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const currentWeekEndDate = momentEndDate.format(DATE_DATABASE_FORMAT)
      const customizedData: any =
        data?.trendItemMetricValuesByDateRangeNumber?.nodes

      if (!customizedData) return null

      const idToSourceDataMap: { [id: number]: any } = {}
      const summary: { [key: string]: number } = {}

      customizedData.forEach((itemData: any) => {
        const weekStr = `Week${itemData.dateIndex}InPast`

        let existingData: any = idToSourceDataMap[itemData.itemName]
        if (!existingData) {
          existingData = {
            cogsCategoryName: itemData.itemName,
            currentWeekEndDate,
          }
        }

        for (const key in itemData.metricData) {
          if (itemData.metricData.hasOwnProperty(key)) {
            let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            newKey = `${newKey}${weekStr}`

            switch (itemData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                existingData[newKey] = itemData.metricData[key].value * 100
                break

              default:
                existingData[newKey] = itemData.metricData[key].value
                break
            }
          }
        }

        if (itemData.metricSummaryData) {
          const metricSummaryData = itemData.metricSummaryData

          for (const key in metricSummaryData) {
            if (metricSummaryData.hasOwnProperty(key)) {
              let newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
              newKey = `${newKey}${weekStr}`

              switch (metricSummaryData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  summary[newKey] = metricSummaryData[key].value * 100
                  break

                default:
                  summary[newKey] = metricSummaryData[key].value
                  break
              }
            }
          }
        }

        idToSourceDataMap[itemData.itemName] = existingData
      })

      return {
        source: Object.values(idToSourceDataMap),
        summary: {
          ...summary,
          currentWeekEndDate,
          cogsCategoryName: 'TOTAL',
        },
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useFarwestFiveWeekItemTrendData
