import { IRouteType } from '../../types'
import dhCorporateSalesmanshipConfig from './dhCorporateSalesmanship'
import dhDeliveryConfig from './dhDelivery'
import dhExpoAi from './dhExpoAi'
import dhFlash from './dhFlash'
import dhWbGuestConfig from './dhGuest'
import dhInventory from './dhInventory'
import dhLocationSalesmanshipConfig from './dhLocationSalesmanship'
import dhLossPreventionConfig from './dhLossPrevention'
import dhPAndL from './dhPAndL'
import dhRankingsConfig from './dhRankings'
import dhStorePerformance from './dhStorePerformance'
import dhSystemwideOverviewConfig from './dhSystemwideOverview'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Expo AI',
        link: '/:brand/expo_ai',
      },
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
      {
        title: 'Rankings',
        link: '/:brand/rankings',
      },
      {
        title: 'Flash',
        link: '/:brand/flash',
        default: true,
      },
      {
        title: 'Salesmanship',
        link: '/:brand/corporate_salesmanship',
      },
      {
        title: 'Loss Prevention',
        link: '/:brand/corporate_loss_prevention',
      },
      {
        title: 'Inventory',
        link: '/:brand/corporate_inventory',
        default: true,
      },
      {
        title: 'Guest',
        link: '/:brand/corporate_guest',
        default: true,
      },
      {
        title: 'Delivery',
        link: '/:brand/corporate_delivery',
        default: true,
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Store Performance',
        link: '/:brand/store_detail',
      },
      {
        title: 'Salesmanship',
        link: '/:brand/salesmanship',
      },
      {
        title: 'Loss Prevention',
        link: '/:brand/loss_prevention',
      },
      {
        title: 'Guest',
        link: '/:brand/guest',
        default: true,
      },
      {
        title: 'Delivery',
        link: '/:brand/delivery',
      },
    ],
  },
  {
    title: 'P&L',
    children: [
      {
        title: 'Expo AI P&L',
        link: '/:brand/p-and-l',
      },
    ],
  },
]

export default [
  dhSystemwideOverviewConfig,
  dhStorePerformance,
  dhWbGuestConfig,
  dhFlash,
  dhDeliveryConfig,
  dhCorporateSalesmanshipConfig,
  dhLocationSalesmanshipConfig,
  dhRankingsConfig,
  dhLossPreventionConfig,
  dhExpoAi,
  dhInventory,
  dhPAndL,
]
