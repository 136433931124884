import { gql, useMutation } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import { BRAND_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IQueryDataType } from './useListLocationGroupMapping'

export interface IOptionType {
  manageType: 'addLocationGroup'
  locationGroupType: string
}

export interface IDataType {
  locationGroupType: string
  value: string
  onChange: (value: IDataType['value']) => void
  email: string
  onChangeEmail: (email: IDataType['email']) => void
  loading: boolean
  errorMessage?: string
  submit: () => Promise<void>
}

const mutate = gql`
  mutation CreateLocationGroup(
    $type: String!
    $name: String!
    $brandId: Int!
    $email: String
  ) {
    createLocationGroupV2(
      input: { iType: $type, iName: $name, iBrandId: $brandId, iEmail: $email }
    ) {
      integer
    }
  }
`

const useAddLocationGroup = (
  data: IQueryDataType | null,
  option: IOptionType | null,
  close: () => void,
) => {
  const addLocationGroupHook = useState<string>('')
  const addEmailHook = useState<string>('')
  const isSubmittingHook = useState(false)
  const prevManageType = usePrevious(option?.manageType)
  const { brand } = useBrands()
  const [createLocationGroup, { loading }] = useMutation(mutate, {
    refetchQueries: ['listLocationGroupMapping'],
    awaitRefetchQueries: true,
  })
  const options = useMemo(
    () =>
      !option
        ? []
        : (data?.listLocationGroupMapping.nodes || [])
            .filter((n) => n.locationGroupType === option.locationGroupType)
            .reduce(
              (result, n) =>
                result.some((r) => r === n.locationGroupName)
                  ? result
                  : [...result, n.locationGroupName],
              [] as string[],
            ),
    [data, option],
  )

  useEffect(() => {
    if (
      prevManageType !== option?.manageType &&
      option?.manageType !== 'addLocationGroup'
    ) {
      addLocationGroupHook[1]('')
      addEmailHook[1]('')
      isSubmittingHook[1](false)
    }
  }, [option, prevManageType, addLocationGroupHook, isSubmittingHook])

  return useMemo((): IDataType | undefined => {
    if (option?.manageType !== 'addLocationGroup') return

    const [value, onChange] = addLocationGroupHook
    const [email, onChangeEmail] = addEmailHook
    const [isSubmitting, setIsSubmitting] = isSubmittingHook
    const validator = (isSubmitting: boolean) => {
      if (!value && isSubmitting) return `Couldn't be empty`

      if (options.includes(value)) return `${value} exists`
    }

    return {
      locationGroupType: option.locationGroupType,
      value,
      onChange,
      email,
      onChangeEmail,
      loading,
      errorMessage: validator(isSubmitting),
      submit: async () => {
        const errorMessage = validator(true)
        const hasError = Boolean(errorMessage)

        if (!isSubmitting) setIsSubmitting(true)

        if (loading || hasError) return

        await createLocationGroup({
          variables: {
            type: option.locationGroupType,
            name: value,
            brandId: BRAND_ID[brand],
            email: (email || '').trim().toLowerCase() || null,
          },
        })
        setIsSubmitting(false)
        close()
      },
    }
  }, [
    option,
    close,
    addLocationGroupHook,
    isSubmittingHook,
    loading,
    brand,
    options,
  ])
}

export default useAddLocationGroup
