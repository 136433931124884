import { IConfigsType } from '../../types'

const mwbConfig: IConfigsType = {
  mwb: {
    '/:brand/item_by_daypart': {
      variables: [
        { type: 'items', initialSelection: ['302', 'category-302'] },
        'itemByDaypartColumnFilter',
      ],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
        ],
      },
      title: {
        type: 'title',
        title: 'Items by Daypart',
      },
      filters: {
        type: 'grid',
        children: {
          item: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Item',
          },
          unit: {
            type: 'select',
            value: 'itemByDaypartColumnFilter.value',
            onChange: 'itemByDaypartColumnFilter.onChange',
            options: 'itemByDaypartColumnFilter.options',
            label: 'Unit',
          },
        },
      },
      itemByDaypart: {
        type: 'table-v2',
        api: 'itemByDaypart',
        columns: [
          {
            key: 'locationName',
            title: 'Name',
            sortable: true,
          },
          {
            key: 'itemOrCategoryName',
            title: 'Item',
            align: 'center',
            sortable: true,
          },
          {
            key: 'earlyBird.percent',
            title: 'Early Bird',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'earlyBird.quantity',
            title: 'Early Bird',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'breakfast.percent',
            title: 'Breakfast',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'breakfast.quantity',
            title: 'Breakfast',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'lunch.percent',
            title: 'Lunch',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'lunch.quantity',
            title: 'Lunch',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'afternoonSnack.percent',
            title: 'Afternoon Snack',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'afternoonSnack.quantity',
            title: 'Afternoon Snack',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'dinner.percent',
            title: 'Dinner',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'dinner.quantity',
            title: 'Dinner',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'eveningSnack.percent',
            title: 'Evening Snack',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'eveningSnack.quantity',
            title: 'Evening Snack',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
        ],
      },
    },
    '/:brand/item_velocity': {
      variables: [
        { type: 'items', initialSelection: ['302', 'category-302'] },
        'interval',
        'timeBetween',
      ],
      groupFilter: {
        api: 'companyFilter',
      },
      dateFilter: {
        types: [
          'week',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
        ],
      },
      title: {
        type: 'title',
        title: 'Item Velocity',
      },
      filters: {
        type: 'grid',
        children: {
          items: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Show',
          },
          intervals: {
            type: 'select',
            value: 'interval.value',
            onChange: 'interval.onChange',
            options: 'interval.options',
            label: 'Time Interval',
          },
          timeBetween: {
            type: 'select',
            value: 'timeBetween.value',
            onChange: 'timeBetween.onChange',
            options: 'timeBetween.options',
            label: 'Minutes Between Orders',
          },
        },
      },
      itemVelocitySummary: {
        type: 'list',
        api: 'madeFromScratchSummary',
        fields: [
          {
            width: '150px',
            content: {
              key: 'value',
            },
            bottom: 'Total Incidences',
          },
          {
            width: '200px',
            content: {
              key: 'percentScratch',
              decimal: 1,
            },
            bottom: '% Affected Customers who ordered Item',
          },
        ],
      },
      itemVelocityCsv: {
        type: 'download-csv',
        api: 'madeFromScratch',
        message: 'Download as CSV',
        fileName: 'getexpo-item-velocity',
        fields: [
          {
            key: 'daypart',
            title: 'Time',
          },
          {
            key: 'value',
            title: 'Made From Scratch #',
          },
          {
            key: 'percentScratch',
            title: 'Made From Scratch %',
          },
          {
            key: 'total',
            title: 'Total Times Ordered',
          },
        ],
      },
      itemVelocity: {
        type: 'table-v2',
        api: 'madeFromScratch',
        columns: [
          {
            key: 'daypart',
            title: 'Time',
            sortable: true,
            background:
              '<%- values?.daypart === "Average Per Day" ? "#57AFE7" : "initial" %>',
          },
          {
            key: 'madeFromScratch',
            title: 'Made From Scratch',
            align: 'center',
            background:
              '<%- values?.daypart === "Average Per Day" ? "#57AFE7" : "initial" %>',
            children: [
              {
                key: 'value',
                title: '#',
                sortable: true,
                align: 'center',
              },
              {
                key: 'percentScratch',
                title: '%',
                sortable: true,
                align: 'center',
                decimal: 1,
              },
            ],
          },
          {
            key: 'total',
            title: 'Total Times Ordered',
            sortable: true,
            align: 'center',
            background:
              '<%- values?.daypart === "Average Per Day" ? "#57AFE7" : "initial" %>',
          },
        ],
      },
    },
    '/:brand/product_trending': {
      variables: ['date'],
      groupFilter: {
        api: 'corporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['week'],
      },
      title: {
        type: 'title',
        title: 'Trending',
      },
      topItemWowGrowthByQuantity: {
        type: 'table-v2',
        api: 'topItemWowGrowthByQuantity',
        title: 'Top Trending Items by Quantity Sold',
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        pagination: {
          pageSize: 5,
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            width: '320px',
          },
          {
            key: 'categoryName',
            title: 'Category',
            width: '150px',
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
            align: 'center',
            children: [
              {
                key: 'lastWeek4',
                title: '<%- date?.getInfo(-4)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek3',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek2',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek1',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                width: '60px',
              },
            ],
          },
          {
            key: 'wowGrowth',
            title: 'WoW Growth',
            align: 'center',
            width: '120px',
          },
        ],
      },
      bottomItemWowGrowthByQuantity: {
        type: 'table-v2',
        api: 'bottomItemWowGrowthByQuantity',
        title: 'Bottom Trending Items by Quantity Sold',
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        pagination: {
          pageSize: 5,
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            width: '320px',
          },
          {
            key: 'categoryName',
            title: 'Category',
            width: '150px',
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
            align: 'center',
            children: [
              {
                key: 'lastWeek4',
                title: '<%- date?.getInfo(-4)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek3',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek2',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek1',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                width: '60px',
              },
            ],
          },
          {
            key: 'wowGrowth',
            title: 'WoW Growth',
            align: 'center',
            width: '120px',
          },
        ],
      },
      topItemWowGrowthBySales: {
        type: 'table-v2',
        api: 'topItemWowGrowthBySales',
        title: 'Top Trending Items by BP of Sales',
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        pagination: {
          pageSize: 5,
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            width: '320px',
          },
          {
            key: 'categoryName',
            title: 'Category',
            width: '150px',
          },
          {
            key: 'itemSales',
            title: 'BP of Sales',
            align: 'center',
            children: [
              {
                key: 'lastWeek4',
                title: '<%- date?.getInfo(-4)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek3',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek2',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek1',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                width: '60px',
              },
            ],
          },
          {
            key: 'wowGrowth',
            title: 'WoW Growth',
            align: 'center',
            width: '120px',
          },
        ],
      },
      bottomItemWowGrowthBySales: {
        type: 'table-v2',
        api: 'bottomItemWowGrowthBySales',
        title: 'Bottom Trending Items by BP of Sales',
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        pagination: {
          pageSize: 5,
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            width: '320px',
          },
          {
            key: 'categoryName',
            title: 'Category',
            width: '150px',
          },
          {
            key: 'itemSales',
            title: 'BP of Sales',
            align: 'center',
            children: [
              {
                key: 'lastWeek4',
                title: '<%- date?.getInfo(-4)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek3',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek2',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                width: '60px',
              },
              {
                key: 'lastWeek1',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                width: '60px',
              },
            ],
          },
          {
            key: 'wowGrowth',
            title: 'WoW Growth',
            align: 'center',
            width: '120px',
          },
        ],
      },
    },
    '/:brand/product_insight': {
      variables: ['date'],
      groupFilter: {
        api: 'corporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      title: {
        type: 'title',
        title: 'Product Insight',
      },
      productInsightCsvDownload: {
        type: 'download-csv',
        api: 'item',
        fileName: 'getexpo-mwb-product-insight',
        message: 'Download as CSV',
        fields: [
          {
            key: 'itemName',
            title: 'Item',
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
          },
          {
            key: 'totalSales',
            title: 'Item Sales',
          },
          {
            key: 'checkCount',
            title: 'Check Count',
          },
          {
            key: 'percentOfChecks',
            title: '% of Checks',
          },
          {
            key: 'checkTotalSales',
            title: 'Check Total',
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
          },
          {
            key: 'avgCheckAdditionalItemCount',
            title: `Avg # Add'l Items Ordered`,
          },
        ],
      },
      chatboxForTable: {
        type: 'chatbox-for-table',
        api: 'item',
        fields: [
          {
            key: 'itemName',
            title: 'Item',
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
          },
          {
            key: 'totalSales',
            title: 'Item Sales',
          },
          {
            key: 'checkCount',
            title: 'Check Count',
          },
          {
            key: 'percentOfChecks',
            title: '% of Checks',
          },
          {
            key: 'checkTotalSales',
            title: 'Check Total',
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
          },
          {
            key: 'avgCheckAdditionalItemCount',
            title: `Avg # Add'l Items Ordered`,
          },
        ],
        hasCannedItemQuestions: true,
        hasCategoryMenu: false,
        hasMessageBar: false,
      },
      productInsight: {
        type: 'table-v2',
        api: 'item',
        title: '',
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        pagination: {
          pageSize: 20,
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: true,
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'totalSales',
            title: 'Item Sales',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'checkCount',
            title: 'Check Count',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'percentOfChecks',
            title: '% of Checks',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'checkTotalSales',
            title: 'Check Total',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'avgCheckAdditionalItemCount',
            title: `Avg # Add'l Items Ordered`,
            align: 'center',
            decimal: 1,
            sortable: true,
            width: '125px',
          },
        ],
      },
    },
  },
  'mwb-only-for-qa': {
    '/:brand/product_insight_qa': {
      variables: ['date'],
      groupFilter: {
        api: 'corporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      title: {
        type: 'title',
        title: 'Product Insight QA',
      },
      productInsightCsvDownload: {
        type: 'download-csv',
        api: 'item',
        fileName: 'getexpo-mwb-product-insight',
        message: 'Download as CSV',
        fields: [
          {
            key: 'itemName',
            title: 'Item',
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
          },
          {
            key: 'totalSales',
            title: 'Item Sales',
          },
          {
            key: 'checkCount',
            title: 'Check Count',
          },
          {
            key: 'percentOfChecks',
            title: '% of Checks',
          },
          {
            key: 'checkTotalSales',
            title: 'Check Total',
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
          },
          {
            key: 'avgCheckAdditionalItemCount',
            title: `Avg # Add'l Items Ordered`,
          },
        ],
      },
      chatboxForTable: {
        type: 'chatbox-for-table',
        api: 'item',
        fields: [
          {
            key: 'itemName',
            title: 'Item',
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
          },
          {
            key: 'totalSales',
            title: 'Item Sales',
          },
          {
            key: 'checkCount',
            title: 'Check Count',
          },
          {
            key: 'percentOfChecks',
            title: '% of Checks',
          },
          {
            key: 'checkTotalSales',
            title: 'Check Total',
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
          },
          {
            key: 'avgCheckAdditionalItemCount',
            title: `Avg # Add'l Items Ordered`,
          },
        ],
        hasCannedItemQuestions: true,
        hasCategoryMenu: false,
        hasMessageBar: true,
      },
      productInsight: {
        type: 'table-v2',
        api: 'item',
        title: '',
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        pagination: {
          pageSize: 20,
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: true,
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'totalSales',
            title: 'Item Sales',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'checkCount',
            title: 'Check Count',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'percentOfChecks',
            title: '% of Checks',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'checkTotalSales',
            title: 'Check Total',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'checkAverage',
            title: 'Check Avg',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'avgCheckAdditionalItemCount',
            title: `Avg # Add'l Items Ordered`,
            align: 'center',
            decimal: 1,
            sortable: true,
            width: '125px',
          },
        ],
      },
    },
  },
}

export default mwbConfig
