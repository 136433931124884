import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Row } from '@tanstack/react-table'
import React, { useCallback, useState } from 'react'
import { createPortal } from 'react-dom'

import { IDnDType, useVariables } from '../variables'
import DragMoveContextProvider, {
  IPropsType as IDragMoveContextPropsType,
  calcDepth,
} from './DragMoveContext'
import { IDataType } from './hooks/useData'

export interface IPropsType
  extends Omit<IDragMoveContextPropsType, 'offsetLeft'> {
  onDrop?: (data: IDnDType) => void
  children: React.ReactNode
}

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
}

const findParentRow = (
  row: Row<IDataType>,
  depth: number,
): Row<IDataType> | undefined => {
  if (row.depth === depth) return row

  const parentRow = row.getParentRow()

  if (parentRow) return findParentRow(parentRow, depth)
}

const Dnd = ({ dataIds, table, onDrop, children, ...props }: IPropsType) => {
  const { variables } = useVariables()
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor),
  )

  const [offsetLeft, setOffsetLeft] = useState(0)
  const onDragMove = useCallback(
    ({ delta }: DragMoveEvent) => {
      setOffsetLeft(delta.x)
    },
    [setOffsetLeft],
  )

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!active || !over) return

      const activeData = active.data.current as Row<IDataType> | null
      const info = calcDepth({ active, over, offsetLeft, dataIds, table })
      const overData = info?.data

      if (!overData || !activeData) return

      const depth = info?.depth || overData.depth

      if (activeData.id === overData.id && activeData.depth === overData.depth)
        return

      if (onDrop)
        onDrop(
          (() => {
            if (
              activeData.getParentRow()?.id === overData.getParentRow()?.id &&
              overData.depth === depth
            )
              return {
                type: 'RE_ORDER' as const,
                draggedId: activeData.id,
                moveUnder: overData.id,
              }

            if (info.isFirstItem)
              return {
                type: 'MOVE_TO_GROUP_FIRST_ITEM' as const,
                draggedId: activeData.id,
                parentId: overData.id,
              }

            return {
              type: 'MOVE_TO_ANOTHER_GROUP' as const,
              draggedId: activeData.id,
              parentId: findParentRow(overData, depth - 1)?.id as string,
              moveUnder: overData.id,
            }
          })(),
        )
    },
    [dataIds, table, onDrop, variables, offsetLeft],
  )

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
    >
      <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
        <DragMoveContextProvider
          {...props}
          offsetLeft={offsetLeft}
          dataIds={dataIds}
          table={table}
        >
          {children}
        </DragMoveContextProvider>

        {createPortal(<DragOverlay />, document.body)}
      </SortableContext>
    </DndContext>
  )
}

export default Dnd
