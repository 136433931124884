import React from 'react'
import styled from 'styled-components'

import { useVariables } from '../variables'

export interface IPropsType {
  children: React.ReactElement
  layout?: 'horizontal' | 'vertical' | 'middle' | 'space-between'
  gap?: string
  margin?: string
  width?: string
  maxWidth?: string
  minWidth?: string
  hide?: string
}

export interface IConfigsType extends Omit<IPropsType, 'children'> {
  type: 'grid'
}

const Container = styled.div<Omit<IPropsType, 'children'>>`
  gap: ${({ gap }) => gap || '25px'};
  ${({ margin }) => (!margin ? '' : `margin: ${margin};`)}
  ${({ width }) => (!width ? '' : `width: ${width};`)}
  ${({ maxWidth }) => (!maxWidth ? '' : `max-width: ${maxWidth};`)}
  ${({ minWidth }) => (!minWidth ? '' : `min-width: ${minWidth};`)}
  ${({ layout = 'horizontal' }) => {
    switch (layout) {
      case 'horizontal':
        return 'display: flex'

      case 'middle':
        return `
          display: flex;
          justify-content: center;
        `

      case 'space-between':
        return `
          display: flex;
          align-items: center;
          justify-content: space-between;
        `

      default:
        return ''
    }
  }}
`

const Grid = ({ children, hide, ...props }: IPropsType) => {
  const { template } = useVariables()
  if (hide && template(hide) === 'true') return null
  return <Container {...props}>{children}</Container>
}

export default Grid
