import CheckIcon from '@mui/icons-material/Check'
import * as d3Interpolate from 'd3-interpolate'
import * as d3 from 'd3-scale'
import _ from 'lodash'
import { Moment } from 'moment'
import { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import {
  toPercentString,
  toPercentageString,
  toUsdString,
} from 'pared/utils/number'

import { useVariables } from '../variables'
import Link, { IPropsType as ILinkPropsType } from './Link'
import Stars from './Stars'

export interface IBasePropsType {
  value: unknown
  values: Record<string, unknown>
  onClick?: string
}

type IColorType =
  | string
  | {
      range: number[]
      colors: string[]
      value?: `<%- ${string} %>`
    }

export type IPropsType =
  | (IBasePropsType & {
      type: 'price'
      decimal?: number
      color?: IColorType
      unit?: 'dollar' | 'cent'
    })
  | (IBasePropsType & {
      type: 'percent'
      decimal?: number
      color?: IColorType
      isDecimal?: boolean
    })
  | (IBasePropsType & {
      type: 'number'
      decimal?: number
      color?: IColorType
      format?: `<%- ${string} %>`
    })
  | (IBasePropsType & {
      type: 'string'
      useDangerouslySetInnerHTML?: boolean
    })
  | (IBasePropsType & {
      type: 'link'
    })
  | (IBasePropsType & {
      type: 'boolean'
    })
  | (IBasePropsType & {
      type: 'stars'
    })
  | (IBasePropsType & {
      type: 'button'
    })
  | (IBasePropsType & {
      type: 'date'
      format?: string
    })

const Text = styled.span<{
  color?: string
}>`
  ${({ color }) => (!color ? '' : `color: ${color};`)}
`

const Button = styled.button`
  color: #07a4c7;
  background: transparent;
  font-family: Lexend-Regular;
  text-decoration-line: underline;
  border: 0px;
  cursor: pointer;
  outline: none;
`

const getColor = (
  color: IColorType,
  template: ReturnType<typeof useVariables>['template'],
  external: Record<string, unknown>,
) => {
  if (!color) return

  if (typeof color === 'string') return template(color, { external })

  const value = parseFloat(
    template(color.value || '<%- value %>', { external }),
  )

  if (isNaN(value)) return

  return d3
    .scaleLinear(color.range, color.colors)
    .interpolate(d3Interpolate.interpolateHsl)(value)
}

const NULLABLE_TYPE = ['button']

const Format = ({ value, values, onClick, ...props }: IPropsType) => {
  const { variables, template } = useVariables()
  const defaultProps = useMemo(
    () => ({
      onClick: () => {
        const action = onClick && _.get(variables, onClick)

        if (action) action({ value, values })
      },
    }),
    [value, values, onClick, variables],
  )

  if (value === '-' || value === undefined || value === null)
    return NULLABLE_TYPE.includes(props.type as string) ? null : <>-</>

  switch (props.type) {
    case 'price': {
      const numberValue =
        typeof value === 'string' ? parseFloat(value) : (value as number)

      return (
        <Text
          {...defaultProps}
          color={getColor(
            props.color || '<%- value < 0 ? colors.Pomodoro : "" %>',
            template,
            { value: numberValue, colors: COLORS },
          )}
        >
          {toUsdString(
            numberValue / (props.unit === 'dollar' ? 1 : 100),
            props.decimal,
          )}
        </Text>
      )
    }

    case 'percent':
      return (
        <Text
          {...defaultProps}
          color={getColor(
            props.color || '<%- value < 0 ? colors.Pomodoro : "" %>',
            template,
            { value, colors: COLORS },
          )}
        >
          {props.isDecimal
            ? toPercentageString(value as string, props.decimal)
            : toPercentString(value as string, props.decimal)}
        </Text>
      )

    case 'number': {
      const format =
        props.format ||
        '<%- value < 0 ? "(" + Math.abs(valueStr) + ")" : valueStr %>'

      if (typeof value === 'string') {
        const numberValue = parseFloat(value)
        const valueStr = numberValue.toLocaleString('en-US', {
          minimumFractionDigits: props.decimal || 0,
          maximumFractionDigits: props.decimal || 0,
        })

        return (
          <Text
            {...defaultProps}
            color={getColor(
              props.color || '<%- value < 0 ? colors.Pomodoro : "" %>',
              template,
              { value, colors: COLORS },
            )}
          >
            {template(format, {
              external: {
                value: numberValue,
                valueStr,
              },
            })}
          </Text>
        )
      }

      const valueStr = (value as number).toLocaleString('en-US', {
        minimumFractionDigits: props.decimal || 0,
        maximumFractionDigits: props.decimal || 0,
      })

      return (
        <Text
          {...defaultProps}
          color={getColor(
            props.color || '<%- value < 0 ? colors.Pomodoro : "" %>',
            template,
            { value, colors: COLORS },
          )}
        >
          {template(format, {
            external: {
              value,
              valueStr,
            },
          })}
        </Text>
      )
    }

    case 'string':
      if (props.useDangerouslySetInnerHTML)
        return (
          <Text
            {...defaultProps}
            dangerouslySetInnerHTML={{ __html: value as string }}
          />
        )
      return <Text {...defaultProps}>{value}</Text>

    case 'link':
      return <Link {...defaultProps} {...(value as ILinkPropsType)} />

    case 'boolean':
      if (value !== true) return null
      return <CheckIcon color="primary" />

    case 'stars':
      if (!value || typeof value !== 'number') return null
      return <Stars {...defaultProps} numberOfStars={value} />

    case 'button':
      return <Button {...defaultProps}>{value}</Button>

    case 'date': {
      const date = value as Moment
      return (
        <Text {...defaultProps}>
          {date?.isValid() ? date.format(props.format || 'YYYY/MM/DD') : '-'}
        </Text>
      )
    }

    default:
      return null
  }
}

export default Format
