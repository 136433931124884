import _ from 'lodash'
import { useMemo } from 'react'

import { IDataType as IGroupType, useGroupFilter } from '../../groupFilter'
import {
  IColoredText,
  ILinkPropsType,
  ITogglePropsType,
} from '../../table/Format'
import { useVariables } from '../../variables'
import useApi, { IApiKeyType, configs } from './useApi'

export interface ICsvDataArgsType<K extends IApiKeyType> {
  api: K
  sortedBy?: keyof typeof configs[K] | 'groupInfo'
  fields: (
    | {
        key: keyof typeof configs[K]
        title: string
        hide?: string
      }
    | 'groupInfo'
  )[]
}

type IGroupInfoType = NonNullable<IGroupType['list']>[number]

const useCsvData = <K extends IApiKeyType>(
  { api, sortedBy, fields }: ICsvDataArgsType<K>,
  data?: ReturnType<typeof useApi>['data'],
) => {
  const { groupFilter } = useGroupFilter()
  const dataSource = useMemo((): NonNullable<typeof data>['source'] | null => {
    if (!data?.source) return null

    const groupInfo = groupFilter?.list?.[0]

    if (!data.summary || !groupInfo) return data.source

    if (groupInfo.groupBy)
      return [
        ...data.source,
        {
          ...data.summary,
          groupInfo: {
            ...groupInfo,
            name: 'Total',
            groupBy: {
              ...groupInfo.groupBy,
              name: ' ',
            },
          },
        },
      ]

    return [
      ...data.source,
      {
        ...data.summary,
        groupInfo: {
          ...groupInfo,
          name: 'Total',
        },
      },
    ]
  }, [data, groupFilter])
  const { variables } = useVariables()

  const csvData = useMemo(() => {
    if (!dataSource) return []

    const headers = fields
      .filter((field) => {
        if (field === 'groupInfo') {
          return true
        }

        if (!field.hide) return true

        return _.template(field.hide)(variables) !== 'true'
      })
      .map((field) => {
        if (field === 'groupInfo') {
          if (dataSource[0] && 'groupInfo' in dataSource[0]) {
            const groupInfo = dataSource[0].groupInfo as IGroupInfoType
            return [groupInfo.header, groupInfo.groupBy?.header].filter(Boolean)
          }

          return []
        }

        return (() => {
          try {
            return _.template(field.title)(variables) || ' '
          } catch (e) {
            return ' '
          }
        })()
      })
      .flat()
    const rows = (
      !sortedBy
        ? dataSource
        : dataSource.sort((a, b) => {
            const aValue = a[sortedBy as keyof typeof a]
            const bValue = b[sortedBy as keyof typeof b]

            if (sortedBy === 'groupInfo')
              return (aValue as IGroupInfoType).name.localeCompare(
                (bValue as IGroupInfoType).name,
              )

            const config = configs[api][sortedBy]

            switch (config) {
              case 'string':
                return (aValue as string).localeCompare(bValue as string)
              default:
                return (aValue as number) - (bValue as number)
            }
          })
    ).map((d) =>
      fields
        .filter((field) => {
          if (field === 'groupInfo') {
            return true
          }

          if (!field.hide) return true

          return _.template(field.hide)(variables) !== 'true'
        })
        .map((field) => {
          if (field === 'groupInfo') {
            if (d && 'groupInfo' in d) {
              const groupInfo = d.groupInfo as IGroupInfoType
              if ('groupBy' in groupInfo) {
                return [groupInfo.name, groupInfo.groupBy?.name]
              }

              return [groupInfo.name]
            }

            return []
          }

          const value = _.get(d, field.key)
          const config = configs[api][field.key]

          if (value === null || value === undefined) return ''

          switch (config) {
            case 'price':
              return (parseFloat(value as string) / 100).toFixed(2)
            case 'percent':
            case 'number':
              return parseFloat(value as string).toFixed(2)
            case 'link':
              return (value as ILinkPropsType).label
            case 'toggle':
              const toggleValue = value as ITogglePropsType
              if (toggleValue.label === undefined) return toggleValue.checked

              return toggleValue.label
            case 'colored-text':
              return (value as IColoredText).text
            default:
              return value
          }
        })
        .flat(),
    )

    return [headers, ...rows]
  }, [dataSource, api, sortedBy, fields, variables])

  return csvData
}

export default useCsvData
