import _ from 'lodash'
import { useMemo } from 'react'

import {
  toFormattedInteger,
  toPercentString,
  toUsdString,
} from 'pared/utils/number'

import { useGroupFilter } from '../../../../groupFilter'
import calc from '../../../../utils/calc'
import { useVariables } from '../../../../variables'
import { IApiDataType } from '../../../types'
import useGlTotal from './useGlTotal'

const format = (
  value: number | null,
  callback: (condition: boolean, value: number) => string,
) => !_.isNil(value) && callback(value >= 0, Math.abs(value))

const buildPnl = (
  exrayCategory:
    | 'Sales'
    | 'Expenses'
    | 'Food Cost'
    | 'Labor Cost'
    | 'Net Profit',
) => {
  const usePnl = (): IApiDataType => {
    const { variables } = useVariables()
    const { groupFilter } = useGroupFilter()
    const isCorporate = Boolean(groupFilter?.list)
    const { loading, ...data } = useGlTotal(
      exrayCategory,
      (() => {
        switch (exrayCategory) {
          case 'Sales':
            return ['current', 'yoy', 'ytd', 'prior ytd']
          case 'Net Profit':
            return ['current', 'yoy', 'ytd', 'prior', 'prior ytd']
          default:
            return isCorporate
              ? ['current', 'yoy', 'ytd', 'prior', 'prior ytd']
              : ['current', 'yoy', 'prior']
        }
      })(),
    )
    const { loading: salesLoading, ...salesData } = useGlTotal(
      'Sales',
      (() => {
        if (exrayCategory === 'Net Profit')
          return ['current', 'ytd', 'yoy', 'prior']

        if (isCorporate) return ['current', 'ytd', 'prior ytd', 'yoy', 'prior']

        return ['current', 'yoy', 'prior']
      })(),
      exrayCategory === 'Sales',
    )

    return {
      data: useMemo(() => {
        const currentPeriod = variables.date?.getInfo(0)
        const name = isCorporate
          ? ''
          : `${groupFilter?.label.replace(/\d+ -/, '')}'s`
        const { total, yoyTotal, ytdTotal, priorTotal, priorYtdTotal } = data

        if (total === null) return null

        const yoyDiff = calc(total, '-', yoyTotal) as number | null
        const yoyDiffPercent = calc(yoyDiff, 'percentageOf', yoyTotal) as
          | number
          | null

        const priorYtdDiff = calc(ytdTotal, '-', priorYtdTotal) as number | null
        const priorYtdDiffPercent = calc(
          priorYtdDiff,
          'percentageOf',
          priorYtdTotal,
        ) as number | null

        const priorDiffPercent = calc(
          calc(total, '-', priorTotal),
          'percentageOf',
          priorTotal,
        ) as number | null

        const salesPercent = calc(total, 'percentageOf', salesData.total) as
          | number
          | null
        const salesYtdPercent = calc(
          ytdTotal,
          'percentageOf',
          salesData.ytdTotal,
        ) as number | null
        const salesYtdPriorPercent = calc(
          priorYtdTotal,
          'percentageOf',
          salesData.priorYtdTotal,
        ) as number | null
        const salesYtdPercentBasis = calc(
          calc(salesYtdPercent, '-', salesYtdPriorPercent),
          '*',
          100,
        ) as number | null
        const salesYoyPercent = calc(
          yoyTotal,
          'percentageOf',
          salesData.yoyTotal,
        ) as number | null
        const salesYoyPercentBasis = calc(
          calc(salesPercent, '-', salesYoyPercent),
          '*',
          100,
        ) as number | null
        const salesPriorPercent = calc(
          priorTotal,
          'percentageOf',
          salesData.priorTotal,
        ) as number | null
        const salesPriorPercentBasis = calc(
          calc(salesPercent, '-', salesPriorPercent),
          '*',
          100,
        ) as number | null

        const { conditionTotal, summary, details, hasDetails } = (() => {
          switch (exrayCategory) {
            case 'Sales':
              return {
                conditionTotal: yoyDiffPercent ?? priorDiffPercent ?? total,
                summary: `${name} ${exrayCategory} in ${
                  currentPeriod?.displayName
                } was ${toUsdString(total / 100)}`,
                details: [
                  format(
                    yoyDiffPercent,
                    (condition, value) =>
                      `${currentPeriod?.displayName} Sales ${
                        condition ? 'increased' : 'decreased'
                      }${
                        !isCorporate
                          ? ' '
                          : ` by ${toUsdString(
                              Math.abs(yoyDiff as number) / 100,
                            )} or `
                      }${toPercentString(value)} vs Prior Year ${
                        currentPeriod?.toDateDisplayType
                      }`,
                  ),
                  currentPeriod?.displayType === 'Year'
                    ? null
                    : format(
                        priorYtdDiffPercent,
                        (condition, value) =>
                          `YTD Sales of ${toUsdString(
                            (ytdTotal as number) / 100,
                          )} ${condition ? 'increased' : 'decreased'}${
                            !isCorporate
                              ? ' '
                              : ` by ${toUsdString(
                                  Math.abs(priorYtdDiff as number) / 100,
                                )} or `
                          }${toPercentString(value)} vs Prior YTD`,
                      ),
                ],
                hasDetails: true,
              }

            case 'Net Profit':
              return {
                conditionTotal:
                  salesYoyPercentBasis ?? salesPriorPercentBasis ?? total,
                summary: `${name} ${exrayCategory} in ${
                  currentPeriod?.displayName
                } was ${toUsdString(total / 100)}${
                  salesPercent
                    ? `, or ${toPercentString(salesPercent)} of Sales`
                    : ''
                }`,
                details: [
                  format(
                    salesYoyPercentBasis,
                    (condition, value) =>
                      `${
                        condition ? 'Improved' : 'Worsened'
                      } by ${toFormattedInteger(
                        Math.round(value),
                      )} basis points vs Prior Year ${
                        currentPeriod?.toDateDisplayType
                      } of ${toPercentString(salesYoyPercent as number)}`,
                  ),
                  format(
                    salesPriorPercentBasis,
                    (condition, value) =>
                      `${
                        condition ? 'Improved' : 'Worsened'
                      } by ${toFormattedInteger(
                        Math.round(value),
                      )} basis points vs Prior ${
                        currentPeriod?.displayType
                      } of ${toPercentString(salesPriorPercent as number)}`,
                  ),
                  currentPeriod?.displayType !== 'Year' &&
                    !_.isNil(ytdTotal) &&
                    !_.isNil(salesYtdPercent) &&
                    `YTD Net Profit is ${toUsdString(
                      ytdTotal / 100,
                    )} or ${toPercentString(salesYtdPercent)} of Sales.`,
                  format(
                    priorYtdDiff,
                    (condition, value) =>
                      `YTD Net Profit is ${
                        condition ? 'beating' : 'lagging'
                      } Prior YTD by ${toUsdString(value / 100)}`,
                  ),
                ],
                hasDetails: isCorporate,
              }

            default:
              return {
                conditionTotal:
                  salesYoyPercentBasis ?? salesPriorPercentBasis ?? total,
                summary: `${name} ${exrayCategory} in ${
                  currentPeriod?.displayName
                } was ${toUsdString(total / 100)}${
                  salesPercent
                    ? `, or ${toPercentString(salesPercent)} of Sales`
                    : ''
                }`,
                details: [
                  format(
                    salesYoyPercentBasis,
                    (condition, value) =>
                      `${
                        condition ? 'Worsened' : 'Improved'
                      } by ${toFormattedInteger(
                        Math.round(value),
                      )} basis points vs Prior Year ${
                        currentPeriod?.toDateDisplayType
                      } of ${toPercentString(salesYoyPercent as number)}`,
                  ),
                  format(
                    salesPriorPercentBasis,
                    (condition, value) =>
                      `${
                        condition ? 'Worsened' : 'Improved'
                      } by ${toFormattedInteger(
                        Math.round(value),
                      )} basis points vs Prior ${
                        currentPeriod?.displayType
                      } of ${toPercentString(salesPriorPercent as number)}`,
                  ),
                  currentPeriod?.displayType !== 'Year' &&
                    isCorporate &&
                    format(
                      salesYtdPercentBasis,
                      (condition, value) =>
                        `YTD ${exrayCategory} is ${toPercentString(
                          salesYtdPercent as number,
                        )} of Sales which is ${
                          condition ? 'worse' : 'better'
                        } than Prior YTD by ${toFormattedInteger(
                          Math.round(value),
                        )} basis points.`,
                    ),
                ],
                hasDetails: true,
              }
          }
        })()

        return {
          title: exrayCategory,
          total: conditionTotal,
          summary,
          detail: `
            <ul>
              ${details
                .filter(Boolean)
                .map((m) => `<li>${m}</li>`)
                .join('\n')}
            </ul>
          `,
          hasDetails,
        }
      }, [data, salesData, variables, isCorporate]),
      loading: loading || salesLoading,
    }
  }

  return usePnl
}

export default buildPnl
