import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { useVariables } from '../../variables'
import { IApiDataType } from '../types'

const query = gql`
  query ListFarwestFlashSalesByOrderMode(
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendLocationGroupMetricValuesByDateRangeNumber(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
      iDateRangeNumber: 8
    ) {
      nodes {
        locationGroupId
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        dateIndex
        metricData
        metricSummaryData
      }
    }

    trendDateCountByDateRangeNumber(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iDateRangeNumber: 8
    ) {
      nodes {
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessWeekOfMonth
        dateIndex
        dateCount
      }
    }
  }
`

export const farwestEquivalentUnitCountConfigs = {
  equivalentUnitCountName: 'string',
  equivalentUnitCountWeek1InPast: 'number',
  equivalentUnitCountWeek2InPast: 'number',
  equivalentUnitCountWeek3InPast: 'number',
  equivalentUnitCountWeek4InPast: 'number',
  equivalentUnitCountWeek5InPast: 'number',
  equivalentUnitCountWeek6InPast: 'number',
  equivalentUnitCountWeek7InPast: 'number',
  equivalentUnitCountWeek8InPast: 'number',
} as const

export const KPIS = [
  {
    metricCode: 'daily_equivalent_unit_count',
    equivalentUnitCountName: 'Equivalent Unit Count',
    type: 'equivalentUnitCount',
  },
]

const codeToType = (metricCode: string) =>
  KPIS.find((k) => k.metricCode === metricCode)?.type || ''

const useFarwestEquivalentUnitCount = () => {
  const { endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { variables } = useVariables()

  const yesterday = moment().subtract(1, 'day')
  const adjustedEndDate =
    endDate && moment(endDate, 'YYYY-MM-DD').isAfter(yesterday)
      ? yesterday.format('YYYY-MM-DD')
      : endDate

  const dateType = variables.date?.value.type
  const iGroupBy = ((dateType?: string) => {
    if (dateType === 'period') {
      return 'last_x_periods'
    } else if (dateType === 'quarter') {
      return 'last_x_quarters'
    } else if (dateType === 'year') {
      return 'last_x_years'
    } else {
      return 'last_x_weeks'
    }
  })(dateType)

  const { data, loading } = useQuery(query, {
    variables: {
      iEndDate: adjustedEndDate,
      iGroupBy,
      iFilter: {
        location_group_ids: groupFilter?.ids,
        metrics: KPIS.map((k) => k.metricCode),
      },
    },
    skip: !adjustedEndDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: any =
        data?.trendLocationGroupMetricValuesByDateRangeNumber?.nodes
      const dayCountList: any = data?.trendDateCountByDateRangeNumber?.nodes

      if (!customizedData || !dayCountList) return null

      let source: any[] = KPIS

      customizedData.forEach((weeklyData: any) => {
        const dayCount =
          dayCountList.find((d: any) => d.dateIndex === weeklyData.dateIndex)
            ?.dateCount || 1

        Object.keys(weeklyData.metricData).forEach((code) => {
          const currentSource = source.find((s) => s.metricCode === code)
          if (currentSource) {
            const newKey = `${codeToType(code)}Week${
              weeklyData.dateIndex
            }InPast`

            currentSource[newKey] = weeklyData.metricData[code].value / dayCount

            if (
              moment(endDate).isAfter(yesterday) &&
              weeklyData.dateIndex === 1
            ) {
              currentSource[newKey] = null
            }
          }
        })
      })

      return {
        source,
      }
    }, [data]),
    loading,
  }
}

export default useFarwestEquivalentUnitCount
