import { IDiscountDetailsType } from './types'

export const DISCOUNT_TYPES: IDiscountDetailsType[] = [
  {
    discountName: 'Coupons And Others',
    checkCountKey: 'discountCheckCountCouponsAndOthers',
    compsKey: 'discountCompsCouponsAndOthers',
  },
  {
    discountName: 'Employee Meal',
    checkCountKey: 'discountCheckCountEmployeeMeal',
    compsKey: 'discountCompsEmployeeMeal',
  },
  {
    discountName: 'Police Discount',
    checkCountKey: 'discountCheckCountPoliceDiscount',
    compsKey: 'discountCompsPoliceDiscount',
  },
  {
    discountName: 'Promo Meal',
    checkCountKey: 'discountCheckCountPromoMeal',
    compsKey: 'discountCompsPromoMeal',
  },
  {
    discountName: 'Large Order',
    checkCountKey: 'discountCheckCountLargeOrder',
    compsKey: 'discountCompsLargeOrder',
  },
  {
    discountName: 'Mobile Courtesy Meal',
    checkCountKey: 'discountCheckCountMobileCourtesyMeal',
    compsKey: 'discountCompsMobileCourtesyMeal',
  },
  {
    discountName: 'Trainer Promo',
    checkCountKey: 'discountCheckCountTrainerPromo',
    compsKey: 'discountCompsTrainerPromo',
  },
]
