import { useCallback, useMemo, useState } from 'react'

import useAddLocationGroup, {
  IDataType as IAddLocationGroupDataType,
  IOptionType as IAddLocationGroupOptionType,
} from './useAddLocationGroup'
import useEditLocationGroup, {
  IDataType as IEditLocationGroupDataType,
  IOptionType as IEditLocationGroupOptionType,
} from './useEditLocationGroup'
import useListLocationGroupMapping from './useListLocationGroupMapping'

type IStateType = IAddLocationGroupOptionType | IEditLocationGroupOptionType

export interface IDataType {
  addLocationGroup?: IAddLocationGroupDataType
  editLocationGroup?: IEditLocationGroupDataType
  manageLocationGroups?: {
    loading: boolean
    addLocationGroupOpen: boolean
    editLocationGroupOpen: boolean
    onOpen: (options: { values: IStateType }) => void
    onClose: () => void
  }
}

const useManageLocationGroups = (): IDataType => {
  const manageHook = useState<IStateType | null>(null)
  const onClose = useCallback(() => manageHook[1](null), [manageHook])
  const { data, loading } = useListLocationGroupMapping()
  const addLocationGroup = useAddLocationGroup(
    data || null,
    manageHook[0] as IAddLocationGroupOptionType,
    onClose,
  )
  const editLocationGroup = useEditLocationGroup(
    data || null,
    manageHook[0] as IEditLocationGroupOptionType,
    onClose,
  )

  return {
    addLocationGroup,
    editLocationGroup,
    manageLocationGroups: useMemo((): IDataType['manageLocationGroups'] => {
      const [manageState, setManageState] = manageHook

      return {
        loading,
        addLocationGroupOpen: manageState?.manageType === 'addLocationGroup',
        editLocationGroupOpen: manageState?.manageType === 'editLocationGroup',
        onOpen: ({ values }) => setManageState(values),
        onClose,
      }
    }, [manageHook, onClose]),
  }
}

export default useManageLocationGroups
