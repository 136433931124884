import { gql, useQuery } from '@apollo/client'

import { BRAND_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

export interface IQueryDataType {
  listLocationGroupMapping: {
    nodes: {
      locationId: number
      locationCode: string
      locationName: string
      locationOpenedAt: string
      locationClosedAt: string
      locationGroupId: number
      locationGroupName: string
      locationGroupType: string
      locationGroupStartDate: string
      locationGroupEndDate: string
    }[]
  }
}

interface IQueryVariables {
  iBrandId: number
}

const query = gql`
  query listLocationGroupMapping($iBrandId: Int!) {
    listLocationGroupMapping(iBrandId: $iBrandId) {
      nodes {
        locationId
        locationCode
        locationName
        locationOpenedAt
        locationClosedAt
        locationGroupId
        locationGroupName
        locationGroupType
        locationGroupStartDate
        locationGroupEndDate
      }
    }
  }
`

const useListLocationGroupMapping = () => {
  const { brand } = useBrands()

  return useQuery<IQueryDataType, IQueryVariables>(query, {
    variables: {
      iBrandId: BRAND_ID[brand],
    },
  })
}

export default useListLocationGroupMapping
