import useTrendLocationDeliveryKpis, {
  burgerworksTrendLocationDeliveryConfigs,
} from './useTrendLocationDeliveryKpis'
import useTrendLocationGroupDelivery, {
  trendLocationGroupDeliveryConfigs,
} from './useTrendLocationGroupDelivery'
import useTrendLocationGroupGuest, {
  trendLocationGroupGuestConfigs,
} from './useTrendLocationGroupGuest'
import useTrendLocationGroupSalesmanshipKpis, {
  burgerworksTrendLocationGroupSalesmanshipKpisConfigs,
} from './useTrendLocationGroupSalesmanshipKpis'
import useTrendLocationGuestKpis, {
  burgerworksTrendLocationGuestConfigs,
} from './useTrendLocationGuestKpis'
import useTrendLocationSalesByOriginAmount, {
  trendLocationSalesByOriginConfigs,
} from './useTrendLocationSalesByOriginAmount'
import useTrendLocationSalesmanshipKpis, {
  burgerworksTrendLocationSalesmanshipKpisConfigs,
} from './useTrendLocationSalesmanshipKpis'
import useBurgerworksWeeklySales, {
  burgerworksWeeklySalesConfigs,
} from './useWeeklySales'

export const configs = {
  burgerworksWeeklySales: burgerworksWeeklySalesConfigs,
  burgerworksTrendLocationGroupDelivery: trendLocationGroupDeliveryConfigs,
  burgerworksTrendLocationGroupSalesmanshipKpis:
    burgerworksTrendLocationGroupSalesmanshipKpisConfigs,
  burgerworksTrendLocationSalesmanshipKpis:
    burgerworksTrendLocationSalesmanshipKpisConfigs,
  burgerworksTrendLocationDeliveryKpis: burgerworksTrendLocationDeliveryConfigs,
  burgerworksTrendLocationGroupGuest: trendLocationGroupGuestConfigs,
  burgerworksTrendLocationGuestKpis: burgerworksTrendLocationGuestConfigs,
  burgerworksTrendLocationSalesByOriginAmount:
    trendLocationSalesByOriginConfigs,
}

export const api = {
  burgerworksWeeklySales: useBurgerworksWeeklySales,
  burgerworksTrendLocationGroupDelivery: useTrendLocationGroupDelivery,
  burgerworksTrendLocationGroupSalesmanshipKpis:
    useTrendLocationGroupSalesmanshipKpis,
  burgerworksTrendLocationSalesmanshipKpis: useTrendLocationSalesmanshipKpis,
  burgerworksTrendLocationDeliveryKpis: useTrendLocationDeliveryKpis,
  burgerworksTrendLocationGroupGuest: useTrendLocationGroupGuest,
  burgerworksTrendLocationGuestKpis: useTrendLocationGuestKpis,
  burgerworksTrendLocationSalesByOriginAmount:
    useTrendLocationSalesByOriginAmount,
}
