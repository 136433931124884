// FIXME: should add download-csv-v2
import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import useDhWbFlash, {
  dhWbFlashConfigs,
} from '../../tableV2/hooks/dhWb/useFlash'

export const dhWbFlashV2Configs = {
  ...dhWbFlashConfigs,
  sosAvgStr: 'string',
  sosAvgLane2Str: 'string',
}

const useDhWbFlashV2 = () => {
  const { data, loading } = useDhWbFlash()
  const { groupFilter } = useGroupFilter()

  return {
    data: useMemo((): {
      source: Record<string, unknown>[]
      summary: Record<string, unknown> | null
    } | null => {
      if (!data) return null

      const formattedData = data.map((d) => {
        const sosAvg = d.sosAvg as number
        const sosAvgLane2 = d.sosAvgLane2 as number
        return {
          ...d,
          sosAvgStr:
            sosAvg === null
              ? null
              : Math.floor(sosAvg).toString() +
                ':' +
                Math.floor((sosAvg - Math.floor(sosAvg)) * 60)
                  .toString()
                  .padStart(2, '0'),
          sosAvgLane2Str:
            sosAvgLane2 === null
              ? null
              : Math.floor(sosAvgLane2).toString() +
                ':' +
                Math.floor((sosAvgLane2 - Math.floor(sosAvgLane2)) * 60)
                  .toString()
                  .padStart(2, '0'),
        }
      })

      return {
        source: formattedData
          .filter((d) => d.parentId === 'summary')
          .map((d) => {
            const groupInfo = groupFilter?.list?.find(
              (l) => l.id.toString() === d.id,
            )

            return {
              ...d,
              groupInfo,
            }
          }),
        summary:
          formattedData.filter((d) => d.parentId === 'root')?.[0] || null,
      }
    }, [data, groupFilter]),
    loading,
  }
}

export default useDhWbFlashV2
