import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import styled from 'styled-components'

import { useVariables } from '../variables'
import DatePickerComponent, {
  IPropsType as IDatePickerPropsType,
} from './DatePicker'

export interface IPropsType {
  type: 'date-picker'
  value: string
  onChange: string
  startDate?: string
  endDate?: string
  label?: string
  margin?: string
}

const Container = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  gap: 25px;
  ${({ margin }) => (!margin ? '' : `margin: ${margin};`)}
`

const Label = styled.div`
  display: inline-block;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
`

const DatePicker = ({
  startDate,
  endDate,
  label,
  margin,
  ...props
}: IPropsType) => {
  const { variables } = useVariables()
  const { value, onChange } = useMemo(
    (): Pick<IDatePickerPropsType, 'value' | 'onChange'> => ({
      value: _.get(variables, props.value),
      onChange: _.get(variables, props.onChange),
    }),
    [props, variables],
  )

  return (
    <Container margin={margin}>
      {!label ? null : <Label>{label}</Label>}

      <DatePickerComponent
        value={value}
        onChange={onChange}
        startDate={!startDate ? undefined : moment.utc(startDate)}
        endDate={!endDate ? undefined : moment.utc(endDate)}
      />
    </Container>
  )
}

export default DatePicker
