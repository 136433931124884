import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType } from '../../types'

interface IQueryData {
  listLocationGroupMapping: {
    nodes: {
      locationId: number
      locationCode: string
      locationName: string
      locationOpenedAt: string
      locationClosedAt: string
      locationGroupMappingId: number
      locationGroupId: number
      locationGroupName: string
      locationGroupType: string
      locationGroupStartDate: string
      locationGroupEndDate: string
    }[]
  }
}

interface IQueryVariables {
  iBrandId: number
}

const query = gql`
  query listLocationGroupMapping($iBrandId: Int!) {
    listLocationGroupMapping(iBrandId: $iBrandId) {
      nodes {
        locationId
        locationCode
        locationName
        locationOpenedAt
        locationClosedAt
        locationGroupMappingId
        locationGroupId
        locationGroupName
        locationGroupType
        locationGroupStartDate
        locationGroupEndDate
      }
    }
  }
`

export const manageLocationsConfigs = {
  code: 'string',
  name: 'string',
  startAt: 'string',
  endAt: 'string',
  action: 'button',
} as const

const formatDate = (date: string) =>
  date && moment.utc(date).format('YYYY-MM-DD')

const useManageLocations = () => {
  const { brand } = useBrands()
  const { data, loading } = useQuery<IQueryData, IQueryVariables>(query, {
    variables: {
      iBrandId: BRAND_ID[brand],
    },
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const locationGroupTypes = data.listLocationGroupMapping.nodes.reduce(
        (result, n) =>
          ['Brand', 'Open Status', 'Legal Entity'].includes(
            n.locationGroupType,
          ) || result.includes(n.locationGroupType)
            ? result
            : [...result, n.locationGroupType],
        [] as string[],
      )

      const locations: IApiDataType = []
      const locationGroups: IApiDataType = []

      data.listLocationGroupMapping.nodes.forEach((n) => {
        if (!n.locationId) return

        const openOrComingSoonStore =
          n.locationOpenedAt &&
          moment.utc(n.locationOpenedAt) < moment.utc().add(1, 'years')
        const existingLocation = locations.find(
          (l) => l.code === n.locationCode,
        ) as
          | {
              autoUpdateLocationGroupMapping: {
                locationGroupMappingId: number
                startDateOffset?: number
                endDateOffset?: number
              }[]
            }
          | undefined

        if (!existingLocation) {
          if (n.locationGroupType !== 'Legal Entity') {
            locations.push({
              ...(openOrComingSoonStore
                ? {
                    startAt: formatDate(n.locationOpenedAt),
                    endAt: formatDate(n.locationClosedAt),
                  }
                : {
                    startAt: 'Not Open',
                  }),
              id: n.locationCode,
              parentId: 'root',
              code: n.locationCode,
              name: n.locationName,
              action: 'EDIT',
              manageType: 'editLocation',
              locationId: n.locationId,
              locationName: n.locationName,
              locationOpenedAt: formatDate(n.locationOpenedAt),
              autoUpdateLocationGroupMapping: [
                {
                  locationGroupMappingId: n.locationGroupMappingId,
                },
              ],
            })

            locationGroupTypes.forEach((locationGroupType) => {
              if (
                !openOrComingSoonStore &&
                locationGroupType === 'Comp or Non-Comp'
              )
                return

              locationGroups.push({
                id: `${n.locationCode}${locationGroupType}`,
                parentId: n.locationCode,
                code: locationGroupType,
                action:
                  locationGroupType === 'Comp or Non-Comp' ? null : 'EDIT',
                manageType: / or /.test(locationGroupType)
                  ? 'editLocationGroupHistory'
                  : 'changeLocationGroup',
                locationId: n.locationId,
                locationGroupType: locationGroupType,
                locationGroupHistory: [
                  {
                    date: formatDate(n.locationOpenedAt),
                  },
                ],
              })
            })
          }
        } else {
          if (
            !/ or /.test(n.locationGroupType) &&
            n.locationGroupType !== 'Open Status'
          )
            existingLocation.autoUpdateLocationGroupMapping.push({
              locationGroupMappingId: n.locationGroupMappingId,
            })

          if (n.locationGroupType === 'Comp or Non-Comp')
            existingLocation.autoUpdateLocationGroupMapping.push({
              locationGroupMappingId: n.locationGroupMappingId,
              startDateOffset:
                n.locationGroupName === 'Non-Comp' ? undefined : 365,
              endDateOffset:
                n.locationGroupName === 'Non-Comp' ? 364 : undefined,
            })
        }

        if (
          ['Brand', 'Open Status', 'Legal Entity'].includes(n.locationGroupType)
        )
          return

        if (
          !openOrComingSoonStore &&
          n.locationGroupType === 'Comp or Non-Comp'
        )
          return

        const locationGroupId = `${n.locationCode}${n.locationGroupType}`
        const existingLocationGroup = locationGroups.find(
          (lg) => lg.id === locationGroupId,
        )

        if (!existingLocationGroup)
          throw new Error(`unknown location group type: ${n.locationGroupType}`)

        if (!existingLocationGroup.name) {
          Object.assign(existingLocationGroup, {
            ...(!openOrComingSoonStore && !/ or /.test(n.locationGroupType)
              ? {}
              : {
                  startAt: formatDate(n.locationGroupStartDate),
                  endAt: formatDate(n.locationGroupEndDate),
                }),
            name: n.locationGroupName,
            locationGroupId: n.locationGroupId,
            locationGroupHistory: [
              {
                id: n.locationGroupId,
                date: formatDate(n.locationGroupStartDate),
              },
            ],
          })
          return
        }

        ;(
          existingLocationGroup.locationGroupHistory as {
            id: number
            date: string
          }[]
        ).push({
          id: n.locationGroupId,
          date: formatDate(n.locationGroupStartDate),
        })
        locationGroups.push({
          id: [
            n.locationCode,
            n.locationGroupName,
            n.locationGroupType,
            n.locationGroupStartDate,
            n.locationGroupEndDate,
          ].join('-'),
          parentId: locationGroupId,
          code: ' ',
          name: n.locationGroupName,
          startAt: formatDate(n.locationGroupStartDate),
          endAt: formatDate(n.locationGroupEndDate),
        })
      })

      return [...locations, ...locationGroups]
    }, [data]),
    loading,
  }
}

export default useManageLocations
