import Popover from '@material-ui/core/Popover'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import Calendar from 'rc-calendar'
import 'rc-calendar/assets/index.css'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

export interface IPropsType {
  margin?: string
  startDate?: moment.Moment
  endDate?: moment.Moment
  value?: moment.Moment
  onChange: (value: moment.Moment) => void
  error?: boolean
}

const StyledTextField = styled(TextField)<{ styleMargin?: string }>`
  margin: ${({ styleMargin }) => styleMargin || '15px 0px'};
  width: 80%;
  cursor: pointer;

  > *,
  input {
    font-family: Lexend-Regular;
    cursor: pointer;
    text-align: center;
  }
`

const DatePicker = ({
  margin,
  startDate,
  endDate,
  value,
  onChange,
  error,
}: IPropsType) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLDivElement>(
    null,
  )
  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setPopoverAnchorEl(e.target as HTMLDivElement)
    },
    [setPopoverAnchorEl],
  )
  const onClose = useCallback(() => {
    setPopoverAnchorEl(null)
  }, [setPopoverAnchorEl])
  const onDateChange = useCallback(
    (date: moment.Moment | null) => {
      if (!date) return

      onChange(date)
      setPopoverAnchorEl(null)
    },
    [onChange, setPopoverAnchorEl],
  )

  return (
    <>
      <StyledTextField
        styleMargin={margin}
        value={value?.format('YYYY-MM-DD') || ''}
        placeholder="Please select a date"
        InputProps={{
          readOnly: true,
        }}
        onClick={onOpen}
        error={error}
      />

      <Popover
        onClose={onClose}
        anchorEl={popoverAnchorEl}
        open={Boolean(popoverAnchorEl)}
      >
        {/* @ts-ignore moment version problem */}
        <Calendar
          selectedValue={value}
          defaultValue={value}
          onSelect={onDateChange}
          onClear={onClose}
          disabledDate={(date?: moment.Moment) =>
            (startDate && date?.isBefore(startDate, 'days')) ||
            (endDate && date?.isAfter(endDate, 'days'))
          }
        />
      </Popover>
    </>
  )
}

export default React.memo(DatePicker)
