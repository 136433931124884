import { gql, useMutation } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import { IQueryDataType } from './useListLocationGroupMapping'

export interface IOptionType {
  manageType: 'changeLocationGroup'
  locationId: number
  locationGroupId?: number
  locationGroupType: string
  locationGroupHistory: [
    {
      date: string
    },
  ]
}

export interface IDataType {
  locationGroupType: string
  value: string[][]
  onChange: (value: string[][]) => void
  options: {
    id: string
    parentId: string
    displayName: string
  }[]
  loading: boolean
  submit: () => Promise<void>
}

const mutate = gql`
  mutation UpdateLocationMapping(
    $iLocationId: Int!
    $iLocationGroupType: String!
    $iLocationGroupMappings: JSON!
  ) {
    updateLocationGroupMappingV3(
      input: {
        iLocationId: $iLocationId
        iLocationGroupType: $iLocationGroupType
        iLocationGroupMappings: $iLocationGroupMappings
      }
    ) {
      clientMutationId
    }
  }
`

const useChangeLocationGroup = (
  data: IQueryDataType | null,
  option: IOptionType | null,
  close: () => void,
) => {
  const changeLocationGroupHook = useState<string[][]>([[]])
  const prevManageType = usePrevious(option?.manageType)
  const [updateLocationMapping, { loading }] = useMutation(mutate, {
    refetchQueries: ['listLocationGroupMapping'],
    awaitRefetchQueries: true,
  })
  const options = useMemo(
    () =>
      !option
        ? []
        : (data?.listLocationGroupMapping.nodes || [])
            .filter((n) => n.locationGroupType === option.locationGroupType)
            .reduce(
              (result, n) =>
                result.some((r) => r.id === n.locationGroupId.toString())
                  ? result
                  : [
                      ...result,
                      {
                        id: n.locationGroupId.toString(),
                        parentId: 'root',
                        displayName: n.locationGroupName,
                      },
                    ],
              [] as IDataType['options'],
            )
            .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [data, option],
  )

  useEffect(() => {
    if (prevManageType !== option?.manageType) {
      if (
        option?.manageType === 'changeLocationGroup' &&
        option.locationGroupId
      )
        changeLocationGroupHook[1]([[option.locationGroupId.toString()]])
      else changeLocationGroupHook[1]([[]])
    }
  }, [prevManageType, option, changeLocationGroupHook])

  return useMemo((): IDataType | undefined => {
    if (option?.manageType !== 'changeLocationGroup') return

    const [value, onChange] = changeLocationGroupHook

    return {
      locationGroupType: option.locationGroupType,
      value,
      onChange,
      options,
      loading,
      submit: async () => {
        if (!value[0]?.[0] || loading) return

        await updateLocationMapping({
          variables: {
            iLocationId: option.locationId,
            iLocationGroupType: option.locationGroupType,
            iLocationGroupMappings: [
              {
                locationGroupId: value[0]?.[0],
                startDate: option.locationGroupHistory[0].date,
              },
            ],
          },
        })
        close()
      },
    }
  }, [
    option,
    close,
    changeLocationGroupHook,
    loading,
    updateLocationMapping,
    options,
  ])
}

export default useChangeLocationGroup
