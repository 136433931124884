import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import { toUsdStr } from 'pared/utils/number'

import { IApiDataType } from '../../types'

interface ILossPreventionSummaryNodeType {
  locationCode: string
  locationName: string
  amount: number
  annualOpportunityCost: number
  employeeDiscountInfos: {
    employeeName: string
  }[]
  rankPercent: number
}

interface ILossPreventionSummaryType {
  locationULossPreventionSummary: {
    nodes: ILossPreventionSummaryNodeType[]
  }
  listEmployeesLossPreventionOutliers: {
    nodes: ILossPreventionOutliersType[]
  }
}

interface ILossPreventionOutliersType {
  locationId: number
  employeeName: string
  outliersType: string
  outliersValue: number
  outliersTime: string
  checkNumber: string
}

const query = gql`
  query LocationLossPreventionSummary(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iMetricInfo: JSON!
    $iFilter: JSON!
  ) {
    locationULossPreventionSummary(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iMetricInfo: $iMetricInfo
    ) {
      nodes {
        locationCode
        locationName
        amount
        annualOpportunityCost
        employeeDiscountInfos
        rankPercent
      }
    }

    listEmployeesLossPreventionOutliers(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        employeeName
        outliersType
        outliersValue
        outliersTime
        checkNumber
      }
    }
  }
`

const useLocationLossPrevention = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'

  const { data, loading } = useQuery<ILossPreventionSummaryType>(query, {
    variables: {
      iLocationId: groupFilter?.ids?.[0],
      iStartDate: startDate,
      iEndDate: endDate,
      iMetricInfo: {
        grossSalesMetric: 'gross_sales',
        discountCompsTotalMetric: 'discount_comps_total',
        discountCompsPercentTotalMetric: 'discount_comps_percent_total',
      },
      iFilter: {
        location_ids: groupFilter?.ids,
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const summary = data?.locationULossPreventionSummary.nodes[0]
      const outliers = data?.listEmployeesLossPreventionOutliers?.nodes

      const summaryMessage = (() => {
        if (!summary) return null

        const store = `<strong>${summary.locationName}</strong>`
        const amount = `<strong>$${Math.abs(summary.amount) / 100}</strong>`

        if (summary.rankPercent < 1 / 3) {
          return `${store} is discounting ${amount} more than expected in the past 30 days.`
        } else if (summary.rankPercent < 2 / 3) {
          return `${store} is discounting ${amount} <strong>${
            summary.amount > 0 ? 'more' : 'less'
          }</strong> than expected but is around average for the company for the past 30 days.`
        } else {
          return `${store} is discounting as expected for the past 30 days.`
        }
      })()

      const outliersMessage = (() => {
        if (!outliers) return null

        const discountData = outliers.filter(
          (o) => o.outliersType === 'discount',
        )
        const voidData = outliers.filter((o) => o.outliersType === 'void')

        return `<br /><br /> There were <strong> ${voidData.length}</strong>  Voids >$50 and <strong> ${discountData.length}</strong>  Discounts >$50`
      })()

      const improvementMessage = (() => {
        if (!summary) return null

        const employeeDiscountInfos = summary.employeeDiscountInfos || []
        if (employeeDiscountInfos.length === 0)
          return 'No employees are discounting above the Company Average for this time period.'

        const names = employeeDiscountInfos.map(({ employeeName }, index) => {
          return `<strong>${employeeName}</strong>${(() => {
            if (index === employeeDiscountInfos.length - 1) return ''
            else if (index === employeeDiscountInfos.length - 2) return ', and '
            else return ', '
          })()}`
        })

        return `${names.join(' ')} are discounting more than company averages.`
      })()

      const allInsights: string[] = []
      if (summaryMessage) {
        allInsights.push(summaryMessage)
      }
      if (improvementMessage) {
        allInsights.push(improvementMessage)
      }
      if (summary?.annualOpportunityCost && summary.annualOpportunityCost > 0) {
        allInsights.push(
          `Annual opportunity cost for all employees: ${toUsdStr(
            summary.annualOpportunityCost / 100.0,
          )}`,
        )
      }
      if (outliersMessage) {
        allInsights.push(outliersMessage)
      }

      if (allInsights.length <= 0) {
        allInsights.push('No enough data to generate insights')
      }

      return {
        title: 'Loss Prevention',
        total:
          summary?.annualOpportunityCost && summary.annualOpportunityCost > 0
            ? summary.annualOpportunityCost / 100.0
            : null,
        detail: allInsights.join(' '),
      }
    }, [locationName, data, loading]),
    loading: loading,
  }
}

export default useLocationLossPrevention
