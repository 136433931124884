import * as _ from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import VariablesContext from './context'
import useVariablesHook, {
  IDataType,
  IVariablesType,
} from './hooks/useVariables'

export { revenueCenterSalesConfigs } from './hooks/common/useRevenueCenterSales'
export { tableConfigs as allStoresTableConfigs } from './hooks/common/useAllStores'
export { tableConfigs as corporateGroupTableConfigs } from './hooks/common/useCorporateGroup'
export { tableConfigs as groupFilterTableConfigs } from './hooks/groupFilter'
export type { IGroupFilterDataType } from './hooks/groupFilter'
export type {
  IGroupFilterType,
  IGroupFilterVariablesNameType,
} from './hooks/utils/buildGroupFilter'
export type { IDateDataType } from './hooks/common/date'
export type { IDnDType } from './hooks/common/administration/usePnlDrop'

interface IPropsType {
  test?: string
  types: IVariablesType[]
  children: React.ReactNode
}

interface IVariablesDataType extends IDataType {
  aiSummary: Record<
    string,
    {
      isClicked: boolean
      clickedId: unknown
      value: unknown
    }
  >
}

type IInternalVariablesDataType = Omit<IVariablesDataType, keyof IDataType>
type IInternalVariablesContextType = [
  IInternalVariablesDataType,
  (state: IInternalVariablesDataType) => void,
]

export type { IVariablesType, IVariablesDataType }

const defaultInternalVariables = {
  aiSummary: {},
}
const InternalVariablesContext =
  React.createContext<IInternalVariablesContextType>([
    defaultInternalVariables,
    () => {
      throw new Error('not found provider')
    },
  ])

const MAP_PATTERN = /JSON\((.+)\)/

export const useVariables = () => {
  const variables = useContext(VariablesContext)
  const [internalVariables, setInternalVariables] = useContext(
    InternalVariablesContext,
  )
  const mergedVariables = useMemo(
    () => ({
      ...variables,
      ...internalVariables,
    }),
    [variables, internalVariables],
  )
  const template = useCallback(
    (
      str: string,
      {
        showError = false,
        external = {},
      }: {
        showError?: boolean
        external?: Record<string, unknown>
      } = {},
    ) => {
      try {
        if (MAP_PATTERN.test(str))
          return JSON.parse(
            _.template(
              str.replace(/JSON\((.+)\)/, (_, p1) => `JSON.stringify(${p1})`),
            )({ ...mergedVariables, ...external }).replace(/&quot;/g, '"') ||
              '[]',
          )

        return _.template(str)({ ...mergedVariables, ...external }) || ''
      } catch (e) {
        if (showError) return `Error: ${(e as Error).message}`

        console.error(e)
        return ''
      }
    },
    [mergedVariables],
  )

  return {
    variables: mergedVariables,
    setVariables: setInternalVariables,
    template,
  }
}

const TestVariable = ({ test }: Pick<IPropsType, 'test'>) => {
  const { template } = useVariables()

  if (!test) return null

  const data = template(test, { showError: true })

  return (
    <pre>{typeof data === 'string' ? data : JSON.stringify(data, null, 2)}</pre>
  )
}

const VariablesProvider = ({ test, types, children }: IPropsType) => {
  const variables = useVariablesHook(types)
  const internalVariablesHook = useState(defaultInternalVariables)

  return (
    <VariablesContext.Provider value={variables}>
      <InternalVariablesContext.Provider value={internalVariablesHook}>
        <TestVariable test={test} />

        {children}
      </InternalVariablesContext.Provider>
    </VariablesContext.Provider>
  )
}

export default VariablesProvider
