import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import { IQueryDataType } from './useListLocationGroupMapping'

export interface IOptionType {
  manageType: 'editLocationGroupHistory'
  locationId: number
  locationGroupType: string
  locationGroupHistory: {
    id?: number
    date: string
  }[]
}

export interface IDataType {
  title: string
  values: {
    type: string
    date: moment.Moment
  }[]
  onChange: (values: IDataType['values']) => void
  options: {
    value: string
    displayName: string
  }[]
  loading: boolean
  errorMessages: (string | undefined)[]
  submit: () => Promise<void>
}

const mutate = gql`
  mutation UpdateLocationMapping(
    $iLocationId: Int!
    $iLocationGroupType: String!
    $iLocationGroupMappings: JSON!
  ) {
    updateLocationGroupMappingV3(
      input: {
        iLocationId: $iLocationId
        iLocationGroupType: $iLocationGroupType
        iLocationGroupMappings: $iLocationGroupMappings
      }
    ) {
      clientMutationId
    }
  }
`

const useEditLocationGroupHistory = (
  data: IQueryDataType | null,
  option: IOptionType | null,
  close: () => void,
) => {
  const editLocationGroupHook = useState<IDataType['values']>([])
  const prevManageType = usePrevious(option?.manageType)
  const [updateLocationMapping, { loading }] = useMutation(mutate, {
    refetchQueries: ['listLocationGroupMapping'],
    awaitRefetchQueries: true,
  })
  const options = useMemo(
    () =>
      !option
        ? []
        : (data?.listLocationGroupMapping.nodes || [])
            .filter((n) => n.locationGroupType === option.locationGroupType)
            .reduce(
              (result, n) =>
                result.some((r) => r.value === n.locationGroupId.toString())
                  ? result
                  : [
                      ...result,
                      {
                        value: n.locationGroupId.toString(),
                        displayName: n.locationGroupName,
                      },
                    ],
              [] as IDataType['options'],
            )
            .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [data, option],
  )

  useEffect(() => {
    if (prevManageType !== option?.manageType) {
      if (option?.manageType === 'editLocationGroupHistory')
        editLocationGroupHook[1](
          option.locationGroupHistory.map((o) => ({
            type: o.id?.toString() || options[0].value,
            date: moment.utc(o.date),
          })),
        )
      else editLocationGroupHook[1]([])
    }
  }, [option, options, prevManageType, editLocationGroupHook])

  return useMemo((): IDataType | undefined => {
    if (option?.manageType !== 'editLocationGroupHistory') return

    const [values, onChange] = editLocationGroupHook
    const errorMessages = values.map(
      (value, index) =>
        [
          value.type === values[index - 1]?.type
            ? `Couldn't use the same group consecutively`
            : null,
          value.date >= values[index - 1]?.date
            ? 'Date must be greater than the previous one'
            : null,
        ]
          .filter(Boolean)
          .join(', ') || undefined,
    )

    return {
      title: `Editing: ${option.locationGroupType}`,
      values,
      onChange,
      options,
      loading,
      errorMessages,
      submit: async () => {
        const hasError = errorMessages.some(Boolean)

        if (loading || hasError) return

        await updateLocationMapping({
          variables: {
            iLocationId: option.locationId,
            iLocationGroupType: option.locationGroupType,
            iLocationGroupMappings: values.map((v, index) => ({
              locationGroupId: v.type,
              startDate: v.date.format('YYYY-MM-DD'),
              endDate: !values[index - 1]
                ? null
                : values[index - 1].date
                    .clone()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
            })),
          },
        })
        close()
      },
    }
  }, [option, close, editLocationGroupHook, loading, options])
}

export default useEditLocationGroupHistory
