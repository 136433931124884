import * as _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { getYearMonthDiff } from 'pared/utils/date'
import { toPercentString, toUsdString } from 'pared/utils/number'

import FeedbackName, {
  IPropsType as IFeedbackNamePropsType,
} from './FeedbackName'
import Link, { IPropsType as ILinkPropsType } from './Link'
import ProgressBar from './ProgressBar'
import TrackerCompletionRate from './TrackerCompletionRate'
import TrackerName, { IPropsType as ITrackerNamePropsType } from './TrackerName'
import DeleteDevelopmentPlan, {
  IPropsType as IDeleteDevelopmentPlanPropsType,
} from './deleteDevelopmentPlan'
import DeleteTrainingTracker, {
  IPropsType as IDeleteTrainingTrackerPropsType,
} from './deleteTrainingTracker'
import Feedback, { IPropsType as IFeedbackPropsType } from './feedback'
import Toggle, { IPropsType as ITogglePropsType } from './toggle'
import Tracker, { IPropsType as ITrackerPropsType } from './tracker'
import { IColoredText } from './types'

interface IBasePropsType {
  value: unknown
  values: Record<string, unknown>
  style?: React.CSSProperties
  onClick?: () => void
  danger?: string
  dangerThreshold?: number
}

export type IPropsType =
  | (IBasePropsType & {
      type: 'price'
      decimal?: number
    })
  | (IBasePropsType & {
      type: 'percent'
      decimal?: number
      reverse?: boolean
      disableDangerColor?: boolean
    })
  | (IBasePropsType & {
      type: 'number'
      decimal?: number
      reverse?: boolean
    })
  | (IBasePropsType & {
      type: 'basisPoint'
      reverse?: boolean
    })
  | (IBasePropsType & {
      type: 'date'
    })
  | (IBasePropsType & {
      type: 'date-string'
      format?: string
      timezone?: number
    })
  | (IBasePropsType & {
      type: 'date-diff'
    })
  | (IBasePropsType & {
      type: 'second'
    })
  | (IBasePropsType & {
      type: 'string'
    })
  | (IBasePropsType & {
      type: 'toggle'
      mutation: ITogglePropsType['mutation']
      color?: ITogglePropsType['color']
    })
  | (IBasePropsType & {
      type: 'feedback-name'
    })
  | (IBasePropsType & {
      type: 'feedback'
    })
  | (IBasePropsType & {
      type: 'progress-bar'
    })
  | (IBasePropsType & {
      type: 'tracker'
    })
  | (IBasePropsType & {
      type: 'link'
    })
  | (IBasePropsType & {
      type: 'tracker-completion-rate'
    })
  | (IBasePropsType & {
      type: 'delete-training-tracker'
    })
  | (IBasePropsType & {
      type: 'tracker-name'
    })
  | (IBasePropsType & {
      type: 'colored-text'
    })
  | (IBasePropsType & {
      type: 'delete-development-plan'
    })

const Text = styled.span<{
  danger?: boolean
  warning?: boolean
  color?: string
}>`
  color: ${({ danger, warning, color }) =>
    danger
      ? COLORS.Pomodoro
      : warning
      ? COLORS.Mango
      : color
      ? color
      : 'black'};}
`

const displayDangerString = (number: string | number, danger: boolean) =>
  danger ? `(${number})` : `${number}`

const Format = ({ value, style, onClick, ...props }: IPropsType) => {
  const inheritProps = useMemo(
    () => ({
      style,
      onClick,
    }),
    [style, onClick],
  )

  const dangerThreshold = props.dangerThreshold ?? 0
  switch (props.type) {
    case 'price': {
      if (value === '-' || value === undefined || value === null) return <>-</>

      const numberValue =
        typeof value === 'string' ? parseFloat(value) : (value as number)
      const danger =
        props.danger === 'positive'
          ? numberValue > dangerThreshold
          : numberValue < dangerThreshold
      return (
        <Text {...inheritProps} danger={danger}>
          {toUsdString(numberValue / 100, props.decimal)}
        </Text>
      )
    }

    case 'percent': {
      const danger = props.disableDangerColor
        ? false
        : props.danger === 'positive'
        ? (value as number) > dangerThreshold
        : (value as number) < dangerThreshold

      return (
        <Text {...inheritProps} danger={props.reverse ? !danger : danger}>
          {toPercentString(value as string, props.decimal)}
        </Text>
      )
    }

    case 'number': {
      if (value === '-' || value === undefined || value === null) return <>-</>

      if (typeof value === 'string') {
        const numberValue = parseFloat(value)
        const danger = numberValue < dangerThreshold

        return (
          <Text {...inheritProps} danger={props.reverse ? !danger : danger}>
            {displayDangerString(
              Math.abs(numberValue).toLocaleString('en-US', {
                minimumFractionDigits: props.decimal || 0,
                maximumFractionDigits: props.decimal || 0,
              }),
              props.reverse ? !danger : danger,
            )}
          </Text>
        )
      }

      const danger = (value as number) < dangerThreshold

      return (
        <Text {...inheritProps} danger={props.reverse ? !danger : danger}>
          {displayDangerString(
            Math.abs(value as number).toLocaleString('en-US', {
              minimumFractionDigits: props.decimal || 0,
              maximumFractionDigits: props.decimal || 0,
            }),
            props.reverse ? !danger : danger,
          )}
        </Text>
      )
    }

    case 'basisPoint': {
      if (value === '-' || value === undefined || value === null) return <>-</>
      const danger = (value as number) < dangerThreshold
      let prefix = (value as number) > 0 ? '+' : ''
      return (
        <Text {...inheritProps} danger={props.reverse ? !danger : danger}>
          {prefix}
          {value}
        </Text>
      )
    }

    case 'date':
      return (
        <Text
          {...inheritProps}
          danger={moment(value as string).diff(moment()) < dangerThreshold}
        >
          {moment(value as string).format('M/D/YYYY')}
        </Text>
      )

    case 'date-diff': {
      const date = moment(value as string)

      return (
        <span {...inheritProps}>
          {date.isValid() ? getYearMonthDiff(date, moment()) : '-'}
        </span>
      )
    }

    case 'date-string': {
      const date = _.isNil(props.timezone)
        ? moment(value as string)
        : moment(value as string).utcOffset(props.timezone)

      return (
        <span {...inheritProps}>
          {date.isValid() ? date.format(props.format || 'YYYY/MM/DD') : '-'}
        </span>
      )
    }

    case 'second': {
      const date = moment.utc((value as number) * 1000)

      return (
        <span {...inheritProps}>
          {date.isValid() ? date.format('HH:mm:ss') : '-'}
        </span>
      )
    }

    case 'string':
      return <span {...inheritProps}>{value}</span>

    case 'toggle':
      if (value === null) return null

      return (
        <Toggle
          {...(value as ITogglePropsType)}
          mutation={props.mutation}
          color={props.color}
          values={props.values}
        />
      )

    case 'feedback-name':
      return <FeedbackName {...(value as IFeedbackNamePropsType)} />

    case 'feedback':
      return <Feedback {...(value as IFeedbackPropsType)} />

    case 'progress-bar':
      return <ProgressBar value={value as number} />

    case 'tracker':
      return <Tracker {...(value as ITrackerPropsType)} />

    case 'link':
      if (value === undefined || value === null) return null
      return <Link {...(value as ILinkPropsType)} />

    case 'tracker-completion-rate':
      return <TrackerCompletionRate value={value as number | string} />

    case 'delete-training-tracker':
      return (
        <DeleteTrainingTracker
          {...(value as IDeleteTrainingTrackerPropsType)}
        />
      )

    case 'tracker-name':
      return <TrackerName {...(value as ITrackerNamePropsType)} />

    case 'colored-text':
      if (value) {
        const { text, textColor } = value as IColoredText
        if (textColor === 'danger') {
          return <Text danger>{text}</Text>
        } else if (textColor === 'warning') {
          return <Text warning>{text}</Text>
        } else {
          return <Text color={textColor}>{text}</Text>
        }
      } else {
        return null
      }

    case 'delete-development-plan':
      return (
        <DeleteDevelopmentPlan
          {...(value as IDeleteDevelopmentPlanPropsType)}
        />
      )

    default:
      return null
  }
}

export type { ILinkPropsType, ITogglePropsType, IColoredText }
export default Format
