import _ from 'lodash'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricTrendsHook from '../utils/buildMetricTrendsHook'
import useRegionMetricTrendsData from './useRegionMetricTrendsData'

export const burgerworksTrendLocationGuestConfigs = {
  date: 'string',
  smgOverallSatisfactionRating: 'number',
  systemwideSmgOverallSatisfactionRating: 'number',
  coloradoRegionSmgOverallSatisfactionRating: 'number',
  texasRegionSmgOverallSatisfactionRating: 'number',
} as const

const useLocationMetricTrendsHook = buildMetricTrendsHook({
  groupFilterType: 'store',
  metrics: {
    codes: ['smg_overall_satisfaction_rating'],
  },
})

const useSystemwideMetricTrendsHook = buildMetricTrendsHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['smg_overall_satisfaction_rating'],
  },
})

const useTrendLocationGuestKpis = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''

  const { brand } = useBrands()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]

  const { data: locationData, loading: isLocationDataLoading } =
    useLocationMetricTrendsHook(dateRange)
  const { data: systemwideData, loading: isSystemwideDataLoading } =
    useSystemwideMetricTrendsHook(dateRange, {
      customLocationGroupIds: [brandLocationGroupId],
      shouldBypassRowLevelSecurity: true,
    })
  const { data: regionData, loading: isRegionDataLoading } =
    useRegionMetricTrendsData({
      metrics: ['smg_overall_satisfaction_rating'],
    })

  return {
    data: useMemo((): IApiDataType => {
      if (!locationData || !systemwideData || !regionData) return null

      const timeRangeDataMap: { [timeRange: string]: Record<string, unknown> } =
        {}

      systemwideData.forEach((details: any, index: number) => {
        const timeRange: string = details.date
        if (timeRange) {
          const dataAlreadyInMap = timeRangeDataMap[timeRange] || {}
          timeRangeDataMap[timeRange] = {
            ...dataAlreadyInMap,
            date: details.date,
            timeRangeIndex: index,
            systemwideSmgOverallSatisfactionRating:
              details.smgOverallSatisfactionRating,
          }
        }
      })

      regionData.forEach((details: any, index: number) => {
        const timeRange: string = details.date
        if (timeRange) {
          const dataAlreadyInMap = timeRangeDataMap[timeRange] || {}
          const regionName = _.camelCase(details.locationGroupName)
          timeRangeDataMap[timeRange] = {
            ...dataAlreadyInMap,
            date: details.date,
            timeRangeIndex: index,
            [`${regionName}RegionSmgOverallSatisfactionRating`]:
              details.smgOverallSatisfactionRating,
          }
        }
      })

      locationData.forEach((details: any) => {
        const timeRange: string = details.date
        if (timeRange) {
          const dataAlreadyInMap = timeRangeDataMap[timeRange] || {}
          timeRangeDataMap[timeRange] = {
            ...dataAlreadyInMap,
            ...details,
          }
        }
      })

      return _.orderBy(Object.values(timeRangeDataMap), 'timeRangeIndex')
    }, [locationData, systemwideData, regionData]),
    loading:
      isLocationDataLoading || isSystemwideDataLoading || isRegionDataLoading,
  }
}

export default useTrendLocationGuestKpis
