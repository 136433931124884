import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { BRAND_ID, BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType, IDataType } from '../types'

interface IQueryDataType {
  listDirectors: {
    nodes: {
      employeeId: number
      locationGroupId: number
      firstName: string
      lastName: string
    }[]
  }
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
      }[]
    }[]
  }
  getLocationAccessGroupsForBrand: {
    nodes: {
      id: number
      name: string
      type: string
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids: [number]
    brand_ids: [number]
  }
  iBrandId: number
}

const query = gql`
  query corporateGroupFilter($iFilter: JSON!, $iBrandId: Int!) {
    listDirectors(iFilter: $iFilter) {
      nodes {
        employeeId
        locationGroupId
        firstName
        lastName
      }
    }

    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }

    getLocationAccessGroupsForBrand(iBrandId: $iBrandId) {
      nodes {
        id
        name
        type
      }
    }
  }
`

const useJscBkGroupFilter = () => {
  const { brand } = useBrands()
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        iFilter: {
          location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
          brand_ids: [BRAND_ID[brand]],
        },
        iBrandId: BRAND_ID[brand],
      },
    },
  )
  const { search } = useLocation()

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const allStores = data.listLocationDetails.nodes.map((l) => {
        const director = data.listDirectors.nodes.find((d) =>
          l.locationGroups.some((lg) => lg.id === d.locationGroupId),
        )
        const queryObject = new URLSearchParams(search)

        queryObject.set('store', l.id.toString())

        const link = `/${brand}/store_detail?${queryObject.toString()}`

        return {
          id: l.id,
          name: `${l.code} - ${l.name}`,
          header: 'Stores',
          link,
          groupBy: director && {
            id: director.employeeId,
            header: 'District Leader',
            name: `${director.firstName} ${director.lastName}`.trim(),
          },
        }
      })

      const locationGroups = data?.getLocationAccessGroupsForBrand?.nodes || []
      const locationGroupTypes = [
        'Comp or Non-Comp',
        'Region',
        'District Leader',
      ]
      const values = [
        {
          id: 'store',
          parentId: 'root',
          label: `All Stores`,
          ids: [BRAND_LOCATION_GROUP_ID[brand]],
          list: allStores,
        },
        ...locationGroupTypes.map((name) => ({
          id: name,
          parentId: 'root',
          label: name,
        })),
        ...locationGroupTypes.map((name) => ({
          id: `${name}Breakdown`,
          parentId: name,
          label: `Show All ${name}s`,
          ids: locationGroups.filter((l) => l.type === name).map((d) => d.id),
          list: locationGroups
            .filter((l) => l.type === name)
            .map((d) => ({
              id: d.id,
              name: d.name,
              header: d.type,
            })),
        })),
        ...locationGroupTypes.map((name) => ({
          id: `${name}Select`,
          parentId: name,
          label: name,
        })),
        ...locationGroups.reduce((res, group) => {
          const type = group.type
          const id = group.id
          const name = group.name
          if (type === 'null' || type === 'Brand') {
            return [...res]
          }

          return [
            ...res,
            {
              id: id.toString(),
              parentId: `${type}Select`,
              ids: [id],
              label: name,
              list: data.listLocationDetails.nodes
                .filter((l) =>
                  l.locationGroups.some((lg) => lg.id === group.id),
                )
                .map((l) => {
                  const director = data.listDirectors.nodes.find((d) =>
                    l.locationGroups.some((lg) => lg.id === d.locationGroupId),
                  )
                  const queryObject = new URLSearchParams(search)

                  queryObject.set('store', l.id.toString())

                  const link = `/${brand}/store_detail?${queryObject.toString()}`

                  return {
                    id: l.id,
                    name: `${l.code} - ${l.name}`,
                    header: 'Stores',
                    link,
                    groupBy: director && {
                      id: director.employeeId,
                      header: 'District Leader',
                      name: `${director.firstName} ${director.lastName}`.trim(),
                    },
                  }
                }),
            },
          ]
        }, [] as IDataType[]),
      ]

      return {
        values,
        defaultValue: [values[0].id],
      }
    }, [data, brand, search]),
    loading,
  }
}

export default useJscBkGroupFilter
