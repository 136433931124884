import moment from 'moment'

import { WEEKDAYS } from 'pared/constants'
import {
  GROUP_BY_LAST_X_DAYS,
  GROUP_BY_LAST_X_PERIODS,
  GROUP_BY_LAST_X_WEEKS,
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  GROUP_BY_YEAR,
} from 'pared/data/getDateRanges'

export function checkDateInCurrentPeriod(
  date: string,
  latestPeriodStartDate: string,
  latestPeriodEndDate: string,
) {
  return moment(date).isBetween(
    moment(latestPeriodStartDate).subtract(1, 'day'),
    moment(latestPeriodEndDate).add(1, 'day'),
  )
}

export function getYearMonthDiff(
  earlierDate: moment.Moment,
  laterDate: moment.Moment,
) {
  const duration = moment.duration(laterDate.diff(earlierDate))
  const numYears = duration.years()
  const numMonths = duration.months()
  let durationStr = ''
  if (numYears > 1) {
    durationStr = `${numYears} years`
  } else if (numYears === 1) {
    durationStr = `1 year`
  }
  if (durationStr) {
    if (numMonths > 0) {
      durationStr += ' '
    }
  } else {
    if (numMonths < 1) {
      durationStr = 'less than 1 month'
    }
  }
  if (numMonths > 1) {
    durationStr += `${numMonths} months`
  } else if (numMonths === 1) {
    durationStr += `1 month`
  }
  return durationStr
}

export const getDateRangeLabel = (startDate: string, groupBy: string) => {
  const midPeriodDate = moment.utc(startDate).add(15, 'days')
  const formattedStartDate = moment.utc(startDate).format('M/D/YY')
  const weekEndDate = moment.utc(startDate).add(6, 'days').format('M/D/YY')
  const year = midPeriodDate.year()
  if (groupBy === GROUP_BY_YEAR) {
    return `Y${year}`
  } else if (groupBy === GROUP_BY_QUARTER) {
    const quarter = midPeriodDate.quarter()
    return `Q${quarter} ${year}`
  } else if (groupBy === GROUP_BY_LAST_X_WEEKS) {
    return `${formattedStartDate}-${weekEndDate}`
  } else if (groupBy === GROUP_BY_LAST_X_DAYS) {
    return formattedStartDate
  }

  const period = midPeriodDate.month() + 1
  return `P${period} ${year}`
}

export const getDateRangeLabelV2 = (
  groupBy: string,
  dateInfo: {
    groupByStartDate: string
    groupByEndDate: string
    businessWeek: string
    businessWeekOfMonth: string
    businessMonth: string
    businessQuarter: string
    businessYear: string
  },
) => {
  const {
    groupByStartDate,
    groupByEndDate,
    businessMonth,
    businessQuarter,
    businessWeekOfMonth,
    businessYear,
  } = dateInfo
  const formattedStartDate = moment.utc(groupByStartDate).format('M/D/YY')
  const formattedEndDate = moment.utc(groupByEndDate).format('M/D/YY')
  if (groupBy === GROUP_BY_YEAR) {
    return `Y${businessYear}`
  } else if (groupBy === GROUP_BY_QUARTER) {
    return `Q${businessQuarter} ${businessYear}`
  } else if (
    groupBy === GROUP_BY_LAST_X_PERIODS ||
    groupBy === GROUP_BY_PERIOD
  ) {
    return `P${businessMonth} ${businessYear}`
  } else if (groupBy === GROUP_BY_WEEK) {
    return `P${businessMonth} W${businessWeekOfMonth} ${businessYear}`
  } else if (groupBy === GROUP_BY_LAST_X_DAYS) {
    return formattedStartDate
  }

  return `${formattedStartDate}-${formattedEndDate}`
}

export const getDay = (date: string, format: string = 'YYYY-MM-DD') => {
  const dayIndex = moment(date).day()
  return WEEKDAYS[dayIndex]
}
