import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import useBrands from '../hooks/useBrands'
import LogoImage from './LogoImage'

const StyledSelect = styled(Select)`
  svg {
    display: none;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }
`

const BrandSelector = () => {
  const history = useHistory()
  const { brand, brands } = useBrands()

  return (
    <StyledSelect
      value={brand}
      onChange={(e) => {
        history.push(`/${e.target.value as string}`)
      }}
      renderValue={(value) => {
        return <LogoImage brandCode={value as string} isDark={false} />
      }}
      disableUnderline
      disabled={brands.length === 1}
    >
      {brands.map((code: string) => {
        return (
          <MenuItem key={code} value={code}>
            <LogoImage brandCode={code} isDark />
          </MenuItem>
        )
      })}
    </StyledSelect>
  )
}

export default React.memo(BrandSelector)
