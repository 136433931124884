import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import { useVariables } from '../variables'

export interface IPropsType {
  type: 'button'
  label: string
  disable?: string
  loading?: string
  onClick?: string
  hide?: `<%- ${string} %>`
  margin?: string
  width?: string
  color?: string
  backgroundColor?: string
}

interface IButtonType {
  disable?: boolean
  loading?: boolean
  onClick?: () => void
}

const SpinProgress = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 100%;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px;
  }
`

const StyledButton = styled.div<
  Pick<IPropsType, 'width' | 'margin' | 'color' | 'backgroundColor'> &
    Pick<IButtonType, 'disable' | 'loading'>
>`
  position: relative;
  flex: 1;
  display: inline-block;
  padding: 6px 16px;
  ${({ margin }) => (!margin ? '' : `margin: ${margin};`)}
  ${({ width }) => `width: ${width || '100%'};`}
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.Expo};
  color: ${({ color }) => color || 'white'};
  text-align: center;
  line-height: 1.75;
  border-radius: 5px;
  font-family: Lexend-Semibold;
  cursor: pointer;
  user-select: none;

  ${({ loading }) =>
    loading
      ? ''
      : `&:hover {
          opacity: 50%;
        }`}

  ${({ disable }) =>
    !disable
      ? ''
      : `
          color: rgba(0, 0, 0, 0.26);
          background-color: rgba(0, 0, 0, 0.12);
          cursor: default;
          pointer-events: none;
        `}
`

const Button = ({ label, hide, backgroundColor, ...props }: IPropsType) => {
  const { variables, template } = useVariables()
  const { disable, loading, onClick } = useMemo(
    (): IButtonType => ({
      disable: props.disable && _.get(variables, props.disable),
      loading: props.loading && _.get(variables, props.loading),
      onClick: props.onClick && _.get(variables, props.onClick),
    }),
    [props, variables],
  )

  if (hide && template(hide) !== 'false') return null

  return (
    <StyledButton
      {...props}
      backgroundColor={
        backgroundColor &&
        template(backgroundColor, { external: { colors: COLORS } })
      }
      onClick={loading || disable ? undefined : onClick}
      loading={loading}
      disable={disable}
    >
      {!loading ? (
        label
      ) : (
        <>
          <SpinProgress>
            <CircularProgress size={30} />
          </SpinProgress>

          {label}
        </>
      )}
    </StyledButton>
  )
}

export default React.memo(Button) as unknown as typeof Button
