import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBrand } from 'pared/utils/brand'
import { toUsdStr } from 'pared/utils/number'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IPurchaseSummaryNodeType {
  itemName: string
  itemCode: string
  displayParentCategoryName: string
  purchaseAmount: number
  expectedPurchaseAmount: number
  opportunityCost: number
}

interface ILocationCogePercentSummaryNodeType {
  actualCogsPercent: number
  cogsPercentPercentileRank: number
}

interface IPurchaseSummaryType {
  listLocationPurchaseData: {
    nodes: IPurchaseSummaryNodeType[]
  }
  locationCogsPercentSummary: {
    nodes: ILocationCogePercentSummaryNodeType[]
  }
}

const query = gql`
  query LocationPurchaseSummary(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listLocationPurchaseData(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemName
        itemCode
        displayParentCategoryName
        purchaseAmount
        expectedPurchaseAmount
        opportunityCost
      }
    }

    locationCogsPercentSummary(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        actualCogsPercent
        cogsPercentPercentileRank
      }
    }
  }
`

const useLocationPurchaseAiSummary = (): IApiDataType => {
  const metricCodes = ['purchase_amount', 'actual_cogs_percent']
  const brand = getBrand()
  const locationGroupIds = [BRAND_LOCATION_GROUP_ID[brand]]
  const { groupFilter } = useGroupFilter()
  const dateFilter = useDateFilter()
  const startDate = dateFilter.startDate
  const endDate = dateFilter.endDate
  const locationId = groupFilter?.ids?.[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { variables } = useVariables()
  const selectedItemOrCategory = useMemo(
    () => ({
      value: variables.items?.value,
      options: variables.items?.options,
    }),
    [variables],
  )
  const itemCategoryIds =
    parseInt(selectedItemOrCategory.value?.[0]?.[0]) || null
  const { data, loading } = useQuery<IPurchaseSummaryType>(query, {
    variables: {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: 'ITEM',
      iFilter: {
        location_group_ids: locationGroupIds,
        metrics: metricCodes,
        use_location_details: true,
        item_category_ids: [itemCategoryIds],
        bypass_row_level_security: true,
      },
    },
    skip: !startDate || !endDate || !groupFilter || !itemCategoryIds,
  })

  return {
    data: useMemo(() => {
      const purchaseData = data?.listLocationPurchaseData.nodes
      const summary = data?.locationCogsPercentSummary.nodes[0]

      const summaryMessage = (() => {
        if (!summary) return null

        if (summary.cogsPercentPercentileRank <= 33) {
          return `${locationName} is underperforming and has higher COGS% than most of your group.`
        } else if (summary.cogsPercentPercentileRank <= 66) {
          return `${locationName} is around average COGS% in your group.`
        } else {
          return `Good Job!  ${locationName} has one of the best COGS% in your group.`
        }
      })()

      const currentCategory =
        selectedItemOrCategory.options?.find(
          (o: any) => o.id == itemCategoryIds,
        )?.displayName || ''
      const improvementMessage = (() => {
        if (!purchaseData) return null

        const sortedOpportunityCostArray =
          purchaseData
            .filter((n) => n.opportunityCost > 0)
            ?.map((n) => ({
              itemName: n.itemName,
              opportunityCost: n.opportunityCost,
            }))
            ?.sort((a, b) => b.opportunityCost - a.opportunityCost)
            ?.slice(0, 3) || []

        if (sortedOpportunityCostArray.length === 0)
          return `${locationName} is purchasing ${currentCategory} items as expected for its sales. Keep up the good work!`

        const names = sortedOpportunityCostArray
          .map(({ itemName }, index) =>
            index === sortedOpportunityCostArray.length - 1 && index > 0
              ? `and <strong>${itemName}</strong>`
              : `<strong>${itemName}</strong>`,
          )
          .join(', ')
        const opportunity = sortedOpportunityCostArray.reduce(
          (sum, { opportunityCost }) => sum + opportunityCost,
          0,
        )

        return `${names} are being purchased in higher quantities than restaurants with similar sales.<br/>These items represent a <strong>${toUsdStr(
          opportunity,
        )}</strong> opportunity.`
      })()

      return {
        conclusion: summaryMessage,
        insight: improvementMessage,
      }
    }, [data]),
    loading,
  }
}

export default useLocationPurchaseAiSummary
