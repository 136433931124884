import moment from 'moment'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { BRAND_ID, BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../dateFilter'
import { CONSTANTS } from '../../variables/hooks/bbb/useBbbConstants'
import { IApiDataType, IDataType } from '../types'
import { useCorporateGroupFilterQuery } from './useCorporateFilter'

const buildBbbCorporateGroupFilterHook = (hookType: 'brand' | 'company') => {
  const useBbbCorporateGroupFilterHook = () => {
    const { brand } = useBrands()
    const locationGroupIds =
      hookType === 'company'
        ? // FIXME: should get the constants from the variables
          [CONSTANTS.COMPANY_STORE_LOCATION_GROUP_ID]
        : [BRAND_LOCATION_GROUP_ID[brand]]
    const directorLabel = getBusinessLabel('director')
    const { startDate, endDate } = useDateFilter()
    const {
      data: newData,
      loading,
      previousData,
    } = useCorporateGroupFilterQuery(
      {
        iFilter: {
          location_group_ids: locationGroupIds,
          brand_ids: [BRAND_ID[brand]],
        },
        iStartDate: startDate,
        iEndDate: endDate,
        iBrandId: BRAND_ID[brand],
      },
      !startDate || !endDate,
    )
    const data = newData || previousData
    const { search } = useLocation()

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const locationGroups = (
          data?.getLocationAccessGroupsForBrand?.nodes || []
        ).filter(
          (d) =>
            ![
              'Brand',
              'Custom',
              'null',
              'Company',
              'City',
              'Legal Entity',
              'Company (Period)',
              'All',
            ].includes(d.type),
        )
        const locationGroupTypes = locationGroups.reduce(
          (result, { type }) =>
            result.includes(type) ? result : [...result, type],
          [] as string[],
        )

        const allStores = data.listLocationDetails.nodes.map((l) => {
          const directors = data.listDirectors.nodes
            .filter((d) =>
              l.locationGroups.some((lg) => lg.id === d.locationGroupId),
            )
            .sort((a, b) => {
              const locationGroupA = l.locationGroups.find(
                (lg) => lg.id === a.locationGroupId,
              )
              const locationGroupB = l.locationGroups.find(
                (lg) => lg.id === b.locationGroupId,
              )

              if (!locationGroupA || !locationGroupB) return 0

              return moment
                .utc(locationGroupA.end_date)
                .isSameOrBefore(moment.utc(locationGroupB.end_date))
                ? 1
                : -1
            })
          const director = directors[0]
          const queryObject = new URLSearchParams(search)

          queryObject.set('store', l.id.toString())

          const link = `/${brand}/store_detail?${queryObject.toString()}`
          const locationGroup = locationGroups.find(
            (lg) => lg.id === director?.locationGroupId,
          )

          return {
            id: l.id,
            name: `${l.code} - ${l.name}`,
            header: 'Stores',
            link,
            groupBy: director && {
              id: director.employeeId,
              header: locationGroup?.type || directorLabel,
              name: `${director.firstName} ${director.lastName}`.trim(),
            },
          }
        })

        const cities = locationGroups
          .filter((lg) => lg.type === 'State')
          .map((lg) => {
            const cityLocationGroups = data.listLocationDetails.nodes
              .filter((l) => l.locationGroups.some((llg) => llg.id === lg.id))
              .map((l) => l.locationGroups.filter((llg) => llg.type === 'City'))
              .flat()
              .reduce((result, { id, name }) => {
                if (result.some((d) => d.id === id.toString())) return result

                return [
                  ...result,
                  {
                    id: id.toString(),
                    parentId: lg.id.toString(),
                    ids: [id],
                    label: name,
                    list: data.listLocationDetails.nodes
                      .filter((l) =>
                        l.locationGroups.some((lg) => lg.id === id),
                      )
                      .map((l) => {
                        const director = data.listDirectors.nodes.find((d) =>
                          l.locationGroups.some(
                            (lg) => lg.id === d.locationGroupId,
                          ),
                        )
                        const locationGroup = locationGroups.find(
                          (lg) => lg.id === director?.locationGroupId,
                        )
                        const queryObject = new URLSearchParams(search)

                        queryObject.set('store', l.id.toString())

                        const link = `/${brand}/store_detail?${queryObject.toString()}`

                        return {
                          id: l.id,
                          name: `${l.code} - ${l.name}`,
                          header: 'Stores',
                          link,
                          groupBy: director && {
                            id: director.employeeId,
                            header: locationGroup?.type || directorLabel,
                            name: `${director.firstName} ${director.lastName}`.trim(),
                          },
                        }
                      }),
                  },
                ]
              }, [] as IDataType[])

            return [
              {
                id: `${lg.name} All Stores`,
                parentId: lg.id.toString(),
                label: `All Stores in ${lg.name}`,
                ids: cityLocationGroups.map((c) => c.ids).flat(),
                list: cityLocationGroups.map((c) => c.list).flat(),
              },
              {
                id: `${lg.name} Breakdown`,
                parentId: lg.id.toString(),
                label: `Breakdown By ${lg.name}`,
                ids: cityLocationGroups.map((c) => c.ids).flat(),
                list: cityLocationGroups.map((c) => ({
                  id: parseInt(c.id),
                  name: c.label,
                  header: 'City',
                })),
              },
              ...cityLocationGroups,
            ]
          })
          .flat()

        const values = [
          {
            id: 'all-stores',
            parentId: 'root',
            ids: locationGroupIds,
            label: 'All Stores',
            list: allStores,
          },
          ...(hookType === 'brand'
            ? []
            : [
                {
                  id: 'opened-stores',
                  parentId: 'root',
                  label: 'Opened Stores',
                },
              ]),
          ...locationGroupTypes.map((locationGroupType) => [
            {
              id: locationGroupType,
              parentId: hookType === 'brand' ? 'root' : 'opened-stores',
              label: locationGroupType,
            },
            {
              id: `${locationGroupType} Breakdown`,
              parentId: locationGroupType,
              label: `Breakdown By ${locationGroupType}`,
              ids: locationGroups
                .filter((l) => l.type === locationGroupType)
                .map((d) => d.id),
              list: locationGroups
                .filter((l) => l.type === locationGroupType)
                .map((d) => ({
                  id: d.id,
                  name: d.name,
                  header: d.type,
                })),
            },
          ]),
          locationGroups.map(({ id, type, name }) => ({
            id: id.toString(),
            parentId: type,
            ids: [id],
            label: name,
            list: data.listLocationDetails.nodes
              .filter((l) => l.locationGroups.some((lg) => lg.id === id))
              .map((l) => {
                const director = data.listDirectors.nodes.find((d) =>
                  l.locationGroups.some((lg) => lg.id === d.locationGroupId),
                )
                const locationGroup = locationGroups.find(
                  (lg) => lg.id === director?.locationGroupId,
                )
                const queryObject = new URLSearchParams(search)

                queryObject.set('store', l.id.toString())

                const link = `/${brand}/store_detail?${queryObject.toString()}`

                return {
                  id: l.id,
                  name: `${l.code} - ${l.name}`,
                  header: 'Stores',
                  link,
                  groupBy: director && {
                    id: director.employeeId,
                    header: locationGroup?.type || directorLabel,
                    name: `${director.firstName} ${director.lastName}`.trim(),
                  },
                }
              }),
          })),
          ...cities,
        ].flat()
        const compOrNonComp = values.find(
          (v) => v.id === 'Comp or Non-Comp Breakdown',
        )
        const comp = values.find((v) => v.label === 'Comp')
        const nonComp = values.find((v) => v.label === 'Non-Comp')
        const hasAllStores = values
          .filter((v) => /All Stores/.test(v.label))
          .map((v) => v.parentId)

        return {
          defaultValue: [values[0].id],
          values: values
            .map((v) => {
              if (
                !compOrNonComp ||
                !comp ||
                !('ids' in comp) ||
                !nonComp ||
                !('ids' in nonComp) ||
                ['root', 'opened-stores', compOrNonComp.parentId].includes(
                  v.parentId,
                ) ||
                /Breakdown By/.test(v.label) ||
                hasAllStores.includes(v.id)
              )
                return v

              const isAllStores = /All Stores/.test(v.label)

              return [
                isAllStores
                  ? v
                  : [
                      {
                        id: `${v.id} root`,
                        parentId: v.parentId,
                        label: v.label,
                      },
                      {
                        ...v,
                        parentId: `${v.id} root`,
                        label: `All Stores in ${v.label}`,
                      },
                    ],
                {
                  ...compOrNonComp,
                  id: `${v.id} ${compOrNonComp?.id}`,
                  parentId: isAllStores ? v.parentId : `${v.id} root`,
                  intersectedIds: 'ids' in v ? v.ids : undefined,
                },
                {
                  ...v,
                  id: `${v.id} Comp`,
                  parentId: isAllStores ? v.parentId : `${v.id} root`,
                  label: comp.label,
                  intersectedIds: comp.ids,
                  list:
                    'ids' in v
                      ? (v as IDataType).list?.filter((l) =>
                          comp.list?.some((c) => c?.id === l.id),
                        )
                      : undefined,
                },
                {
                  ...v,
                  id: `${v.id} Non-Comp`,
                  parentId: isAllStores ? v.parentId : `${v.id} root`,
                  label: nonComp.label,
                  intersectedIds: nonComp.ids,
                  list:
                    'list' in v
                      ? (v as IDataType).list?.filter((l) =>
                          nonComp.list?.some((c) => c?.id === l.id),
                        )
                      : undefined,
                },
              ].flat()
            })
            .flat(),
        }
      }, [data, brand, directorLabel, search]),
      loading,
    }
  }

  return useBbbCorporateGroupFilterHook
}

export const useBbbCorporateFilter = buildBbbCorporateGroupFilterHook('brand')
export const useBbbPnlCorporateFilter =
  buildBbbCorporateGroupFilterHook('company')
