import calc from '../../../utils/calc'
import { corporateGroupTableConfigs } from '../../../variables'
import buildItemMetricValuesHook from '../utils/buildItemMetricValuesHook'

const commonInventoryConfigs = {
  itemName: 'string',
  displayParentCategoryName: 'string',
  locationId: 'string',
  actualCogs: 'price',
  idealCogs: 'price',
  cogsVariance: 'price',
  totalNetSales: 'price',
  actualCogsPercent: 'percent',
  idealCogsPercent: 'percent',
  cogsVariancePercent: 'percent',
} as const

export const burgerworksLocationGroupInventoryConfigs = {
  ...corporateGroupTableConfigs,
  ...commonInventoryConfigs,
}

const metricCodes = [
  'actual_cogs',
  'ideal_cogs',
  'cogs_variance',
  'total_net_sales',
  'actual_cogs_percent',
  'ideal_cogs_percent',
  'cogs_variance_percent',
]

const useBurgerworksLocationGroupInventory = buildItemMetricValuesHook(
  {
    metrics: metricCodes,
    hasSummary: true,
    includeLocations: true,
  },
  (data) => ({
    ...data,
    actualCogsPercent: calc(data.actualCogsPercent, '*', 100),
    idealCogsPercent: calc(data.idealCogsPercent, '*', 100),
    cogsVariancePercent: calc(data.cogsVariancePercent, '*', 100),
  }),
)

export default useBurgerworksLocationGroupInventory
