import COLORS from 'pared/constants/colors'
import { ILossPreventionConfigType } from 'pared/pages/CorporateLossPrevention/hooks/useLossPreventionConfig'

const cashControlKpiName = {
  cashOverOrShort: 'Cash +/-',
  overRing: 'Over Ring',
  deletes: 'Voids',
}

export const bibibopConfig: ILossPreventionConfigType = {
  kpiSettings: [
    { category: 'basic', key: 'compsTotalAmount', title: 'Total Discounts' },
    { category: 'basic', key: 'compsPercent', title: 'Discounts %' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Voids',
      title: 'Voids',
      key: 'amount',
    },
  ],

  discountTable: {
    isVisible: true,
    isTotalCountVisible: false,
    isTotalAmountVisible: true,
    isCompsPercentVisible: true,

    discountColumns: [
      {
        key: 'Coupons',
        title: 'Coupons',
        isCountVisible: false,
        isAmountVisible: true,
        isCompsPercentVisible: true,
      },
      {
        key: 'Employee Discount',
        title: 'Employee Discount',
        isCountVisible: false,
        isAmountVisible: true,
        isCompsPercentVisible: true,
      },
    ],
  },

  discountTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Discount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'Coupons',
        name: 'Coupons',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.DarkOrchid,
        hidden: true,
      },
      {
        key: 'Employee Discount',
        name: 'Employee Discount',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Mango,
        hidden: true,
      },
    ],
  },

  cashControlTable: {
    isVisible: true,
    cashControlColumns: [
      {
        key: 'cashOverOrShort',
        title: 'Cash +/-',
        width: '150px',
        children: [
          {
            key: 'cashOverOrShort.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Voids',
        title: 'Voids',
        width: '150px',
        children: [
          {
            key: 'Voids.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
    ],
  },

  cashControlTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Amount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'cashOverOrShort',
        name: `${cashControlKpiName.cashOverOrShort} $`,
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
      },
      {
        key: 'Voids',
        name: `${cashControlKpiName.deletes} $`,
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
    ],
  },
}
