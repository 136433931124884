import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { BRAND_LOGOS, BRAND_NAME } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getIsProductionBrand } from 'pared/customer/general'
import ParedLogo from 'pared/images/Expo/expo-logo-blue.png'
import { generateEnterKeyPressedHandler } from 'pared/utils/keyboard'

import useConfig from './hooks/useConfig'

interface IProps {
  isLoading: boolean
  onSignIn: (email: string, password: string) => Promise<void>
  errorMessage: string
  brand: string
}

function WebLargeMain({ isLoading, onSignIn, errorMessage, brand }: IProps) {
  const config = useConfig()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const location = useLocation()

  const onEmailChange = (event: any) => {
    setEmail(event.target.value)
  }

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value)
  }

  const onClickSignInButton = () => {
    onSignIn(email, password)
  }

  const onEnterKeyPressed = generateEnterKeyPressedHandler(onClickSignInButton)

  const logo = BRAND_LOGOS[brand].dark ? (
    <LogoImg src={BRAND_LOGOS[brand].dark} alt={`${BRAND_NAME[brand]} Logo`} />
  ) : null

  return (
    <OuterContainer>
      <InnerContainer>
        <LogoContainer>{logo}</LogoContainer>
        <SignInForm>
          <StyledTextField
            type="email"
            label="Email"
            size="medium"
            fullWidth={true}
            onChange={onEmailChange}
            onKeyPress={onEnterKeyPressed}
            inputProps={{
              'data-cy': 'email',
            }}
          />
          <br />
          <br />
          <StyledTextField
            type="password"
            label="Password"
            size="medium"
            fullWidth={true}
            onChange={onPasswordChange}
            onKeyPress={onEnterKeyPressed}
            inputProps={{
              'data-cy': 'password',
            }}
          />
          <br />
          <br />
          <br />
          {isLoading ? (
            <SubmittingButton>Signing In ...</SubmittingButton>
          ) : (
            <SignInButton
              disabled={isLoading}
              onClick={onClickSignInButton}
              data-cy="sign-in"
            >
              Sign In
            </SignInButton>
          )}
        </SignInForm>

        {errorMessage ? (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        ) : null}

        {!config.hideForgotPassword ? (
          <ForgetPasswordDiv>
            <StyledLink
              to={{
                pathname: navigator.forgotPassword(brand),
                state: location.state,
              }}
            >
              Forgot Password?
            </StyledLink>
          </ForgetPasswordDiv>
        ) : null}

        <PowerdByContainer>
          <PowerdByText>powered by</PowerdByText>
        </PowerdByContainer>

        <ParedLogoContainer>
          <ParedLogoImg src={ParedLogo} alt="Pared" />
        </ParedLogoContainer>
      </InnerContainer>
    </OuterContainer>
  )
}

const StyledTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#0071E3',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0071E3',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#0071E3',
      },
    },
  },
})(TextField)

const OuterContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: table;
`

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`

const LogoContainer = styled.div`
  padding: 0 0 30px 0;
`

const LogoImg = styled.img`
  width: 170px;
`

const SignInForm = styled.div`
  display: inline-block;
  width: 250px;
`

const ErrorMessageContainer = styled.div`
  color: red;
  padding: 20px 0 0 0;
`

const SignInButton = styled.button`
  height: 41px;
  width: 141px;
  background-color: ${COLORS.Expo};
  color: ${COLORS.Salt};
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border: none;
  cursor: pointer;
`

const SubmittingButton = styled.button`
  height: 41px;
  width: 141px;
  background-color: ${COLORS.Expo};
  color: ${COLORS.Salt};
  opacity: 0.7;
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border: none;
`

const StyledLink = styled(Link)`
  color: #07a4c7;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const PowerdByContainer = styled.div`
  text-align: center;
  padding: 120px 0 0 0;
`

const PowerdByText = styled.div`
  display: inline-block;
  width: 300px;
  padding: 20px 0 0 0;
  border-top: 1px solid #bcbcbc;
  font-family: Lexend-Regular;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
`

const ParedLogoContainer = styled.div`
  padding: 8px 0 0 0;
`

const ParedLogoImg = styled.img`
  width: 80px;
`

const ForgetPasswordDiv = styled.div`
  padding: 60px 0 0 0;
  font-size: 12px;
  color: ${COLORS.Steel};
`

export default WebLargeMain
