import { gql, useQuery } from '@apollo/client'

export interface IQueryDataType {
  listGlCodes: {
    nodes: {
      code: string
      displayOrder: number
      id: string
      name: string
      parentGroupId: string
    }[]
  }
  listGlGroups: {
    nodes: {
      displayOrder: number
      exrayCategory: string
      id: string
      name: string
      parentGroupId: string
    }[]
  }
}

const query = gql`
  query ListGlCodesAndGroups {
    listGlCodes {
      nodes {
        id
        code
        name
        parentGroupId
        displayOrder
      }
    }

    listGlGroups {
      nodes {
        id
        name
        parentGroupId
        displayOrder
        exrayCategory
      }
    }
  }
`

const useListGlCodesAndGroups = () => {
  return useQuery<IQueryDataType>(query)
}

export default useListGlCodesAndGroups
