import { IRouteType } from '../../types'
import pnl from './pnl'
import pnlConfig from './pnlConfig'

export const routes: IRouteType[] = [
  {
    title: 'P&L',
    children: [
      {
        title: 'Expo AI P&L',
        link: '/:brand/pnl',
        default: true,
      },
      {
        title: 'Config',
        link: '/:brand/pnl-config',
        hideFromNavMenu: true,
      },
    ],
  },
]

export default [pnl, pnlConfig]
