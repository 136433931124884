import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import useLocationMetricValuesQuery, {
  IVariablesType,
} from '../../../hooks/useLocationMetricValuesQuery'
import calc from '../../../utils/calc'
import format from '../../../utils/format'
import { allStoresTableConfigs, useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IQueryDataType {
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
        type: string
      }[]
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids?: number[]
    location_group_types?: string[]
  }
  iStartDate?: string
  iEndDate?: string
}

const query = gql`
  query corporateGroupFilter(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationDetails: listLocationDetailsV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }
  }
`

export const salesAlertDashboardConfig = {
  ...allStoresTableConfigs,
  market: 'string',
  check: 'number',
  sales: 'price',
  sdlwSales: 'price',
  wowSales: 'percent',
  marketWowSales: 'percent',
  varianceToMarket: 'percent',
} as const

const variablesHandler = (variables: IVariablesType) => ({
  variables,
  skip:
    !variables.iStartDate ||
    !variables.iEndDate ||
    (!variables.iFilter.location_group_ids &&
      !variables.iFilter.location_group_types) ||
    (variables.iFilter.metrics || []).length === 0,
})

const useSalesAlertDashboardQuery = (type: 'allStores' | 'market') => {
  const { variables } = useVariables()
  const orderMode = variables.selectOptions?.orderMode.value[0]?.[0]
  const { data, loading } = useLocationMetricValuesQuery({
    groupFilterTypes: ['listLocation', 'listLocationGroup'],
    metrics: !orderMode
      ? []
      : ['sales', 'checks'].map((t) => `${t}_${orderMode}`),
    fields: ['metricData'],
    handler: (prevVariables) =>
      variablesHandler({
        ...prevVariables,
        iFilter: {
          ...prevVariables.iFilter,
          ...(type === 'allStores'
            ? {
                location_group_ids: variables.allStores?.locationGroupIds,
              }
            : {
                location_group_types: ['Market'],
              }),
        },
        isLocationGroup: type !== 'allStores',
      }),
  })
  const { data: sdlwData, loading: sdlwLoading } = useLocationMetricValuesQuery(
    {
      groupFilterTypes: ['listLocation', 'listLocationGroup'],
      metrics: !orderMode ? [] : [`sales_${orderMode}`],
      fields: ['metricData'],
      handler: (prevVariables) =>
        variablesHandler({
          ...prevVariables,
          iFilter: {
            ...prevVariables.iFilter,
            ...(type === 'allStores'
              ? {
                  location_group_ids: variables.allStores?.locationGroupIds,
                }
              : {
                  location_group_types: ['Market'],
                }),
          },
          isLocationGroup: type !== 'allStores',
          iStartDate:
            prevVariables.iStartDate &&
            moment
              .utc(prevVariables.iStartDate)
              .subtract(1, 'weeks')
              .format('YYYY-MM-DD'),
          iEndDate:
            prevVariables.iEndDate &&
            moment
              .utc(prevVariables.iEndDate)
              .subtract(1, 'weeks')
              .format('YYYY-MM-DD'),
        }),
    },
  )

  return {
    data: useMemo(
      () =>
        data?.map((d) => {
          const id = 'locationId' in d ? d.locationId : d.locationGroupId
          const sdlwD = sdlwData?.find((s) =>
            'locationId' in s ? s.locationId === id : s.locationGroupId === id,
          )

          return {
            ...format({
              ...d.metricData,
              ..._.mapKeys(sdlwD?.metricData || {}, (_, key) => `sdlw_${key}`),
            }),
            id,
          } as Record<string, number> & {
            id: number
          }
        }),
      [data, sdlwData],
    ),
    loading: loading || sdlwLoading,
  }
}

const useSalesAlertDashboard = () => {
  const { variables } = useVariables()
  const orderMode = variables.selectOptions?.orderMode.value[0]?.[0]
  const { data, loading } = useSalesAlertDashboardQuery('allStores')
  const { data: marketData, loading: marketLoading } =
    useSalesAlertDashboardQuery('market')
  const { data: locationData, loading: locationLoading } = useQuery<
    IQueryDataType,
    IQueryVariablesType
  >(query, {
    variables: {
      iFilter: {
        location_group_ids: variables.allStores?.locationGroupIds,
      },
      iStartDate: variables.date?.value.dateRange.startDateStr,
      iEndDate: variables.date?.value.dateRange.endDateStr,
    },
    skip:
      !variables.date?.value.dateRange.startDateStr ||
      !variables.date.value.dateRange.endDateStr ||
      !variables.allStores?.locationGroupIds,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      return data.map((d) => {
        const sales = d[_.camelCase(`sales_${orderMode}`)]
        const sdlwSales = d[_.camelCase(`sdlw_sales_${orderMode}`)]
        const wowSales = calc(
          calc(calc(sales, '/', sdlwSales), '-', 1),
          '*',
          100,
        )

        const locationGroups = locationData?.listLocationDetails.nodes.find(
          (n) => n.id === d.id,
        )?.locationGroups
        const market = locationGroups?.find((l) => l.type === 'Market')
        const marketD = marketData?.find((md) => md.id === market?.id)
        const marketWowSales = calc(
          calc(
            calc(
              marketD?.[_.camelCase(`sales_${orderMode}`)],
              '/',
              marketD?.[_.camelCase(`sdlw_sales_${orderMode}`)],
            ),
            '-',
            1,
          ),
          '*',
          100,
        )

        // FIXME: should use new variables.groupFilter
        // Use this way to overwirte group by info
        const dm = locationGroups?.find((lg) => lg.type === 'District Manager')

        return {
          ...variables.allStores?.locations[d.id].tableRow,
          groupBy: dm && {
            ...variables.allStores?.locations[d.id].tableRow.groupBy,
            id: dm?.id,
            label: dm?.name,
          },
          id: d.id.toString(),
          parentId: 'root',
          market: market?.name,
          check: d[_.camelCase(`checks_${orderMode}`)],
          sales,
          sdlwSales,
          wowSales,
          marketWowSales,
          varianceToMarket: calc(wowSales, '-', marketWowSales),
        }
      })
    }, [data, marketData, locationData, variables, orderMode]),
    loading: loading || marketLoading || locationLoading,
  }
}

export default useSalesAlertDashboard
