import ExpoAiLogo from 'pared/images/Expo/expoai.png'

import { IConfigsType } from '../../types'

const fwWingstopExpoAi: IConfigsType = {
  fw_wingstop: {
    '/:brand/expo_ai': {
      groupFilter: {
        api: 'corporateWithoutBreakdownFilter',
      },
      title: {
        type: 'title',
        title: 'Expo AI',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exray: {
        type: 'ai-summary',
        fields: [
          {
            type: 'uLossPrevention',
            details: [
              {
                key: 'uLossPrevention',
                api: 'farwestCorporateExpoAiLossPreventionSummary',
                rowKey: 'type',
                columns: [
                  {
                    key: 'issueType',
                    title: 'Issue',
                    sortable: true,
                    actionName: 'aiSummary',
                  },
                  {
                    key: 'storeCount',
                    title: 'Store Count',
                    sortable: true,
                  },
                  {
                    key: 'annualOpportunityCost',
                    title: 'Annual Opportunity Cost',
                    sortable: true,
                  },
                ],
              },
              {
                key: 'uLossPreventionLocationDetails',
                api: 'farwestCorporateExpoAiLossPreventionDetails',
                rowKey: 'groupInfo.id',
                hide: '<%- !aiSummary.uLossPrevention?.clickedId %>',
                pageSize: 10,
                groupInfo: {
                  width: '200px',
                  groupBy: {
                    width: '200px',
                  },
                },
                columns: [
                  {
                    key: 'annualOpportunityCost',
                    title: 'Discounting - Annual Opportunity Cost',
                    sortable: true,
                    hide: '<%- aiSummary.uLossPrevention?.clickedId !== "20%" && aiSummary.uLossPrevention?.clickedId !== "10%" && aiSummary.uLossPrevention?.clickedId !== "5%" %>',
                  },
                  {
                    key: 'voidCount',
                    title: 'Void Count',
                    sortable: true,
                    hide: '<%- aiSummary.uLossPrevention?.clickedId !== "void" %>',
                  },
                  {
                    key: 'voidCountPercent',
                    title: 'Void Count %',
                    sortable: true,
                    hide: '<%- aiSummary.uLossPrevention?.clickedId !== "void" %>',
                  },
                  {
                    key: 'cashOverOrShort',
                    title: 'Large Cash Over/Short',
                    sortable: true,
                    hide: '<%- aiSummary.uLossPrevention?.clickedId !== "cash_over_or_short" %>',
                  },
                  {
                    key: 'discountOutliers',
                    title: 'Discounts > $50',
                    sortable: true,
                    hide: '<%- aiSummary.uLossPrevention?.clickedId !== "discountOutliers" %>',
                  },
                  {
                    key: 'voidOutliers',
                    title: 'Voids > $50',
                    sortable: true,
                    hide: '<%- aiSummary.uLossPrevention?.clickedId !== "voidOutliers" %>',
                  },
                ],
              },
            ],
          },
          {
            type: 'fwWingstopCorporateGuest',
            details: [
              {
                key: 'uSmg',
                api: 'corporateExpoAiSmgSummary',
                rowKey: 'metricCode',
                columns: [
                  {
                    key: 'metricName',
                    title: 'Top Problem',
                    sortable: true,
                    width: '220px',
                    actionName: 'aiSummary',
                  },
                  {
                    key: 'storeCount',
                    title: 'Store Count',
                    sortable: true,
                    width: '220px',
                  },
                ],
              },
              {
                key: 'uSmgLocationDetails',
                api: 'corporateExpoAiSmgLocationDetails',
                rowKey: 'groupInfo.id',
                defaultSortedField: 'metricValueInPercent',
                defaultSortedDirection:
                  '<%- aiSummary.uSmg?.value === "Dissatisfaction" ? "descend" : "ascend" %>',
                pageSize: 10,
                hide: '<%- !aiSummary.uSmg?.clickedId %>',
                groupInfo: {
                  width: '220px',
                  groupBy: {
                    width: '220px',
                  },
                },
                columns: [
                  {
                    key: 'metricValueInPercent',
                    title: '<%- aiSummary.uSmg?.value %>',
                    sortable: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopExpoAi
