import { IConfigsType } from '../../types'

const fwUploadReportConfig: IConfigsType = {
  'fw_wingstop-admin': {
    '/:brand/upload-reports': {
      disableAutoMargin: true,
      variables: ['farwestUploadReport'],
      title: {
        type: 'typography',
        apiOrContent: 'Upload Reports',
      },
      upload: {
        type: 'grid',
        layout: 'middle',
        margin: '50px 0px 0px',
        children: {
          container: {
            type: 'grid',
            layout: 'vertical',
            width: '100%',
            maxWidth: '600px',
            children: {
              reportType: {
                type: 'select',
                value: 'selectOptions.uploadReportType.value',
                options: 'selectOptions.uploadReportType.options',
                onChange: 'selectOptions.uploadReportType.onChange',
                width: '100%',
              },
              fileUpload: {
                type: 'input',
                value: 'inputValue.file.value',
                onChange: 'inputValue.file.onChange',
                margin: '12px 0px 0px',
                width: '100%',
                inputType: 'file',
              },
              errorMessage: {
                type: 'typography',
                apiOrContent: '<%- uploadReport.errorMessage %>',
                hide: '<%- !uploadReport.errorMessage %>',
                variant: 'body2',
                margin: '4px 0px 0px',
                color: '#d32f2f',
              },
              submit: {
                type: 'button',
                label: 'UPLOAD',
                disable: 'uploadReport.disable',
                loading: 'uploadReport.loading',
                onClick: 'uploadReport.onClick',
                margin: '12px 0px 0px',
                width: 'initial',
              },
            },
          },
        },
      },
      overwriteConfirm: {
        type: 'modal',
        open: 'uploadReport.overwriteConfirm.open',
        onClose: 'uploadReport.overwriteConfirm.onClose',
        children: {
          title: {
            type: 'typography',
            apiOrContent: 'Confirm Overwrite',
          },
          content: {
            type: 'typography',
            apiOrContent:
              'The <%- selectOptions.uploadReportType.displayName[0] %> report of today already exists. Do you want to overwrite it?',
            variant: 'body1',
          },
          buttons: {
            type: 'grid',
            margin: '40px 0px 0px',
            gap: '8px',
            children: {
              cancel: {
                type: 'button',
                label: 'Cancel',
                backgroundColor: '#cfcece',
                color: 'black',
                onClick: 'uploadReport.overwriteConfirm.onClose',
              },
              overwrite: {
                type: 'button',
                label: 'Overwrite',
                onClick: 'uploadReport.overwriteConfirm.submit',
              },
            },
          },
        },
      },
      uploadStatusTitle: {
        type: 'typography',
        apiOrContent: 'Upload Events',
        margin: '50px 0px 0px',
      },
      uploadStatus: {
        type: 'table',
        api: 'farwestUploadReportStatus',
        pageSize: 30,
        columns: [
          {
            key: 'time',
            format: 'M/D/YYYY, hh:mm A',
            timezone: -7,
            title: 'Event At (PST)',
          },
          {
            key: 'report',
            title: 'Report Name',
          },
          {
            key: 'status',
            title: 'Status',
          },
          {
            key: 'message',
            title: 'Notes',
          },
        ],
      },
    },
  },
}

export default fwUploadReportConfig
