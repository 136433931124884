import { useMemo, useState } from 'react'

export interface IOptionType {
  fields: string[]
}

export interface IDataType {
  inputValue?: Record<
    string,
    {
      value?: string | HTMLInputElement['files']
      onChange: (value: string | HTMLInputElement['files']) => void
    }
  >
}

const useInputValue = ({ fields }: IOptionType): IDataType => {
  const defaultValues = useMemo(
    () =>
      fields.reduce(
        (result, field) => ({
          ...result,
          [field]: '',
        }),
        {} as Record<string, string | HTMLInputElement['files']>,
      ),
    [fields],
  )
  const inputHook = useState(defaultValues)

  return {
    inputValue: useMemo(
      () =>
        fields.reduce(
          (result, field) => ({
            ...result,
            [field]: {
              value: inputHook[0][field],
              onChange: (value: string | HTMLInputElement['files']) =>
                inputHook[1]((prev) => ({
                  ...prev,
                  [field]: value,
                })),
            },
          }),
          {},
        ),
      [fields, inputHook],
    ),
  }
}

export default useInputValue
