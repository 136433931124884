import { useVariables } from '../../variables'
import { IApiType } from '../types'
import { api as bbbApi } from './bbb'

export type IApiKeyType = keyof typeof api

const api = {
  ...bbbApi,
}

const apiKeys = Object.keys(api) as (keyof typeof api)[]

const isApi = (
  apiOrContent: IApiKeyType | string,
): apiOrContent is IApiKeyType => apiKeys.includes(apiOrContent as IApiKeyType)

const useApi = (apiOrContent: IApiKeyType | string): IApiType => {
  const { template } = useVariables()
  const data = template(apiOrContent)

  return isApi(apiOrContent) ? api[apiOrContent]() : { data, loading: false }
}

export default useApi
