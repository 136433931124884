import CustomizedReport from 'pared/components/CustomizedReport'

interface IProps {
  locationGroupId: number
  locationGroupType: string | null
  startDate: string
  endDate: string
}

const KpiBar = ({
  locationGroupId,
  locationGroupType,
  startDate,
  endDate,
}: IProps) => {
  const inputParams = {
    locationGroupId,
    locationGroupType,
    startDate,
    endDate,
  }

  return (
    <>
      <CustomizedReport
        title="Real-Time KPIs"
        reportName="CORPORATE_CALLS_KPIS"
        inputParams={inputParams}
      />
    </>
  )
}

export default KpiBar
