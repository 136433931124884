import { useMemo } from 'react'

import { useGroupFilter } from '../../../groupFilter'
import useLocationMetricValuesQuery from '../../../hooks/useLocationMetricValuesQuery'
import calc, { LOADING } from '../../../utils/calc'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

type CalcType = null | number | typeof LOADING
interface IRowType {
  [key: string]: unknown
  id: string
  parentId: string
  displayOrder?: number
  pAndLGlCode?: string
  // All below are optional?
  isFrozen?: boolean
  pAndLName: string
  pAndLSales: CalcType
  pAndLSalesPercent: CalcType
  pAndLYoySales: CalcType
  pAndLYoySalesPercent: CalcType
  pAndLYoyCompare: CalcType
  pAndLYoyComparePercent: CalcType
  pAndLYtdSales: CalcType
  pAndLYtdSalesPercent: CalcType
  pAndLLYYtdSales: CalcType
  pAndLLYYtdSalesPercent: CalcType
  pAndLLYYtdCompare: CalcType
  pAndLLYYtdComparePercent: CalcType
  pAndLPriorPeriodSales: CalcType
  pAndLPriorPeriodSalesPercent: CalcType
  pAndLPriorPeriodCompare: CalcType
  pAndLPriorPeriodComparePercent: CalcType
}

export const pAndLConfigs = {
  pAndLName: 'string',
  pAndLGlCode: 'string',
  pAndLCategory: 'string',
  pAndLType: 'string',
  pAndLSales: 'price',
  pAndLSalesPercent: 'percent',
  pAndLBudget: 'price',
  pAndLBudgetPercent: 'percent',
  pAndLCompare: 'price',
  pAndLComparePercent: 'percent',
  pAndLYoySales: 'price',
  pAndLYoySalesPercent: 'percent',
  pAndLYoyCompare: 'price',
  pAndLYoyComparePercent: 'percent',
  pAndLYtdSales: 'price',
  pAndLYtdSalesPercent: 'percent',
  pAndLYtdBudget: 'price',
  pAndLYtdBudgetPercent: 'percent',
  pAndLYtdCompare: 'price',
  pAndLYtdComparePercent: 'percent',
  pAndLLYYtdSales: 'price',
  pAndLLYYtdSalesPercent: 'percent',
  pAndLLYYtdCompare: 'price',
  pAndLLYYtdComparePercent: 'percent',
  pAndLPriorPeriodSales: 'price',
  pAndLPriorPeriodSalesPercent: 'percent',
  pAndLPriorPeriodCompare: 'price',
  pAndLPriorPeriodComparePercent: 'percent',
  edit: 'button',
  add: 'button',
} as const

const usePAndL = (): IApiType => {
  const { variables } = useVariables()
  const glData = variables.manageGls.data
  const glCodes = glData?.listGlCodes?.nodes ?? []
  const glGroups = glData?.listGlGroups?.nodes ?? []
  const draggable = Boolean(variables.pnlDrop?.onDrop)
  const { groupFilter } = useGroupFilter()
  const isLegalEntity = groupFilter?.id.indexOf('group') === 0

  const { data, yoyData, ytdData, priorYtdData, priorData, loading } =
    useLocationMetricValuesQuery({
      groupFilterTypes: ['location', 'locationGroup'],
      metricGroups: [
        'PnL',
        { key: 'PnL', type: 'yoy' },
        { key: 'PnL', type: 'ytd' },
        { key: 'PnL', type: 'prior ytd' },
        { key: 'PnL', type: 'prior' },
      ],
      fields: ['metricSummaryData'],
      handler: (variables) => {
        const newVariables = {
          ...variables,
          iFilter: {
            ...variables.iFilter,
            ...(isLegalEntity
              ? {
                  location_group_ids: groupFilter?.ids,
                }
              : {
                  location_ids: groupFilter?.ids,
                }),
          },
        }

        return {
          variables: newVariables,
          skip:
            !groupFilter ||
            !newVariables.iStartDate ||
            !newVariables.iEndDate ||
            (newVariables.iFilter.metric_groups || []).length === 0,
        }
      },
    })

  const metricData =
    data?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}
  const yoyMetricData =
    yoyData?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}
  const ytdMetricData =
    ytdData?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}
  const lyYtdMetricData =
    priorYtdData?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}
  const priorPeriodMetricData =
    priorData?.filter(({ metricSummaryData }) => metricSummaryData)?.[0]
      ?.metricSummaryData ?? {}

  return {
    data: useMemo(() => {
      if (!metricData) return null
      const getSalesForGlGroup = (glGroupId: string) => {
        const glNamesInGroup = glCodes
          .filter(({ parentGroupId }) => parentGroupId === glGroupId)
          .map(({ name }) => name)
        const pAndLSales = Object.values(metricData)
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)
        const pAndLYoySales = Object.values(yoyMetricData)
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)
        const pAndLYoyCompare = calc(pAndLSales, '-', pAndLYoySales)
        const pAndLYtdSales = Object.values(ytdMetricData ?? {})
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)

        const pAndLLYYtdSales = Object.values(lyYtdMetricData ?? {})
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)
        const pAndLLYYtdCompare = calc(pAndLYtdSales, '-', pAndLLYYtdSales)

        const pAndLPriorPeriodSales = Object.values(priorPeriodMetricData ?? {})
          .filter(({ name }) => glNamesInGroup.includes(name))
          .reduce((acc, cur) => acc + cur.value, 0)
        const pAndLPriorPeriodCompare = calc(
          pAndLSales,
          '-',
          pAndLPriorPeriodSales,
        )

        const glGroupsInGroup = glGroups
          .filter(({ parentGroupId }) => parentGroupId === glGroupId)
          .map(({ id }) => id)
        if (glGroupsInGroup.length === 0) {
          return {
            pAndLSales,
            pAndLYoySales,
            pAndLYoyCompare,
            pAndLYtdSales,
            pAndLLYYtdSales,
            pAndLLYYtdCompare,
            pAndLPriorPeriodSales,
            pAndLPriorPeriodCompare,
          }
        }

        let totalSubSales: CalcType = 0
        let totalSubYoySales: CalcType = 0
        let totalSubYoyCompare: CalcType = 0
        let totalSubYtdSales: CalcType = 0
        let totalSubLYYtdSales: CalcType = 0
        let totalSubLYYtdCompare: CalcType = 0
        let totalSubPriorPeriodSales: CalcType = 0
        let totalSubPriorPeriodCompare: CalcType = 0
        for (const glSubgroupId of glGroupsInGroup) {
          const {
            pAndLSales: subSales,
            pAndLYoySales: subYoySales,
            pAndLYoyCompare: subYoyCompare,
            pAndLYtdSales: subYtdSales,
            pAndLLYYtdSales: subLYYtdSales,
            pAndLLYYtdCompare: subLYYtdCompare,
            pAndLPriorPeriodSales: subPriorPeriodSales,
            pAndLPriorPeriodCompare: subPriorPeriodCompare,
          } = getSalesForGlGroup(glSubgroupId)
          totalSubSales = calc(totalSubSales, '+', subSales)
          totalSubYoySales = calc(totalSubYoySales, '+', subYoySales)
          totalSubYoyCompare = calc(totalSubYoyCompare, '+', subYoyCompare)
          totalSubYtdSales = calc(totalSubYtdSales, '+', subYtdSales)
          totalSubLYYtdSales = calc(totalSubLYYtdSales, '+', subLYYtdSales)
          totalSubLYYtdCompare = calc(
            totalSubLYYtdCompare,
            '+',
            subLYYtdCompare,
          )
          totalSubPriorPeriodSales = calc(
            totalSubPriorPeriodSales,
            '+',
            subPriorPeriodSales,
          )
          totalSubPriorPeriodCompare = calc(
            totalSubPriorPeriodCompare,
            '+',
            subPriorPeriodCompare,
          )
        }

        return {
          pAndLSales: calc(pAndLSales, '+', totalSubSales),
          pAndLYoySales: calc(pAndLYoySales, '+', totalSubYoySales),
          pAndLYoyCompare: calc(pAndLYoyCompare, '+', totalSubYoyCompare),
          pAndLYtdSales: calc(pAndLYtdSales, '+', totalSubYtdSales),
          pAndLLYYtdSales: calc(pAndLLYYtdSales, '+', totalSubLYYtdSales),
          pAndLLYYtdCompare: calc(pAndLLYYtdCompare, '+', totalSubLYYtdCompare),
          pAndLPriorPeriodSales: calc(
            pAndLPriorPeriodSales,
            '+',
            totalSubPriorPeriodSales,
          ),
          pAndLPriorPeriodCompare: calc(
            pAndLPriorPeriodCompare,
            '+',
            totalSubPriorPeriodCompare,
          ),
        }
      }

      const groupRows: IRowType[] = []
      const salesGlGroup =
        glGroups.filter(({ exrayCategory }) => exrayCategory === 'Sales')[0] ??
        {}
      const {
        pAndLSales: totalSales,
        pAndLYoySales: yoyTotalSales,
        pAndLYtdSales: ytdTotalSales,
        pAndLLYYtdSales: lyYtdTotalSales,
        pAndLPriorPeriodSales: priorPeriodTotalSales,
      } = getSalesForGlGroup(salesGlGroup.id)

      for (const glGroup of glGroups) {
        const {
          pAndLSales,
          pAndLYoySales,
          pAndLYoyCompare,
          pAndLYtdSales,
          pAndLLYYtdSales,
          pAndLLYYtdCompare,
          pAndLPriorPeriodSales,
          pAndLPriorPeriodCompare,
        } = getSalesForGlGroup(glGroup.id)

        groupRows.push({
          id: `folder-${glGroup.id}`,
          parentId:
            glGroup.parentGroupId === null
              ? 'root'
              : `folder-${glGroup.parentGroupId}`,
          pAndLName: glGroup.name,
          pAndLSales,
          pAndLSalesPercent: calc(pAndLSales, 'percentageOf', totalSales),
          pAndLYoySales,
          pAndLYoySalesPercent: calc(
            pAndLYoySales,
            'percentageOf',
            yoyTotalSales,
          ),
          pAndLYoyCompare,
          pAndLYoyComparePercent: calc(
            pAndLYoyCompare,
            'percentageOf',
            Math.abs(pAndLYoySales as number),
          ),
          pAndLYtdSales,
          pAndLYtdSalesPercent: calc(
            pAndLYtdSales,
            'percentageOf',
            ytdTotalSales,
          ),
          pAndLLYYtdSales,
          pAndLLYYtdSalesPercent: calc(
            pAndLLYYtdSales,
            'percentageOf',
            lyYtdTotalSales,
          ),
          pAndLLYYtdCompare,
          pAndLLYYtdComparePercent: calc(
            pAndLLYYtdCompare,
            'percentageOf',
            Math.abs(pAndLLYYtdSales as number),
          ),
          pAndLPriorPeriodSales,
          pAndLPriorPeriodSalesPercent: calc(
            pAndLPriorPeriodSales,
            'percentageOf',
            priorPeriodTotalSales,
          ),
          pAndLPriorPeriodCompare,
          pAndLPriorPeriodComparePercent: calc(
            pAndLPriorPeriodCompare,
            'percentageOf',
            Math.abs(pAndLPriorPeriodSales as number),
          ),
          edit: 'EDIT',
          add: 'ADD',
          displayOrder: glGroup.displayOrder,
          isFrozen: glGroup.exrayCategory === 'Net Profit',
        } as IRowType)
      }

      const itemRows = []
      for (const glCode of glCodes) {
        const metric = Object.values(metricData).find(
          ({ name }) => glCode.name === name,
        )
        const yoyMetric = Object.values(yoyMetricData).find(
          ({ name }) => glCode.name === name,
        )
        const ytdMetric = Object.values(ytdMetricData ?? {}).find(
          ({ name }) => glCode.name === name,
        )
        const lyYtdMetric = Object.values(lyYtdMetricData ?? {}).find(
          ({ name }) => glCode.name === name,
        )
        const priorPeriodMetric = Object.values(
          priorPeriodMetricData ?? {},
        ).find(({ name }) => glCode.name === name)

        const item: IRowType = {
          id: `item-${glCode.id}`,
          parentId: `folder-${glCode.parentGroupId}`,
          disableSubRows: true,
          pAndLName: `${glCode.code} - ${glCode.name}`,
          pAndLGlCode: glCode.code,
          pAndLSales: metric?.value ?? null,
          pAndLSalesPercent: calc(metric?.value, 'percentageOf', totalSales),
          pAndLYoySales: yoyMetric?.value ?? null,
          pAndLYoySalesPercent: calc(
            yoyMetric?.value,
            'percentageOf',
            yoyTotalSales,
          ),
          pAndLYoyCompare: calc(metric?.value, '-', yoyMetric?.value),
          pAndLYoyComparePercent: calc(
            calc(metric?.value, '-', yoyMetric?.value),
            'percentageOf',
            Math.abs(yoyMetric?.value as number),
          ),
          pAndLYtdSales: ytdMetric?.value ?? null,
          pAndLYtdSalesPercent: calc(
            ytdMetric?.value,
            'percentageOf',
            ytdTotalSales,
          ),
          pAndLLYYtdSales: lyYtdMetric?.value ?? null,
          pAndLLYYtdSalesPercent: calc(
            lyYtdMetric?.value,
            'percentageOf',
            lyYtdTotalSales,
          ),
          pAndLLYYtdCompare: calc(ytdMetric?.value, '-', lyYtdMetric?.value),
          pAndLLYYtdComparePercent: calc(
            calc(ytdMetric?.value, '-', lyYtdMetric?.value),
            'percentageOf',
            Math.abs(lyYtdMetric?.value as number),
          ),
          pAndLPriorPeriodSales: priorPeriodMetric?.value ?? null,
          pAndLPriorPeriodSalesPercent: calc(
            priorPeriodMetric?.value,
            'percentageOf',
            priorPeriodTotalSales,
          ),
          pAndLPriorPeriodCompare: calc(
            metric?.value,
            '-',
            priorPeriodMetric?.value,
          ),
          pAndLPriorPeriodComparePercent: calc(
            calc(metric?.value, '-', priorPeriodMetric?.value),
            'percentageOf',
            Math.abs(priorPeriodMetric?.value as number),
          ),
          displayOrder: glCode.displayOrder,
        }

        if (item.pAndLSales || draggable) itemRows.push(item)
      }

      const rows = [...itemRows, ...groupRows].sort((a, b) => {
        if (a.displayOrder && b.displayOrder) {
          return a.displayOrder - b.displayOrder
        }

        if (
          !a.displayOrder &&
          !b.displayOrder &&
          a.pAndLGlCode &&
          b.pAndLGlCode
        ) {
          return a.pAndLGlCode.localeCompare(b.pAndLGlCode)
        }

        if (!a.displayOrder) {
          return -1
        }

        if (!b.displayOrder) {
          return 1
        }

        return -1
      })

      const netProfitGlGroup =
        glGroups.filter(
          ({ exrayCategory }) => exrayCategory === 'Net Profit',
        )[0] ?? {}
      const {
        pAndLSales,
        pAndLYoySales,
        pAndLYoyCompare,
        pAndLYtdSales,
        pAndLLYYtdSales,
        pAndLLYYtdCompare,
        pAndLPriorPeriodSales,
        pAndLPriorPeriodCompare,
      } = getSalesForGlGroup(netProfitGlGroup.id)
      rows.push({
        id: 'netProfit',
        parentId: 'root',
        pAndLName: 'Net Profit',
        pAndLSales,
        pAndLSalesPercent: calc(pAndLSales, 'percentageOf', totalSales),
        pAndLYoySales,
        pAndLYoySalesPercent: calc(
          pAndLYoySales,
          'percentageOf',
          yoyTotalSales,
        ),
        pAndLYoyCompare,
        pAndLYoyComparePercent: calc(
          pAndLYoyCompare,
          'percentageOf',
          Math.abs(pAndLYoySales as number),
        ),
        pAndLYtdSales,
        pAndLYtdSalesPercent: calc(
          pAndLYtdSales,
          'percentageOf',
          ytdTotalSales,
        ),
        pAndLLYYtdSales,
        pAndLLYYtdSalesPercent: calc(
          pAndLLYYtdSales,
          'percentageOf',
          lyYtdTotalSales,
        ),
        pAndLLYYtdCompare,
        pAndLLYYtdComparePercent: calc(
          pAndLLYYtdCompare,
          'percentageOf',
          Math.abs(pAndLLYYtdSales as number),
        ),
        pAndLPriorPeriodSales,
        pAndLPriorPeriodSalesPercent: calc(
          pAndLPriorPeriodSales,
          'percentageOf',
          priorPeriodTotalSales,
        ),
        pAndLPriorPeriodCompare,
        pAndLPriorPeriodComparePercent: calc(
          pAndLPriorPeriodCompare,
          'percentageOf',
          Math.abs(pAndLPriorPeriodSales as number),
        ),
        isFrozen: true,
      } as IRowType)
      return rows
    }, [data, yoyData, ytdData, priorYtdData, variables.manageGls, draggable]),
    loading: loading || Boolean(variables.pnlDrop?.loading),
  }
}

export default usePAndL
