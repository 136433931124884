import { IRouteType } from '../../types'
import irmgBkEodReportConfig from './irmgBkEodReport'
import irmgBkHome from './irmgBkHome'
import irmgBkPlaidPageConfig from './irmgBkPlaidPageConfig'
import originalCorporatePages from './originalCorporatePages'

export const irmgBkRoutes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        useOriginal: true,
      },
      {
        title: 'EOD Report',
        link: '/:brand/eod_report',
      },
      {
        title: 'Plaid',
        link: '/:brand/plaid',
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
    ],
  },
]

export default [
  irmgBkPlaidPageConfig,
  originalCorporatePages,
  irmgBkHome,
  irmgBkEodReportConfig,
]
