import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { groupFilterTableConfigs, useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricValuesHook from '../utils/v2/buildMetricValuesHook'

export const burgerworksSystemwideOverviewConfigs = {
  ...groupFilterTableConfigs,
  netSales: 'price',
  laborTotalCostPercent: 'percent',
  averageWeeklySales: 'price',
  actualCogsPercent: 'percent',
} as const

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
  }
>

type IDataType<
  T extends string =
    | 'listLocationGroupAverageWeeklySales'
    | 'listLocationAverageWeeklySales',
> = Record<
  T,
  {
    nodes: ((T extends 'listLocationGroupAverageWeeklySales'
      ? {
          locationGroupId: number
        }
      : {
          locationId: number
        }) &
      Record<'metricData', IMetricDataType>)[]
  }
>

const query = gql`
  query ListAverageWeeklySales(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isLocationGroup: Boolean!
  ) {
    listLocationGroupAverageWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isLocationGroup) {
      nodes {
        locationGroupId
        metricData
      }
    }

    listLocationAverageWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isLocationGroup) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

const useCommonBurgerworksSystemwideOverview = buildMetricValuesHook({
  groupFilterTypes: ['listLocationGroup', 'listLocation'],
  metrics: ['net_sales', 'labor_total_cost_percent', 'actual_cogs_percent'],
})

const useBurgerworksSystemwideOverview = () => {
  const { data: commonData, loading: commonLoading } =
    useCommonBurgerworksSystemwideOverview()
  const { variables } = useVariables()
  const dateFilterInfo = variables.date?.getInfo(0)
  const groupFilterInfo = variables.groupFilter?.getInfo([
    'listLocationGroup',
    'listLocation',
  ])
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: dateFilterInfo?.dateRange.startDateStr,
      iEndDate: dateFilterInfo?.dateRange.endDateStr,
      iFilter: {
        location_group_ids: groupFilterInfo?.locationGroupIds,
        metrics: ['net_sales'],
      },
      isLocationGroup: groupFilterInfo?.type === 'listLocationGroup',
    },
    skip: !dateFilterInfo || !groupFilterInfo,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return commonData

      const averageWeeklySalesNodes = (
        data?.listLocationGroupAverageWeeklySales ||
        data?.listLocationAverageWeeklySales
      )?.nodes

      const newData = commonData.map((n) => {
        const id = n.id
        const averageWeeklySalesN = averageWeeklySalesNodes?.find((an) =>
          'locationId' in an
            ? an.locationId.toString() === id
            : an.locationGroupId.toString() === id,
        )

        const averageWeeklySales =
          averageWeeklySalesN?.metricData.averageWeeklySales.value

        return {
          ...n,
          averageWeeklySales: averageWeeklySales
            ? averageWeeklySales * 100
            : null,
        }
      })

      return newData
    }, [commonData, data]),
    loading: commonLoading || loading,
  }
}

export default useBurgerworksSystemwideOverview
