import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

interface IDataType {
  listLocationMetricValues: {
    nodes: {
      locationId: number
      metricData: Record<
        string,
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      locationGroups: {
        id: number
        name: string
        type: string
      }[]
    }[]
  }
}

type Metric = {
  name: string
  value: string
  budget: string
}

const query = gql`
  query listLocationMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        locationId
        metricData
      }
    }

    listLocationDetails(iFilter: { location_group_ids: $iLocationGroupIds }) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }
  }
`

export const bbbGroupPnLConfigs = {
  variance: 'price',
  variance_pct: 'percent',
} as const

const pnlMetricsSales: Metric[] = [
  {
    name: 'Total Sales',
    value: 'total_sales',
    budget: 'total_sales_budget',
  },
]

const pnlMetricsFnp: Metric[] = [
  {
    name: 'Total Food and Paper',
    value: 'total_food_and_paper',
    budget: 'total_food_and_paper_budget',
  },
]

const pnlMetricsLabor: Metric[] = [
  { name: 'Total Labor', value: 'total_labor', budget: 'total_labor_budget' },
]

const pnlMetricsCtrlExpenses: Metric[] = [
  {
    name: 'Total Controllable Expenses',
    value:
      'total_controllable_expenses_without_bank_charges_and_third_party_fees',
    budget:
      'total_controllable_expenses_without_bank_charges_and_third_party_fees_budget',
  },
]

const createBbbGroupPnlHook = (pnlMetrics: Metric[]) => {
  const useBbbGroupPnl = () => {
    const { startDate, endDate } = useDateFilter()
    const { groupFilter } = useGroupFilter()

    const metrics = pnlMetrics.map((metric) => metric.value)
    const budgetMetrics = pnlMetrics.map((metric) => metric.budget)

    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iLocationGroupIds: groupFilter?.ids,
        iMetricCodes: [...metrics, ...budgetMetrics],
      },
      skip: !startDate || !endDate || !groupFilter?.ids,
    })

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const pnlVales = data.listLocationMetricValues.nodes
        const listLocationDetails = data.listLocationDetails.nodes

        let source = pnlVales.map((m) => {
          const metric = pnlMetrics[0]

          let variance =
            m.metricData[metric.budget]?.value -
            m.metricData[metric.value]?.value

          let variance_pct =
            m.metricData[metric.budget]?.value === 0 ||
            m.metricData[metric.budget]?.value === null
              ? null
              : ((m.metricData[metric.budget]?.value -
                  m.metricData[metric.value]?.value) /
                  m.metricData[metric.budget]?.value) *
                100

          if (pnlMetrics === pnlMetricsSales) {
            variance *= -1
            if (variance_pct !== null) {
              variance_pct *= -1
            }
          }

          const groupInfo = groupFilter?.list?.find(
            (l) => l.id === m.locationId,
          )
          const locationDetail = listLocationDetails.find(
            (l) => l.id === m.locationId,
          )

          return {
            ...metric,
            groupInfo: groupInfo && {
              ...groupInfo,
              link: groupInfo?.link?.replace(
                /store_detail/,
                'p-and-l-store-opportunities',
              ),
              groupBy: groupInfo.groupBy && {
                ...groupInfo.groupBy,
                header: 'Region',
                label: locationDetail?.locationGroups.find(
                  (lg) => lg.type === 'Region',
                )?.name,
              },
            },
            variance,
            variance_pct,
          }
        })

        source = source
          .filter((item) => item.variance < 0)
          .sort((a, b) => a.variance - b.variance)

        return {
          source: source,
        }
      }, [data]),
      loading,
    }
  }
  return useBbbGroupPnl
}

export const useBbbGroupSales = createBbbGroupPnlHook(pnlMetricsSales)
export const useBbbGroupFnp = createBbbGroupPnlHook(pnlMetricsFnp)
export const useBbbGroupLabor = createBbbGroupPnlHook(pnlMetricsLabor)
export const useBbbGroupCtrlExpenses = createBbbGroupPnlHook(
  pnlMetricsCtrlExpenses,
)
