import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_FORMAT } from 'pared/data/getDateRanges'

import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'
import { useCorporateExpoAiLossPreventionSummaryFormat2 } from './useCorporateExpoAiLossPreventionSummary'

interface ILossPreventionOutliersType {
  listEmployeesLossPreventionOutliers: {
    nodes: {
      locationId: number
      employeeName: string
      outliersType: string
      outliersValue: number
      outliersTime: string
      checkNumber: string
    }[]
  }
}

const query = gql`
  query ListFarwestLossPreventionOutliers(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listEmployeesLossPreventionOutliers(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        employeeName
        outliersType
        outliersValue
        outliersTime
        checkNumber
      }
    }
  }
`

export const farwestCorporateExpoAiLossPreventionSummaryConfigs = {
  storeCount: 'string',
  issueType: 'string',
  annualOpportunityCost: 'price',
} as const

const useFarwestCorporateExpoAiLossPreventionSummaryFormat = () => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()

  const { data: corperateSummaryData, loading: corperateSummaryLoading } =
    useCorporateExpoAiLossPreventionSummaryFormat2()
  const { data: outliersData, loading: outliersLoading } =
    useQuery<ILossPreventionOutliersType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_group_ids: groupFilter?.ids,
        },
      },
      skip: !startDate || !endDate || !groupFilter,
    })

  return {
    data: useMemo((): IApiDataType => {
      const outlier = outliersData?.listEmployeesLossPreventionOutliers?.nodes
      if (!corperateSummaryData || !outlier) return null

      const discountStoreList = Array.from(
        new Set(
          outlier.map((d) => {
            if (d.outliersType === 'discount') return d.locationId
          }),
        ),
      )

      const voidStoreList = Array.from(
        new Set(
          outlier.map((d) => {
            if (d.outliersType === 'void') return d.locationId
          }),
        ),
      )

      return {
        source: [
          ...corperateSummaryData.source,
          {
            type: 'discountOutliers',
            issueType: 'Discounts >$50',
            storeCount: `${discountStoreList.length} stores`,
            annualOpportunityCost: null,
          },
          {
            type: 'voidOutliers',
            issueType: 'Void >$50',
            storeCount: `${voidStoreList.length} stores`,
            annualOpportunityCost: null,
          },
        ],
      }
    }, [corperateSummaryData, outliersData, groupFilter]),
    loading: corperateSummaryLoading || outliersLoading,
  }
}

export default useFarwestCorporateExpoAiLossPreventionSummaryFormat
