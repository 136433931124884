import useFwWingstopListLocationGroupLossPreventionKpis, {
  fwWingstopListLocationGroupLossPreventionKpisConfigs,
} from './useListLocationGroupLossPreventionKpis'
import useFwWingstopListLocationGuestKpis, {
  fwWingstopListLocationGuestKpisConfigs,
} from './useListLocationGuestKpis'
import useFwWingstopListLocationLossPreventionKpis, {
  fwWingstopListLocationLossPreventionKpisConfigs,
} from './useListLocationLossPreventionKpis'
import useFwWingstopSystemwideOverview, {
  fwWingstopSystemwideOverviewConfigs,
} from './useListSystemwideOverview'

export const configs = {
  fwWingstopListLocationGroupLossPreventionKpis:
    fwWingstopListLocationGroupLossPreventionKpisConfigs,
  fwWingstopListLocationLossPreventionKpis:
    fwWingstopListLocationLossPreventionKpisConfigs,
  fwWingstopListLocationGuestKpis: fwWingstopListLocationGuestKpisConfigs,
  fwWingstopSystemwideOverview: fwWingstopSystemwideOverviewConfigs,
}

export const api = {
  fwWingstopListLocationGroupLossPreventionKpis:
    useFwWingstopListLocationGroupLossPreventionKpis,
  fwWingstopListLocationLossPreventionKpis:
    useFwWingstopListLocationLossPreventionKpis,
  fwWingstopListLocationGuestKpis: useFwWingstopListLocationGuestKpis,
  fwWingstopSystemwideOverview: useFwWingstopSystemwideOverview,
}
