import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const burgerworksListLocationGroupLossPreventionKpisConfigs = {
  discountCompsTotal: 'price',
  discountCheckCountTotal: 'number',
  lossPreventionCompsDeletes: 'price',
  lossPreventionCompsOverrings: 'price',
  lossPreventionCompsRefunds: 'price',
  lossPreventionCompsVoids: 'price',
  lossPreventionCompsCashOverShort: 'price',
} as const

const useBurgerworksListLocationGroupLossPreventionKpis = buildMetricValuesHook(
  {
    groupFilterType: 'corporate',
    metrics: {
      codes: ['discount_comps_total', 'discount_check_count_total'],
      groups: ['Expo Module - Loss Prevention Comps'],
    },
  },
)

export default useBurgerworksListLocationGroupLossPreventionKpis
