import * as d3 from 'd3-scale'
import { useMemo } from 'react'

import { IApiDataType, INestedDataType } from '../../../types'
import buildLocationEmployeeMetricValuesHook from '../../utils/buildLocationEmployeeMetricValuesHook'

const commonLossPreventionDataConfigs = {
  checkCount: 'number',
  lossPreventionOverringCheckCount: 'number',
  lossPreventionOverringAmount: 'price',
  lossPreventionDeleteCheckCount: 'number',
  lossPreventionDeleteAmount: 'price',
  lossPreventionVoidCheckCount: 'number',
  lossPreventionVoidAmount: 'price',
  lossPreventionRefundCheckCount: 'number',
  lossPreventionRefundAmount: 'price',
  lossPreventionDeleteCheckCountPercent: 'percent',
  lossPreventionVoidCheckCountPercent: 'percent',
  lossPreventionOverringCheckCountPercent: 'percent',
  lossPreventionRefundCheckCountPercent: 'percent',
} as const

export const dhWbListEmployeeLossPreventionDataConfigs = {
  ...commonLossPreventionDataConfigs,
  employeeName: 'string',
} as const

const useCommonEmployeeDiscountData = buildLocationEmployeeMetricValuesHook({
  codes: ['check_count'],
  groups: [
    'Expo Module - Loss Prevention Comps',
    'Expo Module - Loss Prevention Check Count',
    'Expo Module - Loss Prevention Check Count Percent',
  ],
})

const heatMapKeys = [
  'lossPreventionDeleteCheckCountPercent',
  'lossPreventionVoidCheckCountPercent',
  'lossPreventionOverringCheckCountPercent',
  'lossPreventionRefundCheckCountPercent',
]

const useListEmployeeLossPreventionData = () => {
  const { data: commonData, loading } = useCommonEmployeeDiscountData()

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return null
      if (commonData.length === 0) return []

      const scalers = heatMapKeys.reduce((result, code) => {
        const min = Math.min(...commonData.map((n) => n[code] as number))
        const max = Math.max(...commonData.map((n) => n[code] as number))
        return {
          ...result,
          [code]: d3.scaleLinear([0, 100], [min, max]),
        }
      }, {} as { [key: string]: d3.ScaleLinear<number, number> })

      const calculateHeatMapValue = (data: INestedDataType) =>
        heatMapKeys.reduce(
          (res, key) => ({
            ...res,
            [`${key}Heatmap`]: scalers[key].invert(data[key] as number),
          }),
          data,
        )

      const newData = commonData.map(calculateHeatMapValue)

      return newData
    }, [commonData, loading]),
    loading: loading,
  }
}

export default useListEmployeeLossPreventionData
