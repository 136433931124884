import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import useFarwestPmixSubcategoryData from './useFarwestPmixSubcategoryData'
import useTrendUnifiedData from './useTrendUnifiedData'
import useUnifiedData from './useUnifiedData'

export const farwestPmixItemDataConfigs = {
  categoryName: 'string',
  subcategoryName: 'string',
  itemName: 'string',
  totalSales: 'price',
  quantitySold: 'number',
  salesPercentage: 'percent',

  currentWeekEndDate: 'date-string',
  salesPercentageWeek1InPast: 'percent',
  salesPercentageWeek2InPast: 'percent',
  salesPercentageWeek3InPast: 'percent',
  salesPercentageWeek4InPast: 'percent',

  quantitySoldWeek1InPast: 'number',
  quantitySoldWeek2InPast: 'number',
  quantitySoldWeek3InPast: 'number',
  quantitySoldWeek4InPast: 'number',

  totalSalesWeek1InPast: 'price',
  totalSalesWeek2InPast: 'price',
  totalSalesWeek3InPast: 'price',
  totalSalesWeek4InPast: 'price',
} as const

const useFarwestPmixItemData = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data: subcategoryPmixData, loading: subcategoryLoading } =
    useFarwestPmixSubcategoryData()
  const {
    variables: { isTrend },
  } = useVariables()

  const subcategoryCodeToDetailsMap: {
    [subcategoryCode: string]: {
      subcategoryName: string
      subcategoryCode: string
      categoryName: string
      categoryCode: string
    }
  } = {}

  if (subcategoryPmixData && subcategoryPmixData.source) {
    subcategoryPmixData.source.forEach((rowData) => {
      if (rowData?.subcategoryCode) {
        subcategoryCodeToDetailsMap[rowData.subcategoryCode as string] = {
          subcategoryName: rowData.subcategoryName as string,
          subcategoryCode: rowData.subcategoryCode as string,
          categoryName: rowData.categoryName as string,
          categoryCode: rowData.categoryCode as string,
        }
      }
    })
  }

  const { data: trendData, loading: trendLoading } = useTrendUnifiedData({
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: 'ITEM',
      iFilter: {
        location_group_ids: groupFilter?.ids,
        intersected_location_group_ids: groupFilter?.intersectedIds,
        item_category_codes: ['EXPO_MENU_CATEGORY_ROOT'],
        metrics: [
          'total_sales',
          'quantity_sold',
          'sales_percentage',
          'location_total_sales',
        ],
      },
    },
    skip: !isTrend || !endDate || !groupFilter,
  })

  const { data: itemData, loading: itemDataLoading } = useUnifiedData({
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: 'ITEM',
      iFilter: {
        location_group_ids: groupFilter?.ids,
        intersected_location_group_ids: groupFilter?.intersectedIds,
        item_category_codes: ['EXPO_MENU_CATEGORY_ROOT'],
        metrics: [
          'total_sales',
          'quantity_sold',
          'sales_percentage',
          'location_total_sales',
        ],
      },
    },
    skip:
      isTrend ||
      !subcategoryPmixData ||
      !startDate ||
      !endDate ||
      !groupFilter ||
      !hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const source = (isTrend ? trendData : itemData)?.source?.reduce(
        (s: any, r: any) => {
          const subcategoryDetails =
            subcategoryCodeToDetailsMap[r?.displayParentCategoryCode]

          return !subcategoryDetails
            ? s
            : [
                ...s,
                {
                  ...r,
                  itemName: r.itemName,
                  itemCode: r.itemCode,
                  subcategoryName: subcategoryDetails.subcategoryName,
                  subcategoryCode: subcategoryDetails.subcategoryCode,
                  categoryName: subcategoryDetails.categoryName,
                  categoryCode: subcategoryDetails.categoryCode,
                },
              ]
        },
        [] as { [key: string]: unknown }[],
      )

      if (!source) return null

      return {
        source: _.orderBy(source, [
          'categoryName',
          'subcategoryName',
          'itemName',
        ]),
        summary: (isTrend ? trendData : itemData)?.summary,
      }
    }, [itemData, subcategoryPmixData, trendData]),
    loading: subcategoryLoading || itemDataLoading || trendLoading,
  }
}

export default useFarwestPmixItemData
